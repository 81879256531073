import { UIDataState } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { IUIWebcamStream, KSpaceUserId } from '@openteam/models';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { DSPanel, DSRow } from '../../DesignSystem/DSLayout';
import CallRoomTile from './CallRoomTile';

const logger = new Logger('CallRoomContent');

type Props = {};

type TStreamType = 'camera' | 'screen';

const minTileSize = 100;

const CallRoomContent: React.FC<Props> = ({ }) => {
  const tileAr = 1;

  const { width = 0, height = 0, ref } = useResizeObserver();
  const [layout, setLayout] = useState({ rows: 1, columns: 1 });
  const [rowHeight, setRowHeight] = useState(0);
  const [focusedHeight, setFocusedHeight] = useState(0);
  const [participantTiles, setParticipantTiles] = useState<KSpaceUserId[]>([]);
  const [focusedTile, setFocusedTile] = useState<{ userId: string, streamType: TStreamType }>();
  const [focusAspectRatio, setFocusAspectRatio] = useState<number>(1);
  const userScreenShares = useRef<Record<KSpaceUserId, boolean>>({});


  const streams = Object.keys(UIDataState.activeCall?.participants || {}).map((userId) => Object.values(UIDataState.activeCall!.participants[userId].streams))


  useEffect(() => {
    logger.debug("UIDataState.activeCall?.screenShares", UIDataState.activeCall?.screenShares)

    for (const userId in UIDataState.activeCall?.screenShares) {

      if (!(userId in userScreenShares.current)) {
        setFocusedTile({ userId, streamType: 'screen' })
        userScreenShares.current[userId] = true
      }
    }

    for (const userId in userScreenShares.current) {
      if ((userId in (UIDataState.activeCall?.screenShares || {}))) {
        delete userScreenShares.current[userId]
      }
    }

  }, [UIDataState.activeCall?.screenShares, streams])

  useEffect(() => {
    logger.debug("checking focusedTile", focusedTile, streams)

    if (focusedTile) {

      const call = UIDataState.activeCall;

      const callUser = call?.participants[focusedTile.userId];
      const stream = callUser?.streams[focusedTile.streamType] as IUIWebcamStream;

      if (!stream) {
        setFocusedTile(undefined)
      }
    }
  }, [focusedTile, streams])


  useEffect(() => {
    logger.debug(`width: ${width}, height: ${height}`);
  }, [width, height]);


  useEffect(() => {
    if (width && height) {

      let remWidth = width
      let remHeight = height


      if (focusedTile) {

        const call = UIDataState.activeCall;

        const callUser = call?.participants[focusedTile.userId];
        const stream = callUser?.streams[focusedTile.streamType] as IUIWebcamStream;
        let focusTileAspectRatio: number = 1

        if (stream && focusedTile.streamType === 'screen') {
          const videoTrackSettings = stream.videoTrack?.getSettings();

          if (videoTrackSettings?.aspectRatio) {
            focusTileAspectRatio = videoTrackSettings?.aspectRatio;
          }
        }

        if (focusTileAspectRatio && focusAspectRatio != focusTileAspectRatio) {
          logger.debug("setFocusAspectRatio(focusTileAspectRatio)", focusTileAspectRatio)
          setFocusAspectRatio(focusTileAspectRatio)

        }

        let focusHeight = height - 16
        let focusWidth = width - minTileSize

        if (focusHeight * focusTileAspectRatio > focusWidth) {

          focusHeight = focusWidth / focusTileAspectRatio

        } else {
          focusWidth = focusHeight * focusTileAspectRatio
        }

        setFocusedHeight(focusHeight)


        // logger.debug("setFocusedHeight", Math.min(height, width - minTileSize))
        logger.debug("focusHeight", focusHeight, "focusWidth", focusWidth, "focusedTile", focusedTile, "participantTiles", participantTiles)

        remWidth = width - focusWidth

      }

      const numTiles = participantTiles.length;

      let [rows, columns] = [1, numTiles];

      while (Math.round(remWidth / columns / tileAr) < Math.round(remHeight / (rows + 1))) {
        rows += 1;
        columns = Math.ceil(numTiles / rows);
      }
      const rowHeight = Math.min(remWidth / columns / tileAr, remHeight / rows, 1024);

      logger.debug(`Recalc: ${remWidth}x${remHeight}, ${numTiles} tiles, rowHeight ${rowHeight}, layout ${rows},${columns}`);
      setLayout({ rows, columns });
      setRowHeight(rowHeight);
    }
  }, [participantTiles, width, height, focusedTile]);


  useEffect(
    () =>
      autorun(() => {
        if (UIDataState.activeCall) {
          const tiles = [...UIDataState.activeCall!.participantList];
          if (!UIDataState.activeCall?.minimizeSelf || UIDataState.activeCall?.sharingScreen) {
            tiles.unshift(UIDataState.activeCall?.myUserId);
          }
          setParticipantTiles(tiles);
        }
      }),
    []
  );

  const tileSize = rowHeight - 12;

  return (
    <DSRow
      ref={ref}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
      }}
    >
      {
        focusedTile ?

          <CallRoomTile
            userId={focusedTile.userId}
            streamType={focusedTile.streamType}
            hovered={false}
            tileSize={focusedHeight}
            onClick={() => setFocusedTile(undefined)}
            aspectRatio={`${focusAspectRatio}`}

          />
          :
          undefined
      }
      <DSPanel
        style={{
          ...Styles.content,
          flexDirection: focusedTile ? 'column' : 'row',
          //height: layout.rows * rowHeight
          height: "100%",
          width: layout.columns * rowHeight * tileAr + participantTiles.length * 3,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {participantTiles.map((userId) => {
          let streamType: TStreamType = 'camera';
          // if (userId === UIDataState.activeCall?.myUserId && UIDataState.activeCall?.sharingScreen) {
          //   streamType = 'screen';
          // }
          if (focusedTile && focusedTile.userId == userId && focusedTile.streamType === 'camera') {
            return undefined
          }

          return (
            <div
              key={`${userId}-${streamType}`}
              style={{
                position: 'relative',
                height: rowHeight,
                aspectRatio: `${tileAr}`,
                boxSizing: 'border-box',
                padding: 3
              }}
            >
              <CallRoomTile
                userId={userId}
                streamType={streamType}
                hovered={false}
                tileSize={tileSize}
                onClick={() => setFocusedTile({ userId, streamType })}
                onScreenShareClick={() => setFocusedTile({ userId, streamType: 'screen' })}
                aspectRatio="1"
              />
            </div>
          );
        })}
      </DSPanel>
    </DSRow>
  );
};

const Styles: Record<string, CSSProperties> = {
  content: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  topIndicators: {
    position: 'absolute',
    top: '3%',
    right: '3%'
  },
  bottomIndicators: {
    position: 'absolute',
    top: '3%',
    right: '3%'
  },
  tileContainer: {}
};

export default observer(CallRoomContent);
