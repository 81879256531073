import { UIDataState } from '@openteam/app-core';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { FaPause } from 'react-icons/fa';
import { DSH2 } from '../../../DesignSystem/DSText';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import CallTileStyles from './CallTileStyles';
import HoverShade from './HoverShade';

type Props = {
  userId: string;
  tileHovered: boolean;
};

const TileHover: React.FC<Props> = ({ userId, tileHovered }) => {
  const call = UIDataState.activeCall;
  const callUser = call?.participants[userId];

  return (
    <>
      <HoverShade tileHovered={tileHovered} style={{ justifyContent: 'flex-start' }}>
        <DSH2 maxLines={2} style={Styles.title}>
          {callUser?.name}
        </DSH2>
      </HoverShade>

      {callUser?.isPaused ? (
        <div
          style={{
            ...CallTileStyles.absolute,
            ...Styles.pauseIndicator
          }}
        >
          <div style={{ backgroundColor: 'white', padding: 10, borderRadius: 999 }}>
            <FaPause color={DSTheme.WarningColor} size={20} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default observer(TileHover);

const Styles: Record<string, CSSProperties> = {
  title: {
    marginLeft: 10,
    cursor: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  pauseIndicator: {
    pointerEvents: 'none',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '7%'
  }
};
