import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Column, H3, Panel, Row, WrappedRow } from "@openteam/design-system";

import EhloLogo from '../assets/logoe.svg?component';
import { getCurrentUser, signOut } from "../Controllers/Login";
import { LoginState } from "../Controllers/UIState";
import useWindowDimensions from "./WindowDimensions";
import { DSImage } from "../DesignSystem/DSImage";



interface PageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const Page: React.FC<PageProps> = ({ children, ...props }) => {

  const { height, width } = useWindowDimensions();

  const isMobile = width <= 720

  return (
    <Panel
      style={{
        backgroundColor: "white",
        flex: 1,
        minHeight: "100vh",
        width: "100%",
        alignItems: 'center',
        ...props.style
      }}>
      <Panel style={{
        minHeight: "100vh",
        maxWidth: 1000,
        width: "100%",
      }}>
        <WrappedRow style={{ paddingLeft: 20, paddingRight: 20, marginTop: 35 }}>
          <Column style={{}}>
            <EhloLogo style={{ width: 120, height: 50 }} />
          </Column>
          <Column style={{ flex: 1, }}>
            {
              LoginState.isSignedIn && !LoginState.isAnonymous ?
                <PageHeaderLogin />
                :
                null
            }
          </Column>
        </WrappedRow>
        <Row style={{ flex: 1, justifyContent: 'center', }}>
          <Column style={{ width: "100%", minWidth: 360, maxWidth: 1000, justifyContent: 'center' }}>
            <Row style={{ flex: 1, alignItems: 'center', }}>
              <Column style={{
                padding: 20,
                flex: 2,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                {children}
              </Column>

              {
                isMobile ?
                  undefined
                  :
                  <Column style={{
                    flex: 3,
                    alignItems: 'center',
                    justifyContent: 'center'

                  }}>
                    <DSImage
                      url={
                        "https://ehlo.space/hubfs/People-3-small.png"
                      }
                      width={"90%"}
                      height={"auto"}
                      style={{
                      }}
                    />
                  </Column>

              }
            </Row>
          </Column>
        </Row>
      </Panel>
    </Panel>
  )
}

export default observer(Page)

interface PageHeaderLoginProps { }


export const PageHeaderLogin = (props: PageHeaderLoginProps) => {

  const currentUser = getCurrentUser()
  return (
    <Panel style={{ alignItems: "flex-end" }}>
      <Button
        noBadge
        onClick={signOut}
        analyticsEvent="SignOut"
        color="secondary"
      >
        Sign out
      </Button>
      <H3 > {currentUser.email} </H3>
    </Panel>

  )
}
