import React, { forwardRef, useRef, useMemo, useImperativeHandle, useState } from "react";
import { observer } from "mobx-react";
import { IMessageFile, IOTChatMessage, KSpaceChannelId, KSpaceId } from '@openteam/models';
import { OTUITree, WebFile } from '@openteam/app-core'
import { Logger } from "@openteam/app-util";
import { DataState } from "../../Data/DataState";
import { DSInput, DSTextArea } from "../../DesignSystem/DSInput";

import { DSBody, DSH5 } from "../../DesignSystem/DSText"
import { DSColumn, DSPanel, DSRow, DSWrappedRow } from "../../DesignSystem/DSLayout"
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { FaLocationArrow, FaPaperclip, FaPaperPlane, FaRegSmile, FaTimes } from "react-icons/fa";

import { EmojiPicker } from './ChatEmojiPicker';
import { GifPicker } from './ChatGifPicker';
import { ChatFileUpload } from "./ChatFileUpload";
import { ChatMessage } from "./ChatMessage";
import { MarkdownEditor, MarkdownEditorHandle } from "./MarkdownEditor";
import { useCallback } from "react";
const logger = new Logger("ChatCompose")

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

type Props = {
  spaceId: KSpaceId
  draftMessage: string
  setMessage: (value: string) => void
  draftFiles: IMessageFile[]
  addFile: (file: File) => void
  addMessageFile: (messageFile: IMessageFile) => void
  removeFile: (index: number) => void
  draftReply?: IOTChatMessage
  setReply: (message?: IOTChatMessage) => void
  userIds: string[]
  usersTyping: Record<string, { lastTyping: Date }>
  sendMessage: () => boolean
  resetDraft: () => void
}

export const ChatCompose = observer(forwardRef((props: Props, ref) => {
  const [assetPicker, setAssetPicker] = useState<undefined | string>(undefined);
  const [key, setKey] = useState(0)

  let _fileInput = React.createRef<HTMLInputElement>();
  const editorRef = React.useRef<MarkdownEditorHandle>(null)

  const space = DataState.spaces[props.spaceId]

  const initialValue = useRef(props.draftMessage);

  function reinitValue() {
    initialValue.current = ""
    setKey(key => key + 1)
  }

  const sendMessage = useCallback(() => {
    logger.debug("sending message ", props.draftMessage)

    // if (props.draftMessage || props.draftFiles.length > 0) {
      // const _chat = DataState.spaces[props.spaceId].channels[props.channelId]

    // logger.debug("sending message ", _chat.draftMessage)
    // _chat.actions.sendMessage(_chat.draftMessage, _chat.draftFiles, _chat.draftReply)
    // _chat.actions.resetDraft()
    const result = props.sendMessage()

    if (result) {
      props.resetDraft()
      reinitValue()

      if (assetPicker) {
        setAssetPicker(undefined)
      }
    }

    // }
  }, [props.draftMessage, props.draftFiles, props.sendMessage, props.resetDraft, reinitValue, setAssetPicker])


  const focus = () => {
    editorRef.current?.focus();
  };

  useImperativeHandle(ref, () => ({
    focus
  }));


  const onPaste = (
    pasteEvent: React.ClipboardEvent<HTMLDivElement>
  ) => {
    Object.keys(pasteEvent.clipboardData.files).forEach((i) => {
      let item = pasteEvent.clipboardData.files[i];

      if (acceptedImageFiles.includes(item.type)) {
        // var blob = item.getAsFile() as File;
        console.log("got pasted image,");
        props.addFile(item)
      }
    })

    return false;
  };


  const addEmoji = (emoji) => {
    editorRef.current?.insertText(emoji)
    focus();
  };

  const addGif = (title, url, size) => {
    const newFile = new WebFile({
      name: title,
      url: url,
      size: size,
      type: "image/gif"
    })
    console.log("adding gif", title, url, size, newFile)
    props.addMessageFile(newFile)
  }

  const onFiles = (files: FileList | null) => {
    if (!files) {
      return;
    }

     Object.keys(files || {}).forEach((i) => {
       let file = files[i];

       props.addFile(file)
    });
  }

  const usersTyping = Object.keys(props.usersTyping || {})

  return (
    <DSColumn style={{ position: 'relative', marginLeft: 20, marginRight: 20, marginBottom: 18, maxWidth: "100%" }}>
      <DSRow style={{ height: 20, alignItems: 'center' }}>
        {
          usersTyping.length > 0 ?
            <DSBody style={{paddingLeft: 8 }}>
              <DSBody style={{ fontWeight: 'bold' }}>
                {usersTyping.map((userId) => space.users[userId]?.name || "").join(", ")}
              </DSBody> {usersTyping.length > 1 ? "are" : "is"} typing ...
            </DSBody>
            :
            null
        }
      </DSRow>
      <DSColumn style={{
        flex: 1,
        borderRadius: 8,
        overflow: 'hidden'
      }}>
        <DSColumn
          style={{
            position: 'relative',
            backgroundColor: DSTheme.ChatComposeAltBackground,
            paddingLeft: 8,
            paddingRight: 8,
        }}>


          {assetPicker == 'emoji' ? <EmojiPicker onEmojiSelected={addEmoji} scrollHeight={190} /> : null}
          {assetPicker == 'gif' ? <GifPicker onGifSelected={addGif} /> : null}

          {
            props.draftReply ?
              <DSRow style={{
                position: 'relative',
                borderRadius: DSTheme.BaseBorderRadius,
                backgroundColor: DSTheme.ChatBodyColor,
                margin: 4,
                overflow: 'hidden'
              }}>
                <DSPanel style={{flex: 1}}>
                <ChatMessage
                  spaceId={props.spaceId}
                    message={props.draftReply}
                  isReply={true}
                />
                </DSPanel>
                <DSCircleIconButton
                  icon={<FaTimes size={10} style={{ color: 'white' }} />}
                  title="Remove reply"
                  analyticsEvent="removeReplyChatMessage"
                  onClick={() => props.setReply(undefined)}
                  style={{ padding: 6, position: 'absolute', top: 2, right: 2 }}
                />
              </DSRow>
              :
              null
          }

          <DSWrappedRow>
            {props.draftFiles.map((uf, index) => (
              <ChatFileUpload
                key={`${index}-${uf.file.name}`}
                file={uf}
                onRemove={() => props.removeFile(index)}
              />
            ))}
          </DSWrappedRow>

        </DSColumn>
        <DSRow style={{
          backgroundColor: DSTheme.ChatComposeBackground
        }} onPaste={onPaste} >

          <DSPanel style={{flex: 1}}>
            <MarkdownEditor
              key={key}
              ref={editorRef}
              initialContent={initialValue.current}
              onChange={(value) => {
                props.setMessage(value);
              }}
              onSubmit={sendMessage}
              mentionUsers={[]}
              placeholder='Start typing...'
              toolbarRight={
                <DSRow style={{ alignItems: 'flex-end', marginBottom: 3, marginRight: 12 }}>

                <DSCircleIconButton
                    style={{ marginLeft: 4, backgroundColor: assetPicker == 'emoji' ? DSTheme.EmphasisColor : DSTheme.ButtonBackgroundColor }}
                  onClick={() => setAssetPicker(assetPicker != 'emoji' ? 'emoji' : undefined)}
                  icon={
                    <FaRegSmile style={{ color: DSTheme.ButtonIconColor }} />
                  }
                  analyticsEvent="chatInputToggleEmoji"
                />
                <DSCircleIconButton
                  style={{
                      backgroundColor: assetPicker == 'gif' ? DSTheme.EmphasisColor : DSTheme.ButtonBackgroundColor,
                    padding: 0,
                    width: 30,
                    height: 30
                  }}
                  outerStyle={{
                    marginLeft: 4,
                    width: 30,
                    height: 30
                  }}
                  onClick={() => setAssetPicker(assetPicker != 'gif' ? 'gif' : undefined)}
                  icon={
                    <DSH5
                      style={{ color: DSTheme.ButtonIconColor }}>
                      GIF
                    </DSH5>
                  }
                  analyticsEvent="chatInputToggleGif"

                />
                <DSCircleIconButton
                    style={{ marginLeft: 4, backgroundColor: DSTheme.ButtonBackgroundColor }}
                  onClick={() => { _fileInput.current?.click() }}
                    icon={<FaPaperclip style={{ color: DSTheme.ButtonIconColor }} />}
                  analyticsEvent="chatInputSelectFile"
                />
                <input
                  ref={_fileInput}
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  onChange={(e) => onFiles(e.target.files)}
                />
                <DSCircleIconButton
                  onClick={sendMessage}
                  disabled={!(props.draftMessage || props.draftFiles.length > 0)}
                  style={{ marginLeft: 4, backgroundColor: (props.draftMessage || props.draftFiles.length > 0) ? DSTheme.InputButtonColor : DSTheme.SecondaryText, }}
                  icon={
                    <FaLocationArrow size={14}
                      style={{ color: (props.draftMessage || props.draftFiles.length > 0) ? DSTheme.EmphasisColor : DSTheme.MainIcon, }} />
                  }
                  analyticsEvent="sendMessage"

                />
              </DSRow>
            }
          />
          </DSPanel>
        </DSRow>
      </DSColumn>
      {
        assetPicker ?
          <DSCircleIconButton
            icon={<FaTimes size={10} style={{ color: 'white' }} />}
            title="Close"
            analyticsEvent="closeAssetPicker"
            onClick={() => setAssetPicker(undefined)}
            style={{ padding: 6, position: 'absolute', top: 8, right: 8, backgroundColor: DSTheme.ChatComposeAssetClose}}
          />
          :
          null
      }

    </DSColumn>

  )
}))
