import { action, autorun, observable, reaction, runInAction, toJS } from "mobx";
import { FireDb, TeamManagerDb } from "./fire";

import { Logger } from "@openteam/app-util";
import {
  IChannel,
  IDataState,
  IFile,
  IMediaDeviceSettings,
  IMessageFile,
  IOTChatMessage,
  IOTRoomUser,
  IOTUser,
  IPeerInfo,
  IPinnedResource,
  IPluginResource,
  ITeamRoomConfig,
  IUActiveCall,
  IUCall,
  IUCallParticipant,
  IUISpaceChannel,
  IUISpaceChatActions,
  IUISpacePod,
  IUISpacePodActions,
  IUISpaceUser,
  IUISpaceUserChat,
  KCallId,
  KSpaceChannelId,
  KSpaceId,
  KSpacePluginId,
  KSpaceUserId,
  NetworkStrength,
  TReceiverStatus,
  TStreamType,
  TUserStatus,
} from "@openteam/models";
import type { TeamManager } from "./TeamManager";
import { OTUITree } from "./OTUITree";
import type { AlertManager } from "./Alert";
import { CallRequestManager } from "./CallRequest";
import { ChatManager } from "./Chat";
import { CallStateManager } from "./CallStateManager";
import {
  HarkStreamDetails,
} from "./MediaDeviceManager";
import { applyObjectUpdate, applyObjectUpdate2 } from "./utils/applyObjectUpdate";
import { OTGlobals } from "./OTGlobals";
import { ExternalMeeting } from "./ExternalMeeting";

const logger = new Logger("UIDataState");

const EmptyObj = {}
const EmptyList = [];
const EmptyString = "";

function getInitialState() {
  return {
    spaces: {},
    deviceSettings: {},
    externalMeeting: undefined,
    actions: {
      createTeam: async (teamName) => {
        const teamId = await FireDb.createTeam(teamName)
        OTGlobals.appHomeManager?.forceLoadTeam(teamId)
        return teamId
      },
      loadExternalMeeting: async (meetToken) => {
        OTGlobals.appHomeManager?.loadExternalMeeting(meetToken)
      },
      clearExternalMeeting: () => {
        OTGlobals.appHomeManager?.clearExternalMeeting()
      }
    }
  }
}

export const UIDataState: IDataState = observable(getInitialState());

export const UIDataStateReset = () => {
  const initialState = getInitialState()
  Object.keys(initialState).forEach(key => {
    UIDataState[key] = initialState[key]
  })
}

export const writeExternalMeeting = action((externalMeeting?: ExternalMeeting) => {
  UIDataState.externalMeeting = externalMeeting ? {
    meetingToken: externalMeeting.meetingToken,
    meetingData: externalMeeting.meetingData,
    loadFailed: externalMeeting.loadFailed,
    status: externalMeeting.status,
    actions: {
      requestJoin: externalMeeting.requestJoin,
    }
  }
    :
    undefined
})

export const writeSpace = action((teamManager: TeamManager) => {
  const { teamId, teamData } = teamManager
  logger.debug(`writeSpace ${teamId}`);
  const _startAt = performance.now();

  if (!(teamId in UIDataState.spaces)) {
    UIDataState.spaces[teamId] = {
      details: {
        name: "",
        icon: "",
      },
      capabilities: teamData.capabilities,
      myUserId: OTUITree.auth.userId,
      amAdmin: false,
      settings: {
        notifyUserOnline: false,
        notifyRoomJoin: true,
      },
      users: {},
      subTeams: {},
      focusRooms: {},
      calls: {},
      pods: {},
      channelDirectory: {},
      userChats: {},
      channels: {},
      externalMeetings: {},
      actions: {
        updateTeamName: (name) => TeamManagerDb.updateTeamName(
          teamManager.fbDb,
          teamId,
          name
        ),
        updateTeamIcon: (imageUrl) => TeamManagerDb.updateTeamIcon(
          teamManager.fbDb,
          teamId,
          imageUrl
        ),
        createSubTeam: (name) => TeamManagerDb.createSubTeam(
          teamManager.fbDb,
          teamId,
          name
        ),
        updateSubTeamName: (subteamId, name) => TeamManagerDb.updateSubTeamName(
          teamManager.fbDb,
          teamId,
          subteamId,
          name
        ),
        deleteSubTeam: (subteamId) => TeamManagerDb.deleteSubTeam(
          teamManager.fbDb,
          teamId,
          subteamId,
        ),
        updateTeamUserName: (name) => TeamManagerDb.updateTeamUser(
          teamManager.fbDb,
          teamId,
          OTUITree.auth.userId,
          { name: name.trim() }),
        updateTeamUserSubTeam: (subTeam, userId) => TeamManagerDb.updateTeamUser(
          teamManager.fbDb,
          teamId,
          userId ?? OTUITree.auth.userId,
          { subTeam: subTeam }),
        updateTeamUserImageUrl: (imageUrl) => TeamManagerDb.updateTeamUser(
          teamManager.fbDb,
          teamId,
          OTUITree.auth.userId,
          { imageUrl: imageUrl }),
        updateTeamUserImage: (image) => FireDb.updateTeamUserImage(
          teamManager.fbDb,
          OTUITree.auth.userId,
          teamId,
          image),
        updateTeamOnlineNotify: (value: boolean) => FireDb.setTeamUserPreference(
          teamManager.fbDb,
          teamId, OTUITree.auth.userId,
          'disableNotifyUserOnline',
          !value),
        updateRoomJoinNotify: (value: boolean) => FireDb.setTeamUserPreference(
          teamManager.fbDb,
          teamId, OTUITree.auth.userId,
          'disableNotifyRoomJoin',
          !value),
        setAdminUser: (userId: string, admin: boolean) => TeamManagerDb.setTeamAdminUser(
          teamManager.fbDb,
          teamId,
          userId,
          admin
        ),
        setCustomStatus: (
          customStatus?: TUserStatus,
          customStatusEmoji?: string,
          customStatusText?: string
        ) => teamManager.setCustomStatus(customStatus, customStatusEmoji, customStatusText),
        removeUserFromTeam: (userId: string) => teamManager.removeUserFromTeam(userId),
        leaveTeam: teamManager.leaveTeam,
        startUserRoom: teamManager.startUserRoom,
        userInvitees: () => FireDb.userInvitees(teamManager.fbDb, teamId, OTUITree.auth.userId),
      }
    };
  }

  const space = UIDataState.spaces[teamId];


  writeSpaceExternalMeetings(teamId)

  applyObjectUpdate(space.details, {
    name: teamData.teamName!,
    icon: teamData.iconImageUrl!,
  });

  applyObjectUpdate(space.capabilities, teamData.capabilities)

  applyObjectUpdate(space.settings, {
    notifyUserOnline: !teamData.preferences?.disableNotifyUserOnline,
    notifyRoomJoin: !teamData.preferences?.disableNotifyRoomJoin
  })

  Object.keys(teamData.users).forEach((userId) => {
    writeUser(teamId, userId);
  });

  Object.keys(UIDataState.spaces[teamId].users || {}).forEach((userId) => {
    if (!teamData.users?.[userId]){
      logger.info(`removing user ${userId} as they are no longer in team ${teamId}` )
      delete UIDataState.spaces[teamId].users[userId]
    }
  });

  const amAdmin = teamManager.myUserId in teamManager._adminUsers
  if (space.amAdmin != amAdmin) {
    space.amAdmin = amAdmin;
  }

  const subTeams = Object.fromEntries(Object.entries(teamData.subTeams).map(([teamId, team]) => ([teamId, team.name])));
  applyObjectUpdate(space.subTeams, subTeams);

  const exclRooms = ["online", "offline"];

  // if (teamData.currentRoomId && teamData.rooms[teamData.currentRoomId]?.config?.call && !teamData.rooms[teamData.currentRoomId]?.config?.focusRoom) {
  //   exclRooms.push(teamData.currentRoomId)
  // }

  const focusRooms = {}
  const activeRooms: KCallId[] = [];
  const allRoomUsers: Set<string> = new Set()

  for (const roomId in teamData.rooms) {
    const room = teamData.rooms[roomId];
    //logger.debug(`WriteRoom ${roomId} (${room.config?.name ?? roomId}) active: ${room.isActive}`)

    if (room.isActive && room.config && !exclRooms.includes(roomId)) {
      const participants = {};
      let oldestUser: IOTRoomUser | undefined = undefined

      for (const userId in room.users) {
        const roomUser = room.users[userId];

        if (userId in teamData.users && (oldestUser == undefined || (roomUser.crDate && roomUser.crDate < oldestUser.crDate!))) {
          oldestUser = roomUser
        }

        participants[userId] = {
          id: userId,
          name: roomUser.name,
          imageUrl: roomUser.imageUrl,
          currentSessionToken: roomUser.currentSessionToken,
        };
        allRoomUsers.add(userId);
      }

      let roomName = room.config?.name || "Meeting Room";

      if (oldestUser && (!roomName || roomName == "Meeting Room")) {
        roomName = `${oldestUser.name}'s Room`;
      }

      let baseDoc: Partial<IUCall> = space.calls[roomId];
      if (!baseDoc) {
        baseDoc = {
          actions: {
            joinCall: () => {
              teamManager.joinTeamRoom(roomId == "online" ? null : roomId);
            },
            requestJoinCall: () => {
              teamManager.requestJoinTeamRoom(roomId);
            },
            cancelRequestJoinCall: () => {
              teamManager.cancelRequestJoinTeamRoom(roomId);
            },
            respondRequestJoinCall: (userId: string, response: TReceiverStatus) => {
              teamManager.respondRequestJoinCall(roomId, userId, response);
            },
            leaveCall: () => {
              const call = UIDataState.spaces[teamManager.teamId].calls[roomId]
              if (OTUITree.auth.userId in call?.participants) {
                teamManager.leaveTeamRoom(roomId)
              }
            },
            updateCallName: (name) => {
              FireDb.updateRoomConfig(OTGlobals.appHomeManager.fbDb, teamManager.teamId, roomId, {
                name,
              });
            },
          },
        };
      }
      const roomDoc: Partial<IUCall> = {
        ...baseDoc,
        name: roomName,
        ownerId: oldestUser?.userId,
        crDate: room.config.crDate as number,
        meetingLink: getMeetingLink(room.meetingToken),
        channelId: room.config.channelId,
        topicId: room.config.topicId,
        summaryCallId: room.config.summaryCallId,
        external: room.config.external,
        focusRoom: room.config.focusRoom,
        id: roomId,
        platform: 'ehlo',
        spaceId: teamManager.teamId,
        inCall: Object.keys(participants).includes(OTUITree.auth.userId),
        participants: participants,
        requests: room.requests,
      };

      if (room.config.focusRoom) {
        focusRooms[roomId] = roomDoc;
      } else {
        space.calls[roomId] = applyObjectUpdate2(roomDoc, baseDoc, true, `Call ${roomId}`);
      }

      if (Object.keys(participants).length) {
        activeRooms.push(roomId);
      }
    }
  }



  const zoomRooms = Object.values(teamData.users)
    .filter(user => !!user.zoomStatus?.meeting)
    .filter(user => !allRoomUsers.has(user.userId))
    .reduce((meetings, user) => {
      const meeting = user.zoomStatus!.meeting!;
      if (!meetings[meeting.id]) {
        meetings[meeting.id] = { ...user.zoomStatus!.meeting, participants: {}}
      }

      if (meeting.joinUrl && !meetings[meeting.id].joinUrl) {
        meetings[meeting.id].joinUrl = meeting.joinUrl;
      }

      meetings[meeting.id].participants[user.userId] ={
        id: user.userId,
        name: user.name,
        imageUrl: user.imageUrl,
        currentSessionToken: user.sessionToken,
      }
      return meetings;
    }, {});

  //logger.debug(`zoomRooms:`, zoomRooms)

  //logger.debug(`teamData has ${Object.keys(teamData.zoomMeetings).length} zoom meetings`)
  for (const zoomId in zoomRooms) {

    const room = zoomRooms[zoomId];

    let baseDoc: Partial<IUCall> = space.calls[zoomId];
    const roomDoc: Partial<IUCall>  = {
      ...baseDoc,
      id: zoomId,
      platform: 'zoom',
      name: baseDoc?.name ?? room.topic,
      meetingLink: room.joinUrl,
      inCall: Object.keys(room.participants).includes(OTUITree.auth.userId),
      participants: room.participants,
      spaceId: teamManager.teamId,
      actions: baseDoc?.actions ?? {
        joinCall: () => null,
        requestJoinCall: () => null,
        cancelRequestJoinCall: () => null,
        respondRequestJoinCall: () => null,
      }
    }
    space.calls[zoomId] = applyObjectUpdate2(roomDoc, baseDoc, true, `Zoom ${zoomId}`);
    logger.debug(`Zoomcall ${zoomId}`, toJS(roomDoc))
    activeRooms.push(zoomId);
  }

  for (const roomId in space.calls) {
    if (!activeRooms.includes(roomId)) {

      delete space.calls[roomId];
    }
  };

  //logger.debug(`calls are `, Object.keys(space.calls));

  // create focus rooms

  const getFocusRoom = (roomId: string, roomName: string) => {
    return {
      name: roomName,
      focusRoom: true,
      id: roomId,
      spaceId: teamManager.teamId,
      participants: [],
      actions: {
        joinCall: async () => {
          if (!teamData.rooms[roomId]) {
            await teamManager.startFocusRoom(roomId, {
              name: roomName,
              focusRoom: true,
              call: true,
              webcamOff: true,
              micOff: true,
            })
          }
          await teamManager.joinTeamRoom(roomId == "online" ? null : roomId);
        },
        leaveCall: () => teamManager.leaveTeamRoom(roomId),
      },
    }
  }

  // global focus room
  const globalFocusRoomId = 'global-focus-room'
  const globalFocusRoomName = 'Global'

  UIDataState.spaces[teamManager.teamId].focusRooms[globalFocusRoomId] = globalFocusRoomId in focusRooms ?
    focusRooms[globalFocusRoomId]
    :
    getFocusRoom(globalFocusRoomId, globalFocusRoomName)

  // my team focus room
  // const mySubTeamId = teamData.me.subTeam

  // if (mySubTeamId) {
  //   const teamFocusRoomId = `team-focus-room-${mySubTeamId}`

  //   const teamFocusRoomName = teamData.subTeams[mySubTeamId].name

  //   UIDataState.spaces[teamManager.teamId].focusRooms[teamFocusRoomId] = teamFocusRoomId in focusRooms ?
  //     focusRooms[teamFocusRoomId]
  //     :
  //     getFocusRoom(teamFocusRoomId, teamFocusRoomName)
  // }


  if (teamManager.chatManager) {
    UIDataState.spaces[teamManager.teamId].actions.getChannels = teamManager.chatManager.getChannels;
    UIDataState.spaces[teamManager.teamId].actions.createPod = async (
      {name, symbol, color, desc, roomType, pinned = true, roomConfig}
    ) => {
      //logger.debug(`creating room ${name}, color: ${color}`, roomConfig)
       const channelId = await teamManager.chatManager.createChannel(
        [],
        name,
        symbol,
        color,
        desc,
        roomType === 'private' ? "private_channel" : "channel",
        roomType === 'global',
        roomConfig
      );
      teamManager.setPinPod(channelId, pinned);
      return channelId;
    }
    writeChats(teamManager.chatManager);

    writeChannelDirectory(teamManager.chatManager)
  }

  logger.debug(`writeSpace ${teamId}, finished in ${(performance.now() - _startAt).toFixed(2)}ms`);
});

export const removeSpace = action((teamId: string) => {
  delete UIDataState.spaces[teamId]
})

export const writeSpaceCapabilities = action((teamId: string, capabilities: any) => {

  if (UIDataState.spaces[teamId]) {
    applyObjectUpdate(
      UIDataState.spaces[teamId].capabilities,
      capabilities
    )
  }
});

export const writeSpaceExternalMeetings = action((teamId: string) => {
  const teamManager = OTUITree.teamManagers[teamId];
  const meetingManager = teamManager.meetingManager;

  const externalMeetings = UIDataState.spaces[teamManager.teamId].externalMeetings

  if (meetingManager) {
    if (!externalMeetings.actions) {
      externalMeetings.actions = {
        acceptReq: (userId: string, roomId?: string, channelId?: string) => meetingManager.acceptReq(userId, roomId, channelId),
        holdReq: (userId: string, roomId?: string, channelId?: string) => meetingManager.holdReq(userId, roomId, channelId),
        rejectReq: (userId: string, roomId?: string, channelId?: string) => meetingManager.rejectReq(userId, roomId, channelId),
      };
    }

    applyObjectUpdate(externalMeetings, {
      ...externalMeetings,
      teamPath: teamManager.teamData.teamPath,
      address: meetingManager.address,
      channels: meetingManager.channels,
      rooms: meetingManager.rooms,
      waitingUsers: { ...meetingManager.addressDoc?.waiting, ...meetingManager.userMeetingsDoc?.waiting },
    });
  }

});


export const writeSpaceBadges = action((alertManager: AlertManager) => {
  const teamManager = OTUITree.teamManagers[alertManager.teamId];

  Object.keys(teamManager.teamData.users).forEach((userId) => {
    writeUser(teamManager.teamId, userId);
  });
});

export const writeCallRequests = action((callRequestManager: CallRequestManager) => {
  const teamManager = OTUITree.teamManagers[callRequestManager.teamId];

  Object.keys(teamManager.teamData.users).forEach((userId) => {
    writeUser(teamManager.teamId, userId);
  });
});


export const writeChannelDirectory = action((chatManager: ChatManager) => {

  applyObjectUpdate(
    UIDataState.spaces[chatManager.teamId].channelDirectory,
    chatManager.channelDirectory
  )
});

export const writeChats = action((chatManager: ChatManager) => {
  const channels = chatManager.channels;

  Object.keys(channels).forEach((channelId) => {
    writeChannel(chatManager.teamId, channelId);
  });
});

export const writeChannelDraft = action((teamId: string, channelId: string) => {
  const topicId = "default";

  const channels = UIDataState.spaces[teamId].channels;
  const teamManager = OTUITree.teamManagers[teamId];
  const chatManager = teamManager.chatManager;
  if (channels[channelId]) {
    const channel = channels[channelId]

    channel.draftMessage = chatManager.draftMessages[channelId]?.[topicId] || ''
    channel.draftReply = chatManager.draftReplyMessage[channelId]?.[topicId]
    channel.draftFiles = chatManager.draftFiles[channelId]?.[topicId] || []
    channel.pendingMessages = chatManager.pendingMessages[channelId]?.[topicId] || {}

  }
})

export const writeChannelUsersTyping = action((teamId: string, channelId: string) => {
  const topicId = "default";

  const channels = UIDataState.spaces[teamId].channels;
  const teamManager = OTUITree.teamManagers[teamId];
  const chatMessageManager = teamManager.chatManager.messageManager[channelId]?.[topicId];
  if (channels[channelId]) {
    const channel = channels[channelId]

    channel.usersTyping = chatMessageManager?.chatUserIsTyping;

  }
})

export const writeChannel = action((teamId: string, channelId: string) => {
  //logger.debug(`writeChannel ${teamId}:${channelId}`)

  const topicId = "default";
  const teamManager = OTUITree.teamManagers[teamId];
  const chatManager = teamManager.chatManager;
  const chatMessageManager = chatManager.messageManager[channelId]?.[topicId];
  const pods = UIDataState.spaces[teamId].pods;
  const channels = UIDataState.spaces[teamId].channels;
  const userChats = UIDataState.spaces[teamId].userChats;

  const channel = chatManager.channels[channelId];
  const userChannel = chatManager.userChannels[channelId];

  const pinPod = teamManager.teamData.preferences?.pods?.[channelId]

  const numUnread = (channel?.topics?.[topicId].messageNum || 0) - (userChannel?.topics?.[topicId]?.messageNum || 0);

  if (channel && userChannel) {
    if (channel.chatType == "channel" || channel.chatType == "private_channel") {

      let baseDoc: Partial<IUISpacePod> = pods[channelId];

      if (!baseDoc) {
        baseDoc = {
          actions: {
            setPinned: (pin) => teamManager.setPinPod(channelId, pin),
            updatePod: ({name, symbol, color, desc, roomType, roomConfig}) => {

              logger.debug(`updating room ${name}, color: ${color}`)

              return teamManager.chatManager.updateChannel(
                channelId,
                [],
                name,
                symbol,
                color,
                desc,
                roomType === 'private',
                roomType === 'global',
                roomConfig
              )},
            addUser: (channelId: string, userId: string) =>
              teamManager.chatManager.joinChannelUser(channelId, userId),
            archivePod: () => teamManager.chatManager.archiveChannel(channelId),
          } as IUISpacePodActions,
        } as any;
      }

      const podDoc: Partial<IUISpacePod> = {
        ...baseDoc,
        id: channelId,
        name: channel.name!,
        symbol: channel.symbol,
        color: channel.color ?? undefined,
        desc: channel.desc ?? undefined,
        archived: !!channel.archived,
        roomType: channel.chatType == "private_channel" ? 'private' : channel.teamDefault ? 'global' : 'normal',
        numUnread: numUnread,
        hasMention: userChannel.topics?.[topicId].hasMention,
        lastUpdate: channel.topics?.[topicId].lastMessage?.crDate,
        numUsers: channel.userIds?.length ?? 0,
        userIds: channel.userIds,
        pinned: pinPod?.pinned == true,
        callId: channel.roomId,
        roomConfig: channel.roomConfig
      };

      pods[channelId] = applyObjectUpdate2(podDoc, baseDoc);

    } else {
      if (channel.chatType == "chat") {
        const users = channel.userIds?.filter((userId) => userId != OTUITree.auth.userId);
        if (users && users.length == 1) {
          const chatDoc: IUISpaceUserChat = {
            channelId: channelId,
            numUnread: numUnread,
            hasMention: userChannel.topics?.[topicId].hasMention,
          };
          userChats[users[0]] = applyObjectUpdate2(chatDoc, userChats[users[0]]);
        }
      }
    }

    let baseDoc: Partial<IUISpaceChannel> = channels[channelId];
    if (!baseDoc) {
      baseDoc = {
        // set these once so that they will not appear to have changed if still empty
        draftFiles: [],
        messages: {},
        callSummaries: {},
        atStart: false,
        draftMessage: "",
        pinnedResources: [],
        pendingMessages: {},
        deletedResources: [],
        recentResources: {},
        actions: {
          setMessage: (text) => chatManager.setDraftText(channelId, topicId, text),
          addMessageFile: (messageFile) =>
            chatManager.addDraftMessageFile(channelId, topicId, messageFile),
          addFile: (file) => chatManager.addDraftFiles(channelId, topicId, [file]),
          removeFile: (index) =>
            chatManager.removeDraftMessageFile(channelId, topicId, index),
          setReply: (message?) =>
            chatManager.setDraftReplyMessage(channelId, topicId, message),
          resetDraft: () => chatManager.resetDraft(channelId, topicId),

          removeChannelUser: (userId) => chatManager.removeChannelUser(channelId, userId),

          loadChat: (loadSince?) => chatManager.goChannel(channelId, topicId, loadSince),
          closeChat: () => chatManager.closeChannel(channelId, topicId),
          loadMoreMessages: (direction) => {
            const _chatMessageManager = chatManager.messageManager[channelId]?.[topicId];
            _chatMessageManager?.loadMore(direction)
          },
          loadCallSummary: (callId) => {
            const _chatMessageManager = chatManager.messageManager[channelId]?.[topicId];
            _chatMessageManager?.loadCallSummary(callId)
          },
          restart: (loadMessageId?) => {
            const _chatMessageManager = chatManager.messageManager[channelId]?.[topicId];
            _chatMessageManager?.reset();
            _chatMessageManager?.initialize(loadMessageId);
          },
          focusChat: () => {
            chatManager.focusChannelTopic(channelId, topicId);
          },
          unfocusChat: () => chatManager.unfocusChannelTopic(),
          markChatRead: (...args) => {
            chatManager.markChatRead(channelId, topicId, ...args);
          },
          setIsTyping: (isTyping) => {
            chatManager.setIsTyping(channelId, topicId, isTyping);
          },
          sendMessage: (...args) =>
            chatManager.sendChatMessage(channelId, topicId, ...args),
          deleteMessage: (messageId) =>
            chatManager.deleteChatMessage(channelId, topicId, messageId),
          editMessage: (messageId, message) =>
            chatManager.editChatMessage(channelId, topicId, messageId, message),
          startCall: (meetingName?: string) => {
            const channel = chatManager.getChannel(channelId);

            var teamManager = OTUITree.teamManagers[teamId];

            const userIds = (channel.userIds || []).filter(
              (userId) => userId != OTUITree.auth.userId
            );
            if (channel.chatType == "chat" && userIds.length == 1) {
              const callRequestUIState = OTUITree.callRequestUIStates[teamId];
              callRequestUIState.sendCallUser(userIds[0]);
            } else {
              var name = meetingName || channel.name || "Chat Call";

              teamManager.startRoomForChannel(name, channelId, topicId);
            }
          },

          muteNotify: (muteNotify) => {
            chatManager.muteChatNotify(channelId, topicId, muteNotify);
          },
          updateResource: (...args) => chatManager.updateResource(channelId, topicId, ...args),
          sendURL: (text: string, systemMessage: string) => chatManager.sendURL(channelId, topicId, text, systemMessage),
        } as IUISpaceChatActions,

      };
    }

    const channelDoc: IUISpaceChannel = {
      ...baseDoc as IUISpaceChannel,
      id: channelId,
      name: channel.name!,
      desc: channel.desc,
      chatType: channel.chatType,
      imageUrl: channel.imageUrl,
      //lastUpdate: new Date(channel.lastUpdate),
      muteNotify: userChannel?.topics?.[topicId]?.muteNotify,
      messageId: channel.topics?.[topicId].messageId || 0,
      lastReadMessageId: chatMessageManager?.lastReadMessageId || 0,
      lastReceivedMessageId: chatMessageManager?.lastReceivedMessageId || 0,
      lastMessage: channel.topics?.[topicId].lastMessage,
      meetingLink: getMeetingLink(channel.meetingToken),
      messageNum: channel.topics?.[topicId].messageNum || 0,
      messageNumRead: userChannel.topics?.[topicId]?.messageNum || 0,
      numUnread: numUnread,
      messages: chatMessageManager?.messages ?? baseDoc.messages,
      callSummaries: chatMessageManager?.callSummaries ?? baseDoc.callSummaries,
      atStart: chatMessageManager?.atStart ?? baseDoc.atStart,
      usersTyping: chatMessageManager?.chatUserIsTyping,
      userIds: channel.userIds,
      draftMessage: chatManager.draftMessages[channelId]?.[topicId] ?? baseDoc.draftMessage,
      draftReply: chatManager.draftReplyMessage[channelId]?.[topicId],
      draftFiles: chatManager.draftFiles[channelId]?.[topicId] ?? baseDoc.draftFiles,
      pendingMessages: chatManager.pendingMessages[channelId]?.[topicId] ?? baseDoc.pendingMessages,
      isWatching: !!chatMessageManager?.started,
      highlightMessageId: chatMessageManager?.highlightMessageId,
      pinnedResources: channel.pinnedResources ?? baseDoc.pinnedResources ?? EmptyList,
      deletedResources: chatManager.deletedResources[channelId] ?? baseDoc.deletedResources,
      recentResources: channel.recentResources ?? baseDoc.recentResources ?? EmptyObj,
    };

    // Don't use recursive update here as messages is an ordered dict and
    // partial updates will mess it up.
    channels[channelId] = applyObjectUpdate2(channelDoc, baseDoc, false, `Channelx ${channelId}`);
  }
});

export const deleteChannel = action((teamId: string, channelId: string) => {
  delete UIDataState.spaces[teamId].pods[channelId];
  delete UIDataState.spaces[teamId].channels[channelId];
  delete UIDataState.spaces[teamId].userChats[channelId];
})


export const writeUser = action((teamId: KSpaceId, userId: KSpaceUserId) => {
  //logger.debug(`writeUser ${teamId}:${userId}`)
  const teamManager = OTUITree.teamManagers[teamId];

  const otUser = teamManager.teamData.users[userId];
  const roomId = teamManager._roomManager.getUserRoom(userId);

  const myUserPrefs = teamManager.teamData.preferences?.users?.[userId]

  const subTeamName = otUser.subTeam ? teamManager.teamData.subTeams[otUser.subTeam]?.name : undefined

  const alertManager = teamManager.alertManager;

  const pinned = !!teamManager.teamData.preferences?.pinnedUserIds?.includes(userId);

  const spaceUser: Partial<IUISpaceUser> = {
    email: otUser.email,
    id: userId,
    meetingLink: getMeetingLink(otUser.meetingToken),
    name: otUser.name,
    isAdmin: userId in teamManager._adminUsers,
    imageUrl: otUser.imageUrl,
    dateJoined: otUser.dateJoined as number,
    lastOnline: otUser.last_changed,
    roomId: roomId,
    pinned: pinned,
    lastInteracted: myUserPrefs?.lastInteracted,
    status: {
      online: otUser.online,
      inCall: roomId && !teamManager.teamData.rooms[roomId]?.config?.focusRoom ? true : false,
      meetingStatus: otUser.meetingStatus,
      status: otUser.idle ? "AWAY" : undefined,
      customStatus: otUser.customStatus,
      customStatusEmoji: otUser.customStatusEmoji,
      customStatusText: otUser.customStatusText,
      zoomStatus: otUser.zoomStatus,
      timezone: otUser.timezone
    },
    teamId: otUser.subTeam,
    team: subTeamName,
  };

  // badges
  const badges = alertManager.badges[userId];

  if (badges) {
    if (!spaceUser.alerts) {
      spaceUser.alerts = {
        clear: () => alertManager.removeUserAlerts(userId),
      };
    }

    if (badges.KNOCK) {
      spaceUser.alerts.knocked = {
        count: badges.KNOCK,
        timestamp: new Date(badges.lastUpdate),
      };
    }

    if (badges.CALL) {
      spaceUser.alerts.called = {
        count: badges.CALL,
        timestamp: new Date(badges.lastUpdate),
      };
    }
  }

  // calls
  const callRequestManager = teamManager.callRequestManager;

  const incomingCall = callRequestManager.call;

  if (incomingCall && userId == incomingCall.userId && incomingCall.active && incomingCall.receiverStatus != "accepted") {
    if (!spaceUser.incoming) {
      spaceUser.incoming = {};
    }

    spaceUser.incoming.calling = {
      timestamp: new Date(),
      answer: () => callRequestManager.recvRespondToCall("accepted"),
      cancel: () => callRequestManager.recvRespondToCall("rejected"),
      wait: () => callRequestManager.recvRespondToCall("holdon"),
      holdon: incomingCall.receiverStatus == "holdon",
    };
  } else {
    if (spaceUser.incoming?.calling) {
      delete spaceUser.incoming?.calling;
    }
  }

  if (callRequestManager.callsFromMe[userId]) {
    if (!spaceUser.outgoing) {
      spaceUser.outgoing = {};
    }

    spaceUser.outgoing.calling = {
      timestamp: new Date(),
      cancel: () => callRequestManager.sendCancelCall(userId, true),
      holdon: callRequestManager.callsFromMe[userId].receiverStatus == "holdon",
    };
  } else {
    if (spaceUser.outgoing?.calling) {
      delete spaceUser.outgoing?.calling;
    }
  }

  let baseDoc: Partial<IUISpaceUser> = UIDataState.spaces[teamId].users[userId];
  if (!baseDoc) {
    baseDoc = {
      actions: {
        togglePinned: () => teamManager.togglePinned(userId),
        updateLastInteracted: () => teamManager.setUserInteracted(userId),
        knockUser: () => {
          teamManager.knockUser(userId);
        },
        callUser: () => {
          const callRequestUIState = OTUITree.callRequestUIStates[teamManager.teamId];
          alertManager.removeUserAlerts(userId);
          callRequestUIState.sendCallUser(userId);
        },
        getChannel: async () => {
          return await teamManager.chatManager.getUserChannelId(userId);
        },
      },
    };
  }
  spaceUser.actions = baseDoc.actions

  UIDataState.spaces[teamId].users[userId] =
    applyObjectUpdate2(spaceUser, baseDoc, true, `user ${userId}`);
});


/* Active Call */

const MAX_BIG_TILES = 10

const CALL_DISPOSERS: (() => void)[] = []
export const writeActiveCall = action((callState?: CallStateManager) => {
  logger.debug("writeActiveCall", callState?.roomId, callState?.name, callState?.room)
  if (callState) {
    const space = UIDataState.spaces[callState.teamId];
    const roomChannelId = callState.room.config?.channelId;
    const hasChannel = roomChannelId && (space?.channels?.[roomChannelId] !== undefined);

    const winChannelId = hasChannel ? roomChannelId : 'adhoc-call'
    const roomWindowId = `chat-${callState.teamId}-${winChannelId}`
    const poppedOut = OTGlobals.localUserSettings.poppedOut === true;

    const room = space?.calls?.[callState.roomId]

    UIDataState.activeCall = {
      id: callState.roomId,
      platform: 'ehlo',
      myUserId: callState.myUserId,
      connected: false,
      inCall: true,
      crDate: callState._startTime,
      name: callState.name ?? "Meeting",
      spaceId: callState.teamId,
      channelId: hasChannel ? roomChannelId : undefined,
      summaryCallId: callState.room.config?.summaryCallId,
      meetingLink: room?.meetingLink,
      external: callState.room.config?.external,
      focusRoom: callState.room.config?.focusRoom,
      widgetHovered: false,
      disableTransparency: false,
      faceTrackingEnabled: OTGlobals.remoteUserSettings.faceDetect ?? false,
      minimizeSelf: false,
      sharingScreen: false,
      numBigTiles: MAX_BIG_TILES,
      isCallPaused: false,
      participantList: [],
      participants: {},
      screenShares: {},
      plugins: {},
      pluginPopouts: {},
      pluginCallbacks: {
        updatePluginArgs: callState.pluginManager.updatePluginArgs,
        onPlaying: callState.pluginManager.onPlaying,
        closePlugin: callState.pluginManager.closePlugin,
      },
      poppedOut,
      roomWindowId,
      windowId: poppedOut ? "CallWidget" : roomWindowId,
      callMessageManager: callState.callMessageManager,
      _hoveredFlags: {},
      actions: {
        leaveCall: callState.leaveCall,
        shareScreen: callState.shareStream,
        toggleAudio: callState.toggleAudio,
        toggleVideo: callState.toggleVideo,
        toggleFaceTracking: toggleFaceTracking,
        updateDeviceSettings: updateDeviceSettings,
        popoutScreenShare: popoutScreenShare,
        popoutPlugin: popoutPlugin,
        setUserSoundDisabled: callState.setDisableUserSound,
        setUserSameRoom: callState.setSameRoom,
        setUserMuted: callState.muteUser,
        setAllMuted: callState.muteAll,
        requestStats: (userId) => callState.requestStats(userId, "camera"),
        cancelStats: (userId) => callState.cancelStats(userId, "camera"),
        onFaceDetect: callState.onFaceDetect,
        focusUser: focusUser,
        setNumDummyParticipants: setNumDummyParticipants,
        removeUserFromCall: callState.removeTeamRoomUser,
        setCallPaused: (paused) => {
          callState.setCallPaused(paused);
          UIDataState.activeCall!.isCallPaused = paused;
        },
        sendURL: callState.sendURL,
        _setFocusedUsers: callState.setFocusedUsers,
      },
    };

    updateCallParticipants(callState, [callState.myUserId].concat(callState.connectedUsers));

    callState.pluginManager.on('pluginupdated', updatePlugin)
    callState.pluginManager.on('plugindeleted', updatePlugin)
    callState.pluginManager.on('mediaplaying', pluginMediaPlaying)

  } else {
    UIDataState.activeCall = undefined;
    logger.debug(`Call disposing of ${CALL_DISPOSERS.length} autoruns`)
    while (CALL_DISPOSERS.length) {
      const f = CALL_DISPOSERS.pop();
      f && f();
    }
  }
});

const updatePlugin = action((pluginId: string, pluginData?: IPluginResource) => {
  const call = UIDataState.activeCall;
  if (call) {
    if (!pluginData) {
      logger.debug(`Deleting plugin ${pluginId}`);
      delete call.plugins[pluginId];
      delete call.pluginPopouts[pluginId];
    } else {
      logger.debug(`Updating plugin ${pluginId}`);
      //call.plugins[pluginId] = applyObjectUpdate2(pluginData, call.plugins[pluginId]);
      if (!(pluginId in call.plugins) && pluginData.crDate > (call.crDate || 0)) {
        call.pluginPopouts[pluginId] = true;
      }
      call.plugins[pluginId] = pluginData
    }
  }
});

const pluginMediaPlaying = action((pluginId: string, playing: boolean) => {
  const call = UIDataState.activeCall;
  if (call?.pluginPopouts[pluginId]) {
    call.actions.toggleAudio(playing ? false : true)
  }
})

reaction(
  () => OTGlobals.remoteUserSettings,
  action((remoteSettings) => {
    logger.debug(`Updating settings for call`)
    if (UIDataState.activeCall) {
      UIDataState.activeCall.faceTrackingEnabled = remoteSettings.faceDetect ?? true;
    }
  })
)


const toggleFaceTracking = action(() => {
  OTGlobals.userSettingsManager.updateRemoteSettings({
    faceDetect: !(OTGlobals.remoteUserSettings.faceDetect === true),
  })
})


const focusUser = action((userId, focus) => {
  const call = UIDataState.activeCall;
  if (call && call.participants[userId]) {
    if (focus) {
      call.participants[userId].dispOrder = - (new Date().getTime());
      call.numBigTiles = Math.min(MAX_BIG_TILES, call.numBigTiles + 1);
    } else {
      call.participants[userId].dispOrder = (new Date().getTime())
      call.numBigTiles = Math.max(1, Math.min(call.participantList.length, call.numBigTiles) - 1);
    }
    writeParticipantList(call);
  }
})

export const setCallWidgetHovered = action((flag, hovered = true) => {
  const call = UIDataState.activeCall;
  if (call) {
    if (hovered) {
      call._hoveredFlags[flag] = true;
    } else {
      delete call._hoveredFlags[flag]
    }
    call.widgetHovered = Object.keys(call._hoveredFlags).length > 0
    logger.debug(`setting CallWidget hovered ${flag} : ${hovered} (overall ${call.widgetHovered})`)
  }
})

export const removeCallWidgetHovered = (flag) => {
  setCallWidgetHovered(flag, false)
}

export const toggleCallSelfMinimized = action(() => {
  const call = UIDataState.activeCall;
  if (call) {
    call.minimizeSelf = !call.minimizeSelf
  }
})

export const toggleCallPoppedOut = action(() => {
  const call = UIDataState.activeCall;
  if (call) {
    if (call.poppedOut) {
      call.poppedOut = false;
      call.windowId = call.roomWindowId;
    } else {
      call.poppedOut = true;
      call.windowId = 'CallWidget';
    }
  }
  return call?.poppedOut === true;
})

const setNumDummyParticipants = action((want: number) => {
  const call = UIDataState.activeCall;
  if (call) {
    const getDummyIds = () => Object.keys(call.participants).filter(userId => userId.startsWith('dummy'))
    let numDummies = Object.keys(call.participants).filter(userId => userId.startsWith('dummy')).length;
    let offset = 0;

    while (want > numDummies) {
      numDummies += 1;
      const num = numDummies
      const id = `dummy-${num}`
      offset += Math.random() * 50;

      setTimeout(() => {
        runInAction(() => {
          logger.debug(`Adding dummy Participant ${id}`)
          call.participants[id] = {
            id,
            name: `Dummy ${num}`,
            isSameRoom: false,
            isSoundDisabled: false,
            isPaused: false,
            streams: {},
            dispOrder: new Date().getTime(),
            connected: true
          }
          writeParticipantList(call);
        })
      }, offset)
    }

    while (want < numDummies) {
      delete call.participants[`dummy-${numDummies}`]
      numDummies--;
    }

    writeParticipantList(call);
  }
})

export function updateDeviceSettings(_key, settings) {
  const key = _key === 'audioinput' ? 'audio' : (_key === 'videoinput' ? 'video' : _key);
  logger.debug(`Setting ${_key} (${key}) settings to `, settings);
  OTGlobals.userSettingsManager.updateLocalSettings({ [key]: settings } as IMediaDeviceSettings);
}

export const updateCallParticipants = action((callState: CallStateManager, userIds: string[]) => {
  if (UIDataState.activeCall) {
    logger.debug(`UpdateCallParticipants with`, toJS(userIds))
    const participants = UIDataState.activeCall.participants;
    UIDataState.activeCall.connected = callState.connected;
    Object.keys(participants)
      .filter((userId) => !userIds.includes(userId) && !userId.startsWith('dummy-'))
      .forEach((userId) => writeCallParticipant(callState, userId));

    userIds
      //.filter((userId) => participants[userId] === undefined)
      .forEach((userId) => {
        const user = callState.getUser(userId);
        writeCallParticipant(callState, userId, user);
      });
  }
});


export const writeCallParticipant = action(
  (callStateManager: CallStateManager, userId: string, user?: IOTRoomUser) => {

    const call = UIDataState.activeCall
    const callState = callStateManager.callState
    let updateList = false;

    if (call && call.id === callState.roomId) {
      if (user) {
        const peerInfo = callStateManager.callState.peerInfo[userId]
        const previous = call.participants[userId] ?? { streams: {} }
        const participant: IUCallParticipant = {
          ...previous,
          id: userId,
          name: user.name,
          imageUrl: user.imageUrl,
          isSameRoom: Boolean(callState.audioGroupId && callState.audioGroupId === peerInfo?.audioGroupId),
          isSoundDisabled: callStateManager.disableUserSound[userId] === true,
          isPaused: callState.pausedUsers[userId] === true,
          volumeFactor: peerInfo?.volume,
          faceDetect: callState.faceDetect[userId],
          dispOrder: previous.dispOrder ?? new Date().getTime(),
          connected: callStateManager.callState.connectedUsers.includes(userId),
        }


        if (!call.participants[userId]) {
          logger.debug(`Adding user ${userId} ${user.name} to call, connected ${participant.connected}`)
          updateList = true;
        } else {
          logger.debug(`Updating user ${userId} ${user.name}, connected ${participant.connected}, paused ${participant.isPaused}`)
        }
        call.participants[userId] = applyObjectUpdate2(participant, previous);

        if (updateList) {
          callStateManager.getUserStreams(userId).forEach(stream => {
            updateParticipantStream(userId, stream.streamType, stream);
          })
        }
      } else {
        logger.debug(`removing ${userId} from call`);
        delete call.participants[userId];
        delete call.screenShares[userId];
        updateList = true;
      }

      if (updateList) {
        writeParticipantList(call);
      }
    }
  }
);

function writeParticipantList(call: IUActiveCall) {
  const participants = Object.values(call.participants).filter((u) => u.id !== call.myUserId);
  participants.sort((u1, u2) => u1.dispOrder - u2.dispOrder);
  const _participantList = participants.map(u => u.id);

  if (_participantList.join(":") !== call.participantList.join(':')) {
    call.participantList = participants.map((u) => u.id);
  }

  const focused = call.windowId === "CallWidget" ? call.participantList.slice(call.numBigTiles) : call.participantList;
  call.actions._setFocusedUsers(focused.length < 6 ? focused : []);
}

export const updateParticipantStream = action(
  (userId: string, streamType: TStreamType, stream?: HarkStreamDetails) => {
    const call = UIDataState.activeCall
    const participant = UIDataState.activeCall?.participants[userId];

    if (call && participant) {
      const prevState = participant.streams[streamType];
      const streamState = stream
        ? {
          type: streamType,
          stream: stream.stream,
          muted: stream.muted,
          videoTrack: stream.tracks.get("video"),
          audioTrack: stream.tracks.get("audio"),
          hasAudio: stream.hasAudio,
          hasVideo: stream.hasVideo,
          volume: stream.volume,
          speaking: stream.volume > 1,
          stats: stream.stats,
        }
        : null;

      //logger.debug(`Updating stream for ${participant.name}`, streamState);

      const isMe = participant.id === UIDataState.activeCall?.myUserId;

      if (!isMe && streamType === "screen") {
        if (stream && !prevState && !(userId in call.screenShares)) {
          logger.debug(`Adding screenshare for ${userId}`)
          call.screenShares[userId] = OTGlobals.localUserSettings.screenSharePopoutOnStart
        } else if (!stream) {
          logger.debug(`Removing screenshare for ${userId}`)
          delete call.screenShares[userId]
        }
      } else if (isMe && streamType === "screen") {
        call.sharingScreen = stream !== undefined
      }

      participant.streams[streamType] = applyObjectUpdate2(
        streamState,
        prevState
      );

      if (streamType === "camera") {

        participant.networkStatus = applyObjectUpdate2(
          {
            connected: (stream?.networkStatus ?? "ok") === "ok",
            status: stream?.networkStatus ?? "ok",
            strength: (stream?.score ?? null) as NetworkStrength,
          },
          participant.networkStatus
        );
      }
    } else {
      logger.warn(`Unable to updateParticipantStream '${streamType}' for user ${userId}, call or participant not found`, call, participant);
    }
  }
);

const popoutScreenShare = action((userId: KSpaceUserId, popOut: boolean) => {
  if (UIDataState.activeCall) {
    UIDataState.activeCall.screenShares[userId] = popOut
  }
})

const popoutPlugin = action((pluginId: KSpacePluginId, popOut: boolean) => {
  if (UIDataState.activeCall) {
    UIDataState.activeCall.pluginPopouts[pluginId] = popOut
  }
})

/* Device Settings */

export const writeDeviceSettings = action((deviceType, availableDevices, preferredDeviceId, activeDeviceId) => {
  UIDataState.deviceSettings[deviceType] = applyObjectUpdate2({
    preferredDeviceId,
    activeDeviceId,
    availableDevices
  }, UIDataState.deviceSettings[deviceType]);
  logger.debug(`${deviceType} settings now`, toJS(UIDataState.deviceSettings[deviceType]));
})

function getMeetingLink (meetingToken: string | undefined): string | undefined {
  if (meetingToken) {
    const url = `${OTGlobals.config.MEET_URL}/${encodeURIComponent(meetingToken)}`
    return url
  }
}
