import { observer } from "mobx-react";
import React from "react";
import { CSSProperties } from "react";
import { DSPanel } from "./DSLayout";
import { DSBadgeText } from "./DSText";
import { DSTheme } from "./DSTheme";

export type TBadgePosition = 'TopLeft' | 'BottomRight' | 'TopRight' | 'BottomLeft';

interface IDSBadgeViewProps {
  badgeNum?: number;
  badgeOverride?: string | JSX.Element
  badgePosition?: TBadgePosition
  style?: CSSProperties;
  show?: boolean;
}

export const DSBadgeView: React.FC<IDSBadgeViewProps> = observer((props) => {
  const { badgeNum, badgeOverride, badgePosition: position = 'TopLeft', show = true } = props

  const top = position.includes("Top") ? -1 : undefined
  const bottom = position.includes("Bottom") ? -1 : undefined
  const left = position.includes("Left") ? -1 : undefined
  const right = position.includes("Right") ? -1 : undefined

  return (badgeNum ?? 0) > 0 || badgeOverride ? (
      <div
        style={{
          position: "absolute",
          display: 'flex',
          top: top,
          bottom: bottom,
          left: left,
          right: right,
          height: 16,
          minWidth: 16,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: DSTheme.BadgeColor,
          opacity: show ? 1: 0,
          transition: "opacity 0.15s ease-in-out",
          ...props.style
        }}
      >
      <DSBadgeText style={{ color: "white", fontWeight: "bold", fontSize: 11, marginTop: -1 }}>
        {
          badgeOverride ?
            badgeOverride
            :
            badgeNum
        }
        </DSBadgeText>
      </div>
    ) : null
})

export const DSBadgeHolder: React.FC<IDSBadgeViewProps> = observer((props) => {
  return <DSPanel style={{position: 'relative'}}>
    {props.children}
    <DSBadgeView badgeNum={props.badgeNum} style={props.style} />
  </DSPanel>
})