import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, getFirestore as _getFirestore } from 'firebase/firestore';
import { getDatabase, onValue, ref } from 'firebase/database'
import { getFunctions, httpsCallable } from "firebase/functions";
import { Logger } from "@openteam/app-util";
import { RoomManager } from "./RoomManager";
import { CallStateManager } from "./CallStateManager";
import { makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { FireDb, ExternalMeetingDb } from "./fire";
import { OTGlobals } from "./OTGlobals";
import { OTTeamDataClass } from "./OTTeamDataClass";
import { TShowCallFeedback } from "./CallRequest";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";
import { IMeetingRequestStatus, IMeetingTokenDetails, IOTRoom, ITeamExternalWaiting, ITeamRoom } from "@openteam/models";
import { writeExternalMeeting } from "./UIDataState";

const logger = new Logger("ExternalMeeting");

export class ExternalMeeting {
  fbDb: Database;
  fsDb: Firestore;
  userId: string;
  sessionToken: string;
  meetingToken: string;
  name: string = ''

  @observable meetingData?: IMeetingTokenDetails;
  @observable loadFailed?: boolean = false;
  @observable roomId?: string;
  @observable room?: IOTRoom | null;
  _roomManager?: RoomManager;
  teamData?: OTTeamDataClass;
  showCallFeedback = () => { }
  @observable status?: IMeetingRequestStatus

  constructor(
    fbDb: Database,
    fsDb: Firestore,
    userId: string,
    sessionToken: string,
    meetingToken: string,
  ) {

    makeObservable(this)

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.meetingToken = meetingToken;
    writeExternalMeeting(this)

    this.loadMeeting()

  }

  loadMeeting = () => {
    const functions = getFunctions();
    const loadMeetingToken = httpsCallable(functions, 'loadMeetingToken');
    loadMeetingToken({ meetingToken: this.meetingToken })
      .then((result) => {

        const data = result.data as IMeetingTokenDetails;
        logger.debug("data", data)
        runInAction(() => {

          this.meetingData = data

          if (data.roomId && !data.room) {
            this.status = 'F'
          }

          writeExternalMeeting(this)

        })
      })
      .catch(() => {
        logger.debug("error loading meeting details")
        this.loadFailed = true
        writeExternalMeeting(this)
      })
  }

  requestJoin = (name: string) => {
    if (!this.meetingData) {
      return
    }

    this.name = name;

    if (this.meetingData.roomId) {

      ExternalMeetingDb.joinTeamRoomWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.roomId,
        this.userId,
        name,
        this.handleJoin
      )
    } else if (this.meetingData.channelId) {

      ExternalMeetingDb.joinTeamChannelWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.channelId,
        this.userId,
        name,
        this.handleJoin
      )
    } else if (this.meetingData.userId) {
      ExternalMeetingDb.joinTeamUserWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.userId,
        this.userId,
        name,
        this.handleJoin
      )
    }
    this.status = 'P'

    writeExternalMeeting(this)

  }

  handleJoin = (doc: ITeamExternalWaiting) => {
    if (!doc) {
      return
    }

    logger.debug("handleJoin", doc)

    this.status = doc.status || 'P'

    if (doc.status == "A" && doc.roomId) {
      this.roomId = doc.roomId
      this.joinCall(this.name);

      this.stopRequest()

    } else if (doc.status == "H") {
      doc.status == "H"
    } else if (doc.status == "R") {
      this.stopRequest()

      var url = new URL(window.location.href);
      url.searchParams.set('mode', "rejected");

      window.location.href = url.href
    }
    writeExternalMeeting(this)

  }

  stopRequest = () => {

    if (!this.meetingData) {
      return
    }

    if (this.meetingData.roomId) {

      ExternalMeetingDb.cancelJoinTeamRoomWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.roomId,
        this.userId
      );
    } else if (this.meetingData.channelId) {

      ExternalMeetingDb.cancelJoinTeamChannelWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.channelId,
        this.userId
      );
    } else if (this.meetingData.userId) {
      ExternalMeetingDb.cancelJoinTeamUserWaiting(
        getDatabase(),
        this.meetingData.teamId,
        this.meetingData.userId,
        this.userId
      );
    }
  }

  joinCall = async (name: string) => {
    if (!this.meetingData || !this.roomId) {
      return
    }

    if (!OTGlobals._getTeamData || !OTGlobals.getTeamData(this.meetingData.teamId)) {
      const teamData = new OTTeamDataClass(this.fbDb, this.userId, this.meetingData.teamId);
      this.teamData = teamData
      OTGlobals.registerGetTeamData((teamId: string) => teamData);

      logger.info(`Creating ExternalMeeting for teamId=${this.meetingData.teamId}, roomId=${this.meetingData.channelId}, name=${name}`);

      this._roomManager = new RoomManager(this.fbDb, this.userId, this.meetingData.teamId);

    }

    await FireDb.joinTeamRoom(this.fbDb, this.meetingData.teamId, this.userId, this.sessionToken, this.roomId, {
      id: this.userId,
      name
    }, true);

    await ExternalMeetingDb.removeInviteTeamRoom(this.fbDb, this.meetingData.teamId, this.roomId, this.userId);

    if (this.teamData) {

      ExternalMeetingDb.watchRoom(this.fbDb, this.meetingData.teamId, this.roomId, this.hdlRoom);
    }

    OTGlobals.analytics?.logEvent("externalMeetingManager__JoinRoom");
  };

  hdlRoom = async (roomId: string, roomDoc: ITeamRoom) => {
    logger.info("ExternalMeeting hdlRoom", roomId, roomDoc);
    let myRoom: IOTRoom | null = null;

    // let teamDoc = {
    //   rooms: {
    //     [roomId]: roomDoc,
    //   },
    //   users: {},
    // };

    // myRoom = this._roomManager?.handleDoc(teamDoc) || null;

    if (roomDoc?.users?.[this.userId]) {
      myRoom = { ...roomDoc, roomId, inRoom: true, isActive: true }
    }

    await this._manageCallState(myRoom);

  };

  getRoom = () => {
    return this.room
  }

  _manageCallState = async (room: IOTRoom | null) => {
    if (!this.meetingData) {
      return
    }
    this.room = room
    const callStateManager = OTGlobals.callStateManager;

    if (callStateManager) {
      if (callStateManager.roomId != room?.roomId || !room?.config?.call) {
        logger.info(`Ending call for roomId=${callStateManager.roomId}`);

        const callSecs = await callStateManager.shutdown();

        OTGlobals.setCallStateManager(undefined);
      } else {
        callStateManager.updateUsers(room.users);
      }
    }

    if (!OTGlobals.callStateManager && room && room.config?.call) {
      logger.debug(`Starting call for ${room.roomId} with ${room.users}`);
      const callStateManager = new CallStateManager(
        this.fbDb,
        this.fsDb,
        this.userId,
        this.sessionToken,
        this.meetingData.teamId,
        room.roomId,
        room.users,
        this.getRoom,
        this.showCallFeedback
      );
      callStateManager.setFocusRoom(true);
      callStateManager.on("callkicked", (ev) => this.leaveTeamRoom());
      callStateManager.on("leavecall", (ev) => this.leaveTeamRoom());

      OTGlobals.setCallStateManager(callStateManager);
    }
  };

  leaveTeamRoom = async () => {
    logger.debug("in leaveTeamRoom")
    if (!this.meetingData) {
      return
    }

    this.status = 'F'

    const callStateManager = this.getCurrentCallState()

    if (callStateManager) {
      callStateManager.shutdown()
    }
    this.shutdown();
    logger.debug("doing in leaveTeamRoom")
    FireDb.leaveTeamRoom(this.fbDb, this.meetingData.teamId, this.userId, this.sessionToken, this.roomId!);

    logger.debug("done in leaveTeamRoom")

  };
  shutdown = () => {

    logger.debug("shutting down")
    if (this.meetingData) {
      this.stopRequest()
      ExternalMeetingDb.unwatchRoom(this.fbDb, this.meetingData.teamId, this.roomId!);
    }
    writeExternalMeeting(undefined)
    logger.debug("shut down")

  };



  getCurrentCallState = () => {
    return OTGlobals.callStateManager;
  };

  _devicesChangedReaction = reaction(
    () => {
      return [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video];
    },
    async () => {
      logger.info("_devicesChangedReaction");

      const callState = this.getCurrentCallState();
      if (callState) {
        logger.info("camera settings changed", toJS(OTGlobals.mediaDevices));
        if (callState.myStreams["camera"]) {
          await callState.myStreams["camera"].updateSettings(OTGlobals.mediaDevices);
        } else {
          logger.debug("Camera stream not found, recreating");
          callState._updateStream("camera", callState._wantAudio, callState._wantVideo);
        }
      }
    }
  );

  _simulcastChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.videoSimulcastEnabled;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["camera"];
        logger.debug("simulcast change for", stream);
        if (stream) {
          stream.bumpTrack("video");
        }
      }
    }
  );

  _cameraQualityChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.cameraQuality;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["camera"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    }
  );

  _screenShareQualityChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.screenshareQuality;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["screen"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    }
  );
}
