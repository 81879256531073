import { observable, action, computed, makeObservable } from "mobx";
import { assertDefined, Logger, OTCacheClass } from "@openteam/app-util";
import { UserSettingsManager } from "./UserSettingsManager";

import type { IPluginConfig } from "./PluginManager";
import type { IActionTypeDetails, IAnalytics, ILinkPreview, IMediaDeviceSettings } from "@openteam/models";
import type { OTTeamDataClass } from "./OTTeamDataClass";
import type { AppHomeManager } from "./AppHome/AppHomeManager";
import type { CallStateManager } from "./CallStateManager";
import { OTAuth } from "./User/Auth";

const logger = new Logger("OTGlobals");

export type TGetTeamData = (teamId: string) => OTTeamDataClass;
export type TGetUnsafeTeamData = (teamId: string) => OTTeamDataClass | undefined;

type TGetDisplayMedia = (constraints: MediaStreamConstraints) => Promise<MediaStream>;
type TGetLinkPreview = (url: string) => Promise<ILinkPreview | null>

// not sure where this should go at the moment???
export class OTGlobalsClass {
  @observable fbConnected: boolean | undefined = undefined;
  @observable isConnected: boolean | undefined = undefined;

  @observable userSettingsManager = new UserSettingsManager();

  constructor() {
    makeObservable(this)
  }

  get localUserSettings() {
    return this.userSettingsManager.localSettings;
  }

  get remoteUserSettings() {
    return this.userSettingsManager.remoteSettings;
  }

  @observable isIdle: boolean = false;

  @observable _auth: OTAuth | undefined = undefined;
  registerAuth = (f: OTAuth) => {
    if (this._auth) logger.warn("OTAuth already registered");
    this._auth = f;
  };
  @computed get auth() {
    const f = this._auth;
    assertDefined(f);
    return f;
  }

  @observable _appHomeManager: AppHomeManager | undefined;
  registerAppHomeManager = (m: AppHomeManager) => (this._appHomeManager = m);
  @computed get appHomeManager() {
    const f = this._appHomeManager;
    assertDefined(f);
    return f;
  }

  @observable mediaDevices: IMediaDeviceSettings = {};

  @action reset() {
    this.isIdle = false;

    this._getTeamData = undefined;
    this._getUnsafeTeamData = undefined;

    this.userSettingsManager.reset();
    this._appHomeManager = undefined;
    this._callStateManager = undefined;

    this.mediaDevices = {};
    this._cache?.reset();
  }

  pluginConfigList: Record<string, IPluginConfig> = {};
  actionTypeDetails: Record<string, IActionTypeDetails> = {};

  _getTeamData: TGetTeamData | undefined = undefined;
  unregisterGetTeamData = () => {
    logger.info("unregistering getTeamData");
    this._getTeamData = undefined;
  };
  registerGetTeamData = (f: TGetTeamData) => {
    if (this._getTeamData) {
      logger.error("Error, getTeamData already registered");
      throw new Error("getTeamData has already been registered");
    }

    this._getTeamData = f;
  };
  get getTeamData() {
    const f = this._getTeamData;
    assertDefined(f);
    return f;
  }

  _getUnsafeTeamData: TGetUnsafeTeamData | undefined = undefined;
  registerGetUnsafeTeamData = (f: TGetTeamData) => {
    if (this._getUnsafeTeamData) {
      logger.error("Error, getTeamData already registered");
      throw new Error("getTeamData has already been registered");
    }

    this._getUnsafeTeamData = f;
  };
  get getUnsafeTeamData() {
    const f = this._getUnsafeTeamData;
    assertDefined(f);
    return f;
  }

  _analytics: IAnalytics | undefined;
  registerAnalytics = (f: IAnalytics) => (this._analytics = f);
  get analytics() {
    const f = this._analytics;
    if (!f) logger.warn("analytics not registered");
    return f;
  }

  _sentry: any;
  registerSentry = (sentry: any) => (this._sentry = sentry);
  get sentry() {
    const f = this._sentry;
    assertDefined(f);
    return f;
  }

  _getDisplayMedia: TGetDisplayMedia | undefined;
  registerGetDisplayMedia = (getDisplayMedia: TGetDisplayMedia) =>
    (this._getDisplayMedia = getDisplayMedia);
  get getDisplayMedia() {
    const f = this._getDisplayMedia;
    assertDefined(f);
    return f;
  }

  _getLinkPreview: TGetLinkPreview | undefined;
  registerGetLinkPreview = (getLinkPreview: TGetLinkPreview) =>
    (this._getLinkPreview = getLinkPreview);
  get getLinkPreview() {
    const f = this._getLinkPreview;
    assertDefined(f);
    return f;
  }

  _config: any | undefined;
  setConfig = (config: any) => (this._config = config);
  get config() {
    const f = this._config;
    assertDefined(f);
    return f;
  }

  @observable _callStateManager?: CallStateManager = undefined;
  setCallStateManager = (callStateManager: CallStateManager | undefined) =>
    (this._callStateManager = callStateManager);
  get callStateManager() {
    return this._callStateManager;
  }

  _cache: OTCacheClass | undefined;
  createCache = (version: string) => {
    this._cache = new OTCacheClass(version);
  };
  get cache() {
    const f = this._cache;
    assertDefined(f);
    return f;
  }
}

export const OTGlobals = new OTGlobalsClass();
