import { observer } from "mobx-react";
import { IUIWebcamStream } from "@openteam/models";
import React, { useEffect, useState } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { DSPillButton, IDSPillButtonProps } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { ButtonProps, getDeviceOptions, openCallMenu } from "./callButtonUtil";
import { isMacOs } from "react-device-detect";
import { MediaDeviceManager, OTUserInterface, UIDataState } from "@openteam/app-core";
import { Popover } from 'react-tiny-popover'
import { Menu } from "../../../Util/Menu";
import { DSPanel } from "../../../DesignSystem/DSLayout";


interface MicButtonProps extends Omit<IDSPillButtonProps, 'onMenuClick'> {
  vertical?: boolean
  size?: number
}

export const MicButton: React.FC<MicButtonProps> = observer(
  ({ style, size, color, button1Props, button2Props, ...otherProps }) => {

    const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false)
    const call = UIDataState.activeCall;
    const me = call?.participants[call.myUserId];
    const myStream = me?.streams["camera"] as IUIWebcamStream;
    const hasAudio = myStream?.muted === false;

    const toggleAudio = async () => {
      call?.actions.toggleAudio();
    }

    const openMenu = React.useCallback(async () => {
      await MediaDeviceManager.updateMediaDevices();
      setPopoverOpen(!isPopoverOpen)
    }, [isPopoverOpen, setPopoverOpen]);

    const notMuted = hasAudio

    if (call && call.participants[call.myUserId]) {

      return (
        <Popover
          padding={8}
          isOpen={isPopoverOpen}
          onClickOutside={() => setPopoverOpen(false)}
          content={<Menu
            template={[
              { enabled: false, label: `Select a Microphone` },
              ...getDeviceOptions("audioinput"),
              { type: "separator" },
              { enabled: false, label: `Select a Speaker` },
              ...getDeviceOptions("audiooutput"),
            ]}
          />
          }
        >
          <div>
            <DSPillButton
              style={style}
              stopPropagation={{ onClick: true }}
              icon={
                notMuted ?
                  <FaMicrophone
                    size={size ?? DSTheme.IconSize}
                    color={color}
                  /> :
                  <FaMicrophoneSlash
                    size={size ?? DSTheme.IconSize}
                    color={DSTheme.CancelColor}
                  />
              }
              {...otherProps}
              button1Props={{
                onClick: toggleAudio,
                "data-tooltip": notMuted ? "Mute" : 'UnMute',
                analyticsEvent: notMuted ? "callMute" : "callUnmute",
                ...button1Props
              }}
              button2Props={{
                onClick: openMenu,
                "data-tooltip": "Audio settings",
                analyticsEvent: "CallMicSettings",
                ...button2Props
              }}
            />
          </div>
        </Popover>
      );
    } else {
      return null;
    }
  }
);

