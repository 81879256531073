import React from "react"
import { Checkbox, CheckboxProps, FormControlLabel, styled } from "@mui/material"
import setAlpha from "./setAlpha";


export interface DS2CheckboxProps extends CheckboxProps {
  label?: string;
}

const DS2Checkbox: React.FC<DS2CheckboxProps> = ({label, style, ...props}) => {
  if (label) {
    return <FormControlLabel control={
      <StyledCheckbox {...props} icon={<OuterIcon />} checkedIcon={<OuterIcon><InnerIcon/></OuterIcon>}/>
    } style={style} label={label} />
  } else {
    return <StyledCheckbox style={style} {...props} />
  }
}

const StyledCheckbox = styled(Checkbox)(({theme, color}) => ({
  '.MuiSvgIcon-root': {
    color: theme.palette[color ?? 'primary'].main
  }
}))

const OuterIcon = styled('div')(({theme, color}) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  borderRadius: 1,
  width: 13,
  height: 13,
  margin: 2,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: setAlpha(theme.palette[color ?? 'primary'].main, 0.5),
}))

const InnerIcon = styled('div')(({theme, color}) => ({
  margin: 'auto',
  borderRadius: 1,
  width: 9,
  height: 9,
  backgroundColor: theme.palette[color ?? 'primary'].main,
}))

export default DS2Checkbox;
