import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signOut as FBsignOut,
  User,
} from "firebase/auth";
import { getDatabase, get, ref, onValue, serverTimestamp, update } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { LoginState } from "./UIState";
import { runInAction } from "mobx";
import * as Analytics from "../Util/Analytics";
import { ITeamDoc, IUserDoc } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import validate from "validate.js";
import { FireDb } from "@openteam/app-core";

const logger = new Logger("Login");

export const initLogin = () => {
  const unregisterAuthObserver = onAuthStateChanged(getAuth(), async (authUser) => {
    logger.debug("user auth", authUser);

    runInAction(() => {
      LoginState.isSignedIn = !!authUser;
      LoginState.isAnonymous = authUser?.isAnonymous ?? false;
      LoginState.initialised = !authUser;
    });

    if (authUser) {
      watchUser(authUser);
      Analytics.identifyUser(authUser.uid);
    } else {
      Analytics.reset();
    }
  });
  return unregisterAuthObserver;
};

export const signOut = () => {
  return FBsignOut(getAuth());
};

export const getCurrentUser = () => {
  const fbUser = getAuth().currentUser!;

  return {
    userId: fbUser.uid,
    email: fbUser.email!,
    name: fbUser.displayName,
    imageUrl: fbUser.photoURL,
  };
};

export const getCustomLoginToken = async () => {
  const functions = getFunctions();

  const getCustomToken = httpsCallable(functions, "getCustomToken");
  const result: any = await getCustomToken({});
  return result.data.authToken;
};

let userWatch;
let userTeamWatch;

function watchUser(authUser: User) {
  userWatch && userWatch()
  userTeamWatch && userTeamWatch()

  userWatch = onValue(ref(getDatabase(), `/users/${authUser!.uid}`), (snapshot) => {
    logger.debug(`user snapshot:`, snapshot.val());
    runInAction(() => {
      LoginState.user = snapshot.val();
      LoginState.initialised = true;
    });
  });

  userTeamWatch = onValue(ref(getDatabase(), `/userteams/${authUser!.uid}`), (snapshot) => {
    logger.debug(`teams snapshot:`, snapshot.val());
    runInAction(() => {
     LoginState.teams = snapshot.val()
    })
  })

}

export async function setupUser({
  name,
  imageUrl,
  referrerId,
}: Pick<IUserDoc, "name" | "imageUrl" | "referrerId">) {
  const { userId, email } = getCurrentUser();

  const user: IUserDoc = {
    name,
    email,
    imageUrl: imageUrl ?? null,
    accountSetup: true,
    crDate: serverTimestamp(),
    referrerId: referrerId ?? null,
  };

  await update(ref(getDatabase(), `/users/${userId}`), user);
}

export async function createTeam(teamName: string) {
  if (LoginState.user) {
    LoginState.needsInvite = true;
    LoginState.teamName = teamName;
    const teamId = await FireDb.createTeam(teamName);
    return teamId;
  }
}

export type TInviteDetails = {
  valid: boolean;
  teamName: string;
  imageUrl: string;
  teamId: string;
  inviterName: string;
  inviteremail: string;
  inviteToken: string;
};

export async function verifyInvite(invite: string): Promise<TInviteDetails> {
  console.log("verifyInvite ", invite);

  let inviteToken;
  try {
    inviteToken = new URL(invite).searchParams.get("inviteToken");
  } catch (err) {
    inviteToken = invite;
  }

  const functions = getFunctions();
  const checkInviteToken = httpsCallable(functions, "checkInviteToken");
  const result: any = await checkInviteToken({ inviteToken });

  console.log(`loadInvite answer for token: ${inviteToken}`, result);

  return { inviteToken, ...result.data };
}

export async function acceptInvite(inviteToken: string) {
  const functions = getFunctions();

  const joinWithInviteToken = httpsCallable(functions, "joinWithInviteToken");
  const result: any = await joinWithInviteToken({ inviteToken });
  return result.data;
}
