import events from "events";
import { autorun, computed, makeObservable, observable } from "mobx";
import Peer from "simple-peer";
import {
  IDataMsg,
  IPeerMsg,
  IStreamStateMsg,
  IStreamStateReqMsg,
  IStreamUser,
  TActionType,
  TStreamType,
} from "@openteam/models";
import { changeSDPCodec } from "./utils/WebRTCUtil";
import { sendAction } from "./Alert/alertFunctions";
import { Logger } from "@openteam/app-util";
import { RemoteStream, StreamDetails } from "./MediaDeviceManager";
import { SignalsDb } from "./fire/SignalsDb";
import { OTGlobals } from "./OTGlobals";
import { Database } from "firebase/database";

const logger = new Logger("PeerConnection");

export type PTTType = "PTT_WALKIE" | "PTT_GLOBAL";

export interface ISignalData {
  offer: string;
  fromInitiator: boolean;
  newConnection: boolean;
  pttType: PTTType;
  peerId: string;
}

export class P2PStreamManager extends events.EventEmitter {
  fbDb: Database;
  myUserId: string;
  mySessionToken: string;

  _peers: { [id: string]: PeerDetails } = {};

  _teamId: string;
  _roomId: string;
  _users: { [id: string]: IStreamUser } = {};
  _callUsers: string[] | null = null;
  _started: boolean = false;
  _sendStreams: Map<"camera" | "screen", StreamDetails>;
  _signalCache: { [id: string]: any[] } = {};

  _loudestStream: StreamDetails | null = null;
  _loudestLastChanged: number;

  _autorun: Record<string, any> = {};

  constructor(
    fbDb: Database,
    userId: string,
    sessionToken: string,
    teamId: string,
    roomId: string
  ) {
    super();

    logger.info(`Creating P2PStreamManager for userId=${userId} teamId=${teamId}:${roomId}`);
    this.fbDb = fbDb;
    this.myUserId = userId;
    this.mySessionToken = sessionToken;

    this._teamId = teamId;
    this._roomId = roomId;
    this._sendStreams = new Map();
    this._loudestLastChanged = Date.now();
  }

  start = () => {
    if (this._started) {
      return;
    }
    this._started = true;

    // use our session token, not user token to avoid issues with multiple sessions for a single user
    SignalsDb.watchSignals(
      this.fbDb,
      this._teamId,
      this.myUserId,
      this.mySessionToken,
      this._roomId,
      (data) => {
        this._handleSignalMsg(data);
      }
    );

    this.emit("connected");

    this._autorun["calcLoudest"] = autorun(this._calcLoudestStream);

    return this;
  };

  stop = () => {
    SignalsDb.unwatchSignals(
      this.fbDb,
      this._teamId,
      this.myUserId,
      this.mySessionToken,
      this._roomId
    );

    Object.keys(this._peers).forEach((userId) => this._disconnectPeer(userId));

    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };

  updateUsers = (users: { [id: string]: IStreamUser }) => {
    logger.info(`setting streamManager users, roomId=${this._roomId} users=${users}`);

    Object.keys(users).forEach((userId) => {
      if (userId in this._signalCache) {
        logger.info("user ", userId, "in signalCache");

        this._signalCache[userId].forEach((msg) => this._handleSignalMsg(msg));
        delete this._signalCache[userId];
      }
    });

    Object.keys(this._peers).forEach((userId) => {
      if (!users[userId]) {
        this._disconnectPeer(userId);
      }
    });

    var teamUserDoc = users[this.myUserId];

    this._users = users;

    if (teamUserDoc) {
      var loginTime = teamUserDoc.status?.last_changed;
      var name = teamUserDoc.name;

      Object.keys(users).forEach((userId) => {
        if (this._shouldConnect(userId)) {
          this._connectToPeer(userId);
        }
      });
    }
  };

  _shouldConnect(userId) {
    if (!this._peers[userId]) {
      var teamUserDoc = this._users[this.myUserId];
      var loginTime = teamUserDoc.status?.last_changed;
      var name = teamUserDoc.name;

      if (loginTime && userId != this.myUserId && userId in this._users) {
        var teamUser = this._users[userId];
        if (teamUser.status && teamUser.status.sessionToken) {
          if (
            teamUser.status.sessionToken in (teamUser.status.activeSessions || {}) &&
            (teamUser.status.last_changed < loginTime ||
              (teamUser.status.last_changed == loginTime && teamUser.name > name))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  setCallUsers = (userIds: string[]) => {
    logger.debug(`Setting call users to ${userIds}`);

    if (this._callUsers && this._sendStreams.size > 0) {
      this._callUsers.forEach((userId) => {
        if (!userIds.includes(userId) && this._peers[userId]) {
          Array.from(this._sendStreams.values()).forEach((streamDetails) => {
            this._peers[userId].cancelStream(streamDetails);
          });
        }
      });

      userIds.forEach((userId) => {
        if (!this._callUsers!.includes(userId) && this._peers[userId]) {
          Array.from(this._sendStreams.values()).forEach((streamDetails) => {
            this._peers[userId].sendStream(streamDetails);
          });
        }
      });
    }

    this._callUsers = userIds;
  };

  clearCallUsers() {
    this._callUsers = null;
  }

  get connectedUsers() {
    let peers;
    if (this._callUsers) {
      peers = this._callUsers.map((userId) => this._peers[userId]);
    } else {
      peers = Object.values(this._peers);
    }

    return peers.filter((p) => p && p.connected).map((p) => p.userId);
  }

  getUserStreams = (userId) => {
    if (this._peers[userId]) {
      return Object.values(this._peers[userId].inStreams);
    }
    return [];
  };

  addStream = (stream: StreamDetails) => {
    if (this._callUsers) {
      if (this._sendStreams.has(stream.streamType)) {
        this.cancelStream(stream);
      }

      this._sendStreams.set(stream.streamType, stream);

      this._callUsers.forEach((userId) => {
        if (this._peers[userId]) {
          this._peers[userId].sendStream(stream);
        }
      });
    } else {
      logger.error("addStream() called outside a call, ignoring");
    }
  };

  cancelStream = (stream: StreamDetails) => {
    Object.values(this._peers).forEach((peer) => peer.cancelStream(stream));
    this._sendStreams.delete(stream.streamType);
  };

  shutdownStreams = () => {
    Object.values(this._peers).forEach((peer) => peer.shutdownStreams());
  };

  sendMessageToAll(message: IPeerMsg) {
    Object.values(this._peers).forEach((peer) => peer.sendMessage(message));
  }

  sendMessage = (userId: string, message: IPeerMsg) => {
    if (message.msgType == "ACTION") {
      sendAction(this.fbDb, this.myUserId, this._teamId, userId, message.actionType);
    }

    if (this._peers[userId]) {
      this._peers[userId].sendMessage(message);
    }
  };

  sendDataMessage = (userId, dataType, data) => {
    var msg: IDataMsg = {
      msgType: "DATA_MESSAGE",
      dataType: dataType,
      data: data,
    };
    this.sendMessage(userId, msg);
  };

  _getUserSessionToken = (userId: string) => {
    return this._users[userId]?.status?.sessionToken;
  };

  _connectToPeer = (userId) => {
    if (userId == this.myUserId || this._peers[userId]) {
      return;
    }

    logger.info("connectToPeer:", this._roomId, userId);

    this._createPeer(userId, true);
  };

  _disconnectPeer = (userId) => {
    if (this._peers[userId]) {
      this._peers[userId].shutdownStreams();
      this._peers[userId].destroy();
      delete this._peers[userId];
    }
  };

  _createPeer = (userId: string, initiator: boolean) => {
    logger.debug(`_createPeer myUserId=${this.myUserId} userId=${userId}`);
    this._peers[userId] = new PeerDetails(
      this.fbDb,
      this.myUserId,
      userId,
      this._teamId,
      this._roomId,
      initiator,
      () => this._getUserSessionToken(userId)!,
      this._shouldAcceptStream,
      {
        onClose: this._onClose,
        onError: this._onError,
        onConnect: this._onPeerConnect,
        onMessage: this._onMessage,
        onAction: this._onAction,
        onScreenShare: this._onScreenShare,
        onDataMsg: this._onDataMsg,
        onStreamConnected: this._onStreamConnected,
      }
    );
  };

  _handleSignalMsg = (data) => {
    const msgData = data;

    logger.info("_handleSignalMsg ", msgData);

    var msg = JSON.parse(msgData.offer);
    var fromInitiator = msgData.fromInitiator;
    var from = msgData.from;

    if (!(from in this._users || this._signalCache[from])) {
      logger.info("received message for user", from, " I don't know about yet, adding to cache");
      if (!this._signalCache[from]) {
        this._signalCache[from] = [];
      }
      this._signalCache[from].push(data);
      return;
    }

    if (msg.type == "offer") {
      if (this._peers[from] && fromInitiator && msgData.newConnection) {
        logger.info("Destroying old connection");
        this._peers[from].peer.destroy();
        delete this._peers[from];
      }

      if (!this._peers[from]) {
        logger.debug("_handleSignalMsg._createPeer");
        this._createPeer(from, false);
      }
    }

    if (this._peers[from]) {
      logger.debug("signal message from", from, msg);
      this._peers[from].peer.signal(msg);
    }
  };

  _onError = (userId: string, err: any) => {
    if (this._peers[userId]) {
      this._peers[userId].peer.destroy();
      delete this._peers[userId];
      logger.info("_onError for ", userId);
      setTimeout(() => {
        if (this._shouldConnect(userId)) {
          this._connectToPeer(userId);
        }
      }, 5000);
    }
  };

  _onClose = (userId: string) => {
    delete this._peers[userId];
    logger.info("_onClose for ", userId);
    this.emit("peerdisconnected", userId);
  };

  _onPeerConnect = (userId: string) => {
    if (Object.keys(this._users).includes(userId)) {
      if (this._callUsers?.includes(userId)) {
        logger.debug(`user '${userId}' connected sending streams`);

        Array.from(this._sendStreams.values()).forEach((streamDetails) => {
          this._peers[userId].sendStream(streamDetails);
        });
      }

      this.emit("peerconnected", userId);
    } else {
      logger.warn(`user '${userId}' connected to room but not in users list {}`);
      this._disconnectPeer(userId);
    }
  };

  _onMessage = (userId: string, message: any) => {
    this.emit("message", userId, message);
  };

  _onAction = (userId: string, actionType: TActionType) => {
    this.emit("action", userId, actionType);
  };

  _onScreenShare = (userId: string, streamId: string) => {
    this.emit("screenshare", userId, streamId);
  };

  _onDataMsg = (userId: string, msg: IDataMsg) => {
    this.emit("datamessage", userId, msg);
  };

  _onStreamConnected = (streamId: string, userId: string) => {
    this.emit("streamconnected", streamId, userId);
  };

  _shouldAcceptStream = (userId) => {
    if (this._callUsers) {
      return this._callUsers.includes(userId);
    }
    const teamData = OTGlobals.getTeamData(this._teamId);
    logger.debug(`_shouldAcceptStream: teammId ${this._teamId}`, teamData);
    return teamData.me.canPtt;
  };

  _calcLoudestStream = () => {
    var loudestStream: StreamDetails | null = this._loudestStream;

    Object.values(this._peers)
      .filter((peer) => peer.connected)
      .forEach((peer) => {
        Object.values(peer.inStreams).forEach((stream) => {
          if (loudestStream == null || stream.volumeAvg > loudestStream.volumeAvg) {
            loudestStream = stream;
          }
        });
      });

    if (this._loudestStream != loudestStream) {
      this.emit("speaker", loudestStream);
      this._loudestStream = loudestStream;
    }
  };
}

export class InStreamDetails extends RemoteStream {
  @observable _pttState: string | undefined;

  constructor(
    teamId,
    roomId,
    userId: string,
    stream: MediaStream,
    streamType: TStreamType,
    pttState?: string
  ) {
    super(teamId, roomId, userId, streamType || "camera", stream);

    makeObservable(this)

    this._pttState = pttState;
    this.stream.addEventListener("removetrack", (ev) => {
      this.removeTrack(ev.track);
    });
  }

  @computed get isPushTalking() {
    return this._pttState == "PTT_WALKIE" && this.hasAudio;
  }

  @computed get isBroadcasting() {
    return this._pttState == "PTT_GLOBAL" && this.hasAudio;
  }

  set pttState(value: string | undefined) {
    logger.info("setting pttState", value);
    this._pttState = value;
  }

  checkTracks = () => {
    var tracks: { [kind: string]: MediaStreamTrack } = {};

    this.stream.getTracks().forEach((track) => {
      if (!this.tracks.has(track.kind) || this.tracks.get(track.kind)!.id != track.id) {
        this.addTrack(track);
      }
    });
  };
}

export class PeerDetails {
  peer: Peer;
  fbDb: Database;
  teamId: string;
  roomId: string;
  myUserId: string;
  userId: string;
  initiator: boolean;
  getSessionToken: () => string;
  shouldAcceptStream: (string) => boolean;
  connected: boolean = false;
  _pttState: TActionType | undefined;
  outStreams: { [streamId: string]: StreamDetails } = {};
  inStreams: { [streamId: string]: InStreamDetails } = {};

  constructor(
    fbDb: Database,
    myUserId: string,
    userId: string,
    teamId: string,
    roomId: string,
    initiator: boolean,
    getSessionToken: () => string,
    shouldAcceptStream: (string) => boolean,
    callbacks: { [eventName: string]: (userId: string, ...others) => void }
  ) {
    logger.info("Creating peer for ", myUserId, "as initiator: ", initiator);
    this.fbDb = fbDb;
    this.peer = new Peer({
      initiator: initiator,
      trickle: false,
      config: OTGlobals.config,
    });
    this.teamId = teamId;
    this.roomId = roomId;
    this.myUserId = myUserId;
    this.userId = userId;
    this.initiator = initiator;
    this.getSessionToken = getSessionToken;
    this.shouldAcceptStream = shouldAcceptStream;
    this.bindEvents(callbacks);
  }

  bindEvents = (callbacks) => {
    this.peer.on("error", (err) => {
      logger.info("error for", this.userId, err.message);

      if (callbacks.onError) {
        callbacks.onError(this.userId, err);
      }
    });

    this.peer.on("connect", () => {
      logger.info("Connection established ", this.userId);
      // wait for 'connect' event before using the data channel
      // peer.send('hey ' + userId + ', how is it going?')
      this.connected = true;
      if (callbacks.onConnect) {
        callbacks.onConnect(this.userId);
      }
      // Analytics.logEvent("peerconnection__peer_connected")
    });

    this.peer.on("close", () => {
      logger.info("Connection closed ", this.userId);
      if (callbacks.onClose) {
        callbacks.onClose(this.userId);
      }
      // Analytics.logEvent("peerconnection__peer_disconnected")
    });

    this.peer.on("data", (data) => {
      var msg: IPeerMsg = JSON.parse(data);

      if (msg.msgType == "SIGNAL") {
        logger.info("Received signal message on data channel", msg.data);
        this.peer.signal(msg.data);
      } else if (msg.msgType == "ACTION") {
        logger.info(
          `got an ACTION from userId=${this.userId}, actionType=${msg.actionType}, teamId=${this.teamId}`
        );
        const teamData = OTGlobals.getTeamData(this.teamId);

        if (msg.actionType.startsWith("PTT") && !teamData.me.canPtt) {
          logger.warn("Recieved unwanted PTT request");
        } else if (msg.actionType.startsWith("PTT")) {
          this._pttState = msg.actionType;

          Object.values(this.inStreams).forEach((stream) => {
            stream.pttState = this._pttState;
          });
        }

        if (callbacks.onAction) {
          callbacks.onAction(this.userId, msg.actionType);
        }
      } else if (msg.msgType == "STREAM_STATE") {
        logger.info("got an STREAM_STATE from " + this.userId + ": ", msg);
        if (msg.streamType == "deleted") {
          delete this.inStreams[msg.streamId];
        } else {
          this.setStreamState(msg.streamId, msg.kind, msg.enabled, msg.streamType);
          if (msg.streamType == "screen") {
            if (callbacks.onScreenShare) {
              callbacks.onScreenShare(this.userId, msg.streamId);
            }
          }
        }
      } else if (msg.msgType == "STREAM_STATE_REQ") {
        logger.info("got an STREAM_STATE_REQ from " + this.userId + ": ", msg);
        this.sendStreamInfo(msg.streamId);
        if (callbacks.onStreamConnected) {
          callbacks.onStreamConnected(msg.streamId, this.userId);
        }
      } else if (msg.msgType == "DATA_MESSAGE") {
        logger.info("got DATA_MESSAGE from " + this.userId + ": ", msg);
        callbacks.onDataMsg(this.userId, msg);
      } else {
        if (callbacks.onMessage) {
          callbacks.onMessage(this.userId, msg);
        }
      }
    });

    this.peer.on("stream", (stream: MediaStream) => {
      logger.info("received stream from user: ", this.userId);
      logger.info(stream);
      this.registerStream(stream);
    });

    this.peer.on("track", (track: MediaStreamTrack, stream: MediaStream) => {
      logger.info("received", track.kind, "track from user:", this.userId);
      this.registerStream(stream);
      this.checkStream(stream.id);
    });

    this.peer.on("signal", (data) => {
      data = changeSDPCodec(data); // prefer h264

      if (this.connected) {
        logger.info("Sending signal msg on data channel", data);
        this.sendMessage({ msgType: "SIGNAL", data });
      } else {
        var offerdata = {
          from: this.myUserId,
          to: this.userId,
          offer: JSON.stringify(data),
          fromInitiator: this.initiator,
          newConnection: !this.connected,
        };
        var sessionToken = this.getSessionToken();
        logger.debug(
          `Signaling user myUserId=${this.myUserId}, teamId=${this.teamId} userId=${this.userId}, sessionToken=${sessionToken}, data=${data}`
        );
        SignalsDb.addSignal(
          this.fbDb,
          this.teamId,
          this.userId,
          sessionToken,
          this.roomId,
          offerdata
        );
      }
    });
  };

  registerStream = (stream: MediaStream) => {
    if (!this.inStreams[stream.id]) {
      if (this.shouldAcceptStream(this.userId)) {
        logger.info("Adding stream", stream.id, "from", this.userId);
        const streamType = "camera";
        const inStream = new InStreamDetails(
          this.teamId,
          this.roomId,
          this.userId,
          stream,
          streamType,
          this._pttState
        );
        this.inStreams[stream.id] = inStream;
        inStream.on("trackremoved", (track) => this.checkStream(stream.id));
        this.reqStreamInfo(stream.id);
      } else {
        logger.warn("Rejecting unwanted stream");
      }
    }
  };

  checkStream = (streamId: string, excTrack?: MediaStreamTrack) => {
    if (this.inStreams[streamId]) {
      if (this.inStreams[streamId].stream.active) {
        this.inStreams[streamId].checkTracks();
      }

      if (!this.inStreams[streamId].stream.active || this.inStreams[streamId].isEmpty()) {
        logger.info(
          "Removing inactive stream for",
          this.userId,
          this.inStreams[streamId],
          this.inStreams[streamId].isEmpty()
        );
        delete this.inStreams[streamId];
      }
    }

    if (!this.inStreams[streamId] || !this.inStreams[streamId].hasAudio) {
      this._pttState = undefined;
    }
  };

  setStreamState = (
    streamId: string,
    kind: "audio" | "video",
    enabled: boolean,
    streamType: TStreamType
  ) => {
    if (this.inStreams[streamId]) {
      this.inStreams[streamId].enable(kind, enabled);

      Object.values(this.inStreams)
        .filter((inStrm) => inStrm.streamType == streamType && inStrm.stream.id != streamId)
        .forEach((existingStream) => {
          logger.warn(`Found existing ${streamType} inStream, removing`);
          delete this.inStreams[existingStream.stream.id];
        });

      if (this.inStreams[streamId].streamType != streamType) {
        this.inStreams[streamId].streamType = streamType;
      }
    }
  };

  sendStream = (streamDetails: StreamDetails) => {
    if (this.connected && streamDetails) {
      if (!this.outStreams[streamDetails.stream.id]) {
        Object.values(this.outStreams)
          .filter((oStrm) => oStrm.streamType == streamDetails.streamType)
          .forEach((existingStream) => {
            logger.warn(`Found existing ${streamDetails.streamType} outstream cancelling`);
            this.cancelStream(existingStream);
          });

        this.outStreams[streamDetails.stream.id] = streamDetails;

        logger.info("Sending stream id", streamDetails.stream.id, " to ", this.userId);

        this.peer.addStream(streamDetails.stream);

        streamDetails.on("muted", (track, streamDetails) => {
          this._enableTrack(streamDetails, track, false);
        });

        streamDetails.on("unmuted", (track, streamDetails) => {
          this._enableTrack(streamDetails, track, true);
        });

        streamDetails.on("trackadded", (track, streamDetails) => {
          this.peer.addTrack(track, streamDetails.stream);
        });

        streamDetails.on("trackremoved", (track, streamDetails) => {
          this.peer.removeTrack(track, streamDetails.stream);
        });
      }
    } else {
      logger.info(
        "Unable to send stream",
        streamDetails.stream,
        "to peer",
        this.userId,
        ", connected",
        this.connected
      );
    }
  };

  _enableTrack = (stream: StreamDetails, track: MediaStreamTrack, enabled: boolean) => {
    const msg: IStreamStateMsg = {
      msgType: "STREAM_STATE",
      streamId: stream.stream.id,
      streamType: stream.streamType,
      kind: track.kind as "audio" | "video",
      enabled: track.enabled,
    };

    this.sendMessage(msg);
  };

  reqStreamInfo = (streamId: string) => {
    let msg: IStreamStateReqMsg = {
      msgType: "STREAM_STATE_REQ",
      streamId: streamId,
    };

    this.sendMessage(msg);
  };

  sendStreamInfo = (streamId: string) => {
    let msg: IStreamStateMsg;

    if (streamId in this.outStreams) {
      msg = {
        msgType: "STREAM_STATE",
        streamId: streamId,
        streamType: this.outStreams[streamId].streamType,
        kind: "audio",
        enabled: this.outStreams[streamId].muted == false,
      };
    } else {
      msg = {
        msgType: "STREAM_STATE",
        streamId: streamId,
        streamType: "deleted",
        kind: "audio",
        enabled: false,
      };
    }
    this.sendMessage(msg);
  };

  cancelStream = (streamDetails: StreamDetails) => {
    if (this.outStreams[streamDetails.stream.id]) {
      logger.info(`Cancelling ${streamDetails.streamType} stream to ${this.userId}`);
      this.peer.removeStream(streamDetails.stream);
      delete this.outStreams[streamDetails.stream.id];
    }
  };

  shutdownStreams = () => {
    Object.values(this.outStreams).forEach((streamDetails) => {
      this.cancelStream(streamDetails);
    });
    // this.inStreams = {}
  };

  destroy = () => {
    this.peer.destroy();
    this.peer = undefined;
  };

  sendMessage = (message: IPeerMsg) => {
    if (this.connected && this.peer) {
      try {
        this.peer.send(JSON.stringify(message));
      } catch (err) {
        logger.warn("Error sending on data channel", err);
      }
    }
  };
}
