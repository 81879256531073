import { observer } from "mobx-react";
import React, { CSSProperties, useEffect, useState } from "react";
import { FaComment } from "react-icons/fa";
import { DSCircleIconButton } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";

interface Props {
    color?: string
    size?: number;
    style?: CSSProperties;
    show?: boolean
    chatOpen?: boolean
    onChatOpen?: (open: boolean) => void
    badgeNum?: number
}

export const ChatButton: React.FC<Props> = observer(({ size, style, show = true, chatOpen, onChatOpen, ...props }) => {
    const opacity = show ? 1 : 0;
    const transition = opacity ? 'opacity 0.2s ease-out' : 'opacity 0.3s ease-in';

    return (
        <DSCircleIconButton
            data-tooltip="Open chat"
            data-tip-position="bottom"
            analyticsEvent={chatOpen ? "callChatClose" : "callChatOpen"}
            selected={chatOpen}
            style={{padding: 7, ...style, opacity, transition}}
            icon={<FaComment size={size ?? DSTheme.IconSize} color={DSTheme.MainIcon} />}
            onClick={() => onChatOpen?.(!chatOpen)}
            {...props}
        />
    );
})