import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { DSButton } from '../../DesignSystem/DSButton';
import { CallVideo } from './CallVideo';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { HoverShade } from './CallTileUtil';
import { UIDataState } from '@openteam/app-core';

interface ScreenShareTileProps {
  userId: string;
  size: number;
  circular?: boolean;
  onClick?: () => void
}

const ScreenShareTile: React.FC<ScreenShareTileProps> = (
  { userId, size, circular = false, onClick }
) => {
  const callUser = UIDataState.activeCall?.participants[userId];
  const stream = callUser?.streams['screen'];
  const [hovered, setHovered] = useState(false);
  const screenShareOpen = UIDataState.activeCall?.screenShares[userId] === true;

  return stream ? (
    <DSButton
      data-tooltip={`${screenShareOpen ? 'Focus' : 'Open'} screenshare`}
      // onClick={() => focusScreenShareWindow(userId)}
      onClick={onClick}
      analyticsEvent={screenShareOpen ? "CallScreenshareFocus" : "CallScreenshareOpen"}
      //style={{ filter: 'brightness(100%)' }}
      onHovered={setHovered}
      style={{
        filter: 'brightness(100%)',
        position: 'relative',
        aspectRatio: '1/1',
        width: size,
        height: size,
        borderRadius: circular ? 999 : DSTheme.BaseBorderRadius,
        overflow: 'hidden',
        backgroundColor: 'black'
      }}
    >
      <CallVideo
        key={`${userId}-screenshare`}
        track={stream.videoTrack}
        hasVideo={stream.hasVideo}
      />
      <HoverShade tileHovered={hovered}>
        <FaExternalLinkAlt size={Math.min(size / 2, 30)} />
      </HoverShade>

    </DSButton>
  ) : null;
};

export default observer(ScreenShareTile);
