import { IUISpaceUser, KSpaceId } from "@openteam/models";
import { observer } from "mobx-react";
import React, { CSSProperties, useCallback } from "react";
import {
  FaCalendar,
  FaCamera,
  FaCheck,
  FaClock,
  FaCog,
  FaComment,
  FaHandRock,
  FaPen,
  FaPhone,
  FaPlus,
  FaThumbtack,
  FaTimes,
  FaTrashAlt,
  FaUpload,
  FaUserPlus,
  FaVideo,
  FaCommentDots,
  FaSearch,
  FaBell,
  FaBellSlash,
  FaArrowAltCircleUp,
  FaCheckCircle,
  FaTimesCircle,
  FaGraduationCap,
  FaInfo,
  FaSave
} from "react-icons/fa";
import { DataState } from "../Data/DataState";
import { DSCircleIconButton, IDSCircleIconButtonProps } from "./DSButton";
import { DSPanel, DSRow } from "./DSLayout";
import { DSTheme } from "./DSTheme";

interface IconButtonProps extends IDSCircleIconButtonProps {
  size?: number;
  color?: string;
}

export const DSJoinCall: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaVideo size={size ?? DSTheme.IconSize} style={{ color: color ?? DSTheme.ColorContrastColor }} />}
      title="Enter"
      analyticsEvent="joinCall"
      {...otherProps}
      style={{ backgroundColor: DSTheme.SuccessColor, ...Styles.buttonStyle, ...style }}
    />
  );
};

export const DSRequestJoinCall: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaHandRock
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ColorContrastColor }}
        />
      }
      title="Request"
      analyticsEvent="requestJoinCall"
      {...otherProps}
      style={{ backgroundColor: DSTheme.SuccessColor, ...Styles.buttonStyle, ...style }}
    />
  );
};


export const DSCancelRequest: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaTimes
          size={size ?? 15}
          style={{ color: color ?? DSTheme.ColorContrastColor }}
        />
      }
      title="Cancel"
      analyticsEvent="cancelRequestJoinCall"
      style={{ backgroundColor: DSTheme.CancelColor, ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
};



export const DSLeaveCall: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaPhone
          size={size ?? DSTheme.IconSize}
          style={{
            color: color ?? DSTheme.ColorContrastColor,
            transform: "rotate(225deg)"
          }}
        />

      }
      title="Leave"
      analyticsEvent="leaveCall"
      style={{ backgroundColor: DSTheme.CancelColor, ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
};

export const DSCancelCall: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaVideo size={size ?? DSTheme.IconSize} color={color ?? DSTheme.CancelColor} />}
      title="Cancel Call"
      analyticsEvent="cancelCall"
      {...otherProps}
      style={{ ...Styles.buttonStyle, ...style }}
    />
  );
};

export const DSAnswerCall: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaCheck size={size ?? DSTheme.IconSize} style={{ color: color ?? DSTheme.ColorContrastColor }} />}
      title="Answer"
      analyticsEvent="answerCall"
      style={{ backgroundColor: DSTheme.AnswerColor, ...Styles.buttonStyle, ...style }}
      hoverStyle={{ backgroundColor: DSTheme.AnswerHoverColor }}
      {...otherProps}
    />
  );
};

export const DSRejectCall: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaTimes
          size={size ?? 15}
          style={{ color: color ?? DSTheme.ColorContrastColor }}
        />
      }
      title="Reject"
      analyticsEvent="rejectCall"
      style={{ backgroundColor: DSTheme.CancelColor, ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSHoldCall: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaClock
          size={size ?? 18}
          style={{ color: color ?? DSTheme.ColorContrastColor }}
        />
      }
      title="Hold On"
      analyticsEvent="holdCall"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSKnockUser: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaHandRock
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      mouseDownStyle={{ backgroundColor: DSTheme.EmphasisColor }}
      title="Knock"
      analyticsEvent="userKnock"
      badgePosition="BottomRight"
      hoverStyle={{
        backgroundColor: DSTheme.EmphasisBackgroundColor
      }}
      hoverBadgeOverride={otherProps.badgeNum ? <FaTimes /> : undefined}
      hoverTitle={otherProps.badgeNum ? "Clear" : undefined}
      style={{
        ...Styles.buttonStyle,
        ...style,
        backgroundColor: (otherProps.badgeNum || 0) > 0 ? DSTheme.EmphasisColor : DSTheme.ButtonBackgroundColor
      }}
      {...otherProps}
    />
  );
});

export const DSCallUser: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <DSPanel style={{ position: 'relative', }}>
          <FaVideo
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.ButtonIconColor }}
          />
          {DataState.activeCall && !DataState.activeCall.focusRoom ?
            <DSPanel style={{
              position: 'absolute',
              left: 0,
              right: "25%",
              top: 0,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <FaPlus
                size={DSTheme.IconSize / 2}
                style={{ color: DSTheme.SuccessColor }}
              />
            </DSPanel>
            : null
          }
        </DSPanel>
      }
      title={DataState.activeCall ? "Invite" : "Call"}
      analyticsEvent="userCall"
      badgePosition="BottomRight"
      hoverStyle={{
        backgroundColor: DSTheme.EmphasisBackgroundColor
      }}
      hoverBadgeOverride={otherProps.badgeNum ? <FaTimes /> : undefined}
      hoverTitle={otherProps.badgeNum ? "Clear" : undefined}
      style={{ backgroundColor: DSTheme.SuccessColor, ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

interface CallUserButtonProps extends IconButtonProps {
  spaceId: KSpaceId
  userId: string
  onCall?: () => void
  onCancel?: () => void
}

export const DSCallUserFull: React.FC<CallUserButtonProps> = (
  { spaceId, userId, onCall, onCancel, ...otherProps }) => {
  const space = DataState.spaces[spaceId]

  let user: IUISpaceUser = space.users[userId]
  const badgeNum = user.alerts?.called?.count

  const cancelCall = useCallback(() => {
    if (user.outgoing?.calling) {
      user.actions.callUser()
      onCancel && onCancel()
    }
  }, [onCancel, user.actions.callUser, user.outgoing?.calling])

  const startCall = useCallback(() => {
    if (!user.outgoing?.calling) {
      user.actions.callUser()
      onCall && onCall()
    }
  }, [onCall, user.actions.callUser, user.outgoing?.calling])

  if (user.outgoing?.calling) {
    return <DSCancelCall onClick={cancelCall} {...otherProps} />
  } else {
    const disabled = !(user.status?.online && user.status?.status !== 'INCALL')
    return <DSCallUser
      onClick={() => {
        if (user.alerts?.called?.count && user.alerts?.called?.count > 0) {
          user.alerts.clear()
        } else {
          startCall()
        }
      }}
      badgeNum={badgeNum}
      disabled={disabled} {...otherProps} />
  }
}

export const DSOpenChat: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
          <FaComment
            size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.PanelContrastColor }}
          />
      }
      title="Chat"
      analyticsEvent="userChat"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});


interface PinProps extends IconButtonProps {
  pinned?: boolean;
}

export const DSPin: React.FC<PinProps> = observer(({ size, color, style, pinned = false, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaThumbtack
          size={size ?? DSTheme.IconSize}
          style={
            pinned
              ? { transition: "all 0.075s ease-in", color: DSTheme.EmphasisColor }
              : {
                transition: "all 0.075s ease-in",
                transform: "rotate(45deg)",
                color: DSTheme.ButtonIconColor,
              }
          }
        />
      }
      title="Pin"
      analyticsEvent="userPinToggle"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSSettings: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      data-tooltip="Open settings"
      icon={
        <FaCog
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Settings"
      analyticsEvent="settings"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSMeetings: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaCalendar
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Meetings"
      analyticsEvent="DockMeetingButton"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});


export const DSPlus: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaPlus
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Add"
      analyticsEvent="addButton"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSUserPlus: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaUserPlus
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Invite Teammate"
      analyticsEvent="inviteUserButton"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

interface EditProps extends IconButtonProps {
  editing?: boolean;
}

export const DSEdit: React.FC<EditProps> = observer(({ size, color, style, editing = true, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        editing ?
          <FaPen
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.EmphasisColor }}
          /> :
          <FaPen
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.ButtonIconColor }}
          />
      }
      title="Edit"
      analyticsEvent="editButton"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

interface SelfieButtonProps extends IconButtonProps {
  toggled?: boolean
}
export const DSSelfieButton: React.FC<SelfieButtonProps> = observer(({ size, color, style, toggled, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        toggled ?
          <FaTimes
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.ButtonIconColor }}
          />
          :
          <FaCamera
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.ButtonIconColor }}
          />
      }
      title="Take picture"
      analyticsEvent="selfieButton"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSImageUpload: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaUpload
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Upload Image"
      analyticsEvent="uploadImageButton"
      badgePosition="BottomRight"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSTrash: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaTrashAlt
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Delete"
      analyticsEvent="delete"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
});

export const DSFeedback: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaCommentDots
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Feedback"
      analyticsEvent="feedback"
      style={{ ...Styles.buttonStyle, }}
      outerStyle={style}
      {...otherProps}
    />
  );
});


interface TutorialButtonProps extends IconButtonProps {
  showAlert?: boolean
}

export const DSTutorial: React.FC<TutorialButtonProps> = observer(({ showAlert, size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaGraduationCap
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Tutorial"
      analyticsEvent="manualStartTutorial"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    >
      {
        showAlert ?
          <div
            style={{
              position: "absolute",
              display: 'flex',
              top: -1,
              bottom: -1,
              left: -1,
              right: -1,
              height: 10,
              width: 10,
              borderRadius: 8,
              backgroundColor: DSTheme.EmphasisColor,
              transition: "opacity 0.15s ease-in-out",
            }}
          />
          :
          null
      }

    </DSCircleIconButton>
  );
});

export const DSSearch: React.FC<IconButtonProps> = observer(({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaSearch
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.ButtonIconColor }}
        />
      }
      title="Search"
      analyticsEvent="search"
      style={{ ...Styles.buttonStyle }}
      outerStyle={style}
      {...otherProps}
    />
  );
});

export const DSUpgrade: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaArrowAltCircleUp
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? DSTheme.WarningColor }}
        />
      }
      title="Upgrade"
      analyticsEvent="upgrade"
      style={{ ...Styles.buttonStyle, color: DSTheme.WarningColor }}
      outerStyle={style}
      {...otherProps}
    />
  );
};

interface AlertProps extends IconButtonProps {
  enabled?: boolean;
}

export const DSAlert: React.FC<AlertProps> = ({ enabled, size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        enabled ?
          <FaBellSlash
            size={size ?? DSTheme.IconSize}
            style={{ color: color ?? DSTheme.DisabledColor }}
          />
          :
          <FaBell
            size={size ?? DSTheme.IconSize}
            style={{ color: DSTheme.ButtonIconColor }}
          />
      }
      title="Alerts"
      analyticsEvent="muteAlertButton"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
};

export const DSAccept: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={
        <FaCheck
          size={size ?? DSTheme.IconSize}
          style={{ color: color ?? "white" }}
        />
      }
      title="Accept"
      analyticsEvent="accept"
      style={{ backgroundColor: DSTheme.SuccessColor, ...Styles.buttonStyle, ...style }}
      hoverStyle={{ backgroundColor: "rgba(0, 145, 80, 0.5)" }}
      {...otherProps}
    />
  );
};

export const DSReject: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return <DSCircleIconButton
    icon={
      <FaTimes
        size={size ?? DSTheme.IconSize}
        style={{ color: color ?? "white" }}
      />
    }
    title="Reject"
    analyticsEvent="reject"
    style={{ backgroundColor: "black", ...Styles.buttonStyle, ...style }}
    hoverStyle={{ backgroundColor: "rgba(255, 10, 0, 0.5)" }}
    {...otherProps}
  />
}

export const DSStop: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaTimes size={size ?? DSTheme.IconSize} style={{ color: color ?? 'white' }} />}
      outerStyle={style}
      title="Stop"
      analyticsEvent="stop"
      style={{
        backgroundColor: DSTheme.CancelColor,
        ...Styles.buttonStyle
      }}
      hoverStyle={{ backgroundColor: 'rgba(255, 10, 0, 0.5)' }}
      {...otherProps}
    />
  );
}


export const DSInfo: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaInfo size={size ?? DSTheme.IconSize} style={{ color: color ?? 'white' }} />}
      outerStyle={style}
      title="Show info"
      analyticsEvent="showInfo"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
}

export const DSSave: React.FC<IconButtonProps> = ({ size, color, style, ...otherProps }) => {
  return (
    <DSCircleIconButton
      icon={<FaSave size={size ?? DSTheme.IconSize} style={{ color: color ?? 'white' }} />}
      outerStyle={style}
      title="Save"
      analyticsEvent="save"
      style={{ ...Styles.buttonStyle, ...style }}
      {...otherProps}
    />
  );
}


const Styles = {
  buttonStyle: {
    padding: undefined,
    width: 26,
  },
}