import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  RouteProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { LoginState } from "../Controllers/UIState";
import { EmailField } from "./EmailField";
import { PanelError, PanelLoading } from "./Panels";
import * as Analytics from "../Util/Analytics";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import Page from "../Util/Page";
import { Panel, Column, Row, H1, Button, StrongPrint, Body } from "@openteam/design-system";
import { signOut } from "../Controllers/Login";
import { Logger } from "@openteam/app-util";

const logger = new Logger('ActionHandler')

export const ActionHandler = observer((props: RouteProps) => {

  const [email, setEmail] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const continueUrl = query.get("continueUrl");

  const storedEmail = window.localStorage.getItem('emailForSignIn');

  const extractPath = (url: string) => {
    const { protocol, hostname, port } = window.location;
    const urlPrefix = `${protocol}//${hostname}:${port ? port : ""}`;
    return url.replace(urlPrefix, '');
  }

  const navHome = () => {
    history.push("/login");
  }

  useEffect(() => {
    (async () => {
      Analytics.setCurrentScreen("ActionHandler");
      const auth = getAuth()

      if (LoginState.isSignedIn) {
        logger.warn(`Browser is already signed in, logging out old user`)
        await signOut()
      }

      if (storedEmail || emailConfirmed) {
        if (isSignInWithEmailLink(auth, window.location.href)) {

          const emailForSignIn = storedEmail ? storedEmail : email;

          signInWithEmailLink(auth, emailForSignIn, window.location.href)
            .then((resp) => {
              console.log("processed signInWithEmailLink, this will login", resp);

              const nextLocation: any = {
                pathname: '/applogin'
              }

              if (continueUrl) {
                const url = new URL(continueUrl);
                nextLocation.pathname = url.pathname,
                  nextLocation.search = url.search
              }

              console.log("nextLocation: ", nextLocation);
              history.push(nextLocation)

            }).catch((error) => {
              // Code is invalid or expired. Ask the user to verify their email address
              // again.
              Analytics.logError(error.code ?? "EMAIL_LINK_ERROR");

              console.log("there is an error: ", JSON.stringify(error));
              setError(error);
              setLoading(false);
            });
        } else {
          Analytics.logError("INVALID_EMAIL_INVALID");

          const error = {
            code: "invalidArgs",
            message: `This isn't a valid email link.`
          }
          console.error(`invalidArgs in action handler: ${JSON.stringify(error)}`);
          setError(error);
          setLoading(false);
        }
      }
    })()
  }, [emailConfirmed]);

  console.log(`ActionHandler: storedEmail: ${storedEmail} emailConfirmed: ${emailConfirmed}`);

  return (
    <Page>
      {!storedEmail && !emailConfirmed ? (
        <Column style={{ width: 300, alignItems: "center" }} spacing={24}>
          <H1>Please confirm your email</H1>

          <Row style={{ margin: 16, width: "80%", justifyContent: "center" }}>
            <EmailField
              onChange={(e) => setEmail(e.target.value)}
              onEnter={() => setEmailConfirmed(true)}
              onChangeValid={(valid) => setEmailValid(valid)}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", flexGrow: 1, width: "100%" }}>
            <Button
              onClick={() => setEmailConfirmed(true)}
              analyticsEvent="emailConfirmed"
              disabled={!emailValid}
            >
              Next
            </Button>
          </Row>
        </Column>
      ) : loading ? (
        <PanelLoading text={"Loading"} />

      ) : error ? (
        <PanelError back={navHome}>
          {error.code === 'auth/invalid-action-code' ? (
            <Body style={{ justifyContent: "center", marginBottom: 24, fontSize: 16 }}>
              This link isn't working.
              <br /><br />
              It may have been used already or it may have expired. You can create a new link by logging in again.
            </Body>
          ) : error.code === 'auth/invalid-email' ? (
            <Body style={{ marginBottom: 24, fontSize: 16 }}>
              Hmm, this doesn't appear to be the email address that you registered with.
              <br /><br />
              Please check and try again.
            </Body>
          ) : (
            <Body style={{ marginBottom: 24, fontSize: 16 }}>
              There was an error, please try again.
            </Body>
          )}
        </PanelError>

      ) : LoginState.isSignedIn ? (
        <Panel style={{ margin: 20, alignItems: 'center' }}>
          <H1 style={{ maxWidth: 500, marginBottom: 24, fontWeight: 700, fontSize: 40, textAlign: "center" }}>
            You are signed in.
          </H1>
        </Panel>

      ) : (
        <Panel style={{ margin: 20, alignItems: 'center' }}>
          You are signed out
        </Panel>
      )}

    </Page >
  );
});
