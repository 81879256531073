import React, { CSSProperties, useEffect, useState } from 'react';
import { ZoomManager } from '../../../core/src/User/ZoomManager';
import { getCurrentUser } from '../Controllers/Login';
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { observer } from 'mobx-react';
import { Column, H1, Body, Row, Button, Icons, Switch } from '@openteam/design-system';
import Page from '../Util/Page';

const ZoomIntegrate: React.FC = observer(() => {
  const isDev = window.location.host !== 'auth.ehlo.space'
  const userId = getCurrentUser()?.userId

  const [zoomManager, setZoomManager] = useState<ZoomManager>()

  const clientId = isDev ? "19tEXHx0RzGgpf5krQWOUA" : "s1qfv7KWSd6xOZ_HvhQgww"
  const functionsHost = isDev ? 'api-dev.ehlo.space' : 'api.ehlo.space';

  const token = [userId, clientId].join(':')
  const callbackUrl = `https://${functionsHost}/zoom_authCallback?token=${btoa(token)}`;
  const authUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(callbackUrl)}`


  useEffect(() => {
    if (userId) {
      const zoomManager = new ZoomManager(getDatabase(), getFirestore(), userId)
      zoomManager.start()
      setZoomManager(zoomManager);
    }
  }, [userId])

  return (
    <Page>
      {
        zoomManager?.isAuthorised
          ? (
            <Column style={{ alignItems: 'center', margin: 20 }} spacing={24}>
              <H1 style={Styles.header}>
                Manage Zoom
              </H1>
              <Body style={Styles.body}>
                Your Zoom account <b>{zoomManager.email}</b> is currently connected to ehlo.
              </Body>

              {zoomManager.enabled && zoomManager.status && <Body>Your Zoom status is: <b>{zoomManager.status}</b></Body>}
              <Row spacing={8} >
                <Body>You can temporarily disable the integration to stop Zoom updates from affecting your status in ehlo.</Body>
                <Body><b>Enable</b></Body>
                <Switch checked={zoomManager.enabled} onClick={() => zoomManager.setEnabled(!zoomManager.enabled)} />
              </Row>

              <Row spacing={8} >
                <Body>Or click Disconnect to be directed to the Zoom website where you can permanently remove the integration.</Body>
                <Button startIcon={Icons.close} color="error" style={{ ...Styles.button }} onClick={() => window.open("https://marketplace.zoom.us/user/installed")}>
                  Disconnect
                </Button>
              </Row>
            </Column>
          ) : (
            <Column style={{ alignItems: 'center', margin: 20 }} spacing={24}>
              <H1 style={Styles.header}>
                Link to Zoom
              </H1>

              <Body style={Styles.body}>
                Allow ehlo to show your teammates when you are busy in Zoom meetings.
              </Body>

              <Button
                style={{
                  ...Styles.button,
                  backgroundColor: '#2D8CFF',
                }}
                onClick={() => window.open(authUrl)}
              >
                <ZoomIcon size={32} style={{ marginRight: 4 }} />
                Connect to Zoom
              </Button>
            </Column>
          )
      }

    </Page >
  );
})

export default ZoomIntegrate;

type Props = {
  size?: number
  style?: CSSProperties
}

const ZoomIcon: React.FC<Props> = ({ size = 24, style }) => (
  <div
    style={{
      height: size,
      width: size,
      alignItems: "center",
      justifyContent: "center",
      ...style
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={size} height={size}>
      <path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z" />
      <polygon fill="#fff" points="37,31 31,27 31,21 37,17" />
    </svg>
  </div>
);


const Styles: Record<string, CSSProperties> = {
  header: { color: "black", fontWeight: 700, fontSize: 40 },
  body: { color: "black", fontSize: 16, textAlign: 'center' },
  button: {
    borderRadius: 8,
    height: 'fit-content',
    color: "white",
    padding: "8px",
    fontWeight: 700,
    fontSize: 14
  }
}
