import React, {
	useState,
	DragEvent,
	DragEventHandler,
	CSSProperties,
	ReactNode,
	useRef,
	useEffect,
	MouseEventHandler,
	memo,
} from 'react';

import { useTransition, useSpring, animated } from "@react-spring/web"
import { DSTheme } from '../DesignSystem/DSTheme';
import { assertDefined, Logger } from '@openteam/app-util';
import { FaUpload } from 'react-icons/fa';

const logger = new Logger("DragAndDrop");
export interface IDragData {
	id: string;
	itemType: string;
	[k: string]: any;
}

const checkItemType = (e: DragEvent, acceptItemType: string) => {
	return e.dataTransfer?.types.includes(acceptItemType);
}

interface IDroppableProps {
	onDragOver?: DragEventHandler,
	onDragLeave?: DragEventHandler,
	onDrop: DragEventHandler,
	acceptItemType: string,
	style?: CSSProperties,
	children?: ReactNode,
}


export const Droppable = React.forwardRef<HTMLDivElement, IDroppableProps>((props, ref) => {

	const [isOver, setIsOver] = useState<boolean>(false);

	const _onDragEnter: DragEventHandler = (e) => {
		e.preventDefault();

		if (checkItemType(e, props.acceptItemType)) {
			props.onDragOver?.(e);
			setIsOver(true);
		}
	}

	const _onDragOver: DragEventHandler = (e) => {
		e.preventDefault();

		if (checkItemType(e, props.acceptItemType)) {
			props.onDragOver?.(e);
			setIsOver(true);
		}
	}

	const _onDragLeave: DragEventHandler = (e) => {
		props.onDragLeave?.(e);
		setIsOver(false);
	}

	const _onDrop: DragEventHandler = (e) => {
		if (checkItemType(e, props.acceptItemType)) {
			props.onDrop?.(e)
		}
		setIsOver(false);
	}

	return (
		<div
			className="droppable"
			onDragEnter={(e) => setIsOver(true)}
			onDragOver={(e) => setIsOver(true)}
			style={{
				position: "relative",
				...props.style,
			}}>
			<div
				ref={ref}
				onDragEnter={_onDragEnter}
				onDragOver={_onDragOver}
				onDragLeave={_onDragLeave}
				onDrop={_onDrop}
				style={{
					position: "absolute",
					right: 0,
					left: 0,
					top: 0,
					bottom: 0,
					zIndex: isOver ? 10 : 0,
				}}
			/>
			{props.children ? props.children : null}
		</div>
	);
});

export interface IDraggableProps {
	dragData: IDragData,
	onDragStart?: DragEventHandler,
	onDragEnd?: DragEventHandler,
	style?: CSSProperties,
};

export const Draggable: React.FC<IDraggableProps> = memo((props) => {

	const _onDragStart: DragEventHandler = (e) => {
		e.dataTransfer.setData(props.dragData.itemType, JSON.stringify(props.dragData));
		e.dataTransfer.effectAllowed = "move";

		props.onDragStart?.(e);
	}

	const _onDragEnd: DragEventHandler = (e) => {
		props.onDragEnd?.(e);
	}

	return (
		<div
			draggable={true}
			onDragStart={_onDragStart}
			onDragEnd={_onDragEnd}
			style={{
				// @ts-ignore-next-line
				WebkitAppRegion: "no-drag",
				filter: "brightness(100%)",     // <-- MAGIC so that the generated drag element has transparent corners!
				...props.style
			}}
		>
			{props.children}
		</div>
	)
})

interface IDroppableListProps {
	id: string,
	items: IDragData[],
	addItem: (item: IDragData, atId: string) => void,
	removeItem?: (id: string) => void,
	moveItem: (fromId: string, toId: string | "__last") => void,
	setItems?: (items: IDragData[]) => void,
	acceptItemType: string,
	onDragStart?: DragEventHandler<HTMLDivElement>,
	onDragEnd?: () => void,
	onDrop?: DragEventHandler<HTMLDivElement>,
	style?: CSSProperties,
	isOverStyle?: CSSProperties,
	placeholderHeight: number,
	placeholder: ReactNode,
	renderItem: (
		item: IDragData,
		dragWrapper: IDragWrapper
	) => ReactNode,
	itemSpacing: number;
	itemSize: number;
	onAnimationStart?: (item) => void,
	onAnimationRest?: (item) => void,
	onChangeHeight?: (height: number) => void,
}

export type IDragWrapper = (children: ReactNode) => ReactNode;


interface IDroppableListRef {
	dragId: string | undefined;
	hoverIndex: number | undefined;
	height: number | undefined;
	myItems: IDragData[];
	accept: boolean;
	keys: Record<string, number>;
	overRegion: boolean;
}

export const DroppableList: React.FC<IDroppableListProps> = memo((props) => {

	const [version, setVersion] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	const dragTimer = useRef<{ lastEvent: number, timer: ReturnType<typeof setTimeout> | undefined }>({ lastEvent: 0, timer: undefined });

	const refState = useRef<IDroppableListRef>({
		dragId: undefined,
		hoverIndex: undefined,
		height: undefined,
		myItems: props.items,
		accept: false,
		keys: {},
		overRegion: false,
	});

	const getDragId = () => refState.current.dragId;
	const setDragId = (dragId: string | undefined) => refState.current = { ...refState.current, dragId };

	const totalHeight = props.itemSize + 2 * props.itemSpacing;

	useEffect(() => {
		setMyItems([...props.items]);
		changeHeight(false);
	}, [props.items]);

	const incrKey = (id: string) =>
		refState.current.keys[id] = refState.current.keys[id] ? refState.current.keys[id] + 1 : 1;
	const getKey = (id: string) => `${id}-${refState.current.keys[id] || 0}`;

	const setCanAccept = (accept: boolean) => refState.current.accept = accept;
	const canAccept = () => refState.current.accept;

	const getOverRegion = () => refState.current.overRegion;
	const setOverRegion = (_overRegion: boolean) => {
		if (refState.current.overRegion !== _overRegion) {
			refState.current.overRegion = _overRegion;
			setVersion(version + 1);
		}
	}

	const setHoverIndex = (newHoverIndex: number | undefined) => refState.current.hoverIndex = newHoverIndex;
	const getHoverIndex = () => refState.current.hoverIndex;

	const setMyItems = (items: IDragData[]) => {
		refState.current.myItems = items;
		setVersion(version + 1);
	}
	const myItems = () => refState.current.myItems;

	const changeHeight = (addingRemote: boolean) => {
		const numItems = props.items.length + (addingRemote ? 1 : 0);
		const itemHeight = numItems * (props.itemSize + (2 * props.itemSpacing));
		const height = Math.max(itemHeight, props.placeholder ? props.placeholderHeight : 0)

		if (height !== refState.current.height) {
			refState.current.height = height;
			props.onChangeHeight?.(height);
		}
	}

	const indexFromEvent = (e: DragEvent<HTMLDivElement>) => {
		const rect = ref.current!.getBoundingClientRect();
		const y = e.clientY - rect.top;
		return Math.floor(y / totalHeight);
	}

	const updateDragTimer = () => {
		if (dragTimer.current) {
			if (dragTimer.current.timer)
				clearTimeout(dragTimer.current.timer);

			dragTimer.current.lastEvent = Date.now();
		}
	}



	const onChangeHoverIndex = (newHoverIndex: number | undefined): { operation: "move" | "add" | "notOver", fromId?: string, toId?: string } => {
		const isLocalDrag = getDragId() !== undefined;

		if (newHoverIndex !== undefined) {
			const toId = newHoverIndex === props.items.length ? "__last" : props.items[newHoverIndex].id;

			if (isLocalDrag) {
				return {
					operation: "move",
					fromId: getDragId(), toId
				}

			} else {
				return {
					operation: "add",
					toId: toId
				}
			}
		}
		return { operation: "notOver" };
	}

	const processHoverChange = (
		operation: "noChange" | "move" | "add" | "notOver",
		newHoverIndex: number | undefined,
		fromId?: string,
		toId?: string
	) => {
		//
		// shuffle myItems according to hoverPosition
		//
		if (operation === "move") {

			assertDefined(toId);
			assertDefined(fromId);

			if (toId !== fromId) {

				let newItems = [...props.items];
				const dragIndex = newItems.findIndex(x => x.id === getDragId());

				const item = newItems[dragIndex];
				newItems.splice(dragIndex, 1);

				if (toId === "__last") {
					newItems = [...newItems, item];
				} else {
					const index = newItems.findIndex(x => x.id === toId);
					newItems.splice(index, 0, item);
				}
				incrKey(item.id);
				setMyItems(newItems);
				changeHeight(false);

			} else {
				// item hasn't moved
			}

		} else if (operation === "add") {
			assertDefined(newHoverIndex);
			assertDefined(toId);

			let newItems = [...props.items].filter(x => x.id !== "__adding");

			incrKey("__adding");
			const item = { id: "__adding", itemType: "user" };

			if (toId === "__last") {
				newItems = [...newItems, item];
			} else {
				const index = newItems.findIndex(x => x.id === toId);
				newItems.splice(index, 0, item);
			}

			setMyItems(newItems);
			changeHeight(true);

		} else if (operation === "notOver") {
			setMyItems([...props.items]);
			changeHeight(false);
		}

		setHoverIndex(newHoverIndex);
	}

	const _onMouseOverRegion: MouseEventHandler<HTMLDivElement> = (e) => {

		if (dragTimer.current) {
			const id = getDragId();

			if (id !== undefined) {
				if (Date.now() - dragTimer.current.lastEvent > 1000) {
					dragTimer.current.timer = setTimeout(
						() => {
							if (id) endDrag(id);
						},
						500
					);
					dragTimer.current.lastEvent = 0;

				}
			}
		}
	}

	const _onDragOverRegion: DragEventHandler<HTMLDivElement> = (e) => {
		if (isOverRegion)
			return;

		const accept = checkItemType(e, props.acceptItemType);
		setCanAccept(accept);
		setOverRegion(true);

		updateDragTimer();
	}

	const _onDragLeaveRegion: DragEventHandler<HTMLDivElement> = (e) => {
		setOverRegion(false);
	}

	const _onDragItemStart = (e: DragEvent<HTMLDivElement>, id: string) => {
		setDragId(id);
		setCanAccept(true);
		props.onDragStart?.(e);
		updateDragTimer();
	}

	const endDrag = (id: string) => {
		if (getDragId() !== undefined && getHoverIndex() === undefined) {
			props.removeItem?.(id);
		}
		setHoverIndex(undefined);
		setDragId(undefined);
		setOverRegion(false);
		setMyItems([...props.items]);
		props.onDragEnd?.();
	}

	const _onDragItemEnd = (e: DragEvent<HTMLDivElement>, id: string) => {
		endDrag(id);
	}

	const _onDragItemOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		if (!canAccept())
			return;

		const newHoverIndex = Math.max(Math.min(indexFromEvent(e), props.items.length), 0);

		if (newHoverIndex !== getHoverIndex()) {
			const { operation, fromId, toId } = onChangeHoverIndex(newHoverIndex);
			processHoverChange(operation, newHoverIndex, fromId, toId);
		}
		updateDragTimer();
	}

	const _onDragItemLeave = (e: DragEvent<HTMLDivElement>) => {
		processHoverChange("notOver", undefined);
	}

	const _onDrop = (e: DragEvent<HTMLDivElement>) => {

		const newHoverIndex = Math.max(Math.min(indexFromEvent(e), props.items.length), 0);
		assertDefined(newHoverIndex);

		const accept = checkItemType(e, props.acceptItemType);

		if (accept) {
			const dragData = JSON.parse(e.dataTransfer.getData(props.acceptItemType)) as IDragData;
			const { operation, fromId, toId } = onChangeHoverIndex(newHoverIndex);

			if (operation === "move") {
				assertDefined(fromId);
				assertDefined(toId);
				props.moveItem?.(fromId, toId);

			} else if (operation === "add") {
				assertDefined(toId);
				props.addItem?.(dragData, toId);
			}
			props.onDrop?.(e);
		}

		setOverRegion(false);
		setHoverIndex(undefined);
		setDragId(undefined);
		setCanAccept(false);
		changeHeight(false);
		setMyItems([...props.items]);
	}

	// remove the dragged object if not over the region
	const keyedItems = myItems()
		.map(
			x => ({
				...x,
				key: getKey(x.id),
				isDragging: getDragId() === x.id
			})
		);

	const isOverRegion = (getOverRegion() || getHoverIndex() !== undefined) && canAccept();
	const isDragging = getDragId() !== undefined;
	const hasRemovedElement = !isOverRegion && isDragging;

	const transitions = useTransition(
		keyedItems,
		{
			keys: item => item.key,
			initial: { height: totalHeight, width: props.itemSize, opacity: 1 },
			from: { height: 0, width: 0, opacity: 0 },
			enter: (item) => (
				item.isDragging ?
					{ height: totalHeight, width: props.itemSize, opacity: 0 } :
					{ height: totalHeight, width: props.itemSize, opacity: 1 }
			),
			update: (item) => (
				item.isDragging && !isOverRegion ?
					{ height: 0, width: 0, opacity: 0 } :
					item.isDragging && isOverRegion ?
						{ height: totalHeight, width: props.itemSize, opacity: 0 } :
						{ height: totalHeight, width: props.itemSize, opacity: 1 }
			),
			leave: { height: 0, width: 0, opacity: 0 },
			onStart: (result, spring, item) => props.onAnimationStart?.(item.key),
			onRest: (result, spring, item) => props.onAnimationRest?.(item.key),
			expires: true,
		}
	)
	const placeholderStyle = useSpring({
		opacity: myItems().length === 0 ? 1 : 0,
		onStart: () => props.onAnimationStart?.(`${props.id}-placeholder`),
		onRest: () => props.onAnimationRest?.(`${props.id}-placeholder`),
	});

	return (
		<div
			style={{
				...props.style,
				...(isOverRegion && canAccept ? props.isOverStyle : {}),
			}}
			onDragOver={_onDragOverRegion}
			onDragLeave={_onDragLeaveRegion}
			onMouseOver={_onMouseOverRegion}
		>
			<div style={{
				position: "relative",
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: DSTheme.DockSize,
				minHeight: Math.max(totalHeight, props.placeholder ? props.placeholderHeight : 0)
			}}>
				<div
					ref={ref}
					onDragEnter={_onDragItemOver}
					onDragOver={_onDragItemOver}
					onDragLeave={_onDragItemLeave}
					onDrop={_onDrop}
					style={{
						position: "absolute",
						right: 0,
						left: 0,
						top: isOverRegion ? -totalHeight / 2 : 0,
						bottom: isOverRegion ? -totalHeight / 2 : 0,
						zIndex: isOverRegion ? 10 : 0,
						//						border: "1px solid",
						//						borderColor: isDragging ? "blue" : isOverRegion ? "red" : "green",
					}}
				/>
				{props.placeholder && <animated.div style={{
					position: "absolute",
					display: "flex",
					paddingTop: 4,
					paddingBottom: 4,
					...placeholderStyle
				}}>
					{props.placeholder}
				</animated.div>
				}

				<SpringHeight isOn={hasRemovedElement} style={{ width: props.itemSize }} height={totalHeight / 2} />

				{transitions((springStyle, item) =>
					<animated.div style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						...springStyle
					}}>
						{
							item.id === "__adding" ? (
								null
							) : props.renderItem(
								item,
								(children) => (
									<Draggable
										dragData={item}
										onDragStart={(e: DragEvent<HTMLDivElement>) => _onDragItemStart(e, item.id)}
										onDragEnd={(e: DragEvent<HTMLDivElement>) => _onDragItemEnd(e, item.id)}
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%",
										}}
									>{children}</Draggable>
								)
							)}
					</animated.div>
				)}
				<SpringHeight isOn={hasRemovedElement} style={{ width: props.itemSize }} height={totalHeight / 2} />
			</div>
		</div >
	);
})

const SpringHeight: React.FC<{
	isOn: boolean,
	height: number,
	style?: CSSProperties
}> = ({ isOn, height, style }) => {

	const springStyle = useSpring({
		height: isOn ? height : 0,
	});

	return (
		<animated.div style={{ ...style, ...springStyle }} />
	)
}


