import { Logger } from "@openteam/app-util";
import { toJS } from "mobx";
import { hashObject, hashString } from "react-hash-string";
const logger = new Logger('applyObjectUpdate')

export function applyObjectUpdate(base: Object, update: Object, recurse = false, logChanged: (boolean | string)=false) {
  const changed = {};
  Object.entries(update).forEach(([key, value]) => {
    if (base[key] != value) {
      if (recurse && base[key] && value && typeof base[key] === "object" && typeof value === "object") {
        const _changed = applyObjectUpdate(base[key], value, recurse);
        if (_changed) {
          changed[key] = _changed
        }
      } else {
        changed[key] = { from: toJS(base[key]), to: toJS(value)};
        base[key] = value;
      }

    }
  });

  Object.keys(base).forEach((key) => {
    if (!(key in update)) {
      changed[key] = {from: toJS(base[key])}
      delete base[key];
    }
  });
  //if (hashObject(base) !== hashObject(update)) {
  //  logger.warn(`applyObjectUpdate objects don't match`, toJS(base), toJS(update))
  //}
  if (logChanged && Object.keys(changed).length) {
    logger.debug(`Changed ${typeof logChanged === 'string' ? '('+logChanged+') ' : ''}:`, changed)
  }
  return Object.keys(changed).length ? changed : undefined;
}

export function applyObjectUpdate2(update: Object|null, _base?: Object, recurse?: boolean, logChanged?: boolean | string): any {
  if (update) {
    const base = _base ?? {};
    applyObjectUpdate(base, update, recurse, logChanged);
    return base;
  }
  return undefined;
}
