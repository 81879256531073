import React, { FC, forwardRef, useCallback, useState, useMemo, useImperativeHandle } from "react";
import jsx from "refractor/lang/jsx";
import typescript from "refractor/lang/typescript";

import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  HardBreakExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
  MentionAtomNodeAttributes,
} from "remirror/extensions";

import {
  EditorComponent,
  Remirror,
  ThemeProvider,
  useRemirror,
  useActive,
  useCommands
} from "@remirror/react";


import { Logger } from "@openteam/app-util";
import { DSRow } from "../../DesignSystem/DSLayout";
import { DSButton } from "../../DesignSystem/DSButton";
import {
  FaBold,
  FaItalic,
  FaQuoteRight,
  FaCode,
  FaStrikethrough,
  FaListUl,
  FaListOl,
} from 'react-icons/fa';

import {
  CustomKeymapExtension,
  EhloEmojiExtension
} from "./MarkdownEditorExtensions"
import { MentionAtomState } from "./RemirrorExtensions/Mention/useMention";
import { MentionAtomPopupComponent } from "./RemirrorExtensions/Mention/MentionAtomComponent";
import { MentionAtomExtension } from "./RemirrorExtensions/Mention/MentionAtomExtension"
import { DSImage } from "../../DesignSystem/DSImage";
import UserIcon from "../User/UserIcon";
import { DSH3, DSLargePrint } from "../../DesignSystem/DSText";
import { MarkdownExtension } from "./RemirrorExtensions/Markdown/markdown-extension";

const logger = new Logger("MarkdownEditor")

interface IEmoji {
  name: string;
  unified: string;
  non_qualified: string;
  docomo: string;
  au: string;
  softbank: string;
  google: string;
  image: string;
  sheet_x: number;
  sheet_y: number;
  short_name: string;
  short_names: string[];
  text?: any;
  texts?: any;
  category: string;
  sort_order: number;
  added_in: string;
  has_img_apple: boolean;
  has_img_google: boolean;
  has_img_twitter: boolean;
  has_img_facebook: boolean;
}


// function convertIEmojiToFlatEmoji(emojiList: IEmoji[]) {
//   for (const e of emojiList) {
//     const fe: FlatEmoji = {

//     }
//   }
// }

export type MarkdownEditorHandle = {
  focus: () => void;
  insertText: (text) => void;
};

export interface MarkdownEditorProps {
  placeholder?: string;
  initialContent?: string;
  onChange: (value) => void
  mentionUsers?: { id: string, label: string }[]
  toolbarRight?: JSX.Element
  onSubmit?: () => void
}

/**
 * The editor which is used to create the annotation. Supports formatting.
 */
export const MarkdownEditor = forwardRef(({
  placeholder,
  initialContent,
  onChange,
  mentionUsers,
  toolbarRight,
  onSubmit
}: MarkdownEditorProps, ref) => {
  const menuRef = React.useRef<HTMLDivElement>(null)

  const extensions = useCallback(
    () => [
      new PlaceholderExtension({ placeholder }),
      new LinkExtension({ autoLink: false }),
      new BoldExtension(),
      new StrikeExtension(),
      new ItalicExtension(),
      new BlockquoteExtension(),
      new EhloEmojiExtension(),
      // new BulletListExtension({ enableSpine: true }),
      // new OrderedListExtension(),
      new MentionAtomExtension({
        matchers: [
          { name: 'at', char: '@', appendText: '  ' },
        ],
      }),
      // new ListItemExtension({
      //   priority: ExtensionPriority.High,
      //   enableCollapsible: true
      // }),
      new CodeExtension(),
      new CodeBlockExtension({ supportedLanguages: [typescript, jsx] }),
      new TrailingNodeExtension(),
      new MarkdownExtension({ copyAsMarkdown: false }),
      // /**
      //  * `HardBreakExtension` allows us to create a newline inside paragraphs.
      //  * e.g. in a list item
      //  */
      // new HardBreakExtension({
      // }),
      ...onSubmit ? [
        new CustomKeymapExtension({ onSubmit: onSubmit })
      ] : []

    ],
    [placeholder]
  );


  const { manager } = useRemirror({
    extensions,
    stringHandler: "markdown",
  });

  useImperativeHandle(ref, () => ({
    focus: menuRef.current?.focus,
    insertText: (text) => manager.store.commands.insertText(text)
  } as MarkdownEditorHandle));

  return (
    <>
      <Remirror key="remirror-editor"
        manager={manager}
        autoFocus
        initialContent={initialContent}
        onChange={({ helpers, state }) => {
          const newMarkdown = helpers.getMarkdown()
          if (initialContent != newMarkdown) {
            onChange(newMarkdown)
          }
        }} >
        <EditorComponent key="editor" />
        <Menu ref={menuRef} rightComponent={toolbarRight} />
        <MentionComponent users={mentionUsers} />
      </Remirror>
    </>
  );
});


export const Menu = forwardRef((props: { rightComponent?: JSX.Element }, ref) => {
  const {
    toggleBold,
    toggleItalic,
    toggleStrike,
    toggleCode,
    focus,
    toggleCodeBlock,
    toggleBlockquote,
    toggleBulletList,
    toggleOrderedList
  } = useCommands();
  const active = useActive();

  const buttonFontSize = 16;

  useImperativeHandle(ref, () => ({
    focus
  }));

  return (
    <DSRow style={{ borderTop: "2px #eee solid", }}>
      <DSRow style={{ flex: 1, alignItems: 'center', padding: 5 }}>
        <DSButton
          onClick={() => {
            toggleBold();
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.bold() ? Styles.menuButtonActive : undefined),
          }}
          fontSize={buttonFontSize}
        >
          <FaBold />
        </DSButton>
        <DSButton
          onClick={() => {
            toggleItalic();
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.italic() ? Styles.menuButtonActive : undefined),

          }}
          fontSize={buttonFontSize}
        >
          <FaItalic />
        </DSButton>

        <DSButton
          onClick={() => {
            toggleStrike();
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.strike() ? Styles.menuButtonActive : undefined),
          }}
          fontSize={buttonFontSize}
        >
          <FaStrikethrough />
        </DSButton>

        <DSButton
          onClick={() => {
            toggleCode();
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.code() ? Styles.menuButtonActive : undefined),
          }}
          fontSize={buttonFontSize}
        >
          <FaCode />

        </DSButton>
        <DSButton
          onClick={() => {
            toggleBlockquote();
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.blockquote() ? Styles.menuButtonActive : undefined),
          }}
          fontSize={buttonFontSize}
        >
          <FaQuoteRight />
        </DSButton>


        {/* <DSButton
        onClick={() => {
          toggleBulletList();
          focus();
        }}
        style={{
          ...Styles.menuButton,
          ...(active.bulletList() ? Styles.menuButtonActive : undefined),
        }}
        fontSize={buttonFontSize}
      >
        <FaListUl />
      </DSButton>


      <DSButton
        onClick={() => {
          toggleOrderedList();
          focus();
        }}
        style={{
          ...Styles.menuButton,
          ...(active.orderedList() ? Styles.menuButtonActive : undefined),
        }}
        fontSize={buttonFontSize}
      >
        <FaListOl />
      </DSButton> */}

        <DSButton
          onClick={() => {
            toggleCodeBlock({});
            focus();
          }}
          style={{
            ...Styles.menuButton,
            ...(active.codeBlock() ? Styles.menuButtonActive : undefined),
          }}
          fontSize={buttonFontSize}
        >
          {"{ }"}
        </DSButton>
      </DSRow>
      {props.rightComponent}
    </DSRow>
  );
});



interface MentionComponentProps<
  UserData extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
  > {
  users?: UserData[];
  tags?: string[];
}

function MentionComponent({ users, tags }: MentionComponentProps) {
  const [mentionState, setMentionState] = useState<MentionAtomState | null>();
  const tagItems = useMemo(
    () => (tags ?? []).map((tag) => ({ id: tag, label: `#${tag}` })),
    [tags],
  );
  const items = useMemo(() => {
    if (!mentionState) {
      return [];
    }

    const allItems = mentionState.name === 'at' ? users : tagItems;

    if (!allItems) {
      return [];
    }

    const query = mentionState.query.full?.toLowerCase() ?? '';
    return allItems.filter((item) => item.label?.toLowerCase().includes(query)).sort();
  }, [mentionState, users, tagItems]);

  return <MentionAtomPopupComponent onChange={setMentionState} items={items} ItemComponent={MentionItem} />;
}

function MentionItem<Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes>(
  props,
) {
  const item = props.item
  return <DSRow style={{ alignItems: 'center' }}>
    <UserIcon
      size={24}
      hideStatusDot={true}
      user={{ id: item.id, name: item.label, imageUrl: item.imageUrl }} />
    <DSLargePrint style={{ marginLeft: 8 }}>
      {props.item.label}
    </DSLargePrint>
  </DSRow>;
}

export const RemirrorCSS = `

.remirror-editor-wrapper .ProseMirror {
  min-height: 20px;
  max-height: 180px;
  box-shadow: undefined;
  padding: 8px;
  outline: none;
  overflow-y: scroll;
  color: black;
  overflow-wrap: anywhere;
}

.remirror-editor-wrapper .ProseMirror:active,
.remirror-editor-wrapper .ProseMirror:focus {
  box-shadow: undefined;
}

.ProseMirror p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ProseMirror a {
  color: rgba(0, 174, 249, 1);
}

.remirror-editor-wrapper .ProseMirror code {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  vertical-align: middle;
  padding: 1.6px;
  line-height: 1.5;
}

.remirror-editor-wrapper .ProseMirror pre  {
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  color: #1D1C1D;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: anywhere;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0px;
  border-radius: 0.3em;
  max-width: 80vw;
}

.remirror-editor-wrapper .ProseMirror pre code {
  background-color: initial;
  border: none;
  line-height: inherit;
  padding: 0;
}

.remirror-editor-wrapper .ProseMirror blockquote {
  border-left: 3px solid grey;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  font-style: italic;
}

.remirror-editor-wrapper .ProseMirror blockquote p {
  color: #888;
}

.remirror-editor-wrapper .ProseMirror .remirror-mention-atom {
  color: rgba(255, 159, 0, 1);
  text-decoration: none;
}


.remirror-floating-popover .remirror-mention-atom-popup-wrapper {
  border-radius: 4px;
  overflow: hidden;
  color: white;
  background-color: #1B1D21;
}


.remirror-floating-popover .remirror-mention-atom-popup-item {
  padding: 2px 8px;
}

.remirror-floating-popover .remirror-mention-atom-popup-highlight {
  background-color: rgba(255, 159, 0, 1);
}

.remirror-floating-popover .remirror-mention-atom-popup-hovered {
  background-color: rgba(255, 159, 0, 0.5);
}

`


const Styles = {
  menuButton: {
    color: '#aaa',
    padding: 4,
    marginLeft: 4,
    marginRight: 4,
  },
  menuButtonActive: {
    color: 'black'
  },
}