import { Logger } from "@openteam/app-util";
import { ITeamExternalWaiting, ITeamRoom, KSpaceChannelId, KSpaceId, KSpacePluginId, KSpacePodId, KSpaceUserId } from "@openteam/models";
import { unWatch, watch } from "./FireWatch";
import { child, Database, onDisconnect, ref, remove, set, update, onValue } from "firebase/database";

const logger = new Logger("ExternalMeetingDb");

export class ExternalMeetingDb {

  static joinTeamUserWaiting(
    fbDb: Database,
    teamId: KSpaceId,
    targetUserId: KSpaceUserId,
    myUserId: KSpaceUserId,
    name: string,
    callback: (doc: ITeamExternalWaiting) => void
  ) {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/users/${targetUserId}`)
    var waitingRef = child(addressRef, `waiting/${myUserId}`)

    const connectedRef = ref(fbDb, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        console.log("connected");

        onDisconnect(waitingRef)
          .set(null)
          .then(function () {
            update(waitingRef, {
              name: name
            });
          });


      } else {
        console.log("not connected");
      }
    });

    onValue(waitingRef, (snapshot) => callback(snapshot.val()))

  }


  static joinTeamChannelWaiting(
    fbDb: Database,
    teamId: KSpaceId,
    channelId: KSpaceChannelId,
    myUserId: KSpaceUserId,
    name: string,
    callback: (doc: ITeamExternalWaiting) => void
  ) {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/channels/${channelId}`)
    var waitingRef = child(addressRef, `waiting/${myUserId}`)

    const connectedRef = ref(fbDb, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        console.log("connected");

        onDisconnect(waitingRef)
          .set(null)
          .then(function () {
            update(waitingRef, {
              name: name
            });
          });


      } else {
        console.log("not connected");
      }
    });

    onValue(waitingRef, (snapshot) => callback(snapshot.val()))
  }

  static joinTeamRoomWaiting(
    fbDb: Database,
    teamId: KSpaceId,
    roomId: string,
    myUserId: KSpaceUserId,
    name: string,
    callback: (doc: ITeamExternalWaiting) => void
  ) {
    var addressRef = ref(fbDb, `/teamExternal/${teamId}/rooms/${roomId}`)
    var waitingRef = child(addressRef, `waiting/${myUserId}`)

    const connectedRef = ref(fbDb, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        console.log("connected");

        onDisconnect(waitingRef)
          .set(null)
          .then(function () {
            update(waitingRef, {
              name: name
            });
          });


      } else {
        console.log("not connected");
      }
    });

    onValue(waitingRef, (snapshot) => callback(snapshot.val()))
  }

  static cancelJoinTeamUserWaiting = (
    fbDb: Database,
    teamId: KSpaceId,
    userId: KSpaceUserId,
    myUserId: KSpaceUserId) => {
    const addressRef = ref(fbDb, `/teamExternal/${teamId}/users/${userId}`)
    const waitingRef = child(addressRef, `waiting/${myUserId}`)
    set(waitingRef, null)
  }

  static cancelJoinTeamChannelWaiting = (
    fbDb: Database,
    teamId: KSpaceId,
    channelId: KSpacePodId,
    myUserId: KSpaceUserId,) => {
    const addressRef = ref(fbDb, `/teamExternal/${teamId}/channels/${channelId}`)
    const waitingRef = child(addressRef, `waiting/${myUserId}`)
    set(waitingRef, null)
  }


  static cancelJoinTeamRoomWaiting = (
    fbDb: Database,
    teamId: KSpaceId,
    roomId: string,
    myUserId: KSpaceUserId,) => {
    const addressRef = ref(fbDb, `/teamExternal/${teamId}/rooms/${roomId}`)
    const waitingRef = child(addressRef, `waiting/${myUserId}`)
    set(waitingRef, null)
  }



  static removeInviteTeamRoom = async (
    fbDb: Database,
    teamId: string,
    roomId: string,
    userId
  ) => {
    remove(ref(fbDb, `/teams/${teamId}/rooms/${roomId}/invites/${userId}`));
  }

  static removeTeamRoomUser = async (
    fbDb: Database,
    teamId: string,
    roomId: string,
    userId: string
  ) => {
    ExternalMeetingDb.removeInviteTeamRoom(fbDb, teamId, roomId, userId);
    remove(ref(fbDb, `/teams/${teamId}/rooms/${roomId}/users/${userId}`));
  };

  static watchRoom = (
    fbDb: Database,
    teamId: string,
    roomId: string,
    callback: (id: string, doc: ITeamRoom) => void
  ) => {
    watch(fbDb, `/teams/${teamId}/rooms/${roomId}`, doc => callback(roomId, doc))
  }
  static unwatchRoom = (fbDb: Database, teamId: string, roomId: string) => {
    unWatch(fbDb, `/teams/${teamId}/rooms/${roomId}`);
  }
}
