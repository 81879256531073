import { observable, autorun, makeObservable } from "mobx";
import { IAlertMsg, IUserBadges } from "@openteam/models";
import { FireDb } from "../fire";
import { OTGlobals } from "../OTGlobals";
import { Logger } from "@openteam/app-util";
import { writeSpaceBadges } from "../UIDataState";
import { Database } from "firebase/database";

const logger = new Logger("AlertManager");

export class AlertManager {
  fbDb: Database;
  userId: string;

  teamId: string;

  @observable alerts: IAlertMsg[] = [];
  @observable showAlerts: boolean = false;
  @observable badges: { [id: string]: IUserBadges } = {};

  _autorun: Record<string, any> = {};

  constructor(fbDb: Database, userId: string, teamId: string) {
    this.fbDb = fbDb;
    this.userId = userId;
    this.teamId = teamId;

    makeObservable(this)
    this.start();
  }

  start = () => {
    FireDb.watchAlerts(
      this.fbDb,
      this.userId,
      this.teamId,
      (data: IAlertMsg) => (this.alerts = [data, ...this.alerts]),
      (data: IAlertMsg) => (this.alerts = [...this.alerts.filter((alert) => alert.id != data.id)])
    );

    this._autorun['calcUnread'] = autorun(this.calcUnread);
    this._autorun['calcBadges'] = autorun(this.calcBadges);
  }

  stop = () => {
    FireDb.unwatchAlerts(this.fbDb, this.userId, this.teamId);

    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };

  toggleShowAlerts = () => {
    this.showAlerts = !this.showAlerts;
  };

  calcUnread = () => {
    const teamData = OTGlobals.getUnsafeTeamData(this.teamId);

    if (!teamData) {
      return;
    }

    teamData.unreadAlerts = Array.from(
      new Set(this.alerts.map((alert) => alert.fromUserId))
    ).length;
  };

  calcBadges = () => {
    var badges: { [id: string]: IUserBadges } = {};

    this.alerts.forEach((alert) => {
      if (!(alert.fromUserId in badges)) {
        badges[alert.fromUserId] = {
          userId: alert.fromUserId,
          PTT_WALKIE: 0,
          PTT_GLOBAL: 0,
          KNOCK: 0,
          CALL: 0,
          lastUpdate: 0,
        };
      }

      badges[alert.fromUserId][alert.actionType] += 1;

      if (alert.crDate > badges[alert.fromUserId].lastUpdate) {
        badges[alert.fromUserId].lastUpdate = alert.crDate;
      }
    });

    this.badges = badges;
    writeSpaceBadges(this)
  };

  removeUserAlerts = (userId) => {
    FireDb.removeUserAlerts(this.fbDb, this.userId, this.teamId, userId);
  };

  clearAlerts = () => {
    FireDb.removeAllAlerts(
      this.fbDb,
      this.userId,
      this.teamId,
      this.alerts.map((alert) => alert.id)
    );
    this.alerts = [];
    this.showAlerts = false;
  };
}
