import React from "react"
import { Radio, RadioProps, FormControlLabel, styled } from "@mui/material"
export { RadioGroup } from "@mui/material";
import setAlpha from "./setAlpha";


export interface DS2RadioProps extends RadioProps {
  label?: string;
}

const DS2Radio: React.FC<DS2RadioProps> = ({ label, style, ...props }) => {
  if (label) {
    return <FormControlLabel control={
      <StyledRadio {...props} icon={<OuterIcon />} checkedIcon={<OuterIcon><InnerIcon /></OuterIcon>} />
    } style={style} label={label} />
  } else {
    return <StyledRadio style={style} {...props} />
  }
}

const StyledRadio = styled(Radio)(({ theme, color }) => ({
  '.MuiSvgIcon-root': {
    color: theme.palette[color ?? 'primary'].main,
  }
}))

const OuterIcon = styled('div')(({ theme, color }) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  borderRadius: 999,
  width: 13,
  height: 13,
  margin: 2,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: setAlpha(theme.palette[color ?? 'primary'].main, 0.5),
}))

const InnerIcon = styled('div')(({ theme, color }) => ({
  margin: 'auto',
  borderRadius: 999,
  width: 9,
  height: 9,
  backgroundColor: theme.palette[color ?? 'primary'].main,
}))

export default DS2Radio;
