import React from "react";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Badge, Palette, styled } from "@mui/material";
import useClickable from "./useClickable";


const StyledButton = styled(IconButton)`
  box-shadow: none;
  app-region: no-drag;
  pointer-events: initial;

  &.Mui-focusVisible {
    box-shadow: none;
    outline: 1px solid grey;
  }
`
export interface DS2IconButtonProps extends IconButtonProps {
  analyticsEvent?: string
  analyticsArgs?: any
  badgeNum?: string | number
  badgeOverride?: string
  stopPropagation?: StopPropagation
  badgeColor?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
  noBadge?: boolean
};

type StopPropagation = {
  onClick: boolean
  onPointerDown: boolean
}

export default React.forwardRef<HTMLButtonElement, DS2IconButtonProps>((props, ref) => {

  const  {
      onClick: _onClick,
      stopPropagation: _stopPropagation,
      analyticsEvent,
      analyticsArgs,
      badgeNum,
      badgeOverride,
      badgeColor,
      noBadge=false,
      ...buttonProps
    } = props;

    const {onClick, onPointerDown} = useClickable(props)

    return noBadge ? (
      <StyledButton
          ref={ref}
          onClick={onClick}
          onPointerDown={onPointerDown}
          tabIndex={-1}
          size='small'
          {...buttonProps}
        />
    ) :(
      <Badge badgeContent={badgeOverride ?? badgeNum} color={badgeColor ?? 'primary'}>
        <StyledButton
          ref={ref}
          onClick={onClick}
          onPointerDown={onPointerDown}
          tabIndex={-1}
          size='small'
          {...buttonProps}
        />
      </Badge>
    )
  })
