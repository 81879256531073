import events from "events";
import { assertDefined, Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { action, computed, makeObservable, observable } from "mobx";
import { OTAppCoreData } from "../OTAppCoreData";
import { UserManager } from "./UserManager";
import { v4 as uuidv4 } from "uuid";
import { OTUserInterface } from "../OTUserInterface";
import { OTUITree } from "../OTUITree";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";
import { User } from "firebase/auth"

const logger = new Logger("OTAuth");

export class OTAuth extends events.EventEmitter {
  fbDb: Database;
  fsDb: Firestore;

  @observable userId: string;
  @observable isAnonymous: boolean;
  @observable sessionToken: string;
  @observable _authLoaded: boolean;
  onLoginCallback?: () => void

  _isSigningOut: boolean;

  @observable _userManager: UserManager | undefined = undefined;
  @computed get userManager() {
    const f = this._userManager;
    assertDefined(f);
    return f;
  }

  constructor(fbDb: Database, fsDb: Firestore, onLoginCallback?: () => void) {
    super();
    makeObservable(this)
    this.fbDb = fbDb;
    this.fsDb = fsDb;

    this.userId = "";
    this.onLoginCallback = onLoginCallback;
    this.isAnonymous = false;
    this._authLoaded = false;
    this.sessionToken =
      OTUserInterface.platformUtils.PlatformOS === "web" ? uuidv4() : OTAppCoreData.deviceId!;
    this._isSigningOut = false;

    OTUITree.registerAuth(this);
  }

  @action
  reset = async () => {
    this.userId = "";
    this.isAnonymous = false;

    // nb. authLoaded is 'true' here.
    this._authLoaded = true;

    this._userManager = undefined;

    this.sessionToken =
      OTUserInterface.platformUtils.PlatformOS === "web" ? uuidv4() : OTAppCoreData.deviceId!;

    this._isSigningOut = false;
  };

  loadFromCache = async () => {
    if (!this._userManager) {
      // if this._userManager added for hot reloading
      const cachedUser = OTGlobals.cache.getCache("global", "user", "firebase");

      logger.info("cachedUser", cachedUser);

      if (cachedUser) {
        await this.createUser(cachedUser);
        this.userId = cachedUser.uid;
        this.isAnonymous = cachedUser.isAnonymous;
      }
      this._authLoaded = true;
    }
  };

  @action
  onAuthStateChanged = async (user: User | null) => {
    logger.debug("onAuthStateChanged user=%o", user);

    if (user && user.uid) {
      OTGlobals.cache.setCache("global", "user", "firebase", user);

      this.userId = user.uid;
      this.isAnonymous = user.isAnonymous;
      this._authLoaded = true;
      if (!this._userManager) {
        await this.createUser(user);
      }
    } else {
      logger.info("not logged in");

      if (this.userId && this._isSigningOut === false) {
        this.emit("signOut");
      }
      this.userId = "";
      this.isAnonymous = false;
      this._authLoaded = true;
    }
  };

  signOut = async () => {
    this._isSigningOut = true;

    if (OTGlobals._appHomeManager) await OTGlobals._appHomeManager.stop();

    if (this._userManager) await this._userManager.stop();

    if (OTGlobals._appHomeManager) await OTGlobals._appHomeManager.signOut();

  };

  @action
  createUser = async (user: User) => {
    logger.info("creating user userId=%s isAnonymous=%s user=%o", user.uid, user.isAnonymous, user);

    OTGlobals.userSettingsManager.loadUserSettings(this.fbDb, user.uid);

    if (!user.isAnonymous) {
      this._userManager = new UserManager(
        this.fbDb,
        this.fsDb,
        user.uid,
        this.onLoginCallback
      );
      this._userManager.start();
    } else {
      this.onLoginCallback?.()
    }

    OTGlobals.analytics?.identifyUser(user.uid);

    OTGlobals.sentry.setUser({
      id: user.uid,
      extra: {
        username: user?.email,
        version: OTAppCoreData.version,
      },
    });
  };

  isMe = (userId: string) => userId === this.userId;

  @computed
  get isLoaded() {
    if (!this._authLoaded) {
      return false;
    }

    // user is not logged in, or is anonymous user
    if (this.userId === "" || this.isAnonymous) return true;

    // user is known, so wait on userManager.firstLoad
    return this.userManager.firstLoad;
  }
}
