import { OTUITree } from "./OTUITree";
import { OTGlobals } from "./OTGlobals";
import { action } from "mobx";

const offlineTime = 30 * 1000;

export class HeartbeatManager {
  lastOnline: number | undefined = undefined;

  constructor() {
    OTUITree.heartbeatUI = this;
    this.start();
  }

  start = () => {
    this.doHeartbeat();
    setInterval(this.doHeartbeat, 10 * 1000);
  };

  doHeartbeat = action(() => {
    const now = new Date().getTime();
    if (OTGlobals.fbConnected) {
      this.lastOnline = now;
      OTGlobals.isConnected = true;
    } else {
      if (!this.lastOnline || now - this.lastOnline > offlineTime) {
        OTGlobals.isConnected = false;
      }
    }
  });
}
