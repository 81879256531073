import { useTheme } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";

type Props = {
  size?: number;
};

const DS2Spinner: React.FC<Props> = ({ size = 40 }) => {
  const strokeWidth = Math.round(size / 7);
  const normRadius = size / 2 - strokeWidth;
  const circumference = 2 * normRadius * Math.PI;
  const theme = useTheme()

  return (
    <svg
      className="spinner"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity={0.5}
        cx={size / 2}
        cy={size / 2}
        r={normRadius}
        stroke={theme.palette.primary.main}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={normRadius}
        stroke={theme.palette.primary.main}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference / 3} ${(2 * circumference) / 3}`}
        strokeDashoffset={circumference}
        strokeLinecap="round"
        style={{ animation: "spinner 1s linear 0s infinite running" }}
      />
    </svg>
  );
};

export default DS2Spinner;
