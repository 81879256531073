import {
  Database, ref, get, update, serverTimestamp, child, set, remove
} from 'firebase/database'
import {getFunctions, httpsCallable} from 'firebase/functions'

import { OTUserInterface } from "../OTUserInterface";

import { Logger } from "@openteam/app-util";
import { IUserDoc, IUserTeamReq } from "@openteam/models";
import { unWatch, watch } from './FireWatch';

const logger = new Logger("UserManagerDb");

export class UserManagerDb {

  static setupAccount = async (fbDb: Database, userId: string, name: string, email: string, imageUrl?: string | null, referrerId?: string) => {
    logger.debug("setting up account ", name)

    var existingUser = await get(ref(fbDb, `/users/${userId}`));

    logger.debug("got user ", existingUser.val())

    if (!existingUser.val()) {
      var userObj: IUserDoc = {
        name: name,
        email: email,
        imageUrl: imageUrl || null,
        accountSetup: true,
        crDate: serverTimestamp(),
        referrerId: referrerId || null
      }

      await update(ref(fbDb, `/users/${userId}`), userObj)
    }
  }

  static updateName = async (fbDb: Database, userId: string, name: string) => {
    logger.debug("updating name ", name)

    const existingUser = await get(ref(fbDb, `/users/${userId}`));
    logger.debug("got user ", existingUser.val())

    if (existingUser.val()) {
      update(existingUser.ref, { name })
    } else {
      throw new Error("user not found");
    }
  }

  static setUserTeamId = (
    fbDb: Database,
    userId: string,
    teamId: string | undefined
  ) => {
    if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      set(child(ref(fbDb, `/users/${userId}`), "teamId"), teamId ?? null);
    }
  };

  static clearNextUserTeamId = (
    fbDb: Database,
    userId: string,
    teamId: string | undefined
  ) => {
    if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      set(child(ref(fbDb, `/users/${userId}`), "nextTeamId"), teamId ?? null);
    }
  };

  static watchUserTeamAccessRequest = (
    fbDb: Database,
    userId: string,
    teamId: string,
    callback: (doc) => void
  ) => {
    const path = `/teamAccessReq/${teamId}/${userId}`;
    watch(fbDb, path, callback);
  };

  static unwatchUserTeamAccessRequest = (
    fbDb: Database,
    userId: string,
    teamId: string
  ) => {
    const path = `/teamAccessReq/${teamId}/${userId}`;
    unWatch(fbDb, path);
  };

  static requestTeamAccess = async (
    fbDb: Database,
    userId: string,
    teamId: string,
    requestDetails: Partial<IUserTeamReq>,
    teamPath: string
  ) => {
    logger.info(`requestTeamAccess: teamId=${teamId} teamPath=${teamPath}`);

    const userTeamReq: IUserTeamReq = {
      id: teamId,
      date: serverTimestamp(),
      email: "",
      name: "",
      imageUrl: null,
      status: "waiting",
      ...requestDetails,
    };

    set(ref(fbDb, `/teamAccessReq/${teamId}/${userId}`), userTeamReq);
    set(ref(fbDb, `/users/${userId}/teams/${teamId}`), teamPath);
  };

  static removeTeamAccessReq = async (
    fbDb: Database,
    userId: string,
    teamId: string
  ) => {
    set(ref(fbDb, `/users/${userId}/reqTeams/${teamId}`), null);
    remove(ref(fbDb, `/teamAccessReq/${teamId}/${userId}`));
  };

  static accessTeamWithInvite = async (teamId: string, inviteId: string) => {
    logger.debug("trying to join team with invite, teamId=%s, inviteId=%s", teamId, inviteId);
    const joinWithInvite = httpsCallable(getFunctions(), "joinWithInvite");
    const result = await joinWithInvite({ teamId: teamId, inviteId: inviteId });
    return (result.data as any).success;
  };

  static watchUser = (
    fbDb: Database,
    userId: string,
    callback: (doc) => void
  ) => {
    logger.debug(`Watching user ${userId}`);
    watch(fbDb, `/users/${userId}`, callback);
  };

  static unwatchUser = (fbDb: Database, userId: string) => {
    unWatch(fbDb, `/users/${userId}`);
  };

  static getInvitees = async (fbDb: Database, teamId: string, userId: string) => {
    return (await get(ref(fbDb, `/teamInvite/${teamId}/${userId}`))).val()
  }
}
