import React from 'react';
import { H1, ITextProps } from "@openteam/design-system";

const Title: React.FC<ITextProps> = ({ style, ...props }) => (
  <H1
    style={{
      fontWeight: 700,
      fontSize: 30,
      textAlign: "center",
      ...style
    }}
    {...props}
  />
)

export default Title
