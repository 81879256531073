import events from "events";
import { action, computed, makeObservable, observable } from "mobx";
import { Logger } from "@openteam/app-util";
import { UserManagerDb } from "../fire/UserManagerDb";
import { OTGlobals } from "../OTGlobals";
import { ITeamDoc, IUserDoc, IUserTeamReq } from "@openteam/models";
import { OTUITree } from "../OTUITree";
import { FireDb } from "../fire";
import { OTUserInterface } from "../OTUserInterface";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";
import { CalendarManager } from "./CalendarManager";
import { ZoomManager } from "./ZoomManager";

const logger = new Logger("UserManager");

enum UserManagerStatus {
  Stopped,
  Running,
  Starting,
  Stopping,
}

type IAsyncData<T> =
  | {
      status: "loading";
    }
  | { status: "loaded"; data: T };

export class UserManager {
  fbDb: Database;
  userId: string;

  status: UserManagerStatus = UserManagerStatus.Stopped;
  onLoginCallback?: () => void;

  @observable firstLoad: boolean = false;
  @observable docIsCached: boolean = true;
  @observable currentTeamId?: string = undefined;
  @observable userDoc?: IUserDoc = undefined;
  @observable userTeamsIndexLoaded: boolean = false;
  @observable userTeamsIndex: Record<string, boolean> = {};
  @observable userTeams: Record<string, ITeamDoc> = {};
  @observable reqTeams: Record<string, IAsyncData<IUserTeamReq>> = {};
  @observable calendarManager: CalendarManager;
  @observable zoomManager: ZoomManager

  constructor(fbDb: Database, fsDb: Firestore, userId: string, onLoginCallback?: () => void) {
    makeObservable(this);
    logger.debug("Creating UserManager");
    this.fbDb = fbDb;
    this.userId = userId;
    this.calendarManager = new CalendarManager(fbDb, fsDb, userId);
    this.zoomManager = new ZoomManager(fbDb, fsDb, userId);

    this.onLoginCallback = onLoginCallback;

    OTUITree.registerUserManager(this);
  }

  start = () => {
    logger.debug("starting userId=%s", this.userId);

    if (this.status !== UserManagerStatus.Stopped) {
      logger.debug("can't start, status=%s", this.status);
      return;
    }

    this.watchUser();
    this.calendarManager.start();
    this.zoomManager.start();

    this.status = UserManagerStatus.Running;
  };

  stop = async () => {
    logger.debug("stopping");

    if (this.status !== UserManagerStatus.Running) {
      logger.debug("can't stop, status=%s", this.status);
      return;
    }

    UserManagerDb.unwatchUser(this.fbDb, this.userId);
    this.watchingUser = false;

    Object.keys(this.reqTeams).forEach((teamId) => {
      this.unwatchTeamAccessReq(teamId);
    });
    this.calendarManager.stop();
    this.zoomManager.stop();
    this.status = UserManagerStatus.Stopped;
  };

  watchingUser = false;
  watchUser = () => {
    if (this.watchingUser) {
      return;
    }

    this.watchingUser = true;

    const userDoc = OTGlobals.cache.getCache(this.userId, "user", this.userId);

    if (userDoc) {
      this.syncUser(userDoc, true);
    }

    UserManagerDb.watchUser(this.fbDb, this.userId, (data) => this.syncUser(data as IUserDoc));
  };

  syncUser = async (data: IUserDoc, isCached: boolean = false) => {
    if (!isCached) {
      OTGlobals.cache.setCache(this.userId, "user", this.userId, data);
    }

    logger.debug(
      `syncUser: isCached: ${isCached}, firstLoad: ${this.firstLoad}, accountSetup: ${data?.accountSetup}`
    );
    this.setDoc(data);
    this.docIsCached = isCached;

    if (!isCached && !this.firstLoad && data && data.accountSetup) {
      this.firstLoad = true;
      logger.debug(`calling onLoginCallback`);
      this.onLoginCallback?.();
    }
  };

  setDoc = action((userDoc: IUserDoc) => {
    logger.info("setting userDoc", userDoc);

    this.userDoc = userDoc;
  });

  setCurrentTeam(teamId?: string) {
    if (this.currentTeamId != teamId) {
      if (teamId && !this.userTeamsIndex[teamId]) {
        return;
      }
      logger.info(`switching team from ${this.currentTeamId} to ${teamId}`);
      OTGlobals.analytics?.logEvent("switchTeam", { teamId: teamId });

      this.currentTeamId = teamId;
      UserManagerDb.setUserTeamId(this.fbDb, this.userId, teamId);
    }

    if (teamId === this.userDoc?.nextTeamId) {
      UserManagerDb.clearNextUserTeamId(this.fbDb, this.userId, teamId);
    }
  }

  unsetCurrentTeam(teamId: string) {
    if (this.currentTeamId == teamId) {
      var otherTeamIds = Object.keys(this.userTeamsIndex).filter(
        (otherTeamId) => otherTeamId != teamId
      );
      if (otherTeamIds.length > 0) {
        this.setCurrentTeam(otherTeamIds[0]);
      } else {
        this.setCurrentTeam(undefined);
      }
    }
  }

  @computed get currentTeamData() {
    return this.currentTeamId && this.currentTeamId in this.userTeams
      ? OTGlobals.getUnsafeTeamData(this.currentTeamId)
      : undefined;
  }

  handleTeamList = (doc: Record<string, boolean>) => {
    this.userTeamsIndex = doc;
    if (!this.userTeamsIndexLoaded) {
      this.userTeamsIndexLoaded = true;
    }
  };

  watchTeamAccessReq = (teamId: string) => {
    logger.debug("watchTeamRequest: teamId=%s", teamId);

    if (this.reqTeams[teamId]) {
      // already watching
      logger.debug("watchTeamRequest: already watching");
      return;
    }

    UserManagerDb.watchUserTeamAccessRequest(
      this.fbDb,
      this.userId,
      teamId,
      (doc: IUserTeamReq) => {
        logger.info("watchUserTeamAccessRequest teamId=%s, doc=%o", teamId, doc);

        if (doc) {
          this.reqTeams[teamId] = { status: "loaded", data: doc };

          if (doc.status != "waiting") {
            logger.info("got response to access request", doc.status);
            this.removeTeamAccessReq(teamId);
          }
        } else {
          this.unwatchTeamAccessReq(teamId);
        }
      }
    );
    this.reqTeams[teamId] = { status: "loading" };
  };

  unwatchTeamAccessReq = (teamId: string) => {
    UserManagerDb.unwatchUserTeamAccessRequest(this.fbDb, this.userId, teamId);
    delete this.reqTeams[teamId];
  };

  removeTeamAccessReq = async (teamId: string) => {
    this.unwatchTeamAccessReq(teamId);
    UserManagerDb.removeTeamAccessReq(this.fbDb, this.userId, teamId);
  };

  doTeamAccessReq = async (teamPath: string): Promise<{ status: string; teamId: string }> => {
    const teamId = await FireDb.getTeamPathId(this.fbDb, teamPath);

    logger.info("doTeamAccessReq team", teamPath, teamId);

    var hasAccess = await FireDb.checkTeamAccess(this.fbDb, this.userId, teamId);

    if (!hasAccess) {
      const requestDetails: Partial<IUserTeamReq> = {
        email: this.userDoc!.email,
        name: this.userDoc!.name,
        imageUrl: this.userDoc!.imageUrl || null,
      };

      logger.debug("requesting team access");

      UserManagerDb.requestTeamAccess(this.fbDb, this.userId, teamId, requestDetails, teamPath);
      this.watchTeamAccessReq(teamId);

      return { status: "requested_access", teamId };
    }

    this.setCurrentTeam(teamId);

    return { status: "ok", teamId };
  };

  accessTeamWithInvite = async (teamPath: string, teamInviteId: string): Promise<boolean> => {
    const teamId = await FireDb.getTeamPathId(this.fbDb, teamPath);

    logger.info("accessTeamWithInvite teamPath=%s, teamId=%s", teamPath, teamId);

    var hasAccess = await FireDb.checkTeamAccess(this.fbDb, this.userId, teamId);

    if (!hasAccess && teamInviteId) {
      try {
        hasAccess = await UserManagerDb.accessTeamWithInvite(teamId, teamInviteId);
      } catch (e) {
        logger.info("login with invite failed", e);
        return false;
      }
    }

    this.setCurrentTeam(teamId);

    return true;
  };

  updateName = async (name: string) => {
    await UserManagerDb.updateName(this.fbDb, this.userId, name);
  };

  getInvitees = (teamId: string) => {
    return UserManagerDb.getInvitees(this.fbDb, teamId, this.userId)
  }
}
