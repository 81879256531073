import { UIDataState } from "@openteam/app-core";
import { CallTracker } from "assert";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { DSText } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { getInitials } from "../../../Util/UserInitials";
import CallTileStyles from "./CallTileStyles";

type Props = {
  userId: string;
  tileSize: number;
};

const Initials: React.FC<Props> = ({ userId, tileSize }) => {
  const call = UIDataState.activeCall;
  const callUser = call?.participants[userId];
  const initials = useMemo(() => getInitials(callUser?.name), [callUser?.name]);

  return (
    <div style={CallTileStyles.absolute} >
      <DSText
        color={DSTheme.EmphasisColor}
        style={{ fontSize: Math.min(90, Math.max(12, tileSize / 2)) }}
      >
        {initials}
      </DSText>
    </div >
  );
}

export default observer(Initials)

