import { ISearchKeys } from "@openteam/models";
import { getFunctions, httpsCallable} from "firebase/functions";

export const searchIndexVersion = 3; 

export async function getSearchKeys(teamId: string): Promise<ISearchKeys | undefined> {
  const func = httpsCallable(getFunctions(), `search_getSearchKeys_v${searchIndexVersion}`);
  const result = await func({ teamId });
  return result.data as any;
}
