export const isDev = process.env.NODE_ENV !== 'production'

export var WWW_URL = 'https://ehlo.space'
export var WEB_URL = 'https://auth.ehlo.space/'
export var APP_LOGIN_URL = 'https://auth.ehlo.space/'
export var FEEDBACK_URL = 'https://trello.com/invite/b/zJQ1KRPH/d1fa43b72f4879515ac252e1f3a095d2/openteam-feedback'
export var debugTrue = isDev
export var SENTRY_AUTH_DSN: string | undefined = "https://f69f2ec746ce465ab1d2cb36bda1033e@o395118.ingest.sentry.io/6199963"
export var SENTRY_WEB_DSN: string | undefined = "https://709bf7842b2e4995a1ae8d8c08140752@o395118.ingest.sentry.io/5247939"
export var SENTRY_ELECTRON_DSN: string | undefined = "https://2d4ff7091c7f4ad68db0b7169962d72a@o395118.ingest.sentry.io/5246431"

export var SENTRY_REACT_NATIVE_DSN: string | undefined = "https://a1c18649652f438b9f19277fca25a96f@o395118.ingest.sentry.io/5478666"
export var LINKPROTOCOL = 'ehlo'
export var IMAGECDN = "https://ik.imagekit.io/openteam"
export var IMAGEFILECDN = "https://ik.imagekit.io/openteam/teamfiles"
export var TEAM_STORAGE_BUCKET_PATH = "openteamfiles"
export var TEAM_STORAGE_BUCKET = `gs://${TEAM_STORAGE_BUCKET_PATH}`

//export var LOGGLY_CUSTOMER_TOKEN = "851a5b46-474e-4ce5-843f-f7433162bf8d"
//export var MIXPANELID = "bea2feae026f099bc47a675e681275de"
export var MIXPANELID = "b5678057c9a3fd4e5f91298a820d946d"
export var INFLUX_APP_LOG_TOKEN: string | undefined = "OMhrNbjUXY7GHXWsKmENXRBqzYqg6t54Ct_C7HtmvgthP17lDKRzVIixO2Gj6-EN-mY4WWKlvAUpDxkvryoakg=="

export var firebaseConfig = {
  apiKey: "AIzaSyBfyupdj9gdDENYXjlszBIM8z6pQrtO0zs",
  authDomain: "auth.openteam.space", //"openteam-12bd3.firebaseapp.com",
  databaseURL: "https://openteam-12bd3.firebaseio.com",
  projectId: "openteam-12bd3",
  storageBucket: "openteam-12bd3.appspot.com",
  messagingSenderId: "588101274675",
  appId: "1:588101274675:web:ba6838d33dcf8d767fa746",
  measurementId: "G-LWJMZM1NQ5"
};

export var googleConfig = {
  clientId:'588101274675-0gm6v1qrknu0vmags9d7j053kinhd1pk.apps.googleusercontent.com',
  apiKey: 'AIzaSyBfyupdj9gdDENYXjlszBIM8z6pQrtO0zs'
}

export const RTCConfig = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:turn.openteam.space:443' },
    {
      urls: [
        'turn:turn.openteam.space:443',
        'turn:turn.openteam.space:443?transport=tcp',
        'turns:turn.openteam.space:443?transport=tcp'
      ],
      username: 'openteam',
      credential: 'openteamkey1',
      credentialType: "password"
    },
  ],
  iceCandidatePoolSize: 10,
};

export const mediasoup = {
  forceH264: true,
  forceVP9: false
}

if (isDev || window?.location?.host === 'openteam-dev.firebaseapp.com' || window?.location?.host === 'nudgeos-dev.firebaseapp.com' || window?.location?.host === 'ehlo-dev.firebaseapp.com') {

  INFLUX_APP_LOG_TOKEN = undefined

  WEB_URL = window?.location?.origin || "https://ehlo-dev.firebaseapp.com/"
  APP_LOGIN_URL = window?.location?.origin || "https://ehlo-dev.firebaseapp.com/"
  IMAGECDN = "https://ik.imagekit.io/openteam/dev"
  IMAGEFILECDN = "https://ik.imagekit.io/openteam/teamfilesdev"
    //MIXPANELID = "6386f2ed529999772c2a6507dc5e207a"
  MIXPANELID = "42a10cb1d161760bb85c0122f0d62eb5"
  // WEB_URL = "http://localhost:19006"
  // WEB_URL = "https://openteam-dev.firebaseapp.com"
  SENTRY_AUTH_DSN = undefined
  SENTRY_WEB_DSN = undefined
  SENTRY_ELECTRON_DSN = undefined
  // SENTRY_REACT_NATIVE_DSN = undefined
  LINKPROTOCOL = 'ehlodev'
//  LOGGLY_CUSTOMER_TOKEN = "0ed8051c-fd34-4bde-94fe-47b1fa69bf9f"

  TEAM_STORAGE_BUCKET_PATH = "openteamfilesdev"
  TEAM_STORAGE_BUCKET = `gs://${TEAM_STORAGE_BUCKET_PATH}`

  firebaseConfig = {
    apiKey: "AIzaSyDhHwtr9TWQwzM8nW18_KG1OAgwqIlgIlM",
    authDomain: "openteam-dev.firebaseapp.com",
    databaseURL: "https://openteam-dev.firebaseio.com",
    projectId: "openteam-dev",
    storageBucket: "openteam-dev.appspot.com",
    messagingSenderId: "1069925529607",
    appId: "1:1069925529607:web:34f9b54b2bba964bcb12a2",
    measurementId: "G-5QCTHK6VFR"
  };

  googleConfig = {
    clientId:'1069925529607-4ouureodva6512bi8fcv9og4sp54ku51.apps.googleusercontent.com',
    apiKey: 'AIzaSyDhHwtr9TWQwzM8nW18_KG1OAgwqIlgIlM'
  }
}