import React from 'react';
import { FaFile, FaFileAudio, FaFileExcel, FaFileImage, FaFilePdf, FaFileVideo, FaFileWord } from 'react-icons/fa';


export const MimeTypeIcon = ({ mimeType, ...props }) => {
  if (mimeType.startsWith("image"))
    return <FaFileImage {...props} />;
  else if (mimeType.startsWith("audio"))
    return <FaFileAudio {...props} />;
  else if (mimeType.startsWith("video"))
    return <FaFileVideo {...props} />;
  else if (mimeType === "application/pdf")
    return <FaFilePdf {...props} />;
  else if (mimeType === "application/msword" ||
    mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    mimeType === "application/vnd.ms-word")
    return <FaFileWord {...props} />;
  else if (mimeType === "application/vnd.ms-excel" ||
    mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml" ||
    mimeType === "application/vnd.oasis.opendocument.spreadsheet")
    return <FaFileExcel {...props} />;

  else
    return <FaFile {...props} />
}
/* 

  const icons: Record<string, IconType> = {
    "image": Icons.FaFileImage,
  };
    audio: "fa-file-audio-o",
   video: "fa-file-video-o",
   // Documents
   "application/pdf": "fa-file-pdf-o",
   "application/msword": "fa-file-word-o",
   "application/vnd.ms-word": "fa-file-word-o",
   "application/vnd.oasis.opendocument.text": "fa-file-word-o",
   "application/vnd.openxmlformats-officedocument.wordprocessingml":
     "fa-file-word-o",
   "application/vnd.ms-excel": "fa-file-excel-o",
   "application/vnd.openxmlformats-officedocument.spreadsheetml":
     "fa-file-excel-o",
   "application/vnd.oasis.opendocument.spreadsheet": "fa-file-excel-o",
   "application/vnd.ms-powerpoint": "fa-file-powerpoint-o",
   "application/vnd.openxmlformats-officedocument.presentationml":
     "fa-file-powerpoint-o",
   "application/vnd.oasis.opendocument.presentation": "fa-file-powerpoint-o",
   "text/plain": "fa-file-text-o",
   "text/html": "fa-file-code-o",
   "application/json": "fa-file-code-o",
   // Archives
   "application/gzip": "fa-file-archive-o",
   "application/zip": "fa-file-archive-o" 

  return icons[mimeType] ? icons[mimeType] : Icons.FaFile;
}
 */
/* const getIconFromName = (name: string) => Icons[name];
 */
