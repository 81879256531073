import * as React from 'react';
import SelectUnstyled, {
  SelectUnstyledProps,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/material';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  open: boolean
}

const SelectButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ open, children, ...props }, ref) => {
    return (
      <button ref={ref} {...props} tabIndex={-1}>
        {children}
        {open ? <FaChevronUp style={{ marginLeft: 6 }} /> : <FaChevronDown style={{ marginLeft: 6 }} />}
      </button>
    )
  })

const StyledButton = styled(SelectButton)<ButtonProps>(
  ({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    boxSizing: "border-box",
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: "4px",
    textAlign: "left",
    background: 'transparent',
    padding: "2px 4px",
    minHeight: "29px",
    alignItems: "center",
    border: `1px solid transparent`,
    color: theme.palette.text.primary,

    '&:hover': {
      borderColor: theme.palette.secondary.dark
    },

    '&.Mui-expanded': {
      borderColor: theme.palette.secondary.dark,
    },
  })
);

const StyledListbox = styled('ul')(
  ({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    boxSizing: "border-box",
    padding: 2,
    margin: "5px 0",
    minWidth: 50,
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    overflowY: 'scroll',
    maxHeight: 150,
    outline: 0,
  })
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 6px;
  margin: 2px;
  border-radius: 4px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main}
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.text.disabled};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.primary.contrastText};
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

interface Props<TValue extends {}> extends SelectUnstyledProps<TValue> {
  options?: [any, any][]
}

const CustomSelect = React.forwardRef(function CustomSelect<TValue extends {}> (
  { options, children, ...props }: Props<TValue>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const slots: SelectUnstyledProps<TValue>['slots'] = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  const [open, setOpen] = React.useState(false);


  return (
    // @ts-ignore
    <SelectUnstyled
      open={open}
      {...props}
      onListboxOpenChange={setOpen}
      ref={ref}
      slots={slots}
    >
      {options?.map(([key, val]) => (<StyledOption value={key}>{val}</StyledOption>))}
      {children}
    </SelectUnstyled>
  )

}) as <TValue extends {}>(
  props: SelectUnstyledProps<TValue> & React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

export default React.memo(CustomSelect)
export const DS2Option = StyledOption;
