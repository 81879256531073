import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Logger } from '@openteam/app-util';
import { observer } from 'mobx-react';
import { DSTheme } from '../../../DesignSystem/DSTheme';
const logger = new Logger('CallWidgetTileWrapper');

type Props = {
  callHovered: boolean;
  tileSize: number;
  circular: boolean;
  aspectRatio?: string;
  backgroundImage?: string;
  muted?: boolean;
  speaking?: boolean;
  onTileHovered: (hovered: boolean) => void;
  onDoubleClick?: () => void
  onClick?: () => void
  style?: CSSProperties;
};

const TileWrapper: React.FC<Props> = ({
  callHovered,
  tileSize,
  circular,
  aspectRatio = "1",
  backgroundImage,
  muted = false,
  speaking = false,
  onTileHovered,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [borderColor, setBorderColor] = useState('transparent');
  const [transition, setTransition] = useState<string>();
  const borderWidth = Math.max(2, Math.min(6, Math.round(tileSize / 100)));
  const [innerBorderRadius, setInnerBorderRadius] = useState(DSTheme.BaseBorderRadius);

  //logger.debug(`circular: ${circular} backgroundImage ${backgroundImage}`)
  useEffect(() => {
    const baseColor = callHovered ? "transparent" : DSTheme.CallShadowColor;
    let color = baseColor;

    if (muted) {
      color = baseColor;
    } else if (speaking) {
      color = DSTheme.EmphasisColor;
    }

    setBorderColor(color);
    if (circular) {
      setInnerBorderRadius(999);
    } else if (color === 'transparent') {
      setInnerBorderRadius(DSTheme.BaseBorderRadius);
    } else {
      setInnerBorderRadius(Math.max(0, DSTheme.BaseBorderRadius - 2 * borderWidth));
    }
  }, [muted, speaking, callHovered]);

  useLayoutEffect(() => {
    const transitions = ['border-color 0.1s ease-in'];
    if (callHovered) {
      transitions.push('box-shadow 0.1s ease-in');
    } else {
      transitions.push('box-shadow 0.5s ease-out 1s');
    }
    setTransition(transitions.join(', '));
  }, [callHovered]);

  const onMouseOver: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (circular) {
        if (ref.current) {
          const radius = tileSize / 2;
          const { left, top } = ref.current.getBoundingClientRect();
          const [mouseX, mouseY] = [e.clientX - left - radius, e.clientY - top - radius];
          onTileHovered(radius > Math.sqrt(mouseX ** 2 + mouseY ** 2) + 4);
        }
      } else {
        onTileHovered(true);
      }
    },
    [ref, tileSize]
  );

  const onMouseOut = useCallback((e) => {
    onTileHovered(false);
  }, []);

  return (
    <div
      ref={ref}
      onMouseMove={onMouseOver}
      onMouseOut={onMouseOut}
      onDoubleClick={props.onDoubleClick}
      onClick={props.onClick}
      style={{
        position: 'relative',
        aspectRatio,
        overflow: 'hidden',
        borderRadius: circular ? 999 : DSTheme.BaseBorderRadius * 1.5,
        borderStyle: 'solid',
        pointerEvents: 'initial',
        borderWidth,
        borderColor,
        transition,
        height: tileSize,
        boxShadow: callHovered ? 'none' : `0px 2px 2px 1px ${DSTheme.CallShadowColor}`,
        ...props.style
      }}
    >
      <div
        style={{
          aspectRatio,
          backgroundColor: 'black',
          backgroundImage: backgroundImage ? `url("${backgroundImage}")` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: innerBorderRadius,
          transition: 'border-radius 0.15s ease-in, background-image 2.5s ease-in',
          ...props.style
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default observer(TileWrapper);
