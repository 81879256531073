import React, { useState } from "react";
import { DSTheme } from "./DSTheme";

type Props = {
  progress: number
  borderRadius?: number
  height?: number
}

export const DSProgressBar: React.FC<Props> = (props) => {

  return (
    <div
      style={{
        width: "100%",
        height: props.height || 16,
        backgroundColor: DSTheme.ProgressBarEmpty,
        borderRadius: props.borderRadius || 5
    }}>
      <div
        style={{
          width: `${props.progress || 0}%`,
          height: props.height || 16,
          borderRadius: props.borderRadius || 5,
          backgroundColor: DSTheme.ProgressBarFilled,
      }}
      >

      </div>
   </div>

  )
}
