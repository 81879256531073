import { DeviceType, IUIDeviceTypeSettings } from "@openteam/models";
import { setCallWidgetHovered, UIDataState, updateDeviceSettings } from "@openteam/app-core";
import { action, runInAction } from "mobx";
import { IDSButtonProps } from "../../../DesignSystem/DSButton";
// import { MenuTemplate, openMenu } from "../../Menu";

export interface ButtonProps extends IDSButtonProps {
  size?: number;
  color?: string;
}

export function openCallMenu(template, onClose?: () => void) {
  // openMenu(template, () => {
  //   setCallWidgetHovered("menu", false)
  //   onClose && onClose();
  // });

  // setCallWidgetHovered("menu", true)
}

export function getDeviceOptions(type: DeviceType) {
  console.log("getDeviceOptions", type)
  const deviceSettings: IUIDeviceTypeSettings = UIDataState.deviceSettings[type]!;

  const selectDevice = (deviceId: string) => {
    console.log("updateDeviceSettings", type, deviceId)
    updateDeviceSettings(type, { deviceId });
  };

  return deviceSettings.availableDevices.map((device) => ({
    label: device.label ?? "Unnamed device",
    type: "radio",
    checked: device.deviceId === deviceSettings.activeDeviceId,
    click: () => selectDevice(device.deviceId),
  }));
}
