import { Panel, Column, H1, Body } from '@openteam/design-system';
import React from 'react';
import Page from '../Util/Page';


const ZoomSuccess: React.FC = () => {
  return (
    <Page>
      <Panel style={{ margin: 20, alignItems: 'center' }}>
        <Column>
          <H1 style={{ color: "black", marginBottom: 24, fontWeight: 700, fontSize: 40}}>
            Success
          </H1>
          <Body style={{ color: "black", marginBottom: 24, fontSize: 16 }}>
            Congratulations, your Zoom account is now linked to ehlo.
            <br/>
            <br/>
            You can close this page and return to ehlo
          </Body>

        </Column>

      </Panel>
    </Page>
  );
}

export default ZoomSuccess;
