import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

import { LoginPage } from './Login/Login';
import { InvitePage } from './Login/Invite';
import React, { useEffect, } from 'react';
import { initLogin } from './Controllers/Login';
import { LoginState } from './Controllers/UIState';
import { AppLoginPage } from './Login/AppLogin';
import { ActionHandler } from './Login/ActionHandler';
import { observer } from 'mobx-react-lite';
import { meetingMode, MeetPage } from './Pages/Meet/Meet';
import { setup } from './Controllers/app';
import { initFire } from './Fire';
import { ChatMarkdownCSS } from './Components/Chat/ChatMarkdownView';
import ZoomSuccess from './Pages/ZoomSuccess';
import ZoomError from './Pages/ZoomError';
import ZoomIntegrate from './Pages/ZoomIntegrate';
import { createTheme, CssBaseline, GlobalStyles, lightTheme, Print, ThemeProvider } from '@openteam/design-system';
import { Logger } from '@openteam/app-util';

initFire()
setup()

const logger = new Logger('App')


const $style = document.createElement("style");
document.head.appendChild($style);
$style.innerHTML = `
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Manrope:wght@300;400;500;600;700&display=swap');

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app {
  display: flex;
  flex-direction: column;
  text-align: center;
  pointer-events: none;
  height: 100vh;
  width: 100%;
}

::placeholder {
  color: #C4C4C4;
}

body {pointer-events: none}
* {pointer-events: all;}
.draggable {-webkit-app-region: drag;}


.noScrollBar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
    -webkit-user-select: none;
    user-select: none;
}

@keyframes spinner {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes pulse {
	0% {
		transform: scale(.50);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(.50);
	}
}

`;

const theme = createTheme(lightTheme, {
  MuiButton: {
    styleOverrides: {
      textSecondary: {
        color: "white",
      },
      sizeSmall: {
        fontSize: 14
      },
      sizeMedium: {
        fontSize: undefined,
      },
      sizeLarge: {
        fontSize: undefined,
      },
    }
  },
  palette: {
    secondary: {
      main: 'rgba(35, 37, 38, 0.9)',
      contrastText: 'white'
    },
    background: 'white'
  }
})

function App () {

  useEffect(() => {
    return initLogin()
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { color: lightTheme.palette.text.primary },
        }}
      />
      <Router>
        <AppRouter />
      </Router>
      <style type="text/css">{ChatMarkdownCSS}</style>
    </ThemeProvider>
  );
}

function AppRouter () {
  let location = useLocation();

  let query = new URLSearchParams(location.search);
  return (
    <Switch>
      <Route path="/meet/:meetingToken">
        <MeetPage mode={query.get("mode") as meetingMode | undefined} />
      </Route>
      <PrivateRoute path="/applogin">
        <AppLoginPage inviteToken={query.get("inviteToken")} />
      </PrivateRoute>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/signup">
        <LoginPage mode={"signup"} />
      </Route>
      <Route path="/invite">
        <InvitePage inviteToken={query.get("inviteToken")} />
      </Route>
      <Route path="/actionHandler">
        <ActionHandler />
      </Route>
      <PrivateRoute path="/zoom-integration">
        <ZoomIntegrate />
      </PrivateRoute>
      <Route path="/zoomSuccess">
        <ZoomSuccess />
      </Route>
      <Route path="/zoomError">
        <ZoomError />
      </Route>
      <Route path="/">
        <Redirect to={"/login"} />
      </Route>
    </Switch>
  )
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = observer(({ children, ...rest }: React.PropsWithChildren<any>) => {
  logger.info("PrivateRoute, LoginState.initialised, LoginState.isSignedIn", LoginState.initialised, LoginState.isSignedIn)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        logger.info("PrivateRoute", location)

        return (
          LoginState.initialised ?
            !LoginState.isSignedIn || LoginState.isAnonymous ?
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
              : (children)
            : <Print> </Print>
        )
      }
      }
    />
  );
})


export default App;
