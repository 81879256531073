import type { google, calendar_v3 } from "googleapis";

export type GoogleCredentials = typeof google.auth.OAuth2.prototype.credentials;

export type ICalChannelType = "calendarList" | "events";
export interface ICalChannel extends calendar_v3.Schema$Channel {
  msgNum?: number;
  channelType: ICalChannelType;
  calendarId?: string;
  meta?: ICalCalendarListMeta | ICalEventsMeta;
  timer?: ICalTimer;
}

export type ICalCalendarListMeta = Omit<calendar_v3.Schema$CalendarList, "items">;
export type ICalCalendarListEntry = calendar_v3.Schema$CalendarListEntry;

export interface ICalTimer {
  taskId?: string;
  timerId: string;
  createdAt: number;
}

export interface ICalEvSummary {
  calendarId: string;
  eventId: string;
  summary: string;
  start: number;
  end: number;
}
export interface ICalStatus {
  current: ICalEvSummary | null;
  next: ICalEvSummary | null;
}
export interface ICalStatusTimer extends ICalStatus {
  timer: ICalTimer | null;
}

export interface ICalRouting {
  path: string;
  userId: string;
  params?: any;
}

export type IGoogleSyncType = "sync" | "exists" | "not_exists";

export interface ICalAuth extends GoogleCredentials { }

export interface ICalUser {
  auth?: {
    email: string;
    tokens: GoogleCredentials | null;
    //authCode?: string | null;
  } | null;
  calendars?: Record<string, ICalSetting> | null;
  //  nextEvent?: ICalNextEvent | null;
  //  currentEvent?: ICalCurrentEvent | null;
  status?: ICalStatusTimer | null;
}

export interface ICalSetting {
  summary: string
  visible: boolean;
  primary: boolean;
  accessRole: string | null;
  id: string;
  sync: boolean;
  error: string | null;
  deleted?: boolean;
  notifyEvents: boolean;
  isPublic: boolean;
  version: number;
}

export type ICalEventsMeta = Omit<calendar_v3.Schema$Events, "items">;
export interface ICalEvent extends calendar_v3.Schema$Event {
  meta: {
    calendarId: string;
    isNotifiable?: boolean
  };
}

export class CalEvent {
  // https://developers.google.com/calendar/api/v3/reference/events#resource

  static getStart = (ev: ICalEvent) => CalEvent.getTime(ev, 'start');
  static getEnd = (ev: ICalEvent) => CalEvent.getTime(ev, 'end');
  static getTime = (ev: ICalEvent, field: 'start' | 'end' | 'originalStartTime') => {
    const d = ev[field];
    if (d && d.date) {
      // tz is whatever local is
      return new Date(d.date + 'T00:00:00').getTime() / 1000;

    } else if (d && d.dateTime) {
      // ev.start.dateTime contains tz info.
      return new Date(d.dateTime).getTime() / 1000;

    } else {
      //console.log("dumping event: ", ev);
      throw new Error(`no ${field} time: ${ev.id}`)
    }
  };

  static isAllDayEvent = (ev: ICalEvent) => ev.start?.date && ev.end?.date;
  static getResponse = (ev: ICalEvent) => ev.attendees?.filter(x => x.self === true)?.[0]?.responseStatus;
  static isOwner = (ev: ICalEvent) => ev.creator?.self === true;
  static isOrganiser = (ev: ICalEvent) => ev.organizer?.self === true;
  static isFree = (ev: ICalEvent) => ev.transparency === 'transparent';
  static isAttendingEv = (ev: ICalEvent) => {

    if (ev.visibility === 'private')
      return true;

    const response = CalEvent.getResponse(ev);

    if (response === undefined || response === "null") {
      return CalEvent.isOrganiser(ev);

    } else {
      return response !== "declined";
    }
  }
  static isEqual = (ev1: ICalEvent, ev2: ICalEvent) =>
    ev1.meta.calendarId === ev2.meta.calendarId && ev1.id === ev2.id;

  static key = (b64CalId: string, evId: string) => `${b64CalId}-${evId}`;

  static isCurrentEvent = (dtNow: Date, event: ICalEvent, start: number) => {
    const duration = CalEvent.getEnd(event) - CalEvent.getStart(event);
    const now = dtNow.getTime() / 1000;
    return now > start && now < start + duration;
  }

}