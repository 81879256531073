import events from "events";
import { makeObservable, computed, observable, reaction, action, toJS, autorun } from "mobx";
import { HarkStreamDetails, StreamDetails, LocalStreamDetails, RemoteStreamDetails, ScreenShareStream, WebcamStream, SimpleScreenShareStream } from "./MediaDeviceManager";
import { AwaitLock, Logger } from "@openteam/app-util";
import MediaSoupStreamManager from "./MediaSoup";
import { P2PStreamManager } from "./PeerConnection";
import {
  IChatMsg,
  IKickUserMsg,
  IMessageFile,
  IMuteUserMsg,
  IOTCallState,
  IOTChatMessage,
  IOTPluginTile,
  IOTRoomUser,
  IOTTile,
  IPeerMsg,
  IPendingMessage,
  TStreamType,
  IFaceDetect,
  IUserPaused,
  IPluginResource,
  ILinkPreview,
  ITeamRoomConfig,
  ITeamRoom,
  IOTRoom
} from "@openteam/models";
import { CloudUpload } from "./CloudUpload";
import { PluginManager } from "./PluginManager";
import { CallMessageManager } from "./CallMessageManager";
import { OTGlobals } from "./OTGlobals";
import { ExternalMeetingDb, FireDb } from "./fire";
import { OTUserInterface } from "./OTUserInterface";
import { OTAppCoreData } from "./OTAppCoreData";
import { TShowCallFeedback } from "./CallRequest";
import { generateTiles } from "./utils/generateTiles";
import { setStream } from "./utils/setStream";
import { updateCallParticipants, writeActiveCall, writeCallParticipant } from "./UIDataState";
import { isMacOs, isWindows } from "react-device-detect";
import { UIDataState as DataState } from "./UIDataState";
import { URLPreview } from "./Chat/LinkPreviewManager";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";
import { UserSettingsManager } from "./UserSettingsManager";

const logger = new Logger("CallState");

export class CallStateManager extends events.EventEmitter {
  fbDb: Database;
  fsDb: Firestore;
  sessionToken: string;

  _streamManager!: P2PStreamManager | MediaSoupStreamManager;

  pluginManager: PluginManager;
  callMessageManager: CallMessageManager;
  teamId: string;
  roomId: string;
  myUserId: string;

  getRoom: () => IOTRoom;

  _audioOn: boolean = false;
  _videoOn: boolean = false;
  _screenShareOn: boolean = false;
  _wantAudio: boolean = true;
  _wantVideo: boolean = true;

  pushToUnmute: boolean = false;
  connected: boolean = false;

  myStreams: Record<string, LocalStreamDetails> = {};

  _loudestLastChanged: number;
  _startTime: number;
  _screenShareStarted: number | undefined;

  stateLock = new AwaitLock();

  roomUsers: Record<string, boolean> = {};


  @observable disableUserSound: { [id: string]: boolean } = {};

  @observable callState: IOTCallState;

  @observable _inCall: boolean = false;

  isStopping: boolean = false;

  showCallFeedback: TShowCallFeedback;

  _autorun: Record<string, any> = {};
  _reaction: Record<string, any> = {};
  _checkDeviceInterval?: ReturnType<typeof setTimeout>;
  _isPaused: boolean = false;


  constructor(
    fbDb: Database,
    fsDb: Firestore,
    userId: string,
    sessionToken: string,
    teamId: string,
    roomId: string,
    users: Record<string, IOTRoomUser>,
    getRoom: () => IOTRoom,
    showCallFeedback: TShowCallFeedback
  ) {
    super();

    makeObservable(this);

    this._loudestLastChanged = Date.now();

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.myUserId = userId;
    this.sessionToken = sessionToken;
    this.showCallFeedback = showCallFeedback;
    this.getRoom = getRoom;

    logger.debug("Creating CallState for room", roomId, "user", this.myUserId);

    this.teamId = teamId;
    this.roomId = roomId;
    this._startTime = Date.now();

    this._setupStreamManager();

    const room = this.getRoom()
    const roomConfig = room.config

    this.pluginManager = new PluginManager(this.fbDb, this.myUserId, this.teamId, this.roomId);
    this.callMessageManager = new CallMessageManager(this.fsDb, this.teamId, this.myUserId, roomConfig?.summaryCallId || this.roomId, this.onLinkDetected)

    this.callState = {
      roomId: this.roomId,
      connectedUsers: [],
      unreadRoomMsgs: 0,
      popoutUser: true,
      streams: {},
      tiles: [],
      orderedTiles: [],
      popoutStreams: {},
      bandwidthLow: false,
      networkDisconnected: false,
      audioGroupId: null,
      isCallPaused: this._isPaused,
      peerInfo: {},
      faceDetect: {},
      pausedUsers: {}
    };

    this.updateUsers(users, true);

    // This will trigger creation of streams so don't set until the end

    this._wantAudio = (roomId ? true : false) && !(roomConfig && roomConfig.micOff) && OTGlobals.remoteUserSettings.callAudioEnabled !== false;
    this._wantVideo = (roomConfig && roomConfig.call && !roomConfig.webcamOff ? true : false) && OTGlobals.remoteUserSettings.callVideoEnabled !== false;

    this._updateStream("camera", this._wantAudio, this._wantVideo);

    this.startPresence();

    OTUserInterface.platformUtils.preventDisplaySleep();

    writeActiveCall(this);

    this.pluginManager.start();

    if (!isMacOs || !isWindows) {
      // linux doesn't seem to get device change events for bluetooth headphones etc, enumerate devices triggers one
      this._checkDeviceInterval = setInterval(async () => {
        await navigator.mediaDevices.enumerateDevices()
      }, 5000)
    }
  }

  get room() {
    return this.getRoom()
  }

  startPresence = () => {
    const room = this.getRoom();

    this._autorun["updateCallStateTiles"] = autorun(() => {
      const { streams } = this.callState;
      if (room) {
        if (
          this.callState.focusUserId &&
          this.callState.focusStreamType &&
          !streams[this.callState.focusUserId]?.[this.callState.focusStreamType]
        ) {
          if (
            !(
              room.users[this.callState.focusUserId]?.online ||
              room.users[this.callState.focusUserId]?.inLeeway
            ) ||
            this.callState.focusStreamType != "camera"
          ) {
            this.callState.focusUserId = undefined;
            this.callState.focusStreamType = undefined;
          }
        }

        var onlineUsers = Object.keys(room.users || {}).filter(
          (userId) => room.users[userId].online || room.users[userId].inLeeway
        );

        this.callState.tiles = generateTiles(onlineUsers, streams);
        // Object.keys(this.callState.popoutStreams).forEach((streamId) => {
        //   if (!teamData.streams[streamId]) {
        //     delete this.callState?.popoutStreams[streamId];
        //   }
        // });
      }
    });

    this.setupReactions();

    //    FireDb.setupRoomPresence(this.fbDb, this.teamId, this.myUserId, this.sessionToken, this.roomId);
    OTUserInterface.foregroundService.startCall(
      this.roomId,
      room.config?.name || "Meeting Room",
      "Call Ongoing"
    );
    this._inCall = true;
  };

  stopPresence = async () => {
    logger.info("removing room presence");
    /*     await FireDb.removeRoomPresence(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      this.roomId
    );
 */
    this._inCall = false;

    OTUserInterface.foregroundService.stopCall();

    Object.values(this._autorun).map((x) => x());
    this._autorun = {}

    Object.values(this._reaction).map((x) => x());
    this._autorun = {};

    if (this._checkDeviceInterval) {
      clearInterval(this._checkDeviceInterval)
    }
  };

  broadcastMessage = (msg: IPeerMsg) => {
    this._streamManager!.sendMessageToAll(msg);
  };

  sendMessage = (userId: string, msg: IPeerMsg) => {
    this._streamManager!.sendMessage(userId, msg);
  };

  onFaceDetect = (faceDetect?: IFaceDetect) => {
    this.hdlFaceDetect(this.myUserId, faceDetect);
    this.broadcastMessage({
      msgType: "FACEDETECT",
      userId: this.myUserId,
      faceDetect: faceDetect,
    });
  };

  hdlFaceDetect = (userId: string, faceDetect?: IFaceDetect) => {
    //logger.debug("hdlFaceDetect", userId, faceDetect);

    if (faceDetect) {
      this.callState.faceDetect[userId] = faceDetect;
    } else {
      delete this.callState.faceDetect[userId];
    }
    this._updateParticipant(userId)
  };

  sendURL = async (text: string) => {
    const previews = {}
    if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
      return previews;
    }

    var urlRegex = /((https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var matches = [...text.matchAll(urlRegex)];

    for (const element of matches) {
      const url = element[0];

      // also create message in ongoing call.
      this.callMessageManager.sendChatMessage(url, [])

      const { pluginType, pluginArgs } = this.pluginManager.getUrlHandler(url);
      logger.debug(`plugin ${pluginType} for ${url}`)
      if (pluginType) {
        this.pluginManager.createPlugin(pluginType, url, pluginArgs);
      } else {
        previews[url] = await OTGlobals.getLinkPreview(url);
      }
    };
    return previews;
  };

  onLinkDetected = async (url: string, linkPreview: URLPreview, parentId?: string, shareWithEveryone?: boolean) => {
    const { pluginType, pluginArgs } = this.pluginManager.getUrlHandler(url);
    if (pluginType) {
      if (shareWithEveryone || (await OTUserInterface.showConfirm(
        {
          parentId: parentId || DataState.activeCall!.windowId,
          title: "Open Resource?",
          message: [
            `We've detected that you're sharing a link.`,
            `Would you like to open it for everyone using the ${pluginType} plugin?`],
          actions: { 'Yes': true, 'No': false },
        }
      )
      )) {
        this.pluginManager.createPlugin(pluginType, url, pluginArgs);
      }
    }
  }

  setDisableUserSound = (userId: string, disable: boolean) => {
    logger.info("setting disableUserSound", userId, disable);
    this.disableUserSound[userId] = disable;
    writeCallParticipant(this, userId, this.room.users[userId])
  };

  muteUser = (userId: string, mute: boolean) => {
    const msg: IMuteUserMsg = {
      msgType: "MUTE",
      mute: mute,
      userId: this.myUserId,
      crDate: Date.now(),
    };

    this._streamManager.sendMessage(userId, msg);
  };

  muteAll = (mute: boolean) => {
    const msg: IMuteUserMsg = {
      msgType: "MUTE",
      mute: mute,
      userId: this.myUserId,
      crDate: Date.now(),
    };
    this._streamManager.sendMessageToAll(msg);
  };

  removeTeamRoomUser = (userId: string) => {
    const msg: IKickUserMsg = {
      msgType: "CALLKICKED",
      userId: this.myUserId,
      crDate: Date.now(),
    };

    this._streamManager.sendMessage(userId, msg);

    ExternalMeetingDb.removeTeamRoomUser(this.fbDb, this.teamId, this.roomId, userId);
  };


  @computed get name() {
    return this.room.config?.name;
  }

  @computed get audioOn() {
    return !!(this.myStreams["camera"] && !this.myStreams["camera"].muted);
  }

  @computed get videoOn() {
    return !!(this.myStreams["camera"] && this.myStreams["camera"].hasVideo);
  }

  @computed get screenShareOn() {
    return this.myStreams["screen"] && true;
  }

  toggleAudio = action(async (toState?: boolean) => {
    try {
      if (toState === undefined || toState != this.audioOn) {
        logger.debug(`toggleAudio from ${this._wantAudio} this.audioOn ${this.audioOn} toState ${toState}`);
        this._wantAudio = !(this._wantAudio && this.audioOn);
        await this._updateStream("camera", this._wantAudio, this._wantVideo);
      }
    } catch (e) {
      const err = <Error>e
      OTUserInterface.toastHandlers.show("Failed to access microphone: " + err.message, "error");
    }
  });

  toggleVideo = action(async (toState?: boolean) => {
    try {
      if (toState === undefined || toState != this.videoOn) {
        this._wantVideo = !(this._wantVideo && this.videoOn);
        await this._updateStream("camera", this._wantAudio, this._wantVideo);
      }
    } catch (e) {
      const err = <Error>e
      OTUserInterface.toastHandlers.show("Failed to access camera: " + err.message, "error");
    }
  });

  toggleScreenShare = async () => {
    try {
      if (!this.screenShareOn) {
        await this._updateStream("screen", true, true);
        this._screenShareStarted = Date.now();
      } else if (this.myStreams.screen) {
        await this._updateStream("screen", false, false);
        if (this._screenShareStarted) {
          OTGlobals.analytics?.logEvent("call_screenshare", {
            duration_secs: (Date.now() - this._screenShareStarted) / 100,
            quality: OTGlobals.localUserSettings.screenshareQuality,
          });
          this._screenShareStarted = undefined;
        }
      }
    } catch (e) {
      const err = <Error>e
      if (err.message) {
        OTUserInterface.toastHandlers.show("Failed to Share Screen: " + err.message, "error");
      }
    }
  };

  setPushToUnmute = (unmute: boolean) => {
    if (this.myStreams["camera"]?.hasAudio) {
      if (this.myStreams["camera"].muted && unmute) {
        this.pushToUnmute = true;
        this.myStreams["camera"].unmute();
      } else if (this.pushToUnmute && !this.myStreams["camera"].muted && !unmute) {
        this.myStreams["camera"].mute();
        this.pushToUnmute = false;
      }
    }
  };

  leaveCall = () => {
    this.emit("leavecall");
    OTGlobals.analytics?.logEvent("room__leave_room");
  };

  shutdown = async () => {
    await this.stateLock.acquireAsync();

    this.isStopping = true;

    const endTime = Date.now();
    const duration = (endTime - this._startTime) / 1000;

    try {
      if (this.pluginManager) {
        this.pluginManager.removeAllListeners();
        this.pluginManager.stop();
      }

      logger.debug(`Shutting down callstate for ${this.roomId}`);

      logger.debug(`Cancelling my ${Object.keys(this.myStreams)} streams`);
      this._streamManager.removeAllListeners();
      this._streamManager.shutdownStreams();
      await this._streamManager.stop();

      Object.keys(this.myStreams).forEach((kind) => {
        this.myStreams[kind].shutdown();
        delete this.myStreams[kind];
      });
      await this.stopPresence();

      OTUserInterface.platformUtils.allowDisplaySleep();

      if (!this.isFocusRoom && Math.random() < (OTAppCoreData.remoteConfig?.CallFeedbackFactor || 0)) {
        this.showCallFeedback(this.teamId, this.myUserId, this.roomId, this._startTime, endTime);
      }
    } finally {
      this.stateLock.release();
      writeActiveCall();
    }

    return duration;
  };

  getUserStreams = (userId) => {
    return this._streamManager.getUserStreams(userId);
  };

  updateUsers = async (users: { [userId: string]: IOTRoomUser }, firstRun: boolean = false) => {
    await this.stateLock.acquireAsync();

    try {
      logger.debug("Updating users");

      this._streamManager.updateUsers(users);

      logger.debug("Room ", this.roomId, " contains ", Object.keys(users));

      const roomUsers = {};

      Object.keys(users)
        .filter((userId) => userId != this.myUserId)
        .forEach((userId) => {
          roomUsers[userId] = this.roomUsers[userId] ?? firstRun;
        });

      Object.keys(this.roomUsers).forEach((userId) => !(userId in roomUsers) && this._onUserLeft(userId))

      this.roomUsers = roomUsers;

      this._updateConnected();
    } finally {
      this.stateLock.release();
    }
  };

  @action
  togglePopoutMe = () => {
    var newPopout = !this.callState.popoutUser;
    if (
      newPopout &&
      this.callState.focusUserId == this.myUserId &&
      this.callState.focusStreamType == "camera"
    ) {
      this.setFocusStream(undefined, undefined);
    }
    this.callState.popoutUser = newPopout;
  };

  setFocusRoom = (focusRoom: boolean) => {
    this.callState.focusRoom = focusRoom;
    if (focusRoom) {
      OTGlobals.analytics?.logEvent("roomchat__set_fullscreen");
    }
  };

  @action
  setFocusStream = (userId: string | undefined, streamType: TStreamType | undefined) => {
    if (userId && streamType) {
      this.setTileOrder({
        tileType: "stream",
        userId: userId,
        streamType: streamType,
        streamId: "",
      });

      if (userId == this.myUserId && streamType == "camera") {
        this.callState.popoutUser = false;
      }

      if (!this.callState.focusRoom) {
        this.setFocusRoom(true);
      }
    }

    this.callState.focusUserId = userId;
    this.callState.focusStreamType = streamType;
    logger.debug("setFocusStream", userId, streamType);
  };

  _onMediaPlaying = (pluginId, playing) => {
    if (pluginId == this.callState.focusedPluginId) {
      if (playing && this.audioOn) {
        logger.debug("Media playing, disabling audio");
        this.toggleAudio();
      } else if (!playing && !this.audioOn) {
        logger.debug("Media stopped, enabling audio");
        OTUserInterface.toastHandlers.show(
          "You have been unmuted as the media has stopped",
          "info"
        );
        this.toggleAudio();
      }
    } else {
      logger.info("Plugin not focussed, ignoring media playing event");
    }
  };

  @action
  setTileOrder = (tile: IOTTile | IOTPluginTile, remove: boolean = false) => {
    var index = -1;
    if (tile.tileType == "plugin") {
      index = this.callState.orderedTiles.findIndex(
        (t) => t.tileType == "plugin" && t.pluginId == tile.pluginId
      );
    }

    if (tile.tileType == "stream") {
      index = this.callState.orderedTiles.findIndex(
        (t) => t.tileType == "stream" && t.userId == tile.userId && t.streamType == tile.streamType
      );
    }

    if (index != -1) {
      this.callState.orderedTiles.splice(index, 1);
    }

    if (!remove) {
      this.callState.orderedTiles.unshift(tile);
    }
  };

  setFocusedUsers = (userIds: string[]) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      //logger.debug("requesting high quality feed for", userIds);
      this._streamManager.setFocusedUsers(userIds);
    }
  };

  setSenderResolution = (layer?: number) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.updateSenderResolution(layer);
    }
  };

  setSameRoom = async (userId: string | null) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      const audioGroupId = (await this._streamManager.setSameRoom(userId)).audioGroupId;
      this.callState.audioGroupId = audioGroupId;
    }
  };

  requestStats = (userId: string, streamType: string) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.requestStats(userId, streamType);
    }
  };

  cancelStats = (userId: string, streamType: string) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.cancelStats(userId, streamType);
    }
  };

  _setupStreamManager = () => {
    const room = this.getRoom();

    if (this.isStopping) {
      logger.info("Reached setupStreamManager but CallStateManager is stopping, doing nothing");
      return;
    } else if (room.users[this.myUserId]?.currentSessionToken != this.sessionToken) {
      logger.info(
        "Reached setupStreamManager but currentSessionToken doesn't match, doing nothing"
      );
      return;
    }

    this._streamManager = new MediaSoupStreamManager(
      this.myUserId,
      this.sessionToken,
      this.teamId,
      this.roomId
    );
      //this._streamManager.on("streamsupdated", this._calcAllStreams)

    this._streamManager.on("connected", this._onConnected);
    this._streamManager.on("peerconnected", this._onPeerConnected);
    this._streamManager.on("peerdisconnected", this._onPeerDisconnected);
    this._streamManager.on("disconnected", this._onDisconnected);
    this._streamManager.on("message", this._onMessage);
    this._streamManager.on("screenshare", this._onScreenShare);
    this._streamManager.on("speaker", this._onSpeaker);
    this._streamManager.on("producerscore", this._onProducerScore);
    this._streamManager.on("producerstats", this._onProducerStats);
    this._streamManager.on("bandwidth", this._onBandwidth);
    this._streamManager.on("network", this._onNetworkStatus);
    this._streamManager.on("audiogroup", this._onAudioGroup);
    this._streamManager.on("peerupdated", this._onPeerInfoUpdated)

    this._streamManager.start();
  };

  _onConnected = () => {
    if (!this.connected) {
      this.connected = true;
      Object.values(this.myStreams).forEach((stream) => this._streamManager.addStream(stream));
      this._updateConnected();
    } else {
      logger.error(`Received connected event, but already connected`);
    }
  };

  _onPeerConnected = (userId, peerInfo, reconnect: boolean = false) => {
    if (Object.keys(this.roomUsers).includes(userId)) {
      logger.debug("Peer connected", userId);

      const existingUser = this.roomUsers[userId]

      this.roomUsers[userId] = true;
      this.callState.peerInfo[userId] = peerInfo;

      this._updateConnected();

      if (!reconnect) {
        if (!existingUser) {
          this._onUserJoined(userId)
        } else {
          if (!this.isFocusRoom) {
            OTUserInterface.soundEffects.videoBell();
          }
        }
      }

      if (this.callState.faceDetect[this.myUserId]) {
        this.sendMessage(userId, {
          msgType: "FACEDETECT",
          userId: this.myUserId,
          faceDetect: this.callState.faceDetect[this.myUserId],
        });
      }
    } else {
      // This may happen in PTT scenarios
      logger.debug(`Peer ${userId} connected, but not in room`);
    }
  };


  _onUserJoined = (userId: string) => {
    logger.debug("user joined", userId);
    const room = this.getRoom()
    if (this.isFocusRoom) {
      if ("speechSynthesis" in window) {
        const user = room.users[userId]

        const summary = `${user.name.split(' ')[0]} joined`

        const msg = new SpeechSynthesisUtterance();
        msg.volume = 0.5;
        msg.text = summary;
        window.speechSynthesis.speak(msg);
      }
    } else {
      OTUserInterface.soundEffects.videoBell();
    }
  }

  _onUserLeft = (userId: string) => {
    logger.debug("user left", userId);

    const room = this.getRoom()
    if (this.isFocusRoom) {
      if ("speechSynthesis" in window) {
        const user = room.users[userId]

        const summary = `${user.name.split(' ')[0]} left`

        const msg = new SpeechSynthesisUtterance();
        msg.text = summary;
        window.speechSynthesis.speak(msg);
      }
    }
  }
  _onPeerDisconnected = (userId) => {
    logger.info(`Peer ${userId} disconnected`);

    setTimeout(() => {
      if (this.room) {
        const user = this.room.users[userId];
        if (user) {
          delete this.callState.peerInfo[userId];
          OTUserInterface.toastHandlers.show(`${user.name} has been disconnected`, "error");
        }
        writeCallParticipant(this, userId, user);
      }
    }, 1500)

    const user = this.room.users[userId];
    writeCallParticipant(this, userId, user);
  };

  _updateConnected() {
    let connList = this._streamManager.connectedUsers;

    if (this.connected) {
      connList = [this.myUserId].concat(connList);
    }

    // Cannot set property 'connectedUsers' of undefined
    logger.debug("Setting connectedUsers", connList, this.callState);
    this.callState!.connectedUsers = connList;
    this._updateParticipants()
  }

  _updateParticipants() {
    updateCallParticipants(this, [this.myUserId].concat(Object.keys(this.roomUsers)));
  }

  _updateParticipant(userId) {
    if (this.room) {
      writeCallParticipant(this, userId, this.room.users[userId]);
    }
  }

  _onDisconnected = action(async () => {
    const wantAudio = this.audioOn;
    const wantVideo = this.videoOn;

    await this.stateLock.acquireAsync();

    try {
      logger.warn("Streamanager disconnected, cleaning up streams");
      this.connected = false;

      Object.keys(this.myStreams).forEach((kind) => {
        this.myStreams[kind].shutdown();
        delete this.myStreams[kind];
      });
      this._streamManager.removeAllListeners();
      await this._streamManager.stop();

      logger.warn("Creating StreamManager");
      this._setupStreamManager();
    } finally {
      this.stateLock.release();
    }

    await this._updateStream("camera", wantAudio, wantVideo);
    await this._updateStream("screen", this.screenShareOn, this.screenShareOn);
  });

  _onMessage = (userId, msg: IPeerMsg) => {
    if (msg.msgType == "MESSAGE") {
      logger.debug("received chat message", msg);
      // this.addRoomMsg(msg);
    } else if (msg.msgType == "MUTE") {
      logger.debug("received mute message", msg, "this.audioOn", this.audioOn);

      if (msg.mute != !this.audioOn) {
        const room = this.getRoom();

        const sendingUser = room.users[userId];
        if (msg.mute) {
          OTUserInterface.toastHandlers.show(`You have been muted by ${sendingUser.name}`, "error");
        } else {
          OTUserInterface.toastHandlers.show(
            `You have been unmuted by ${sendingUser.name}`,
            "success"
          );
          OTUserInterface.soundEffects.unmute();
        }

        this.toggleAudio();
      }
    } else if (msg.msgType == "CALLKICKED") {
      OTUserInterface.toastHandlers.show(`You have been removed from the meeting`, "error");
      this.emit("callkicked");
    } else if (msg.msgType == "FACEDETECT") {
      this.hdlFaceDetect(msg.userId, msg.faceDetect);
    } else if (msg.msgType == "PAUSED") {
      const { userId, paused } = msg
      logger.debug(`${userId} is paused ${paused}`)
      if (paused) {
        this.callState.pausedUsers[userId] = true;
        const user = this.room.users[userId];
        OTUserInterface.toastHandlers.show(`${user.name} has paused for a second`, "info")
      } else {
        delete this.callState.pausedUsers[userId];
      }
      this._updateParticipant(userId)
    } else {
      logger.warn("Unexpected meassge type:", msg.msgType);
    }
  };

  _onSpeaker = (stream) => {
    if (stream && Date.now() - this._loudestLastChanged > 1000) {
      this._loudestLastChanged = Date.now();
      this.callState.loudestStreamId = stream ? stream.stream.id : null;
    }
  };

  _onScreenShare = (userId, streamId) => {
    this.callState.screenshareStreamId = streamId;

    this.setFocusStream(userId, "screen");
    console.log("_onScreenShare");
    this.emit("screenshare", userId, streamId);
  };

  _onProducerScore = (streamType, kind, score: number) => {
    if (this.myStreams[streamType]) {
      this.myStreams[streamType].setScore(kind, score);
    }
  };

  _onProducerStats = (streamType, kind, stats: any) => {
    if (this.myStreams[streamType]) {
      this.myStreams[streamType].setStats(kind, stats);
    }
  };

  _onBandwidth = ({ status, availableBitrate, effectiveDesiredBitrate }) => {
    this.callState.bandwidthLow = status !== "ok";
  };

  _onNetworkStatus = ({ status }) => {
    if (status !== "connecting") {
      // ignore the connecting status to avoid flashing when joining call
      this.callState.networkDisconnected = status !== "connected";
    }
  };

  _onAudioGroup = (audioGroupId) => {
    this.callState.audioGroupId = audioGroupId
    this._updateParticipants()
  }

  _onPeerInfoUpdated = (userId, peerInfo) => {
    this.callState.peerInfo[userId] = peerInfo
    this._updateParticipant(userId)
  }

  _updateStream = action(
    async (streamType: "camera" | "screen", wantAudio: boolean, wantVideo: boolean) => {
      await this.stateLock.acquireAsync();

      logger.debug(`Update ${streamType} stream, audio: ${wantAudio}, video ${wantVideo} paused ${this._isPaused}`);

      try {
        let stream: LocalStreamDetails = this.myStreams[streamType] || null;

        if (!stream && (wantAudio || wantVideo) && !this._isPaused) {
          logger.debug(`Creating ${streamType} stream`);
          if (streamType == "camera") {
            stream = new WebcamStream(this.teamId, this.myUserId, OTGlobals.mediaDevices);
          } else {
            stream = new ScreenShareStream(this.teamId, this.myUserId);
          }
        }

        if (stream) {
          logger.debug(
            `Audio, want: ${wantAudio}, have: ${stream.hasAudio}, muted: ${stream.muted}`
          );

          if (this._isPaused) {
            if (stream.hasAudio && !stream.muted) {
              await stream.mute();
            }
          } else if (wantAudio && !stream.hasAudio) {
            await stream.enableAudio();
          } else if (wantAudio && stream.hasAudio && stream.muted) {
            await stream.unmute();
          } else if (!stream.muted && !wantAudio) {
            await stream.mute();
          }

          logger.debug(`Video, want: ${wantVideo}, have: ${stream.hasVideo}`);

          if (this._isPaused) {
            if (stream.hasVideo) {
              await stream.disableVideo();
            }
          } else if (wantVideo && !stream.hasVideo) {
            await stream.enableVideo();
          } else if (stream.hasVideo && !wantVideo) {
            await stream.disableVideo();
          }

          this.addStream(stream)

          setStream(this.teamId, this.roomId, this.myUserId, streamType, stream.stream!.id);
        }

        return stream;
      } finally {
        this.stateLock.release();
      }
    }
  );

  shareStream = (stream?: MediaStream) => {
    if (stream) {
      const screenShareStream = new SimpleScreenShareStream(this.teamId, this.myUserId, 'screen', stream);
      // screenShareStream._addTrack(stream.getVideoTracks()[0]);
      this.addStream(screenShareStream);
    } else {
      this._updateStream("screen", false, false)
    }
  }

  addStream = (stream: LocalStreamDetails) => {
    if (!this.myStreams[stream.streamType]) {
      this.myStreams[stream.streamType] = stream;

      this._streamManager.addStream(stream);

      stream.on("trackremoved", this._trackRemoved);
    }
  };

  _trackRemoved = async (track: MediaStreamTrack, stream: LocalStreamDetails) => {
    await this.stateLock.acquireAsync();
    try {
      const streamType = stream.streamType;
      if (!(stream.hasAudio || stream.hasVideo)) {
        this._streamManager.cancelStream(stream);
        if (stream.stream?.id == this.myStreams[stream.streamType]?.stream?.id) {
          delete this.myStreams[stream.streamType];
          setStream(this.teamId, this.roomId, this.myUserId, streamType);
        }
      }
    } finally {
      this.stateLock.release();
    }
  };

  setupReactions = () => {
    this._reaction["_audioDevicesChangedReaction"] = reaction(
      () => {
        return [OTGlobals.mediaDevices.audio];
      },
      async () => {
        const { audio } = OTGlobals.mediaDevices;

        logger.info("audio settings changed", toJS(audio));
        if (this.myStreams["camera"]) {
          await this.myStreams["camera"].updateSettings({ audio });
        } else {
          logger.debug("Camera stream not found, recreating");
          this._updateStream("camera", this._wantAudio, this._wantVideo);
        }
      }
    );

    this._reaction["_videoDevicesChangedReaction"] = reaction(
      () => {
        return [OTGlobals.mediaDevices.video];
      },
      async () => {
        const { video } = OTGlobals.mediaDevices;

        logger.info("video settings changed", toJS(video));
        if (this.myStreams["camera"]) {
          await this.myStreams["camera"].updateSettings({ video });
        } else {
          logger.debug("Camera stream not found, recreating");
          this._updateStream("camera", this._wantAudio, this._wantVideo);
        }
      }
    );
    this._reaction["_simulcastChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.videoSimulcastEnabled;
      },
      () => {
        const stream = this.myStreams["camera"];
        logger.debug("simulcast change for", stream);
        if (stream) {
          stream.bumpTrack("video");
        }
      }
    );

    this._reaction["_cameraQualityChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.cameraQuality;
      },
      () => {
        const stream = this.myStreams["camera"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    );

    this._reaction["_screenShareQualityChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.screenshareQuality;
      },
      () => {
        const stream = this.myStreams["screen"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    );
  };

  @computed get inCall() {
    return this._inCall;
  }

  @computed get inVideoChat() {
    return !!(this.inCall && this.callState.focusRoom && this.callState.tiles.length > 0);
  }

  get connectedUsers(): string[] {
    return this._streamManager.connectedUsers;
  }

  getUser(userId: string): IOTRoomUser | undefined {
    if (this.room) {
      //logger.debug(`getUser(${userId}) ${this.room.users[userId] === undefined ? 'not found' : 'found'}`);
      return this.room.users[userId];
    } else {
      logger.debug(`getUser(${userId}) no room`)
    }
  }

  getUserCamStream(userId: string): RemoteStreamDetails | undefined {
    return this._streamManager
      .getUserStreams(userId)
      .find((stream) => stream.streamType === "camera");
  }

  setCallPaused = action((paused: boolean) => {
    logger.info(`${paused ? 'Pausing' : 'Resuming'} call`)
    this._isPaused = paused;
    this.callState.isCallPaused = paused;
    this._updateStream("camera", this._wantAudio, this._wantVideo);
    this._streamManager.sendMessageToAll(
      {
        msgType: "PAUSED",
        userId: this.myUserId,
        paused
      }
    );
  })

  @computed get isFocusRoom() {
    const room = this.getRoom();
    return room?.config?.focusRoom
  }
}
