import { RRule, rrulestr } from "rrule";
import DataIntervalTree from "node-interval-tree";
import { ICalEvent, CalEvent } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { DateTime } from "luxon";

const logger = new Logger("calendarUtils");

export const nowSecs = () => Math.floor(Date.now() / 1000);
export const parseDate = (dt: string) => toSecs(new Date(dt));
export const fmtDate = (dt: number) => toDate(dt).toISOString();

export const toDate = (dt: number) => new Date(dt * 1000);
export const toSecs = (dt: Date) => Math.floor(dt.getTime() / 1000);

export const getStartOfDay = (dt: Date) => {
  const d = new Date(dt);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return toSecs(d);
};


export const getStartOfWeek = () => {
  const d = new Date(Date.now() - new Date().getDay() * 24 * 60 * 60 * 1000);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return toSecs(d);
};

export const getEndOfWeek = () => getStartOfWeek() + (7 * 24 * 60 * 60);


export const expandEvents = (
  eventsIn: ICalEvent[],
  timeMin: number,
  timeMax: number
): { event: ICalEvent; start: number; end: number }[] => {
  logger.debug(
    "expanding %d Events with timeMin=%s timeMax=%s",
    eventsIn.length,
    fmtDate(timeMin),
    fmtDate(timeMax),
  );

  const exceptions: Record<string, boolean> = {};

  eventsIn.forEach(
    (ev) => {
      if (ev.recurringEventId) {
        const evOrigStart = CalEvent.getTime(ev, 'originalStartTime');
        exceptions[`${ev.recurringEventId}_${evOrigStart}`] = true;
      }
    }
  );
  const events: { event: ICalEvent; start: number; end: number }[] = [];

  eventsIn.forEach(
    (ev) => {
      if (ev.status !== "cancelled") {

        const evStart = CalEvent.getTime(ev, 'start');
        const evEnd = CalEvent.getTime(ev, 'end');
        const evInterval = evEnd - evStart;

        if (evInterval < 0)
          throw new Error("event interval is negative");

        if (evStart > evEnd)
          throw new Error("event end is before start");

        if (ev.recurrence) {
          try {
            /*
              this won't work:
                const rule = rrulestr(ev.recurrence.join('\n'), { dtstart: toDate(evStart) });
              it doesn't set the evStart correctly from the options
            */
            const dtStart = DateTime.fromJSDate(toDate(evStart)).setZone("UTC", { keepLocalTime: true }).toJSDate()

            const startRule = (new RRule({ dtstart: dtStart })).toString();

            const rulestring = [startRule, ...ev.recurrence].join('\n');

            const rule = rrulestr(rulestring, { forceset: true })

            const startTimes = rule.between(toDate(timeMin - evInterval), toDate(timeMax))
              .map(x => DateTime.fromJSDate(x).setZone("UTC").setZone("local", { keepLocalTime: true }).toJSDate());

            logger.debug(`rulestring: ${ev.id} ${ev.summary} ${rulestring} ${ev.start?.dateTime}: ${startTimes}`);

            startTimes.map((startDt, idx) => {
              const start = toSecs(startDt);
              const end = start + evInterval;
              const evId = `${ev.id!}_${start}`;

              if (!(evId in exceptions)) {
                /*                 logger.debug(
                                  `including ${ev.summary} ${ev.id}`,
                                  start, timeMax,
                                  end, timeMin,
                                );
                  */
                events.push({ event: ev, start, end });
              }
            });
          } catch (e) {
            logger.error("error expanding event", ev.id, ev.summary, ev, e);
          }
        } else {
          if (evEnd > timeMin && evStart <= timeMax) {
            /*             logger.debug(
                          `including ${ev.summary} ${ev.id}`,
                          evStart, timeMax,
                          evEnd, timeMin,
                        );
             */
            events.push({ event: ev, start: evStart, end: evEnd });
          }
          /*            else {
                      logger.debug(
                        `ignoring ${ev.summary} ${ev.id}`,
                        evStart, timeMax,
                        evEnd, timeMin,
                      );
                    }
           */
        }
      }
    }
  );

  return events;
};

export const getIntervalTree = (
  eventsIn: ICalEvent[],
  timeMin: number,
  timeMax: number
): [Record<string, ICalEvent>, DataIntervalTree<{ evId: string, start: number }>] => {
  const intervalTree = new DataIntervalTree<{ evId: string, start: number }>();
  const events = expandEvents(eventsIn, timeMin, timeMax);
  const byId: Record<string, ICalEvent> = {};

  logger.debug("getIntervalTree numEvents: ", events.length);
  events.forEach(({ event, start, end }) => {
    const evId = `${event.meta.calendarId}-${event.id!}`; //`${event.id!}_${start}`;
    intervalTree.insert(start, end, { evId, start });
    byId[evId] = event;
  });

  return [byId, intervalTree];
};
