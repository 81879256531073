import { Database, ref, get, set} from "firebase/database"

import { Logger } from "@openteam/app-util";

const logger = new Logger("UserSettingsDb");

export class UserSettingsDb {
  static getUserSettings = async  (fbDb: Database, userId: string) => {
    const snapshot = await get(ref(fbDb, `/users/${userId}/settings`));
    return snapshot.val()
  };

  static setUserSettings = async (fbDb: Database, userId: string, settings: string) => {
    await set(ref(fbDb, `/users/${userId}/settings`), settings);
  };
}
