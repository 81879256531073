

export function getInitials (name?: string) {
  if (name) {
    if (name.length < 3) {
      return name
    } else {
      return name.split(' ').map(word => word[0]).join('').slice(0,2);
    }
  }
  return '';
}