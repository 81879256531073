import React, { CSSProperties, useCallback, useRef } from "react";
import { Logger } from "@openteam/app-util";

const logger = new Logger("Text");

export interface ITextProps extends React.HTMLAttributes<HTMLSpanElement> {
  strong?: boolean
  wrap?: boolean
  selectable?: boolean
  maxLines?: number
  text?: string
}

export function Text(props: ITextProps) {
  const { style, color, strong, wrap, selectable, maxLines=0, text, ...otherProps} = props
  const ref = useRef(null)

  const selectStyle = selectable ? {
      cursor: "text",
      WebkitUserSelect: "text"
    } as CSSProperties : null

  const stopPropagation: React.PointerEventHandler<HTMLSpanElement> = useCallback((e) => {
    if (selectable && e.currentTarget === ref.current) {
      e.stopPropagation()
    }
  }, [selectable])

  return (
    <span
      ref={ref}
      onPointerDown={stopPropagation}
      {...otherProps}
      style={{
        // fontFamily: 'Inter, sans-serif',
        // fontSize: '14px',
        color,
        cursor: "inherit",
        fontWeight: strong ? 700 : undefined,
        whiteSpace: (wrap || maxLines >= 1) ? undefined : "nowrap",
        overflowWrap: (wrap || maxLines > 1) ? "anywhere" : undefined,
        // @ts-ignore
        ...(maxLines >= 1 && {
          display: "-webkit-box",
          WebkitLineClamp: `${maxLines}`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        } as CSSProperties),
        ...selectStyle,
        ...style
      }}
    >
      {text}
      {otherProps.children}
    </span>
  );
}

export function HeaderText(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      {...otherProps}
      style={{
        fontFamily: 'Manrope',
        ...style
      }}
    />
  )
}



export function BadgeText(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      {...otherProps}
      style={{
        fontSize: '10px',
        ...style
      }}
    />
  )
}


export function SmallPrint(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      {...otherProps}
      style={{
        fontSize: '11px',
        ...style
      }}
    />
  )
}

export function Print(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      {...otherProps}
      style={{
        fontSize: '12px',
        ...style
      }}
    />
  )
}

export function StrongPrint(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      {...otherProps}
      style={{
        fontSize: '12px',
        fontWeight: 500,
        ...style
      }}
    />
  )
}
export function LargePrint(props: ITextProps) {
  const { style, ...otherProps } = props

  return (
    <Text
      wrap
      {...otherProps}
      style={{
        fontSize: '13px',
        ...style
      }}
    />
  )
}

export function Body(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <Text
      wrap
      {...otherProps}
      style={{
        fontSize: '14px',
        lineHeight: '18px',
        ...style
      }}
    />
  )
}

export function H5(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <HeaderText
      {...otherProps}
      style={{
        fontSize: '11px',
        fontWeight: 700,
        ...style
      }}
    />
  )
}

export function H4(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <HeaderText
      {...otherProps}
      style={{
        fontSize: '12px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}

export function H3(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <HeaderText
      {...otherProps}
      style={{
        fontSize: '14px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}

export function H2(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <HeaderText
      {...otherProps}
      style={{
        fontSize: '16px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}


export function H1(props: ITextProps) {
  const { style, ...otherProps} = props

  return (
    <HeaderText
      {...otherProps}
      style={{
        fontSize: '18px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}


export function PanelHead(props: ITextProps) {
  const { style, wrap=false, ...otherProps} = props
  return (
    <H3
      wrap={wrap}
      style={{
        //maxWidth: "100%",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...style
      }}
      {...otherProps}
    />
  )
}



interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  strong?: boolean
  htmlFor: string
}

export function Label(props: LabelProps) {
  const { style, color, strong, htmlFor, ...labelProps} = props
  return (
    <label
      htmlFor={htmlFor}
      style={{
        // fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        fontWeight: strong ? 700 : undefined,
        WebkitUserSelect: "none",
        ...style
      }}
      {...labelProps}
    />

  )
}
