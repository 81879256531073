import React, { memo } from "react";
import { styled } from ".";
import DS2Icons from "./DS2Icons";


interface Props extends React.HTMLAttributes<HTMLInputElement> {
    value?: string | null
    onValueChange?: (value: string) => void
}


const DS2SearchInput = React.forwardRef<HTMLInputElement, Props>(({value, onValueChange, ...props }, ref) => {

    const onChange = React.useCallback((e) => {
        onValueChange?.(e.target.value)
    }, [onValueChange])

    return (
        <InputWrapper>
            {DS2Icons.search}
            <StyledInput
                ref={ref}
                value={value ?? ''}
                onChange={onChange}
                placeholder={'Search...'}
                {...props}
            />
        </InputWrapper>
    )
})

export default memo(DS2SearchInput)


const InputWrapper = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    alignItems: 'center',
    paddingBottom: 2,
    color: theme.palette.text.primary,

}))


const StyledInput = styled('input')(({theme}) => ({
    flex: 1,
    outline: 'none',
    border: 'none',
    background: 'transparent',
    marginLeft: 4,
    color: theme.palette.text.primary,

    '::placeholder': {
        //opacity: 0.5,
        color: theme.palette.text.disabled,
    }
}))
