import { Logger } from "@openteam/app-util";
import * as React from "react";
import DS2IconActionButton from "./DS2IconActionButton";
import DS2BaseButton, { DS2BaseButtonProps } from "./DS2BaseButton";
import DS2IconButton from "./DS2IconButton";

const logger = new Logger('DS2Button');

export interface DS2ButtonProps extends DS2BaseButtonProps {
  title?: string
  buttonStyle?: 'default' | 'action' | 'icon'
}

const DS2Button: React.FC<DS2ButtonProps> = React.memo((
  { buttonStyle = 'default', startIcon, title, children, ...props }) => {


  if (buttonStyle === 'action') {
    return (
      <DS2IconActionButton data-tooltip={title} {...props}>
        {startIcon}{children}
      </DS2IconActionButton>
    )
  } else if (buttonStyle === 'icon') {
    return (
      <DS2IconButton data-tooltip={title} {...props}>
        {startIcon}{children}
      </DS2IconButton>
    )

  } else {
    return (
      <DS2BaseButton startIcon={startIcon} {...props}>
        {title ?? children}
      </DS2BaseButton>
    )
  }
})

export default DS2Button;
