import { action, autorun, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { ChatDb, CallDetailsDb } from "../fire";
import { ICallDetails, IChannel, IChannelUser, IMessage, IOTChatMessage, IPinnedResource } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { writeChannel, writeChannelUsersTyping } from "../UIDataState";
import { Firestore } from "firebase/firestore";

const logger = new Logger("ChatMessageManager");

export class ChatMessageManager {
  /* copied down from ChatManager */
  teamId: string;
  userId: string;
  channelId: string;
  topicId: string;
  getUserChannel: (channelId: string) => IChannelUser;
  fsDb: Firestore;
  fakeId: number = 0;
  unwatchChannel?: () => void;
  unwatchChannelUsers?: () => void;
  unwatchChannelMessages?: () => void;
  started: boolean = false;

  @observable channel?: IChannel;
  @observable channelUsers: Record<string, IChannelUser> = {};
  @observable messages: { [id: string]: IOTChatMessage } = {};
  @observable lastReadMessageId?: number;
  @observable lastReceivedMessageId?: number;
  @observable gettingMore?: boolean = false;
  @observable atStart: boolean = false;
  @observable atEnd: boolean = false;
  @observable callSummaries: { [id: string]: ICallDetails } = {};

  @observable highlightMessageId?: number = undefined;

  @observable chatUserIsTyping: Record<
    string,
    { lastTyping: Date; timeoutId: ReturnType<typeof setTimeout> }
  > = {};
  @observable pinnedResources: IPinnedResource[] = [];


  constructor(
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    getUserChannel: (channelId: string) => IChannelUser,
    loadAtMessageId?: number,
  ) {
    makeObservable(this)
    this.fsDb = fsDb;
    this.teamId = teamId;
    this.userId = userId;
    this.channelId = channelId;
    this.topicId = topicId;
    this.getUserChannel = getUserChannel;
    logger.info(`initialising for teamId ${teamId} channelId ${channelId} topicId ${topicId} loadSince ${loadAtMessageId}`);

    this.initialize(loadAtMessageId);

  }

  reset = () => {
    if (this.started) {
      this.stop();
    }

    this.fakeId = 0;
    this.channelUsers = {};
    this.messages = {};
    this.lastReadMessageId = undefined;
    this.lastReceivedMessageId = undefined;
    this.gettingMore = false;
    this.atStart = false;
    this.atEnd = false;
    this.chatUserIsTyping = {};
    this.highlightMessageId = undefined;
  }

  initialize = (loadAtMessageId?: number) => {
    if (loadAtMessageId) {
      this.loadAt(loadAtMessageId);
    } else {
      this.start();
    }
  }

  start = () => {
    logger.debug("starting message manager", this.channelId);
    this.started = true;

    this.unwatchChannel = ChatDb.watchChannel(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.handleChannel
    );
    this.unwatchChannelUsers = ChatDb.watchChannelUsers(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.handleChannelUsers
    );

    //const teamManager = OTUserData.teamManagers[this.teamId]
    const userChannel = this.getUserChannel(this.channelId);

    this.lastReadMessageId = userChannel?.topics?.[this.topicId]?.messageId || 0;

    logger.debug("watchChannelMessages", this.channelId, this.lastReadMessageId);

    this.unwatchChannelMessages = ChatDb.watchChannelMessages(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.topicId,
      Math.max(this.lastReadMessageId - 50, 0),
      this.handleWatchMessages
    );

    writeChannel(this.teamId, this.channelId)
  };

  stop = () => {
    logger.debug("stopping message manager", this.channelId);

    this.unwatchChannel && this.unwatchChannel();
    this.unwatchChannelUsers && this.unwatchChannelUsers();
    this.unwatchChannelMessages && this.unwatchChannelMessages();

    this.started = false;
  };

  handleChannel = (channel: IChannel, isCached: boolean = false) => {
    logger.info("handleChannel", channel);
    this.channel = channel;
  };

  handleChannelUsers = (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => {
    for (let userId of deleted) {
      delete this.channelUsers[userId];
    }

    for (let channelUser of added) {
      this.channelUsers[channelUser.userId] = channelUser;
    }

    for (let channelUser of edited) {
      this.channelUsers[channelUser.userId] = channelUser;
    }

    this.calculateIsTyping(this.channelUsers);

    writeChannel(this.teamId, this.channelId)
  };

  loadCallSummary = async(callId: string) => {

    const callDetailsResult = await CallDetailsDb.getCallDetails(
      this.fsDb,
      this.teamId,
      callId
    )

    if (callDetailsResult) {
      runInAction(() => {
        this.callSummaries[callId] = callDetailsResult
      })
    }
  }

  processMessages = () => {
    for (const id in this.messages) {
      const message = this.messages[id]
      if (message.isSystem && message.systemType == 'CALLSUMMARY' && message.systemId) {
        const callId = message.systemId;
        if (!this.callSummaries[callId]) {
          this.loadCallSummary(callId)
        }
      }
    }
  }

  loadAt = async (messageId: number) => {
    const pageSize = 30;

    const teamData = OTGlobals.getTeamData(this.teamId);

    // XXX: Why do we do this here as well as at the end?
    //writeChannel(this.teamId, this.channelId)

    logger.debug(`loadAt: ${this.teamId} channelId: ${this.channelId} topicId: ${this.topicId} messageId:${messageId}`);

    const messageDocs: { [id: string]: IMessage } = await ChatDb.getMoreChatMessages(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.topicId,
      messageId,
      pageSize,
      "forwards"
    );

    const messages = {};
    Object.values(messageDocs)
      .map((doc) => {
        const teamUser = teamData.getTeamUser(doc.userId);

        const otmessage: IOTChatMessage = {
          ...doc,
          name: teamUser.name,
          userImageUrl: teamUser.imageUrl || null,
        };
        messages[doc.id] = otmessage;
        return otmessage;
      });


    this.messages = messages;
    this.loadMore("backwards", pageSize);
    this.highlightMessageId = messageId;

    this.processMessages();
    writeChannel(this.teamId, this.channelId)
  }

  youngestMessageId = () => this.messages[Object.keys(this.messages)[0]].messageId;
  oldestMessageId = () => this.messages[Object.keys(this.messages)[Object.keys(this.messages).length - 1]].messageId;

  loadSince = async (messageId: number) => {
    const teamData = OTGlobals.getTeamData?.(this.teamId);

    logger.info("reloading since", messageId);
    // this.addLog(`reloading all after reconnecting ${sinceMessageId}`);

    // if (Object.keys(this.messages).length > 0) {
    //   const oldestMessage = Object.keys(this.messages)[0];

    const messageDocs: {
      [id: string]: IMessage;
    } = await ChatDb.getChatMessagesSince(this.fsDb, this.teamId, this.channelId, this.topicId, messageId);

    Object.values(messageDocs).map((doc) => {
      // this.addLog(`reloading message ${doc.id}`);

      const teamUser = teamData.getTeamUser(doc.userId);

      const otmessage: IOTChatMessage = {
        ...doc,
        name: teamUser.name,
        userImageUrl: teamUser.imageUrl || null,
      };

      if (this.messages[doc.id]) {
        delete this.messages[doc.id];
      }

      this.messages[doc.id] = otmessage;

      return otmessage;
    });
    this.processMessages();
    // }
  };

  loadMore = async (direction: "backwards" | "forwards", pageSize: number = 50) => {
    //logger.debug(`loading more messages, direction: ${direction}`);
    if (this.gettingMore) return;
    if (direction === "forwards" && this.atEnd) return;
    if (direction === "backwards" && this.atStart) return;

    const teamData = OTGlobals.getTeamData(this.teamId);

    this.gettingMore = true;

    // XXX: Why do we do this here as well as at the end?
    writeChannel(this.teamId, this.channelId);

    if (Object.keys(this.messages).length > 0) {
      logger.debug(
        `loadMore direction: ${direction} youngestMessageId: ${this.youngestMessageId()} oldestMessageId: ${this.oldestMessageId()}`
      );

      let messageDocs: {
        [id: string]: IMessage;
      } = {};

      messageDocs = await ChatDb.getMoreChatMessages(
        this.fsDb,
        this.teamId,
        this.channelId,
        this.topicId,
        direction === "backwards" ? this.youngestMessageId() : this.oldestMessageId(),
        pageSize,
        direction
      );

      const messages = {};
      const otMessages = Object.values(messageDocs)
        .filter((doc) => !(doc.id in this.messages))
        .map((doc) => {
          const teamUser = teamData.getTeamUser(doc.userId);

          const otmessage: IOTChatMessage = {
            ...doc,
            name: teamUser.name,
            userImageUrl: teamUser.imageUrl || null,
          };
          messages[doc.id] = otmessage;
          return otmessage;
        });

      if (direction === "backwards") {
        this.messages = { ...messages, ...this.messages };
        this.atStart = otMessages.length <= 1;
      } else {
        this.messages = { ...this.messages, ...messages };
        this.atEnd = otMessages.length <= 1;

        if (this.atEnd && !this.started) {
          this.start();
        }
      }

      logger.debug(
        `direction: ${direction} atStart: ${this.atStart} atEnd: ${this.atEnd} youngestMessageId: ${this.youngestMessageId} oldestMessageId: ${this.oldestMessageId}`
      );
      //Object.keys(this.messages).map((id, index) =>
      //  logger.debug(`${index}: ${this.messages[id].messageId}`)
      //);
    }
    this.gettingMore = false;
    this.processMessages();
    writeChannel(this.teamId, this.channelId);
  };

  @action
  handleWatchMessages = (added: IMessage[], edited: IMessage[], deleted: string[]) => {
    for (let message of added) {
      this.handleDoc(message);
    }
    for (let message of edited) {
      this.handleDoc(message, true);
    }

    for (let messageId of deleted) {
      this.deleteDoc(messageId);
    }

    this.processMessages();
    writeChannel(this.teamId, this.channelId)
  };

  handleDoc = (doc: IMessage, hasChanged: boolean = false) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const teamUser = teamData.getTeamUser(doc.userId);

    if (hasChanged && !(doc.id in this.messages)) {
      logger.debug("got a change for a message I don't currently have, ignoring");
      return;
    }

    const otmessage: IOTChatMessage = {
      ...doc,
      name: teamUser.name,
      userImageUrl: teamUser.imageUrl || null,
    };

    this.messages[doc.id] = otmessage;

    if (!hasChanged) {
      if (
        doc.userId == this.userId &&
        !doc.isSystem &&
        (this.lastReadMessageId || 0) < doc.messageId
      ) {
        this.lastReadMessageId = doc.messageId;
      }
      if ((this.lastReceivedMessageId || 0) < doc.messageId) {
        this.lastReceivedMessageId = doc.messageId;
      }
    }
    // XXX: Is this needed? it's also called in the caller
    //writeChannel(this.teamId, this.channelId)
  };

  deleteDoc = (messageId) => {
    logger.debug("deleteDoc", messageId);

    delete this.messages[messageId];
  };

  calculateIsTyping = (channelUsers: Record<string, IChannelUser>) => {
    const users = channelUsers;

    for (let userId of Object.keys(users)) {
      if (userId == this.userId) {
        continue;
      }

      if (
        users[userId].topics?.[this.topicId]?.lastTyping &&
        Date.now() - users[userId].topics?.[this.topicId]?.lastTyping?.getTime()! < 5000
      ) {
        if (
          users[userId].topics?.[this.topicId].lastTyping?.getTime() !=
          this.chatUserIsTyping[userId]?.lastTyping.getTime()
        ) {
          const age = Date.now() - users[userId].topics?.[this.topicId].lastTyping?.getTime()!;

          if (this.chatUserIsTyping[userId]) {
            clearTimeout(this.chatUserIsTyping[userId].timeoutId);
          }

          const timeoutId = setTimeout(() => {
            delete this.chatUserIsTyping[userId];
          }, 5000 - age);

          this.chatUserIsTyping[userId] = {
            timeoutId,
            lastTyping: users[userId].topics?.[this.topicId].lastTyping!,
          };
        }
      } else if (this.chatUserIsTyping[userId]) {
        clearTimeout(this.chatUserIsTyping[userId].timeoutId);
        delete this.chatUserIsTyping[userId];
      }
    }

    writeChannelUsersTyping(this.teamId, this.channelId)
  };

}
