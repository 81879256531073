import React, { Component } from "react";
import { DSTheme } from "./DSTheme";


interface IDSSeparatorProps extends React.HTMLAttributes<HTMLDivElement> {

}

export function DSSeparator(props: IDSSeparatorProps) {
  const { style, ...otherProps } = props
  return (
    <div
      {...otherProps}
      style={{
        display: 'flex',
        backgroundColor: 'white',
        minHeight: "2px",
        borderRadius: 999,
        ...style
      }}
    />
  );
}

export function DSSeparatorVertical(props: IDSSeparatorProps) {
  const { style, ...otherProps } = props
  return (
    <div
      {...otherProps}
      style={{
        backgroundColor: DSTheme.EmphasisColor,
        width: "2px",
        height: "100%",
        borderRadius: 999,
        ...style
      }}
    />
  );
}
