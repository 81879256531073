import React, { useEffect } from "react";
import { Logger } from "@openteam/app-util"

const logger = new Logger("CallAudio")

type Props = {
  stream?: MediaStream;
  muted: boolean;
  volume?: number;
};

export const CallAudio: React.FC<Props> = ({ stream, muted, volume }) => {
  const ref = React.useRef<HTMLAudioElement>(null);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    
    if (ref.current) {
      const currentRef = ref.current;
      if (muted) {
        currentRef.srcObject = null;
      } else {
        if (currentRef.srcObject !== stream) {
          currentRef.srcObject = stream ?? null;
        }
      }

      return () => {
        currentRef.srcObject = null;
        timeoutRef.current && clearTimeout(timeoutRef.current);
      };
    }
  }, [stream, muted]);

  useEffect(() => {
    if (ref.current) {
      const _volume = volume ?? 1
      if (ref.current.volume !== _volume) {
        logger.debug(`Setting volume to ${_volume}`)
        ref.current.volume = _volume
      }
    }
  }, [volume]);
  return <audio ref={ref} playsInline autoPlay />;
};
