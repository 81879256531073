import { Button, Column, H1, Panel, Row, Spinner, StrongPrint } from "@openteam/design-system";
import React from "react";
import { DSTheme } from "../DesignSystem/DSTheme";

export const PanelLoading: React.FC<{ text: string }> = ({ text }) => {



  return (
    <Panel style={{ margin: 20, alignItems: 'center', opacity: 1, transition: "opacity 0.5s ease-in-out" }}>
      <Row style={{ marginTop: 16, marginBottom: 8, justifyContent: "center" }}>
        <H1
          wrap
          style={{
            textAlign: "center",
            fontSize: 24,
            marginBottom: 20,
          }}>{text}</H1>
      </Row>
      <Spinner size={40} />
    </Panel>
  );
}

export const PanelError: React.FC<{
  back?: () => void;
}> = ({ back, children }) => {

  return (
    <Column style={{ width: "60%", minWidth: 300, alignItems: 'center' }} spacing={24}>
        <H1>Something went wrong</H1>
      <Column style={{ alignItems: "center" }}>
        {children}
      </Column>
      {back && (
        <Row style={{ justifyContent: "flex-end", flexGrow: 1, width: "100%" }}>
          <Button onClick={() => back()} color='secondary'>
            Back
          </Button>
        </Row>
      )}
    </Column>
  );
}
