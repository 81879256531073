import React, { memo, useState, useEffect } from "react";
import emoji from "emoji-datasource";
import { DSRow } from "../../DesignSystem/DSLayout";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSText } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { FaTimes } from "react-icons/fa";
import { DSPanel, DSPanelScrollable, DSWrappedRow } from "../../DesignSystem/DSLayout";
import { DSInput } from "../../DesignSystem/DSInput";

interface ICategory {
  symbol: string | null;
  name: string;
}

export const Categories: { [id: string]: ICategory } = {
  all: {
    symbol: null,
    name: "All",
  },
  history: {
    symbol: "🕘",
    name: "Recently used",
  },
  emotion: {
    symbol: "😀",
    name: "Smileys & Emotion",
  },
  people: {
    symbol: "🧑",
    name: "People & Body",
  },
  nature: {
    symbol: "🦄",
    name: "Animals & Nature",
  },
  food: {
    symbol: "🍔",
    name: "Food & Drink",
  },
  activities: {
    symbol: "⚾️",
    name: "Activities",
  },
  places: {
    symbol: "✈️",
    name: "Travel & Places",
  },
  objects: {
    symbol: "💡",
    name: "Objects",
  },
  symbols: {
    symbol: "🔣",
    name: "Symbols",
  },
  flags: {
    symbol: "🏳️‍🌈",
    name: "Flags",
  },
};

interface IEmoji {
  name: string;
  unified: string;
  non_qualified: string;
  docomo: string;
  au: string;
  softbank: string;
  google: string;
  image: string;
  sheet_x: number;
  sheet_y: number;
  short_name: string;
  short_names: string[];
  text?: any;
  texts?: any;
  category: string;
  sort_order: number;
  added_in: string;
  has_img_apple: boolean;
  has_img_google: boolean;
  has_img_twitter: boolean;
  has_img_facebook: boolean;
}

const charFromUtf16 = (utf16: any) =>
  String.fromCodePoint(...utf16.split("-").map((u) => "0x" + u));
export const charFromEmojiObject = (obj) => charFromUtf16(obj.unified);
const filteredEmojis = emoji.filter((e) => !e["obsoleted_by"]);
const emojiByCategory = (category) => filteredEmojis.filter((e) => e.category === category);
const sortEmoji = (list) => list.sort((a, b) => a.sort_order - b.sort_order);

let emojiList = {};
Object.keys(Categories).forEach((c) => {
  let name = Categories[c].name;
  emojiList[c] = sortEmoji(emojiByCategory(name));
});

interface ITabBarProps {
  activeCategory: string;
  onClick: (category: string) => void;
  tileSize?: number
}

const TabBar: React.FC<ITabBarProps> = ({ activeCategory, onClick, tileSize = 42 }: ITabBarProps) => {
  return (
    <DSRow>
      {Object.keys(Categories)
        .filter((c) => c !== "all")
        .map((c) => {
          const category = Categories[c];
          return (
            <DSButton
              key={category.name}
              onClick={() => onClick(c)}
              style={{
                flex: 1,
                borderBottomStyle: "solid",
                borderColor: c === activeCategory ? "#aaa" : "transparent",
                borderBottomWidth: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DSText
                style={{
                  textAlign: "center",
                  margin: "0 5",
                  fontSize: tileSize * 0.65,
                }}
              >
                {category.symbol}
              </DSText>
            </DSButton>
          );
        })}
    </DSRow>
  );
};

interface IEmojiCellProps {
  emoji: IEmoji;
  onClick: () => void;
  tileSize?: number
}


const EmojiCell: React.FC<IEmojiCellProps> = memo(({ emoji, onClick, tileSize = 42 }) => (
  <DSButton
    style={{
      width: tileSize,
      height: tileSize,
      margin: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden"
    }}
    onClick={onClick}
  >
    <DSText style={{ color: "#FFFFFF", fontSize: tileSize * 0.75 }}>{charFromUtf16(emoji.unified)}</DSText>
  </DSButton>
));

interface IEmojiPickerProps {
  onEmojiSelected: (emoji: string) => void;
  scrollHeight?: number;
  tileSize?: number
}

export const EmojiPicker: React.FC<IEmojiPickerProps> = ({ onEmojiSelected, scrollHeight, tileSize }) => {
  const [history, setHistory] = useState<IEmoji[]>([]);
  const [category, setCategory] = useState("history");
  const [searchQuery, setSearchQuery] = useState("");
  const storage_key = "@openteam/emoji/history";

  useEffect(() => {
    async function fetchSaved() {
      let savedhistory = localStorage.getItem(storage_key);
      if (savedhistory) {
        let json = JSON.parse(savedhistory);
        setHistory(json);
      }
    }
    fetchSaved();
  }, []);

  const addHistory = (emoji: IEmoji) => {
    var index = history.findIndex((h) => h.name == emoji.name);
    if (index != -1) {
      history.splice(index, 1);
    }

    history.unshift(emoji);
    setHistory(history);

    localStorage.setItem(storage_key, JSON.stringify(history));
  };

  const Searchbar = (
    <DSRow
      style={{
        margin: 10,
        backgroundColor: "#eee",
        borderRadius: 10,
        alignItems: "center",
      }}
    >
      <DSInput
        placeholder={"Search Emoji"}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      {searchQuery ? (
        <DSButton onClick={() => setSearchQuery("")}>
          <FaTimes size={18} style={{ color: "#555" }} />
        </DSButton>
      ) : null}
    </DSRow>
  );

  const renderEmojiCell = (item: IEmoji) => (
    <EmojiCell
      key={item.unified}
      emoji={item}
      tileSize={tileSize}
      onClick={() => {
        onEmojiSelected(charFromUtf16(item.unified));
        addHistory(item);
      }}
    />
  );
  var emojiData: IEmoji[] = [];
  if (searchQuery) {
    const filtered = emoji.filter((e) => {
      let display = false;
      e.short_names.forEach((name) => {
        if (name.includes(searchQuery.toLowerCase())) display = true;
      });
      return display;
    });

    emojiData = filtered as IEmoji[];
  } else if (category == "history") {
    emojiData = history || [];
  } else {
    emojiData = category in emojiList ? emojiList[category] : [];
  }
  return (
    <DSPanel
      style={{
        height: scrollHeight,
        width: "100%",
        padding: 4,
        paddingTop: 10,
      }}
    >
      <TabBar activeCategory={category} onClick={setCategory} tileSize={tileSize} />
      {Searchbar}
      <DSPanelScrollable style={{ alignItems: 'center' }}>
        <DSWrappedRow style={{}}>
        {emojiData.map((item, index) => renderEmojiCell(item))}
        </DSWrappedRow>
      </DSPanelScrollable>
    </DSPanel>
  );
};
