import { Logger } from "@openteam/app-util";
import { ICalUser } from "@openteam/models";
import { Firestore, doc, getDoc, updateDoc, onSnapshot, collection, QuerySnapshot, DocumentData } from "firebase/firestore";

const logger = new Logger("CalendarDb");

export class CalendarDb {
  static getUser = async (
    fsDb: Firestore,
    userId: string
  ): Promise<ICalUser | undefined> => {
    const snapshot = await getDoc(doc(fsDb, `calendar/${userId}`));

    if (snapshot) {
      return snapshot.data() as ICalUser;
    } else {
      return undefined;
    }
  };

  static setAuthCode = async (fsDb: Firestore, userId: string, authCode: string) =>
    updateDoc(
      doc(fsDb, `calendar/${userId}`),
      { auth: { authCode, tokens: null } },
    );

  static updateUser = async (fsDb: Firestore, userId: string, userData: any) =>
    updateDoc(
      doc(fsDb, `calendar/${userId}`),
      userData,
    );

  static watchCalendar = (fsDb: Firestore, userId: string, callback: (doc: ICalUser) => void) =>
    onSnapshot(
      doc(fsDb, `calendar/${userId}`),
      (snapshot) => {
        if (snapshot) {
          logger.debug(`Got snapshot for calendar/${userId}`)
          const data = snapshot.data() as ICalUser;
          data && callback(data);
        }
      },
      (error) => logger.error(`error watching calendar/${userId}: `, error)
    );

  static watchCalendarEvents = (fsDb: Firestore, userId: string, callback: (snapshot: QuerySnapshot<DocumentData>) => void) =>
    onSnapshot(
      collection(fsDb, `calendar/${userId}/events`),
      (snapshot) => {
        snapshot && callback(snapshot);
      },
      (error) => logger.error(`error watching calendar/${userId}/events: `, error)
    );

  static watchCalendarList = (fsDb: Firestore, userId: string, callback: (snapshot: QuerySnapshot<DocumentData>) => void) =>
    onSnapshot(
      collection(fsDb, `calendar/${userId}/calendarList`),
      (snapshot) => {
        snapshot && callback(snapshot);
      },
      (error) => logger.error(`error watching calendar/${userId}/calendarList: `, error)
    );


  static setEventNotifiable = async (fsDb: Firestore, userId: string, compoundEvId: string, isNotificable: boolean) => {
    logger.debug(`setEventNotifiable: calendar/${userId}/events/${compoundEvId}: ${isNotificable}`);
    return updateDoc(
      doc(fsDb, `calendar/${userId}/events/${compoundEvId}`),
      { 'meta.isNotifiable': isNotificable },
    );
  }
}
