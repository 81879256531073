import React from "react";
import {
  FaBell, FaBellSlash, FaCog, FaThumbtack,
  FaTimes, FaUpload, FaArrowAltCircleUp, FaCheck, FaClock, FaComment, FaCommentDots,
  FaHandRock, FaPhone, FaSave, FaSearch, FaTrashAlt, FaVideo, FaPen, FaPlus, FaUserPlus,
   FaReply, FaCopy, FaExternalLinkAlt, FaDownload, FaChevronLeft, FaChevronRight,
   FaCommentMedical, FaHome, FaLink, FaRedo, FaUndo, FaLocationArrow, FaEyeSlash, FaEye,
   FaCamera
} from "react-icons/fa";
import {FiVideo} from "react-icons/fi";

export default {
  'home': <FaHome />,
  'settings': <FaCog />,
  'chat': <FaComment/>,
  'subscribe': <FaCommentMedical/>,
  'knock': <FaHandRock />,
  'call': <FiVideo fill='currentColor'/>,
  'calllink': <FaLink />,
  'answer': <FaCheck />,
  'accept': <FaCheck />,
  'reject': <FaTimes />,
  'close': <FaTimes />,
  'reply': <FaReply />,
  'leave': <FaPhone style={{ transform: "rotate(225deg)" }} />,
  'hold': <FaClock />,
  'edit': <FaPen />,
  'save': <FaSave />,
  'copy': <FaCopy />,
  'delete': <FaTrashAlt />,
  'search': <FaSearch />,
  'feedback': <FaCommentDots />,
  'upgrade': <FaArrowAltCircleUp />,
  'upload': <FaUpload />,
  'download': <FaDownload />,
  'openExt': <FaExternalLinkAlt />,
  'create': <FaPlus />,
  'invite': <FaUserPlus />,
  'prev': <FaChevronLeft />,
  'next': <FaChevronRight />,
  'revert': <FaUndo />,
  'link': <FaLink />,
  'send': <FaLocationArrow />,
  'hide': <FaEyeSlash />,
  'show': <FaEye />,
  'camera': <FaCamera />,
  'pin': <FaThumbtack />,
  'tick': <FaCheck />
}
