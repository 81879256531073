import { observable } from "mobx";
import { IUserDoc } from "@openteam/models";

type TLoginState = {
  initialised: boolean
  isSignedIn: boolean
  isAnonymous: boolean
  user: IUserDoc | null
  teams: Record<string, boolean> | null
  needsInvite: boolean
  teamName?: string
}

export const LoginState: TLoginState = observable({
  initialised: false,
  isSignedIn: false,
  isAnonymous: false,
  needsInvite: false,
  user: null,
  teams: null,
})
