import { MediaDeviceManager, OTGlobals, OTUserInterface, UIDataState } from "@openteam/app-core";
import { observer } from "mobx-react";
import { IUIWebcamStream } from "@openteam/models";
import React, { useState } from "react";
import { FaVideo, FaVideoSlash } from "react-icons/fa";
import { DSPillButton } from "../../../DesignSystem/DSButton";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { ButtonProps, getDeviceOptions, openCallMenu } from "./callButtonUtil";
import { isMacOs } from "react-device-detect";
import { Popover } from 'react-tiny-popover'
import { Menu } from "../../../Util/Menu";

interface VideoButtonProps extends ButtonProps {
  vertical?: boolean;
}

export const VideoButton: React.FC<VideoButtonProps> = observer(
  ({ style, size, color, ...otherProps }) => {
    const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false)
    const call = UIDataState.activeCall;
    const me = call?.participants[call.myUserId];
    const myStream = me?.streams["camera"] as IUIWebcamStream;
    const hasVideo = myStream?.hasVideo === true;

    const toggleVideo = async () => {
      call?.actions.toggleVideo();
    }

    const _openMenu = React.useCallback(async () => {
      await MediaDeviceManager.updateMediaDevices();

      setPopoverOpen(!isPopoverOpen)
    }, [isPopoverOpen, setPopoverOpen]);

    const template = [
      { enabled: false, label: `Select a Camera` },
      ...getDeviceOptions("videoinput"),

    ];

    if (UIDataState.deviceSettings["cameraQuality"]) {
      const { activeDeviceId: quality, availableDevices: sizes } =
        UIDataState.deviceSettings["cameraQuality"];

      template.push({ type: "separator" });

      Object.keys(sizes).forEach((size) => {
        template.push({
          type: "radio",
          label: sizes[size].label,
          checked: size === quality,
          click: () => call?.actions.updateDeviceSettings("cameraQuality", size),
        });
      });
    }


    if (call && call.participants[call.myUserId]) {

      return (
        <Popover
          padding={8}
          isOpen={isPopoverOpen}
          onClickOutside={() => setPopoverOpen(false)}
          content={<Menu
            template={template}
          />
          }
        >
          <div>
            <DSPillButton
              style={style}
              stopPropagation={{ onClick: true }}
              icon={
                hasVideo ? (
                  <FaVideo size={size ?? DSTheme.IconSize} color={color} />
                ) : (
                  <FaVideoSlash size={size ?? DSTheme.IconSize} color={DSTheme.CancelColor} />
                )
              }
              {...otherProps}
              button1Props={{
                onClick: toggleVideo,
                analyticsEvent: hasVideo ? "CallCameraOff" : "CallCameraOn",
                'data-tooltip': hasVideo ? 'Disable camera' : 'Enable camera',
              }}
              button2Props={{
                onClick: _openMenu,
                analyticsEvent: "CallCameraSettings",
                'data-tooltip': 'Camera settings',
              }}
            />
          </div>
        </Popover>
      );
    } else {
      return null;
    }
  }
);
