import events from "events";
import retry from "retry";
import { io, Socket } from "socket.io-client";
import { Logger } from "@openteam/app-util";
import { ITeamServerResponse, TActionType } from "@openteam/models";
import { FireUtils } from "./fire";
import { makeObservable, observable } from "mobx";
import { OTAppCoreData } from "./OTAppCoreData";

const logger = new Logger("TeamConnection");

export class TeamConnection extends events.EventEmitter {
  myUserId: string;
  teamId: string;
  socket: Socket<any> | undefined;
  socketNum = 0;
  @observable connected: boolean = false;
  stopping: boolean = false;

  constructor(userId: string, teamId: string) {
    super();

    makeObservable(this)

    this.myUserId = userId;
    this.teamId = teamId;

    this.connect();
  }

  connect = async () => {
    var operation = retry.operation({
      forever: true,
      factor: 2,
      minTimeout: 1 * 1000,
      maxTimeout: 15 * 1000,
    });

    operation.attempt(async (currentAttempt) => {
      try {
        logger.info("teamConnection attempt", this.teamId, currentAttempt); // prints the message associated with the error
        var token = await FireUtils.getAuthToken();
        const teamServerUrl = OTAppCoreData.TeamServerUrl;

        if (!teamServerUrl) {
          logger.error("teamConnection Missing TeamServerUrl", this.teamId);
          if (!this.stopping) {
            operation.retry(Error("Missing TeamServerUrl"));
          }
          return;
        }

        const url = new URL(`/teamServer/${this.teamId}`, teamServerUrl);

        this.socketNum += 1;

        logger.info(
          `teamConnection attempt ${this.teamId} ${
            this.socketNum
          } ${currentAttempt}: ${url.toString()}`
        );

        const socket = io(url.origin, {
          forceNew: true,
          path: url.pathname,
          transports: ["websocket"],
          auth: {
            token: token,
          },
          query: {
            userId: this.myUserId,
          },
        });

        socket.on("connect_error", (err) => {
          logger.error(
            `teamConnection connection error ${this.teamId} ${this.socketNum}`,
            err.message
          ); // prints the message associated with the error
          socket.close();
          this.socket = undefined;

          if (!this.stopping) {
            operation.retry(err);
          }
        });

        socket.on("connect", () => {
          logger.info(`teamConnection connected ${this.teamId} ${this.socketNum}`);
          this.connected = true;
        });

        socket.on("disconnect", (reason) => {
          logger.info(`teamConnection disconnected ${this.teamId} ${this.socketNum}`, reason);
          this.connected = false;
        });

        socket.on("action", this.hdlAction);
        socket.on("message", this.hdlMessage);
        socket.on("signal", this.hdlSignal);

        this.socket = socket;
      } catch (e) {
        logger.error("unexpected error", e);
        operation.retry(e as Error);
      }
    });
  };

  stop = () => {
    this.stopping = true;

    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  };

  sendAction = (
    toUserId: string,
    actionType: TActionType,
    callback?: (responseData: ITeamServerResponse) => void
  ) => {
    logger.info("action", toUserId, actionType);
    this.socket?.emit("action", toUserId, actionType, callback);
  };

  hdlAction = (
    fromUserId: string,
    actionType: TActionType,
    callback?: (responseData: ITeamServerResponse) => void
  ) => {
    console.info("action from", fromUserId, actionType);
    callback && callback({ status: "OK" });
    this.emit("action", fromUserId, actionType);
  };

  sendMessage = (toUserId, data, callback?: (responseData: ITeamServerResponse) => void) => {
    logger.info("message", toUserId, data);
    this.socket?.emit("message", toUserId, data, callback);
  };

  hdlMessage = (fromUserId, data, callback?: (responseData: ITeamServerResponse) => void) => {
    console.info("message from", fromUserId, data);
    callback && callback({ status: "OK" });
    this.emit("message", fromUserId, data);
  };

  sendSignal = (roomId, toUserId, data, callback?: (responseData: ITeamServerResponse) => void) => {
    this.socket?.emit("signal", roomId, toUserId, data, callback);
  };

  hdlSignal = (
    roomId,
    fromUserId,
    data,
    callback?: (responseData: ITeamServerResponse) => void
  ) => {
    console.info("signal from", roomId, fromUserId, data);
    callback && callback({ status: "OK" });
    this.emit("signal", roomId, fromUserId, data);
  };
}
