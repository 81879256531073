import free_email_domains from './free_email_domains';

export default function isGenericEmail(email: string) {
  const domain = email.split('@')[1];
  return free_email_domains.has(domain)
}

const genericDomains = [
  /@gmail.*/,
  /@yahoo.*/,
  /@hotmail.*/,
  /@aol.*/,
  /@msn.*/,
  /@wanadoo.fr/,
  /@orange.*/,
  /@comcast.net/,
  /@live.*/,
  /@rediffmail.com/,
  /@free.fr/,
  /@gmx.*/,
  /@web.*/,
  /@yandex.*/,
  /@ymail.*/,
  /@libero.*/,
  /@outlook.*/,
  /@uol.com.br/,
  /@bol.com.br/,
  /@mail.*/,
  /@cox.net/,
  /@sbcglobal.net/,
  /@sfr.*/,
  /@verizon.*/,
  /@googlemail.*/,
  /@ig.com.br/,
  /@bigpond.*/,
  /@terra.com.br/,
  /@neuf.fr/,
  /@alice.it/,
  /@rocketmail.com/,
  /@att.net/,
  /@laposte.net/,
  /@facebook.com/,
  /@bellsouth.net/,
  /@charter.net/,
  /@rambler.*/,
  /@tiscali.*/,
  /@shaw.ca/,
  /@sky.*/,
  /@earthlink.net/,
  /@optonline.net/,
  /@freenet.*/,
  /@t-online.*/,
  /@aliceadsl.fr/,
  /@virgilio.it/,
  /@home.*/,
  /@qq.com/,
  /@telenet.*/,
  /@me.*/,
  /@voila.fr/,
  /@planet.*/,
  /@tin.it/,
  /@ntlworld.com/,
  /@arcor.de/,
  /@frontiernet.net/,
  /@hetnet.nl/,
  /@zonnet.nl/,
  /@club-internet.*/,
  /@juno.com/,
  /@optusnet.*/,
  /@blueyonder.co.uk/,
  /@bluewin.ch/,
  /@skynet.*/,
  /@sympatico.ca/,
  /@windstream.net/,
  /@mac.*/,
  /@centurytel.net/,
  /@chello.nl/,
  /@aim.com/,
  /@btinternet.*/,
  /@virginmedia.*/,
  /@o2.*/,
  /@vodafone.*/,
  /@tmomail.*/,
  /@t-mobile.*/,
]
