import { toggleCallSelfMinimized, UIDataState } from '@openteam/app-core';
import { Logger } from '@openteam/app-util';
import { IUIWebcamStream } from '@openteam/models';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DSColumn, DSVSpacer } from '../../DesignSystem/DSLayout';
import { MutedIcon, showMuted } from './CallIndicators';
import { CallVideo } from './CallVideo';
import ScreenShareTile from './ScreenShareTile';
import { SignalStrength } from './SignalStrength';
import * as CallUtil from './CallTileUtil'
import { DSStop } from '../../DesignSystem/DSIconButtons';
import { DataState } from '../../Data/DataState';



const logger = new Logger('CallViewTile');


type Props = {
  userId: string;
  streamType: 'camera' | 'screen';
  tileSize: number;
  hovered: boolean;
  aspectRatio?: string
  style?: CSSProperties;
  onClick?: () => void
  onScreenShareClick?: () => void
  onHovered?: (hovered: boolean) => void;
};

const CallRoomTile: React.FC<Props> = ({
  userId,
  streamType,
  tileSize,
  hovered: callHovered,
  aspectRatio = "1",
  style,
  onHovered,
  onClick,
  onScreenShareClick
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tileHovered, setTileHovered] = useState(false);

  const call = UIDataState.activeCall;
  const callUser = call?.participants[userId];
  const isMe = userId === call?.myUserId;
  const isCamera = streamType === "camera";
  const flip = isMe && isCamera;
  const hasScreenShare = callUser?.streams['screen'] !== undefined;
  const stream = callUser?.streams[streamType] as IUIWebcamStream;
  const borderWidth = Math.max(2, Math.min(4, Math.round(tileSize / 100)));
  const iconSize = Math.max(10, Math.min(20, Math.floor(tileSize / 30)));


  useEffect(() => onHovered?.(tileHovered), [tileHovered]);
  const showInitials = !(stream?.hasVideo || callUser?.imageUrl);

  return (
    <CallUtil.TileWrapper
      aspectRatio={aspectRatio}
      callHovered={callHovered}
      tileSize={tileSize}
      circular={false}
      backgroundImage={(isCamera && callUser?.imageUrl) || undefined}
      muted={stream?.muted}
      speaking={stream?.speaking}
      onTileHovered={setTileHovered}
      onClick={onClick}
      style={style}
    >
      {stream && (
        <CallVideo
          key={`${userId}-${streamType}`}
          track={stream.videoTrack}
          hasVideo={stream.hasVideo}
          flip={flip}
          faceDetect={isCamera ? callUser?.faceDetect : undefined}
          blur={isCamera && call?.isCallPaused}
          style={{ objectFit: isCamera ? 'cover' : 'contain' }}
        />
      )}

      {showInitials && <CallUtil.Initials userId={userId} tileSize={tileSize} />}

      {isCamera ? (
        <>
          {isMe ? (
            <CallUtil.PauseButton tileHovered={tileHovered} tileSize={tileSize} />
          ) : (
            <CallUtil.TileHover userId={userId} tileHovered={tileHovered} />
          )}

          <div style={Styles.topLeft}>
            <SignalStrength userId={userId} size={iconSize} forceShow={tileHovered} popupOnLeft={false} />
          </div>

          <DSColumn style={Styles.topRight}>
            {showMuted(userId) && (
              <>
                <MutedIcon userId={userId} size={iconSize} />
                <DSVSpacer size={iconSize / 2} />
              </>
            )}
          </DSColumn>
          {hasScreenShare && (
            <div style={Styles.bottomRight}>
              <ScreenShareTile
                onClick={onScreenShareClick}
                userId={userId}
                size={Math.min(200, Math.max(tileSize / 4, 30))}
              />
              {isMe &&
                <DSStop
                  analyticsEvent="stopScreenshare"
                title="Stop Screenshare"
                size={Math.min(14, Math.max(tileSize / 10, 30))}
                style={{
                  position: 'absolute',
                  right: "-10%",
                  top: "-10%"
                }}
                  onClick={() => DataState.activeCall?.actions.shareScreen()}
                />
              }
            </div>
          )}
        </>
      ) : null}
    </CallUtil.TileWrapper>
  );
};

export default observer(CallRoomTile);

const Styles: Record<string, CSSProperties> = {
  absolute: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  topLeft: {
    position: "absolute",
    top: "3%",
    left: "3%",
    alignItems: "self-start",
  },
  topRight: {
    position: "absolute",
    top: "3%",
    right: "3%",
    alignItems: "self-end",
  },
  bottomLeft: {
    position: "absolute",
    bottom: "3%",
    left: "3%",
    alignItems: "self-start",
  },
  bottomRight: {
    position: "absolute",
    bottom: "3%",
    right: "3%",
    alignItems: "self-end",
  },
  tileContainer: {}
};
