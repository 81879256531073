import * as React from "react";
import Fab, { FabProps } from '@mui/material/Fab';
import { Badge, Palette, styled } from "@mui/material";
import useClickable from "./useClickable";


const StyledButton = styled(Fab)`
  app-region: no-drag;
  pointer-events: initial;

  &.Mui-focusVisible {
    box-shadow: none;
    outline: 1px solid grey;
  }
`
export interface DS2FabProps extends FabProps {
  analyticsEvent?: string
  analyticsArgs?: any
  badgeNum?: string | number
  badgeOverride?: string
  stopPropagation?: StopPropagation
  badgeColor?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
  allowDuringTutorial?: boolean
};

type StopPropagation = {
  onClick: boolean
  onPointerDown: boolean
}

export default React.forwardRef<HTMLButtonElement, DS2FabProps>((props, ref) => {

  const {
      onClick: _onClick,
      onPointerDown: _onPointerDown,
      stopPropagation: _stopPropagation,
      analyticsEvent,
      analyticsArgs,
      badgeNum,
      badgeOverride,
      badgeColor,
      allowDuringTutorial,
      ...buttonProps
    } = props;


    const {onClick, onPointerDown} = useClickable(props)

    const enabled = !props.disabled && (_onClick || props.onMouseDown)
    return badgeOverride ?? badgeNum ? (
      <Badge badgeContent={badgeOverride ?? badgeNum} color={badgeColor ?? 'primary'}>
        <StyledButton
          ref={ref}
          onClick={enabled ? onClick : undefined}
          onPointerDown={onPointerDown}
          size='small'
          {...buttonProps}
        />
      </Badge>
    ) : (
      <StyledButton
          ref={ref}
          onClick={enabled ? onClick : undefined}
          onPointerDown={onPointerDown}
          size='small'
          {...buttonProps}
        />
    )
  })
