import EventEmitter from "events";

class AnalyticsEmmitter extends EventEmitter {
  logEvent = (event: string , args: any) => {
    this.emit("button-press", event, args)
  }
}

export const analyticsEmitter = new AnalyticsEmmitter();

export const logEvent = analyticsEmitter.logEvent;
