import { Logger } from "@openteam/app-util";
import { Database, onChildAdded, push, ref, remove } from "firebase/database";
const logger = new Logger("SignalsDb");

export class SignalsDb {
  static _watches: (() => void)[] = []
  static watchSignals = (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string,
    callback: (snapshot) => void
  ) => {
    const path = `/signals/${teamId}/user/${userId}/session/${sessionToken}/room/${roomId}`;

    SignalsDb._watches.push(
      onChildAdded(ref(fbDb, path), (doc) => {
      //logger.debug("Got doc ", doc.key)
      callback(doc.val());
      remove(doc.ref)
      })
    );

    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").onSnapshot(
    //   callback
    // )
  };

  static unwatchSignals = (
    fbDb: Database,
    teamId: string,
    myUserId: string,
    mySessionToken: string,
    roomId: string
  ) => {

    SignalsDb._watches.forEach(f => f())
    SignalsDb._watches = []

    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").onSnapshot(
    //   callback
    // )
  };

  static addSignal = (
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,

    roomId: string,
    msg
  ) => {
    const path = `/signals/${teamId}/user/${userId}/session/${sessionToken}/room/${roomId}`;

    push(ref(fbDb, path), msg);
    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").add(msg)
  };
}
