import { Logger } from "@openteam/app-util";
import { H1, Panel, Spinner, H3, Row, H2, Button } from "@openteam/design-system";
import { IMeetingRequestStatus, IMeetingTokenDetails } from '@openteam/models';
import { observer } from "mobx-react";
import React, { useState } from "react";
import { FaWindowMaximize } from "react-icons/fa";
import Logo from '../../assets/logoo.svg?component';
import { LINKPROTOCOL } from "../../config";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSImage } from "../../DesignSystem/DSImage";
import { DSSeparator } from "../../DesignSystem/DSSeparator";
import Page from "../../Util/Page";
import { MeetConnect } from "./MeetConnect";



const logger = new Logger("MeetConnect")


interface Props {
  meetingToken: string
  status?: IMeetingRequestStatus
  meetingData?: IMeetingTokenDetails
  requestMeeting: (name) => void
  skipOpen?: boolean
}

export const MeetOpen = observer((props: Props) => {
  const [joinHere, setJoinHere] = useState<boolean>(props.skipOpen || false);

  const redirectToApp = async () => {
    var protocolURL = `${LINKPROTOCOL}:meet`

    protocolURL = protocolURL + `?meetToken=${props.meetingToken}`

    console.info("protocolURL", protocolURL)
    window.location.href = protocolURL
  }

  return (
    <Page>
        <H1 wrap style={{ color: "black", marginBottom: 20, fontWeight: 700, fontSize: 32, textAlign: "center" }}>
          Join meeting
        </H1>
      {!props.meetingData ?
        <Panel style={{
          alignItems: 'center',
          flex: 1,
          marginTop: 36,
        }}>
          <Spinner />
          <H3 style={{ paddingTop: 8 }}>
            Loading meeting details
          </H3>
        </Panel> :

        <Panel style={{
          alignItems: 'center',
          flex: 1,
        }}>

          {
            props.meetingData.team.iconImageUrl ?
              <Row style={{ alignItems: 'center', marginBottom: 20 }}>
                <DSImage
                  url={props.meetingData.team.iconImageUrl}
                  height={40}
                  width={40}
                  style={{
                    borderRadius: "50%",
                  }}
                />
                <H2 style={{ paddingLeft: 8 }}>
                  {props.meetingData.team.teamName}
                </H2>
              </Row>
              :
              undefined
          }


          <H2>
            Room: {
              props.meetingData.room ?
                `${props.meetingData.room.name}`
                :
              props.meetingData.channel ?
                `${props.meetingData.channel.name}`
                  :
                  props.meetingData.user ?
                    `${props.meetingData.user.name}'s room`
                    :
                    undefined

            }
          </H2>



          {
            props.meetingData.name ?
              <H3 style={{ marginTop: 12 }}>
                Meeting name: {props.meetingData.name}
              </H3>

              :
              undefined
          }

          <Panel style={{ alignItems: 'center', paddingTop: 24 }}>

            {
              !joinHere ?
                <>
                  <DSButton
                    onClick={redirectToApp}
                    analyticsEvent="OpenApp"
                    style={{
                      backgroundColor: "#393B3C",
                      color: 'white',
                      padding: 4,
                      borderRadius: 8,
                      width: 240,
                    }}>

                    <Row style={{ alignItems: 'center' }}>
                      <Logo style={{ padding: 6, height: 24, width: 24 }} />
                      <H3 style={{ padding: 6 }}>
                        Open with ehlo app
                      </H3>
                    </Row>

                  </DSButton>

                  <DSSeparator style={{ width: 230, margin: 30, backgroundColor: 'black' }} />


                  <H3 style={{ textAlign: 'center' }}>
                    Don't have the app? {<br />}
                    Enter the call using your browser
                  </H3>
                  <DSButton
                    onClick={() => setJoinHere(true)}
                    analyticsEvent="OpenApp"
                    style={{
                      marginTop: 24,
                      backgroundColor: "#393B3C",
                      color: 'white',
                      padding: 4,
                      borderRadius: 8,
                      width: 240,
                    }}>

                    <Row style={{ alignItems: 'center' }}>
                      <FaWindowMaximize size={20}
                        style={{ padding: 6, height: 24, width: 24 }} />
                      <H3 style={{ padding: 6 }}>
                        Continue in browser
                      </H3>
                    </Row>

                  </DSButton>
                </>
                :
                undefined
            }

            {
              joinHere ?
                <MeetConnect meetingData={props.meetingData} status={props.status} requestMeeting={props.requestMeeting} />
                :
                undefined
            }
          </Panel>
        </Panel>
      }
    </Page >
  );
})
