import {
  IOTPTTState,
  IOTRoom,
  IOTRoomUser,
  IOTStream,
  IOTUser,
  ISubTeam,
  ITeamCapabilities,
  ITeamConfigDoc,
  ITeamUserPrefs,
  IZoomMeeting,
} from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { computed, makeObservable, observable } from "mobx";
import { TeamManagerDb } from "./fire";
import { OTGlobals } from "./OTGlobals";
import { writeSpaceCapabilities } from ".";
import { Database } from "firebase/database";

const logger = new Logger("OTTeamData");

const dummyUser: IOTUser = {
  name: "Unknown User",
  userId: "unknown",
  email: "unknownemail",
  imageUrl: undefined,
  online: true,
  inLeeway: false,
  inBackground: false,
  hasMobile: false,
  idle: false,
  sessionToken: null,
  isAdmin: false,
  canPtt: false
};

export class OTTeamDataClass {
  fbDb: Database;
  userId: string;
  teamId: string;

  static defaultCapabilities: ITeamCapabilities = {
    stickyNotes: false,
    newVideoChat: true,
    externalMeetings: true,
    notifyUserOnline: true,
    teamWidget: true,
    scalableSFU: undefined,
    useTeamServer: undefined,
    forceVideoCodec: "H264",
  };

  @observable capabilities: ITeamCapabilities = OTTeamDataClass.defaultCapabilities;

  @observable flags = {
    useOldVideoChat: false,
  };

  @observable isFocused = false;

  @observable isAdmin: boolean = false;
  // @observable teamDoc: ITeamDoc = undefined
  // @observable teamId: string = undefined
  @observable config?: ITeamConfigDoc;
  @observable teamPath?: string = undefined;
  @observable teamPathId?: string = undefined;
  @observable teamName?: string = undefined;
  @observable backgroundImageUrl?: string = undefined;
  @observable iconImageUrl?: string = undefined;
  @observable preferences?: ITeamUserPrefs = undefined;

  @observable subTeams: Record<string, ISubTeam> = {};

  @observable users: Record<string, IOTUser> = {};

  @observable rooms: Record<string, IOTRoom> = {};

  @observable currentRoomId?: string;

  @observable pttState?: IOTPTTState;

  @observable pttStreams: { [userId: string]: { [streamType: string]: string } } = {};

  @observable streams: { [streamId: string]: IOTStream } = {};

  // Chat
  @observable unreadChats: number = 0;

  // Alerts
  @observable unreadAlerts: number = 0;

  // Notes
  @observable unreadNotes: number = 0;

  // access requests
  @observable numAccessReqs: number = 0;

  @observable zoomMeetings: Record<number, IZoomMeeting> = {}

  _autorun: Record<string, any> = {};

  constructor(fbDb: Database, userId: string, teamId: string) {
    makeObservable(this)

    this.fbDb = fbDb;
    this.userId = userId;
    this.teamId = teamId;
    this.start();
  }

  start = () => {
    this.setupTeam();
  };

  stop = () => {
    TeamManagerDb.unwatchTeamCapabilities(this.fbDb, this.teamId);
  };

  _getMyStream(streamType = "camera") {
    const callState = OTGlobals.callStateManager?.callState;
    const streamId = callState ? callState.streams[this.userId]?.[streamType] : undefined;
    return streamId ? this.streams[streamId] : undefined;
  }

  @computed get badgeNum() {
    return this.unreadAlerts + this.unreadChats + this.numAccessReqs + this.unreadNotes;
  }

  @computed get callAudioOn() {
    return this._getMyStream()?.muted == false;
  }

  @computed get callVideoOn() {
    return this._getMyStream()?.hasVideo == true;
  }

  @computed get callScreenshareOn() {
    return this._getMyStream("screen")?.hasVideo == true;
  }

  @computed get sharing() {
    return this.callAudioOn || this.callVideoOn || this.callScreenshareOn;
  }

  @computed get inCall() {
    return (
      OTGlobals.callStateManager &&
      OTGlobals.callStateManager.inCall &&
      OTGlobals.callStateManager.roomId == this.currentRoomId &&
      !OTGlobals.callStateManager.isFocusRoom
    );
  }

  @computed get inQuiet() {
    return this.currentRoomId && this.rooms[this.currentRoomId]?.config?.call == false;
  }

  @computed get inVideoChat() {
    return OTGlobals.callStateManager && OTGlobals.callStateManager.inVideoChat;
  }

  @computed get me() {
    return this.getTeamUser(this.userId);
  }

  getTeamUser = (userId: string): IOTUser => {
    return this.users[userId] ?? dummyUser
  };

  getCallUser = (userId: string): IOTRoomUser => {
    let user: IOTRoomUser | undefined;

    if (OTGlobals.callStateManager) {
      const { roomId } = OTGlobals.callStateManager.callState;
      user = this.rooms[roomId]?.users[userId];
    }
    return user ?? dummyUser;
  };

  //  otherRoomAutoRun = autorun(
  //    () => {
  //      logger.debug("AUTORUN: otherRooms now", JSON.stringify(this.rooms))
  //    }
  //  )

  //callStateAutoRun = autorun(
  //  () => {
  //    logger.debug("AUTORUN: callState now", JSON.stringify(this.callState))
  //  }
  //)

  //pttStateAutoRun = autorun(
  //  () => {
  //    logger.debug("AUTORUN: pttState now", JSON.stringify(this.pttState))
  //  }
  //)

  //pttStreamsAutoRun = autorun(
  //  () => {
  //    logger.debug("AUTORUN: pttStreams now", JSON.stringify(this.pttStreams))
  //  }
  //)

  //streamStateAutoRun = autorun(
  //  () => {
  //    logger.debug("AUTORUN: streamState now", JSON.stringify(this.streams))
  //  }
  //)

  setupTeam = () => {
    logger.debug(`setupTeam teamId=${this.teamId}`);

    const hdlCapabilities = (doc, isCached: boolean = false) => {
      logger.info(`got team capabilities teamId=${this.teamId}, doc=${doc}`);
      if (doc) {
        this.capabilities = { ...OTTeamDataClass.defaultCapabilities, ...doc };

        writeSpaceCapabilities(this.teamId, this.capabilities)

        if (!isCached) {
          OTGlobals.cache.setTeamCache(
            this.userId,
            this.teamId,
            "capabilities",
            "capabilities",
            doc
          );
        }
      }
    };

    const teamCapabilitiesDoc = OTGlobals.cache.getTeamCache(
      this.userId,
      this.teamId,
      "capabilities",
      "capabilities"
    );

    if (teamCapabilitiesDoc) {
      hdlCapabilities(teamCapabilitiesDoc);
    }

    TeamManagerDb.watchTeamCapabilities(this.fbDb, this.teamId, hdlCapabilities);
  };
}
