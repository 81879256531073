import { Firestore, onSnapshot, doc, updateDoc, DocumentReference } from "firebase/firestore";
import { Logger } from "@openteam/app-util";
import { computed, makeObservable, observable, action } from "mobx";
import { IZoomUserData } from "@openteam/models";
import { Database, remove, ref as RDBRef, get } from "firebase/database";
import { b64encodeStr } from "../utils";

const logger = new Logger("ZoomManager");

export class ZoomManager {
  fbDb: Database;

  fsDb: Firestore;
  userId: string;
  @observable doc: IZoomUserData | undefined;
  ref?: DocumentReference;

  constructor(fbDb: Database, fsDb: Firestore, userId: string) {
    makeObservable(this);
    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.userId = userId;
  }

  @computed
  get isAuthorised() {
    return this.doc && !!this.doc?.auth?.access_token;
  }

  @computed
  get email() {
    return this.doc?.zoomUser?.email;
  }

  @computed
  get personalLink() {
    return this.doc?.zoomUser?.personal_meeting_url;
  }

  @computed
  get status() {
    return this.doc?.status;
  }

  @computed
  get enabled() {
    return this.doc?.enabled === true;
  }

  start = async () => {
    this.watchDoc();
  };

  stop = async () => {
    this.unWatchDoc?.();
    this.unWatchDoc = undefined;
  };

  setEnabled = async (enabled) => {
    if (this.ref) {
      await updateDoc(this.ref, { enabled });
      if (!enabled) {
        await this.clearStatus();
      }
    }
  };

  clearStatus = async () => {
    const snapshot = await get(RDBRef(this.fbDb, `/users/${this.userId}/teams`));
    const teams: Record<string, string> = snapshot.val();
    const teamIds = Object.keys(teams);

    const updates = teamIds.map((teamId) => {
      return remove(
        RDBRef(this.fbDb, `/teams/${teamId}/users/${this.userId}/status/zoomStatus`)
      );
    });

    await Promise.all(updates);
  };

  unWatchDoc?: () => void;

  watchDoc = () => {
    if (!this.unWatchDoc) {
      logger.debug(`Watching zoom/${this.userId}`);
      this.unWatchDoc = onSnapshot(
        doc(this.fsDb, `zoom/${this.userId}`),
        action((snap) => {
          logger.debug(`Got snapshot`, snap?.data());
          this.ref = snap.ref;
          this.doc = snap.data() as IZoomUserData;
        })
      );
    }
  };

  getAuthUrl = ({clientId, functionsHost, userId}) => {
    const token = [userId, clientId].join(':')
    const callbackUrl = `https://${functionsHost}/zoom_authCallback?token=${b64encodeStr(token)}`;
    return `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(callbackUrl)}`
  }
}
