import React from "react";
import { ILinkPreview, IPinnedResource, ISearchFileRecord } from '@openteam/models';
import { DSBody, DSH3, DSPrint } from "../../DesignSystem/DSText";
import { DSColumn, DSPanel, DSRow } from "../../DesignSystem/DSLayout";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSImage } from "../../DesignSystem/DSImage";
import { FaThumbtack } from "react-icons/fa";
import { FileIcon } from "../FavIcon";
import { DSInfo, DSPin, DSSave } from "../../DesignSystem/DSIconButtons";
import { useHoverIntent } from "../../Util/HoverIntent";
import { observer } from "mobx-react";
import { createResourceFromLink } from "@openteam/app-core";



type Props = {
  channelId?: string
  messageId: string,
  linkPreview: ILinkPreview,
  linkId?: string,
  saveResource?: (linkPreview: ILinkPreview, linkId: string | undefined, isResource: boolean, isPinned: boolean) => Promise<void>,
  saveLinkToChat?: () => void,
}

export const ChatLinkPreview: React.FC<Props> = observer(({ channelId, messageId, linkPreview, linkId, saveResource, saveLinkToChat }) => {
  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
    timeout: 0,
    minVelocity: 1000,
  });


  const openLink = () => {
  }

  const dummyResource: ISearchFileRecord = createResourceFromLink("dummy", messageId, linkId, linkPreview)

  return (
    <DSPanel style={{ alignItems: "flex-start" }}>
      <DSRow
        ref={hoverRef}
        style={{
          marginTop: 10,
          padding: 12,
          minWidth: 300,
          maxWidth: 500,
          borderRadius: DSTheme.BaseBorderRadius,
          backgroundColor: DSTheme.BoxDarkBackground,
          position: 'relative',
          ...{ WebkitAppRegion: undefined } as any,
        }}
      >
        <FileIcon hit={dummyResource} />
        <DSColumn
          style={{
            marginLeft: 12
          }}
        >
          <DSButton
            onClick={openLink}
            href={linkPreview.url}
          >
            <DSH3
              wrap
              style={{
                fontWeight: "bold",
                color: DSTheme.ChatLinkColor,
                cursor: "pointer"
              }}
            >
              {linkPreview.title || linkPreview.url}
            </DSH3>
          </DSButton>

          <DSBody style={{}}>{linkPreview.description}</DSBody>
          {linkPreview.image ? (
            <DSImage
              key={`${linkPreview.image}`}
              url={linkPreview.image}
              maxWidth={150}
              maxHeight={150}
              style={{
                marginTop: 5,
                marginBottom: 5
              }}
            />
          ) : null}

          {
            isHovering ?
              <DSRow
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                }}
              >
                {
                  !linkPreview.isResource ?
                    saveResource ?
                      <DSSave
                        onClick={() => saveResource(linkPreview, linkId, true, false)}
                        size={12}
                        style={{
                          width: undefined,
                          padding: 4
                        }}
                      />
                      :
                      null

                    :

                    saveLinkToChat && !linkPreview.savedToChat ?
                      <DSSave
                        onClick={() => saveLinkToChat()}
                        size={12}
                        style={{

                          width: undefined,
                          padding: 4
                        }}
                      />
                      :
                      saveResource ?
                        <DSCircleIconButton
                          data-tooltip={linkPreview.isPinned ? "Unpin" : "Pin"}
                          style={{
                            padding: 4,
                          }}
                          outerStyle={{
                            padding: 4
                          }}
                          icon={
                            <FaThumbtack
                              size={12}
                              style={
                                linkPreview.isPinned
                                  ? { transition: "all 0.075s ease-in", color: DSTheme.EmphasisColor }
                                  : {
                                    transition: "all 0.075s ease-in",
                                    transform: "rotate(45deg)",
                                    color: DSTheme.PanelContrastColor,
                                  }
                              }
                            />
                          }
                          onClick={() => saveResource(linkPreview, linkId, true, !linkPreview.isPinned)}
                        />
                        :
                        null
                  // <DSInfo
                  //   onClick={() => { }}
                  //   size={12}
                  //   style={{
                  //     width: undefined,
                  //     padding: 4
                  //   }}
                  // />
                }
              </DSRow>
              :
              null
          }
        </DSColumn>
      </DSRow >

    </DSPanel>
  );

})
