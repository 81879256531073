import { Database, equalTo, get, orderByChild, push, query, ref, remove, serverTimestamp, set, update } from "firebase/database";
import { Logger } from "@openteam/app-util";
import { OTUserInterface } from "../OTUserInterface";
import { OTAppCoreData } from "../OTAppCoreData";
import { ITeamCapabilities, ITeamUser } from "@openteam/models";
import { unWatch, watch } from "./FireWatch";
import { getFunctions, httpsCallable } from "firebase/functions";

const logger = new Logger("SignalsDb");

export class TeamManagerDb {
  static setTeamBackgroundStatus = (
    fbDb: Database,
    userId: string,
    teamId: string,
    inBackground: boolean
  ) => {
    const statusRef = ref(fbDb, "/teams/" + teamId + "/users/" + userId + "/status")

    update(statusRef, {
      inBackground: inBackground,
      focused: !inBackground, // for backwards compatability use inBackground until everyone has upgraded, then can switch to focused which makes more sense.
    });
    logger.info("set background to", inBackground);
  };

  static setIdle = (fbDb: Database, userId: string, teamId: string, idle) => {
    var statusRef = ref(fbDb, "/teams/" + teamId + "/users/" + userId + "/status")

    update(statusRef, {
      idle: idle,
    });
    logger.debug("set idle to", idle);
  };

  static setCustomStatus = (
    fbDb: Database,
    userId: string,
    teamId: string,
    customStatus?: 'DND' | 'AWAY' | 'INCALL',
    customStatusEmoji?: string,
    customStatusText?: string
  ) => {
    var statusRef = ref(fbDb, "/teams/" + teamId + "/users/" + userId + "/status")

    update(statusRef, {
      customStatus: customStatus || null,
      customStatusEmoji: customStatusEmoji || null,
      customStatusText: customStatusText || null,
    });
    logger.debug("setCustomStatus to", customStatus, customStatusEmoji, customStatusText);
  };

  static registerDeviceTeamUser = (
    fbDb: Database,
    userId: string,
    sessionToken: string,
    teamId: string
  ) => {
    const version = OTAppCoreData.version;
    const deviceId = OTAppCoreData.deviceId;

    const deviceRef = ref(fbDb, `/teams/${teamId}/users/${userId}/device/${deviceId}`)

    // const device = {
    //   manufacturer: Device.manufacturer,
    //   modelName: Device.modelName,
    //   deviceName: Device.deviceName,
    //   osName: Device.osName,
    //   osVersion: Device.osVersion,
    // };
    update(deviceRef, {
      last_changed: serverTimestamp(),
      appVersion: version,
      sessionToken: sessionToken,
      platform: OTUserInterface.platformUtils.PlatformOS,
      os: OTUserInterface.platformUtils.Platform.OS,
      // device: device,
    });
  };

  static updateTeamName = async (
    fbDb: Database,
    teamId: string,
    teamName: string
  ) => {
    update(ref(fbDb, `/teams/${teamId}`), {teamName})
  }

  static updateTeamIcon = async (
    fbDb: Database,
    teamId: string,
    imageUrl: string
  ) => {
    update(ref(fbDb, `/teams/${teamId}`), {iconImageUrl: imageUrl ?? null})
  }

  static createSubTeam = async (
    fbDb: Database,
    teamId: string,
    name: string
  ) => {
    logger.debug(`creating subteam ${name}`)
    push(ref(fbDb, `/teams/${teamId}/subteams/`), {name})
  }

  static updateSubTeamName = async (
    fbDb: Database,
    teamId: string,
    subTeamId: string,
    name: string
  ) => {
    update(ref(fbDb, `/teams/${teamId}/subteams/${subTeamId}`), {name})
  }

  static deleteSubTeam = async (
    fbDb: Database,
    teamId: string,
    subTeamId: string,
  ) => {
    remove(ref(fbDb, `/teams/${teamId}/subteams/${subTeamId}`))
    const snap = await get(query(ref(fbDb, `/teams/${teamId}/users/`), orderByChild("subTeam"), equalTo(subTeamId)));

    snap.forEach((doc) => {
      remove(ref(fbDb, `/teams/${teamId}/users/${doc.key}/subTeam`))
    })
  }

  static unregisterDeviceTeamUser = async (
    fbDb: Database,
    userId: string,
    teamId: string
  ) => {
    const deviceId = OTAppCoreData.deviceId;

    await remove(ref(fbDb, `/teams/${teamId}/users/${userId}/device/${deviceId}`));
  };

  static updateTeamUser = async (
    fbDb: Database,
    teamId: string,
    userId: string,
    userObj: Partial<ITeamUser>
  ) => {
    logger.debug("updating team user", teamId, userId, userObj)
    update(ref(fbDb, `/teams/${teamId}/users/${userId}`), userObj);
  };

  static watchTeamCapabilities = (
    fbDb: Database,
    teamId: string,
    callback: (doc: ITeamCapabilities) => void
  ) => {
    watch(fbDb, `/teamCapabilities/${teamId}`, callback)
  };

  static unwatchTeamCapabilities = (fbDb: Database, teamId: string) => {
    unWatch(fbDb, `/teamCapabilities/${teamId}`);
  };

  static setTeamAdminUser = (
    fbDb: Database,
    teamId: string,
    userId: string,
    admin: boolean=true
  ) => {
    const value = admin || null;
    set(ref(fbDb, `/teams/${teamId}/admin/${userId}`), value);
  };

  static removeTeamAdminUser = (
    fbDb: Database,
    teamId: string,
    userId: string
  ) => {
    set(ref(fbDb, `/teams/${teamId}/admin/${userId}`), null);
  };

  static removeTeamUser = async (teamId: string, userId: string) => {
    const _removeTeamUser = httpsCallable(getFunctions(), "removeTeamUser");
    const result = await _removeTeamUser({ teamId, userId })

    return (result.data as any).success;
  }

}