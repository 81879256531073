import mixpanel from "mixpanel-browser";

import * as config from "../config";
import { getCurrentUser } from "../Controllers/Login";
import { LoginState } from "../Controllers/UIState";
import { analyticsEmitter } from "@openteam/design-system";
import { Logger } from "@openteam/app-util";


const logger = new Logger('Analytics')

mixpanel.init(config.MIXPANELID, { cross_subdomain_cookie: true, ignore_dnt: true, api_host: "https://api.mixpanel.com" });

export function aliasUser(userId: string) {
  mixpanel.alias(userId);
}

export function identifyUser(userId: string) {
  mixpanel.identify(userId);
  logger.debug("identifyUser", userId);

  const currentUser = getCurrentUser()

  if (LoginState.isSignedIn && !LoginState.isAnonymous && currentUser) {
    mixpanel.register_once({
      'First Login Date': new Date().toISOString()
    });

    mixpanel.people.set({
      userId: currentUser.userId,
      $email: currentUser.email, // only special properties need the $
    });

  }
}

export function reset() {
  mixpanel.reset();
}

export const setCurrentScreen = (screen: string) => {
  logEvent(`setCurrentScreen_${screen}`);
  logger.debug("setCurrentScreen", screen);
}
export const logError = (errCode: string) => {
  logEvent("Error", { errCode });
}
export function logEvent(event: string, data?: any) {
  const eventData = {
    //teamId: OTUITree.unsafeUserManager?.currentTeamId,
    ...data,
  };

  mixpanel.track(event, eventData);
  logger.debug("tracking event", event, eventData);
}

analyticsEmitter.on('button-press', logEvent);
