import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from "@mui/material";
import { Logger } from "@openteam/app-util";
import React, { useCallback } from "react";
import { styled } from ".";
import { logEvent } from "./Analytics";

const logger = new Logger('DS2Switch')

export interface DS2SwitchProps extends Omit<SwitchProps, 'onChange'> {
  label?: string | JSX.Element
  analyticsEvent?: string
  analyticsArgs?: any
  onChange?: (checked: boolean) => void
}

const DS2Switch: React.FC<DS2SwitchProps> = ({ label, style, analyticsEvent, analyticsArgs, onChange, ...switchProps }) => {

  const _onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    //logger.debug(`onChange ${ev.target.checked}`)

    onChange?.(ev.target.checked)

    analyticsEvent && logEvent(
      `Button_${analyticsEvent}`,
      { interaction: 'button', ...analyticsArgs }
    )
  }, [])

  if (label) {
    return <StyledFormControlLabel control={
      <StyledSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple onChange={_onChange} {...switchProps} />
    } style={style}
    label={label} />
  } else {
    return <StyledSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple onChange={_onChange} style={style} {...switchProps} />
  }
}

export default DS2Switch;


const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '.MuiFormControlLabel-label': {
    margin: '0px 4px'
  }
}))

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#545C5F',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
