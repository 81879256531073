import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { IOTChatMessage, IPinnedResource, ILinkPreview, KSpaceId, IFile, IUIUserMinimal } from '@openteam/models';
import { DataState } from "../../Data/DataState";
import { Logger } from "@openteam/app-util";

import { DSColumn, DSPanel, DSRow } from "../../DesignSystem/DSLayout"
import { DSBody, DSH4, DSPrint, DSSmallPrint } from "../../DesignSystem/DSText";
import UserIcon from "../User/UserIcon";
import { getFriendlyDate } from "../../Util/DateFormat";
import { ChatMessageBody } from "./ChatMessageBody";
import { useHoverIntent } from "../../Util/HoverIntent";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSButton, DSCircleIconButton, DSExpandButton, DSOutlineButton } from "../../DesignSystem/DSButton";
import { FaCheck, FaPen, FaReply, FaTimes, FaTrash } from "react-icons/fa";
import { OTUITree } from "@openteam/app-core";
import { observer } from "mobx-react";
import { MarkdownEditor } from "./MarkdownEditor";
import { ChatCallSummary } from "./ChatCallSummary";
import {
  IPendingMessage,
} from "@openteam/models";

const logger = new Logger("ChatMessage")

type Props = {
  spaceId: KSpaceId
  message: IOTChatMessage
  isReply?: boolean
  prevGrouped?: boolean
  nextGrouped?: boolean
  isLastRead?: boolean
  canEdit?: boolean

  messageNumRead?: number
  pendingMessage?: IPendingMessage;
  deleteMessage?: (messageId) => void
  editMessage?: (messageId, messageDraft) => void
  setReply?: (message?: IOTChatMessage) => void
  updateResource?: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>
  loadCallChat?: (callId: string) => void
  saveLinkToChat?: (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => Promise<void>
  saveFileToChat?: (id: string, file: IFile, fileIndex: number) => Promise<void>
  sendURL?: (url: string, systemMessage: string) => void

  focusInput?: () => void
  style?: CSSProperties
  getUser?: (userId) => IUIUserMinimal | undefined
}

export const ChatMessage = observer(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const userIconSize = 40;
    const messsageStatusSize = 16;
    const { canEdit = true, isReply = false, getUser } = props

    const space = DataState.spaces[props.spaceId]
    const message = props.message;
    const [editing, setEditing] = useState(false);
    const [messageDraft, setMessageDraft] = useState(message.message);

    const myMessage = message.userId == OTUITree.auth.userId

    const localRef = useRef<HTMLDivElement>(null);
    const _ref = ref || localRef;

    const user = space?.users[message.userId] || getUser?.(message.userId) || {
      id: message.userId,
      name: "Unknown User",
    }

    const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
      timeout: 0,
      minVelocity: 1000,
    });

    const deleteMessage = () => {
      props.deleteMessage?.(message.id)
    }

    const saveEdit = () => {
      setMessageDraft((value) => {
        props.editMessage?.(message.id, value);
        return value
      })
      setEditing(false);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        saveEdit();
      }
    }

    //useLayoutEffect(() => logger.debug(`rendering message ${message.messageNum}`,))

    if (message.isSystem && message.systemType == 'CALLSUMMARY' && message.systemId) {
      return <DSPanel
        style={{
          position: 'relative',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: (canEdit && isHovering && !isReply) ? DSTheme.ChatMessageHoverTint : undefined,
          transition: "background-color 0.1s ease-in-out",
          flex: 1
        }}>
        <ChatCallSummary
          spaceId={props.spaceId}
          callId={message.systemId}
          loadCallChat={() => props.loadCallChat?.(message.systemId!)}
          sendURL={props.sendURL!} />
      </DSPanel>
    }

    logger.debug("rendering message ", message.messageId)

    return (
      <DSColumn ref={_ref} style={{
        contentVisibility: "auto",
        // @ts-ignore
        containIntrinsicSize: "23px 50px",
        ...props.style
      }}>
        <DSRow ref={hoverRef} style={{
          position: 'relative',
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 8,
          paddingRight: 8,
          ...(!props.prevGrouped) && {
            marginTop: 3
          },
          ...(!props.nextGrouped) && {
            marginBottom: 3
          },
          backgroundColor: (canEdit && isHovering && !isReply) ? DSTheme.ChatMessageHoverTint : undefined,
          transition: "background-color 0.1s ease-in-out",
          flex: 1
        }}>
          <DSRow
            style={{
              ...props.isReply && {
                width: 4,
                borderRadius: 2,
                backgroundColor: DSTheme.SecondaryText,
                marginRight: 12,
              }
            }}
          />

          <DSColumn style={{ alignItems: 'flex-start' }}>
            <DSRow style={{ alignItems: 'center' }}>

              {!props.isReply ?
                <DSRow style={{ alignItems: 'center', width: messsageStatusSize }}>
                  <div
                    style={{
                      backgroundColor: DSTheme.EmphasisColor,
                      height: 8,
                      width: 8,
                      borderRadius: "50%",
                      opacity: (
                        (!props.messageNumRead ||
                          message.messageNum > props.messageNumRead)
                        && message.userId != OTUITree.auth.userId
                      ) ?
                        1 : 0,
                      transition: "opacity 5s ease-in",
                    }}
                  />
                </DSRow>
                :
                null
              }
              {
                props.prevGrouped ?
                  <DSRow style={{ width: userIconSize }} />
                  :
                  <UserIcon user={user} size={userIconSize} />
              }
            </DSRow>
          </DSColumn>
          <DSColumn style={{ flex: 1, paddingLeft: 8, paddingRight: 5 }}>
            {
              props.prevGrouped && !message.isSystem ?
                null
                :
                <DSRow style={{ paddingBottom: 5, paddingLeft: 0, }}>
                  <DSH4 style={{ color: DSTheme.MainText }}>
                    {user.name}
                    <DSSmallPrint style={{ color: DSTheme.SecondaryText, paddingLeft: 4 }}>
                      {getFriendlyDate(message.crDate)}
                    </DSSmallPrint>
                    {
                      message.isSystem ?
                        <DSPrint style={{ color: DSTheme.SystemMessageColor, marginLeft: 4 }}>{message.systemMessage || "(System Message)"}</DSPrint>
                        :
                        null
                    }
                  </DSH4>

                </DSRow>
            }

            {
              editing ?
                <DSRow>
                  <DSColumn style={{
                    flex: 1,
                    backgroundColor: DSTheme.ChatComposeBackground,
                    borderRadius: DSTheme.BaseBorderRadius,
                    maxWidth: `calc(100vw - ${200}px )`
                  }}>
                    <MarkdownEditor
                      initialContent={messageDraft}
                      onChange={(value) => {
                        setMessageDraft(value);
                      }}
                      onSubmit={saveEdit}
                      placeholder='Start typing...'
                    />
                  </DSColumn>
                  <DSRow style={{ alignItems: 'flex-start', marginTop: 4 }}>
                    <DSButton onClick={saveEdit} style={{ marginLeft: 4, backgroundColor: DSTheme.SuccessColor, width: 28, height: 24, borderRadius: 4 }}>
                      <FaCheck style={{ color: 'white' }} />
                    </DSButton>
                    <DSButton onClick={() => setEditing(false)} style={{ marginLeft: 4, backgroundColor: DSTheme.CancelColor, width: 28, height: 24, borderRadius: 4 }}>
                      <FaTimes style={{ color: 'white' }} />
                    </DSButton>
                  </DSRow>
                </DSRow>
                :
                <ChatMessageBody
                  key={message.messageId}
                  spaceId={props.spaceId}
                  message={message}
                  isReply={props.isReply}
                  updateResource={props.updateResource}
                  pendingMessage={props.pendingMessage}
                  saveLinkToChat={props.saveLinkToChat}
                  getUser={props.getUser}
                />
            }
          </DSColumn>
          {
            isHovering && !editing && !props.isReply && canEdit ?
              <DSRow style={{ position: 'absolute', right: 2, top: 0, alignItems: 'center', }}>
                <DSCircleIconButton
                  icon={<FaReply size={12} style={{ color: 'white' }} />}
                  title="Reply"
                  analyticsEvent="replyChatMessage"
                  onClick={() => {
                    props.setReply?.(message);
                    props.focusInput?.()
                  }}
                  style={{ marginLeft: 2, padding: 6 }}
                />
                {
                  myMessage && !message.isSystem ?
                    <>
                      <DSCircleIconButton
                        icon={<FaPen size={12} style={{ color: 'white' }} />}
                        title="Edit"
                        analyticsEvent="editChatMessage"
                        onClick={() => setEditing(true)}
                        style={{ marginLeft: 2, padding: 6 }}
                      />

                      <DSExpandButton
                        icon={<FaTrash size={11} style={{ color: 'white' }} />}
                        title="Delete"
                        titleWidth={40}
                        fontSize={12}
                        analyticsEvent="deleteChatMessage"
                        onClick={deleteMessage}
                        style={{ marginLeft: 2, padding: 6 }}
                        expandedStyle={{ backgroundColor: DSTheme.DangerColor }}
                      />
                    </>
                    :
                    null
                }
              </DSRow>
              :
              null
          }

        </DSRow>

        {
          props.isLastRead ?
            <DSRow style={{ alignItems: 'center' }}>
              <DSRow style={{ height: 1, flex: 1, backgroundColor: DSTheme.SecondaryText, marginLeft: 24, marginRight: 24 }} />
              <DSBody style={{ color: DSTheme.SecondaryText }}>New messages</DSBody>
              <DSRow style={{ height: 1, flex: 1, backgroundColor: DSTheme.SecondaryText, marginLeft: 24, marginRight: 24 }} />
            </DSRow>
            :
            null
        }
      </DSColumn>
    )
  })
);
