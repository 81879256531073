import React, { Component } from 'react';
import { DSButton } from '../DesignSystem/DSButton';
import { DSPanel, DSRow } from "../DesignSystem/DSLayout"
import { DSH3, DSH4, DSLabel } from '../DesignSystem/DSText';
import { DSTheme } from '../DesignSystem/DSTheme';

interface Props {
  template: any
}
export const Menu = (props: Props) => {
  return (
    <DSPanel
      style={{
        backgroundColor: DSTheme.PanelBackgroundColor,
        color: DSTheme.PanelContrastColor,
        minWidth: 250,
        borderRadius: 8,
        padding: 16,
        margin: 4,
        boxShadow: `0px 0px 8px 1px #111`,
      }}>
      {
        props.template.map(item => (
          !(item.enabled ?? true) ?
            <MenuTitle title={item.label} />
            :
            item.type == "separator" ?
              <MenuSeparator />
              :
              <MenuButton
                title={item.label}
                onClick={item.click}
                type={item.type}
                checked={item.checked}
              />
        ))
      }

    </DSPanel>
  )
}

interface IButtonProps {
  title: string
  onClick?: () => void
  type: "radio" | "checkbox"
  checked: boolean
}

export const MenuButton = (props: IButtonProps) => {
  return (
    <DSButton
      onClick={props.onClick}
      style={{
        padding: 4,
      }}>

      <DSRow style={{ alignItems: 'center' }}>
        {
          props.type == 'radio' || props.type == 'checkbox' ?
            <div
              style={{
                width: 14,
                height: 14,
                borderRadius: 7,
                backgroundColor: DSTheme.EmphasisBackgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {
                props.checked ?
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: DSTheme.EmphasisColor,
                      opacity: 1,
                    }}
                  />
                  :
                  null
              }

            </div>
            :
            undefined
        }
        <DSH4 style={{ marginLeft: 6, }}>
          {props.title}
        </DSH4>
      </DSRow>
    </DSButton>
  )
}

interface ITitleProps {
  title: string
}

export const MenuTitle = (props: ITitleProps) => {
  return (
    <DSH3
      style={{
        margin: 4,
      }}>
      {props.title}
    </DSH3>)
}

export const MenuSeparator = () => {
  return (
    <DSPanel
      style={{
        height: 2,
        margin: 8,
        width: "100%",
        backgroundColor: DSTheme.PanelContrastColor
      }}>

    </DSPanel>
  )
}