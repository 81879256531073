import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import * as uuid from "uuid";
import { throttle } from "throttle-debounce";
import { Firestore } from "firebase/firestore";
import { ChatDb } from "../fire";
import {
  IMessageFile,
  IOTChatMessage,
  IPendingMessage,
  TChatType,
  INotification,
  IChannel,
  IChannelUser,
  IFile,
  IChannels,
  IChatMessage,
  IPinnedResource,
  ILinkPreview,
  IFileAttachment,
  TChatSystemType,
  ITeamRoomConfig,
} from "@openteam/models";
import { Logger, AwaitLock } from "@openteam/app-util";
import { CloudUpload } from "../CloudUpload";
import { OTUITree } from "../OTUITree";
import { OTGlobals } from "../OTGlobals";
import { ChatMessageManager } from "./ChatMessageManager";
import { OTUserInterface } from "../OTUserInterface";
import { writeChannel, writeChannelDirectory, writeChannelDraft } from "../UIDataState";
import removeMD from "remove-markdown";

import { deleteChannel, md5hash } from "..";
import { getLinkPreview, URLPreview } from "./LinkPreviewManager";
import { isSafari } from "react-device-detect";

const logger = new Logger("ChatManager");


export const createResourceFromAttachment = (
  channelId: string,
  messageId: string,
  fileId: string,
  file: IFile | File | IFileAttachment,
  url: string,
  createdBy?: string,
  createdAt?: number,
): IPinnedResource => (
  {
    objectID: md5hash(`${channelId}-${url}`),
    msgType: 'CHAT',
    id: messageId,
    recordType: "attachment",
    fileCategory: "file",
    originalName: file.name,
    fileId: fileId,
    name: file.name,
    type: file.type,
    size: file.size,
    url: url,
    isResource: true,
    isPinned: undefined,

    createdAt: createdAt || Date.now(),
    createdBy
  }
);

export const createResourceFromLink = (
  channelId: string,
  messageId: string,
  linkId: string | undefined,
  link: ILinkPreview,
  createdBy?: string,
  createdAt?: number,
): IPinnedResource => (
  {
    objectID: md5hash(`${channelId}-${link.url}`),
    msgType: 'CHAT',
    id: messageId,
    recordType: "link",
    fileCategory: "link",
    linkId: linkId,

    name: link.title,
    description: link.description,
    image: link.image,
    url: link.url,
    favicon: link.favicon,
    type: link.contentType,

    ...(link.favicons ? { favicons: link.favicons } : undefined),
    originalName: link.title,

    isResource: true,
    isPinned: undefined,

    createdAt: createdAt || Date.now(),
    createdBy,
  }
);

export class ChatManager {
  teamId: string;
  userId: string;
  fbDb: Firestore;

  isFirstChatInfo: boolean = true;

  _createLock = new AwaitLock();
  _messageLock = new AwaitLock();

  @observable channelDirectory: IChannels = {};

  @observable focusedChannelTopic?: { channelId: string; topicId: string };
  @observable draftMessages: Record<string, Record<string, string>> = {};
  @observable draftReplyMessage: Record<string, Record<string, IOTChatMessage>> = {};
  @observable draftFiles: Record<string, Record<string, IMessageFile[]>> = {};
  @observable channels: Record<string, IChannel> = {};
  @observable userChannels: Record<string, IChannelUser> = {};
  @observable messageManager: Record<string, Record<string, ChatMessageManager>> = {};
  @observable pendingMessages: Record<string, Record<string, Record<string, IPendingMessage>>> = {};
  @observable deletedResources: Record<string, string[]> = {};

  _autorun: Record<string, any> = {};

  unwatchChannelDirectory?: () => void;
  unwatchUserChannelList?: () => void;
  unwatchJoinedChannels?: () => void;
  _startTimeout?: ReturnType<typeof setTimeout>
  _notificationTimeout?: ReturnType<typeof setTimeout>

  constructor(fbDb: Firestore, teamId: string, userId: string) {
    makeObservable(this)
    this.teamId = teamId;
    this.userId = userId;
    this.fbDb = fbDb;

    logger.info(`initialised with team_id=${this.teamId}`);

    OTUITree.registerChatManager(this);
  }

  start = async () => {
    if (this._startTimeout) {
      clearTimeout(this._startTimeout)
    }

    const hasAccess = await ChatDb.checkAccess(
      this.fbDb,
      this.teamId,
    )
    if (hasAccess) {
      logger.debug("has access, start chatmanager")
      this._start()
    } else {
      logger.debug("no access, try again in 5 secs")

      this._startTimeout = setTimeout(this.start, 5000)
    }

  }

  _start = () => {
    logger.debug(`start: ${this.teamId}`);

    this.unwatchChannelDirectory = ChatDb.watchChannelDirectory(
      this.fbDb,
      this.teamId,
      this.userId,
      this.handleChannelDirectory
    );


    const userChannelsDoc = OTGlobals.cache.getTeamCache(
      this.userId,
      this.teamId,
      "chat",
      "userchannels"
    );

    if (userChannelsDoc) {
      this.handleUserChannelList(userChannelsDoc, [], [], true);
    }

    this.unwatchUserChannelList = ChatDb.watchUserChannelList(
      this.fbDb,
      this.teamId,
      this.userId,
      this.handleUserChannelList
    );

    const watchJoinedChannelsDoc = OTGlobals.cache.getTeamCache(
      this.userId,
      this.teamId,
      "chat",
      "channels"
    );

    if (watchJoinedChannelsDoc) {
      this.handleJoinedChannels(watchJoinedChannelsDoc, [], [], true);
    }

    this.unwatchJoinedChannels = ChatDb.watchJoinedChannels(
      this.fbDb,
      this.teamId,
      this.userId,
      this.handleJoinedChannels
    );

    this.loadFromAsyncStorage();

    this._autorun["updateUnreadChats"] = autorun(() => {
      const teamData = OTGlobals.getUnsafeTeamData?.(this.teamId);

      let unreadChats = 0;

      for (const channelId in this.userChannels) {
        for (const topicId in this.channels[channelId]?.topics || {}) {
          if (
            (this.userChannels[channelId]?.topics?.[topicId]?.messageNum || 0) <
            (this.channels[channelId]?.topics?.[topicId]?.messageNum || 0)
          ) {
            unreadChats += 1;
          }
        }
      }

      if (teamData) {
        teamData.unreadChats = unreadChats;
      }
    });

    this._autorun["saveChatSettings"] = autorun(
      () => {
        localStorage.setItem(
          `chatSettings-${this.teamId}`,
          JSON.stringify({
            // focusedChannelTopic: this.focusedChannelTopic,
            draftMessages: this.draftMessages,
          })
        );
      },
      { delay: 1000 }
    );
  };

  stop = () => {
    logger.debug("stopping");

    if (this._startTimeout) {
      clearTimeout(this._startTimeout)
    }

    Object.values(this._autorun).map((x) => x());
    this._autorun = {};

    this.unwatchChannelDirectory && this.unwatchChannelDirectory();
    this.unwatchUserChannelList && this.unwatchUserChannelList();
    this.unwatchJoinedChannels && this.unwatchJoinedChannels();
  };

  handleUserChannelList = action((
    added: IChannelUser[],
    edited: IChannelUser[],
    deleted: string[],
    isCached: boolean = false
  ) => {
    for (let channelId of deleted) {
      this.closeChannel(channelId);
      delete this.userChannels[channelId];
      writeChannel(this.teamId, channelId);
    }

    for (let channelUser of added) {
      this.userChannels[channelUser.channelId] = channelUser;
      writeChannel(this.teamId, channelUser.channelId);
    }

    for (let channelUser of edited) {
      this.userChannels[channelUser.channelId] = channelUser;
      writeChannel(this.teamId, channelUser.channelId);
    }

    if (this.userChannels && !isCached) {
      OTGlobals.cache.setTeamCache(
        this.userId,
        this.teamId,
        "chat",
        "userchannels",
        Object.values(this.userChannels)
      );
    }
  });

  handleChannelDirectory = action(async (
    added: IChannel[],
    edited: IChannel[],
    deleted: string[],
    isCached: boolean = false
  ) => {

    for (let channel of added) {
      this.channelDirectory[channel.id] = channel
    }

    for (let channel of edited) {
      this.channelDirectory[channel.id] = channel
    }

    for (let channelId of deleted) {
      delete this.channelDirectory[channelId];
    }
    writeChannelDirectory(this)

  });

  handleJoinedChannels = action(async (
    added: IChannel[],
    edited: IChannel[],
    deleted: string[],
    isCached: boolean = false
  ) => {
    const allowNotify = !isCached && !this.isFirstChatInfo;

    for (let channel of added) {
      await this.handleChannel(channel.id, channel, allowNotify);
    }

    for (let channel of edited) {
      await this.handleChannel(channel.id, channel, allowNotify);
    }

    for (let channelId of deleted) {
      this.closeChannel(channelId)
      delete this.channels[channelId];
      deleteChannel(this.teamId, channelId)
    }

    if (this.channels && !isCached) {
      OTGlobals.cache.setTeamCache(
        this.userId,
        this.teamId,
        "chat",
        "channels",
        Object.values(this.channels)
      );
    }

    if (!isCached && this.isFirstChatInfo) {
      this.isFirstChatInfo = false;
    }
  });

  handleChannel = action(async (channelId: string, doc: IChannel, allowNotify: boolean) => {
    const userChannel = this.getUserChannel(channelId);
    const prevDoc = this.getChannel(channelId);

    if (userChannel && prevDoc) {
      for (let topicId in doc.topics || {}) {
        const userTopic = userChannel.topics?.[topicId];
        const prevTopic = prevDoc.topics?.[topicId];
        const topic = doc.topics?.[topicId];

        if (topic?.messageNum && prevTopic?.messageNum != topic?.messageNum) {
          if (allowNotify && (!userTopic?.muteNotify || topic.lastMessage?.mentions?.includes(this.userId))) {
            if (topic?.lastMessage) {
              this.notify(topic.lastMessage);
            }
          }
          if (!userChannel.bookmarked) {
            logger.info("going to bookmark prevDoc", prevDoc, "doc", doc);
            ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, true);
          }
        }
      }
    }

    this.channels[channelId] = doc;

    if (!this.deletedResources[channelId])
      this.deletedResources[channelId] = [];

    writeChannel(this.teamId, channelId)
  });

  notify = (message: IChatMessage) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const channelId = message.channelId;
    const topicId = message.topicId;

    if (!channelId) {
      return;
    }

    const channel = this.getChannel(channelId);
    const topic = channel.topics?.[topicId];
    const isNotMe = message.userId != this.userId;

    const mentioned = message.mentions?.includes(this.userId)

    const isFocused =
      this.focusedChannelTopic?.channelId == channelId &&
      this.focusedChannelTopic?.topicId == topicId;
    // const present =
    //   windowState.windowFocused && OTGlobals.auth.userManager.currentTeamId === this.teamId;
    // logger.info("present=${present} windowState=${windowState}");

    const now = new Date().getTime();

    logger.debug("notify message.crDate", message.crDate);

    let messageEpoch: number = message.crDate?.getTime() || 0;

    const isRecent = now - messageEpoch < 5 * 60 * 1000;

    const silenceNotify = (teamData.inQuiet || teamData.me.customStatus == 'DND')

    if (message && isNotMe && isRecent && !isFocused && !silenceNotify) {
      // new message that I didn't send
      const sendUserDoc = teamData.getTeamUser(message.userId);

      let title = `${sendUserDoc.name} sent you a message`;

      if (channel?.name) {
        title = `${sendUserDoc.name} sent a message to ${channel.name}`;
      }

      if (mentioned) {
        if (channel?.name) {
          title = `${sendUserDoc.name} mentioned you in a message to ${channel.name}`;
        } else {
          title = `${sendUserDoc.name} mentioned you in a message`;
        }
      }

      if (topic?.name && topic?.name != "default") {
        title += ` - ${topic.name}`;
      }

      const onPress = () => {
        OTUserInterface.navigate("Chat", {
          teamId: this.teamId,
          channelId: channelId,
          topicId: topicId,
        });
      };

      const notification: INotification = {
        id: message.channelId,
        teamId: this.teamId,
        title: title,
        text: removeMD(message.message),
        onPress: onPress,
      };

      this.doNotification(notification);
    }
  };

  doNotification = (n: INotification) => {
    const teamData = OTGlobals.getTeamData(this.teamId);

    if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
      OTUserInterface.toastHandlers.show(n.title, "info", n.text, () => {
        n.onPress && n.onPress();
        OTUserInterface.toastHandlers.hide();
      });
    } else {
      if (this._notificationTimeout) {
        clearTimeout(this._notificationTimeout);
        // hide any previous notifcation on this channel
        OTUserInterface.hideNotification(n.id!);
      }

      OTUserInterface.showNotification(n);

      this._notificationTimeout = setTimeout(() => {
        this._notificationTimeout = undefined;
        OTUserInterface.hideNotification(n.id!)
      }, 20000)
    }

    if (!OTGlobals.appHomeManager.inCall) {
      OTUserInterface.soundEffects.newMessage();
    }
  };

  loadFromAsyncStorage = async () => {
    const jsonString = localStorage.getItem(`chatSettings-${this.teamId}`);
    const loadedSettings = jsonString && JSON.parse(jsonString);

    if (loadedSettings) {
      // if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      //   if (
      //     loadedSettings.focusedChannelTopic?.channelId &&
      //     loadedSettings.focusedChannelTopic?.topicId
      //   ) {
      //     this.focusedChannelTopic = loadedSettings.focusedChannelTopic || this.focusedChannelTopic;
      //   }

      //   if (this.focusedChannelTopic) {
      //     const { channelId, topicId } = this.focusedChannelTopic;

      //     if (!this.messageManager[channelId]) {
      //       this.messageManager[channelId] = {};
      //     }

      //     if (!this.messageManager[channelId][topicId]) {
      //       this.messageManager[channelId][topicId] = new ChatMessageManager(
      //         this.fbDb,
      //         this.teamId,
      //         this.userId,
      //         this.focusedChannelTopic.channelId,
      //         this.focusedChannelTopic.topicId,
      //         this.getUserChannel
      //       );
      //     }
      //   }
      // }

      this.draftMessages = loadedSettings.draftMessages || this.draftMessages;

      for (const channelId in this.draftMessages) {
        if (typeof this.draftMessages[channelId] === "string") {
          this.draftMessages[channelId] = {};
        }
      }
    }
  };

  setDraftText = (channelId: string, topicId: string, text: string) => {

    if (!this.draftMessages[channelId]) {
      this.draftMessages[channelId] = {}
    }
    this.draftMessages[channelId][topicId] = text;

    if (text) {
      this.setIsTyping(channelId, topicId, true);
      this.getDraftLinkPreviews(text)
    }

    writeChannelDraft(this.teamId, channelId)
  };

  getLinkPreviews = (text: string) => {
    const urls = this.getTextURLs(text)

    return urls.map(url => getLinkPreview(url))
  }

  getDraftLinkPreviews = throttle(500, true, this.getLinkPreviews)


  getTextURLs = (text: string) => {
    if (isSafari) {
      return []
    } else {
      const urlRegex = "(?<=[^\[])((https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])";
      const matches = [...text.matchAll(RegExp(urlRegex, "gi"))].map(element => element[0]);
      matches.length && logger.debug("getLinkPreviews, matches", matches)
      return matches
    }
  }


  markChatRead(channelId: string, topicId: string, messageId?: number, messageNum?: number) {
    logger.info("marked chat read", channelId);
    const channel = this.channels?.[channelId];
    const userChannel = this.userChannels?.[channelId];

    messageId = messageId || channel.topics?.[topicId].messageId || 0
    messageNum = messageNum || channel.topics?.[topicId].messageNum || 0

    if (channel) {
      ChatDb.markChatRead(
        this.fbDb,
        this.teamId,
        this.userId,
        channelId,
        topicId,
        Math.max(messageNum, userChannel.topics?.[topicId].messageNum || 0),
        Math.max(messageId, userChannel.topics?.[topicId].messageId || 0)
      );
    }
  }

  getChatName = (chat: IChannel) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const users = chat.userIds?.filter((userId) => userId != this.userId) || [];

    return users.map((userId) => teamData.getTeamUser(userId).name).join(", ");
  };

  createChannel = async (
    userIds: string[],
    name: string,
    symbol?: string,
    color?: string,
    desc?: string,
    chatType: TChatType = "channel",
    teamDefault: boolean = false,
    roomConfig?: Partial<ITeamRoomConfig>
  ) => {
    const channelId = await ChatDb.addChannel(
      this.fbDb,
      this.teamId,
      this.userId,
      userIds,
      name,
      symbol,
      color,
      desc,
      chatType,
      teamDefault,
      roomConfig
    );
    logger.info("creating channel", name, channelId);

    return channelId;
  };

  updateChannel = async (channelId: string, userIds: string[], name: string, symbol?: string, color?: string, desc?: string, isPrivate?: boolean, teamDefault?: boolean, roomConfig?: Partial<ITeamRoomConfig>) => {
    logger.info("updating channel", channelId, name, desc, isPrivate, teamDefault);
    await ChatDb.updateChannel(this.fbDb, channelId, this.teamId, userIds, name, symbol, color, desc, isPrivate, teamDefault, roomConfig);

    return channelId;
  };

  removeChannelUser = async (channelId: string, userId: string) => {
    if (userId === this.userId) {
      await this.leaveChannel(channelId)
    } else {
      await ChatDb.removeChannelUser(this.fbDb, channelId, this.teamId, userId);
    }
  };


  joinChannelUser = async (channelId: string, userId: string) => {
    await ChatDb.joinChannel(this.fbDb, this.teamId, userId, channelId);
  };

  joinChannel = async (channelId: string) => {
    await ChatDb.joinChannel(this.fbDb, this.teamId, this.userId, channelId);

    this.goChannel(channelId, "default");
  };

  leaveChannel = async (channelId: string) => {
    this.closeChannel(channelId);
    await ChatDb.leaveChannel(this.fbDb, this.teamId, this.userId, channelId);
  };

  addDirectChannel = async (userIds: string[]) =>
    await ChatDb.addDirectChannel(this.fbDb, this.teamId, this.userId, userIds);

  getUserChannel = (channelId: string) => {
    return this.userChannels && this.userChannels[channelId];
  };

  getChannel = (channelId: string) => {
    return this.channels && this.channels[channelId];
  };

  getChannels = async () => {
    return await ChatDb.getChannels(this.fbDb, this.teamId);
  };

  bookmarkChat = (channelId: string, topicId: string, bookmarked: boolean) => {
    if (
      !bookmarked &&
      channelId == this.focusedChannelTopic?.channelId &&
      (topicId == this.focusedChannelTopic?.topicId || topicId == "default")
    ) {
      this.focusedChannelTopic = undefined;
    }

    ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, bookmarked);
  };

  muteChatNotify = (channelId: string, topicId: string, muted: boolean) => {
    ChatDb.muteChatNotify(this.fbDb, this.teamId, this.userId, channelId, topicId, muted);
  };

  loadChannel = async (channelId) => {
    return await ChatDb.getChannel(this.fbDb, this.teamId, channelId);
  };

  @computed get channelsByUser() {
    const channels = this.channels;

    return Object.keys(channels)
      .filter((channelId) => (channels[channelId].chatType || "chat") == "chat")
      .reduce(function (obj, x) {
        const userIds = channels[x].userIds
        if (userIds) {
          const otherUsers = userIds.filter((userId) => userId != OTUITree.auth.userId);
          if (otherUsers.length == 1) {
            obj[otherUsers[0]] = x;
          }
        }
        return obj;
      }, {});
  }


  getUserChannelId = async (userId: string) => {
    await this._createLock.acquireAsync();
    try {
      logger.debug("getting channel id for", userId)

      let channelId: string | null = null;

      channelId = this.channelsByUser[userId];

      if (!channelId) {
        logger.debug("didn't find channel, creating..", userId)

        channelId = await this.addDirectChannel([userId]);
        logger.debug("created channel..", channelId, userId)

      }

      return channelId;

    } finally {
      this._createLock.release();
    }
  }

  goChannel = (channelId: string, topicId: string, loadAtMessageId?: number) => {
    logger.info("goChannel", channelId, topicId);

    if (
      this.focusedChannelTopic?.channelId != channelId ||
      this.focusedChannelTopic?.topicId != topicId
    ) {
      if (this.focusedChannelTopic) {
        // this.closeChannel(this.focusedchannelId)
      }
      this.focusChannelTopic(channelId, topicId)

      if (!this.messageManager[channelId]) {
        this.messageManager[channelId] = {};
      }
      if (!this.messageManager[channelId][topicId]) {
        this.messageManager[channelId][topicId] = new ChatMessageManager(
          this.fbDb,
          this.teamId,
          this.userId,
          channelId,
          topicId,
          this.getUserChannel,
          loadAtMessageId
        );
      }
    }

    if (!this.userChannels[channelId]?.bookmarked) {
      ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, true);
    }

    const teamData = OTGlobals.getTeamData(this.teamId);

    if (teamData.inVideoChat) {
      const callStateManager = OTGlobals.callStateManager;

      callStateManager?.setFocusRoom(false);
    }
  };

  focusChannelTopic = (channelId: string, topicId: string) => {
    this.focusedChannelTopic = { channelId, topicId };
  }

  unfocusChannelTopic = () => {
    this.focusedChannelTopic = undefined;
  }

  closeChannel = (channelId?: string, topicId?: string) => {
    logger.info("closeChannel", channelId, topicId);

    if (
      channelId &&
      topicId
    ) {

      if (
        this.focusedChannelTopic?.channelId == channelId &&
        this.focusedChannelTopic?.topicId == topicId
      ) {
        this.unfocusChannelTopic()
      }


    }

    if (channelId) {

      if (!topicId) {
        topicId = "default"
      }

      if (this.messageManager[channelId] && this.messageManager[channelId][topicId]) {
        this.messageManager[channelId][topicId].stop();
        delete this.messageManager[channelId][topicId];
      }
    }



  };

  createTopic = async (channelId: string, name: string) => {
    const topicId = await ChatDb.createTopic(this.fbDb, this.teamId, this.userId, channelId, name);
    return topicId;
  };

  editTopic = async (channelId: string, topicId: string, name: string) => {
    await ChatDb.editTopic(this.fbDb, this.teamId, this.userId, channelId, topicId, name);
  };

  archiveTopic = async (channelId: string, topicId: string, archive: boolean) => {
    await ChatDb.archiveTopic(this.fbDb, this.teamId, this.userId, channelId, topicId, archive);

    if (archive) {
      this.goChannel(channelId, "default");
    }
  };

  addDraftMessageFile = (channelId: string, topicId: string, messageFile: IMessageFile) => {
    if (!this.draftFiles[channelId]) {
      this.draftFiles[channelId] = {};
    }

    if (!this.draftFiles[channelId][topicId]) {
      this.draftFiles[channelId][topicId] = []
    }

    this.draftFiles[channelId][topicId].push(messageFile)

    writeChannelDraft(this.teamId, channelId)
  }

  removeDraftMessageFile = (channelId: string, topicId: string, index) => {
    var newDraftFiles = [...this.draftFiles[channelId][topicId]];
    var uploadFiles = newDraftFiles.splice(index, 1);

    uploadFiles.forEach((uf) => uf.stop());

    this.draftFiles[channelId][topicId] = newDraftFiles;

    writeChannelDraft(this.teamId, channelId)
  }

  addDraftFiles = (channelId: string, topicId: string, files: FileList | File[] | IFile[] | null) => {
    if (!files) {
      return;
    }


    Object.keys(files || {}).forEach((i) => {
      let file = files[i];

      this.addDraftMessageFile(channelId, topicId, new CloudUpload(this.teamId, undefined, this.userId, "chat", file))

    });

  };

  setDraftFiles = (channelId, topicId, draftFiles) => {
    if (!this.draftFiles[channelId]) {
      this.draftFiles[channelId] = {};
    }

    this.draftFiles[channelId][topicId] = draftFiles;
  };

  setDraftReplyMessage = (channelId: string, topicId: string, message?: IOTChatMessage) => {
    if (!this.draftReplyMessage[channelId]) {
      this.draftReplyMessage[channelId] = {};
    }

    if (!message) {
      delete this.draftReplyMessage[channelId]?.[topicId];
    } else {
      this.draftReplyMessage[channelId][topicId] = message;
    }

    writeChannelDraft(this.teamId, channelId)
  };

  deleteDraftReplyMessage = (channelId: string, topicId: string) => {
    delete this.draftReplyMessage[channelId]?.[topicId];

    writeChannelDraft(this.teamId, channelId)
  };

  resetDraft = (channelId: string, topicId: string) => {
    delete this.draftReplyMessage[channelId]?.[topicId];
    this.setDraftFiles(channelId, topicId, [])
    this.setDraftText(channelId, topicId, '')


    writeChannelDraft(this.teamId, channelId)
  };

  sendURL = async (channelId: string, topicId: string, text: string, systemMessage: string) => {
    var urlRegex = /((https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var matches = [...text.matchAll(urlRegex)];
    const urls: string[] = [];

    for (const element of matches) {
      urls.push(element[0]);

      if (urls.length)
        await this.sendChatMessage(
          channelId,
          topicId,
          urls.join(' '),
          [],
          undefined,
          true,
          "RESOURCE",
          systemMessage
        );
    };
  }


  sendChatMessage = async (
    channelId: string,
    topicId: string,
    text: string,
    files: IMessageFile[]=[],
    replyMessage?: IOTChatMessage,
    isSystem?: boolean,
    systemType?: TChatSystemType | null,
    systemMessage?: string | null
  ) => {

    if (!this.pendingMessages[channelId]) {
      this.pendingMessages[channelId] = {};
    }

    if (!this.pendingMessages[channelId][topicId]) {
      this.pendingMessages[channelId][topicId] = {};
    }

    const urlPreviews = this.getLinkPreviews(text)

    const linkPreviews: Record<string, ILinkPreview> = {}
    const loadingPreviews: URLPreview[] = []
    let firstPreview: ILinkPreview | undefined = undefined

    for (let preview of urlPreviews) {
      if (preview.loaded && preview.preview) {
        linkPreviews[md5hash(preview.url)] = preview.preview
        if (!firstPreview) {
          firstPreview = preview.preview
        }
      } else {
        loadingPreviews.push(preview)
      }
    }

    for (var i = 0; i < files.length; i++)
      files[i].index = i;

    const filesUploaded = files.every(file => file.completed)

    logger.debug("filesUploaded", filesUploaded, "files", files)
    const attachments: Record<string, IFileAttachment> = {}

    for (const file of files) {

      attachments[file.id] = {
        name: file.file.name,
        type: file.file.type,
        size: file.file.size,
        uploaded: file.completed,
        url: file.downloadUrl || null,
        progress: file.progress,
        isResource: true,
        order: file.index!,
      }
    }

    const msg = await ChatDb.addChatMessage(
      this.fbDb,
      this.teamId,
      this.userId,
      channelId,
      topicId,
      text,
      attachments,
      replyMessage,
      firstPreview,
      linkPreviews,
      !!isSystem,
      systemType || null,
      systemMessage || null,
    );


    if (!filesUploaded) {
      const attachmentFiles: Record<string, IMessageFile> = {}

      for (const file of files) {
        attachmentFiles[file.id] = file
        this.updatePendingFile(channelId, topicId, msg.id, file)
      }

      const message: IPendingMessage = {
        text,
        attachmentFiles: attachmentFiles,
      };
      this.pendingMessages[channelId][topicId][msg.id] = message;
    }

    writeChannelDraft(this.teamId, channelId)


    const resources = [
      ...Object.entries(linkPreviews).map(([k, p]) => createResourceFromLink(channelId, msg.id, k, p)),
    ]
    await this.updateRecentResources(channelId, resources);

    if (loadingPreviews.length) {
      this.updateLinkPreviews(channelId, topicId, msg.id, loadingPreviews, !firstPreview)
    }

    if (files) {
      try {
        if (!filesUploaded) {
          await Promise.all(files.map((cu) => cu.complete()));
          logger.debug("all files uploaded saving")

          const uploadedFiles = files.filter(file => file.completed)

          delete this.pendingMessages[channelId!][topicId][msg.id];
          writeChannelDraft(this.teamId, channelId)
        }
        const resources = [
          ...files.map(f => createResourceFromAttachment(channelId, msg.id, f.id, f.file, f.downloadUrl!)),
        ]
        await this.updateRecentResources(channelId, resources);

        logger.debug("updateRecentResources resources", resources)

      } catch {
        logger.error("failed uploading all files")
      }
    }
    return msg;
  };

  updatePendingFile = async (channelId: string, topicId: string, id: string, file: IMessageFile) => {
    logger.debug("updatePendingFile: ", file.on, file.progress);
    file.on?.("progress", throttle(2000, async () => {
      logger.debug("updatePendingFile throttled: ", file.progress);

      await ChatDb.updateChatMessagePendingFile(
        this.fbDb,
        this.teamId,
        channelId,
        topicId,
        id,
        file
      );
    }))

    try {
      await file.complete()
    }
    catch {
      logger.error("failed uploading all files")

    }

    await ChatDb.updateChatMessagePendingFile(
      this.fbDb,
      this.teamId,
      channelId,
      topicId,
      id,
      file
    );

  }

  updateLinkPreviews = async (channelId: string, topicId: string, id: string, loadingPreviews: URLPreview[], addLinkPreview: boolean) => {
    logger.debug("got more link previews to get", loadingPreviews)
    await Promise.all(loadingPreviews.map((cu) => cu.loader));

    const linkPreviews: Record<string, ILinkPreview> = {}
    let firstPreview: ILinkPreview | undefined = undefined

    for (let preview of loadingPreviews) {
      if (preview.loaded && preview.preview) {
        linkPreviews["linkPreviews." + md5hash(preview.url)] = preview.preview
        if (addLinkPreview && !firstPreview) {
          firstPreview = preview.preview
          linkPreviews["linkPreview"] = preview.preview
        }
      }
    }
    logger.debug("saving them", linkPreviews)

    await ChatDb.updateChatMessageLinkPreviews(
      this.fbDb,
      this.teamId,
      channelId,
      topicId,
      id,
      linkPreviews
    );

    const resources = [
      ...Object.entries(linkPreviews).map(([k, p]) => createResourceFromLink(channelId, id, k, p)),
    ]
    await this.updateRecentResources(channelId, resources);
  }

  editChatMessage = async (channelId: string, topicId: string, messageId: string, text) => {
    await ChatDb.editChatMessage(this.fbDb, this.teamId, channelId, topicId, messageId, text);
  };

  deleteChatMessage = async (channelId: string, topicId: string, messageId: string) => {
    await ChatDb.deleteChatMessage(this.fbDb, this.teamId, channelId, topicId, messageId);
  };

  setIsTyping = throttle(1000, true, async (channelId: string, topicId: string, isTyping: boolean) => {
    await this._messageLock.acquireAsync();

    try {
      logger.debug("setIsTyping", this.teamId, channelId, topicId, isTyping);
      await ChatDb.setIsTyping(this.fbDb, this.teamId, this.userId, channelId, topicId, isTyping);
    } catch (e) {
      logger.error("failed to set IsTyping", e);
    } finally {
      this._messageLock.release();
    }
  })

  updateResource = async (
    channelId: string,
    topicId: string,
    resource: IPinnedResource,
    isPinned?: boolean,
    isResource?: boolean,
    name?: string
  ) => {
    logger.debug("updateResource", this.teamId, channelId, topicId, resource);

    const updatedResource = {
      ...resource,
      isPinned: isPinned !== undefined ? isPinned : resource.isPinned,
      isResource: isResource !== undefined ? isResource : resource.isResource,
      name: name !== undefined ? name : resource.name,
      originalName: resource.originalName === undefined ? resource.name : resource.originalName,
    }

    await this.updatePinnedResource(channelId, updatedResource);
    await this.updateRecentResources(channelId, [updatedResource]);

    this.deletedResources[channelId] = this.deletedResources[channelId].filter(x => x !== resource.objectID);

    if (isResource === false)
      this.deletedResources[channelId].push(resource.objectID);

    await ChatDb.updateResource(
      this.fbDb,
      this.teamId,
      channelId,
      topicId,
      resource.id,
      resource.recordType,
      {
        isResource: isResource,
        isPinned: isPinned,
        name: name,
      },
      resource.url,
      resource.linkId,
      resource.fileId,
    );
  };

  updateRecentResources = async (channelId: string, resources: IPinnedResource[]) => {
    const channel = this.channels[channelId];
    const recentResources = channel.recentResources || {};

    resources.map(resource => {
      if (!resource.isResource) {
        if (recentResources[resource.objectID])
          delete recentResources[resource.objectID];
      } else {
        recentResources[resource.objectID] = {
          ...resource,
          createdAt: new Date().getTime(),
        }
      }
    })

    // expire old recent additions..
    const newRecents = Object.fromEntries(
      Object.values(recentResources)
        .filter(r => r.createdAt > (new Date().getTime() - (60 * 1000))) // 60 seconds
        .map(r => [r.objectID, r])
    );

    return ChatDb.saveRecentResources(this.fbDb, this.teamId, channelId, newRecents);
  }

  updatePinnedResource = async (channelId: string, resource: IPinnedResource) => {
    const channel = this.channels[channelId];
    const pinnedResources = channel.pinnedResources || [];

    const filteredPinnedResources = pinnedResources.filter((r) => r.objectID !== resource.objectID);
    if (resource.isPinned) {
      filteredPinnedResources.unshift(resource);
    }

    return ChatDb.savePinnedResources(this.fbDb, this.teamId, channelId, filteredPinnedResources);
  }

  startChannelRoom = async (
    channelId: string,
    topicId: string,
    startRoomCallback: (roomId: string | undefined, roomConfig: ITeamRoomConfig) => Promise<string | undefined>
  ) => {
    logger.debug("startRoom", this.teamId, channelId, topicId)

    await ChatDb.startRoom(
      this.fbDb,
      this.teamId,
      channelId,
      topicId,
      startRoomCallback
    )
  }

  archiveChannel = async (
    channelId: string
  ) => {
    await this.closeChannel(channelId);

    return ChatDb.archiveChannel(
      this.fbDb,
      this.teamId,
      channelId
    )
  }
}
