import events from "events";
import { makeObservable, observable } from "mobx";
import * as uuid from "uuid";

import { IMessageFile } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "./OTGlobals";
import { getStorage, UploadTask, ref, uploadBytesResumable, TaskState, getDownloadURL, deleteObject} from "firebase/storage";

const logger = new Logger("CloudUpload");

export class CloudUpload extends events.EventEmitter implements IMessageFile {
  id: string;
  teamId: string;
  roomId: string | undefined;
  userId: string;
  imageKind: string;
  index: number | undefined;

  file: File;

  @observable progress = 0;
  @observable completed = false;
  @observable failed = false;
  @observable error?: string;
  @observable downloadUrl?: string;

  uploadTask?: UploadTask;

  constructor(
    teamId: string,
    roomId: string | undefined,
    userId: string,
    imageKind: string,
    file: File
  ) {
    super();

    makeObservable(this)

    this.file = file;
    this.teamId = teamId;
    this.roomId = roomId;
    this.userId = userId;
    this.imageKind = imageKind;

    logger.info("created CloudUpload for ", file);

    this.id = uuid.v1();
    this.uploadFile();
  }

  getPreviewURL = () => {
    return URL.createObjectURL(this.file);
  };

  getStorageRef = () => {
    const bucket = OTGlobals.config.TEAM_STORAGE_BUCKET || "gs://openteamfiles";
    const storageRef = getStorage(undefined, bucket) //firebase.app().storage(bucket).ref();
    const path = `/team/${this.teamId}/${this.imageKind}/${this.userId}/${this.id}/${this.file.name}`

    logger.info("storage path", path)
    return ref(storageRef, path);
  };

  uploadFile = async () => {
    logger.info("uploading ", this.file);

    const fileRef = this.getStorageRef();

    const { roomId, teamId } = this;

    var metadata = {
      customMetadata: {
        teamId,
        ...(roomId && { roomId }),
      },
    };

    var blob = new Blob([this.file], { type:this.file.type });

    this.uploadTask = uploadBytesResumable(fileRef, blob);

    this.uploadTask.on(
      "state_changed",
      (snapshot) => {
        const newProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        if (this.progress != newProgress) {
          this.progress = newProgress;
          if (newProgress % 5 == 0) {
            this.emit("progress", newProgress)
          }
        }
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            break;
          case 'running': // or 'running'
            break;
        }
      },
      (error) => {
        logger.error("file upload failed", this.file.name, error);
        this.error = error.name;
        this.failed = true
      },
      async () => {
        await this.loadDownloadURL();
        this.completed = true;
        logger.info("File available at", this.downloadUrl);
      }
    );
  };

  loadDownloadURL = async () => {
    this.downloadUrl = await getDownloadURL(this.uploadTask!.snapshot.ref);
  };

  complete = async () => {
    try {
      await this.uploadTask;
      await this.loadDownloadURL();
      this.completed = true;
    } catch {
      logger.error("failed to upload file", this.file)
    }

  };

  stop = () => {
    this.uploadTask && this.uploadTask.cancel();
    this.completed && deleteObject(this.getStorageRef());
    this.emit("stop")

  };
}

export default CloudUpload;
