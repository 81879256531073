import React, { useState } from "react";
import { IFile, ISpaceFile, KSpaceChannelId, KSpaceId } from '@openteam/models';
import { DataState } from "../../Data/DataState";
import { DSBody, DSH3, DSH5, DSPrint } from "../../DesignSystem/DSText";
import { DSColumn, DSPanel, DSRow } from "../../DesignSystem/DSLayout";
import { DSButton, DSCircleIconButton } from "../../DesignSystem/DSButton";
import { FaCheckCircle, FaChevronDown, FaChevronUp, FaFile, FaThumbtack } from "react-icons/fa";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSImage } from "../../DesignSystem/DSImage";
import { MimeTypeIcon } from "../../Util/MimeTypeIcon";
import { DSCancelRequest, DSSave } from "../../DesignSystem/DSIconButtons";
import { DSProgressBar } from "../../DesignSystem/DSProgressBar";
import { useHoverIntent } from "../../Util/HoverIntent";

type Props = {
  file: {
    name: string,
    type: string,
    size: number
  }
  url?: string
  previewUrl?: string
  progress?: number
  uploading?: boolean
  onRemove?: () => void

  size?: number

  resourceParams?: {
    isResource?: boolean
    isPinned?: boolean
    savedToChat?: boolean
  }
}

export const ChatFile: React.FC<Props> = (props) => {

  const {
    file,
    resourceParams
  } = props;
  const [collapsed, setCollapsed] = useState(false);

  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
    timeout: 0,
    minVelocity: 1000,
  });

  const openFile = () => {
    window.open(props.url,'_blank');
  }

  const mediaSize = props.size || (props.uploading ? 50 : 150)

  return (
    <DSPanel
      ref={hoverRef}
      data-tooltip={file.name}
      style={{
        position: 'relative',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 8,
      }}>
      {
        file.type.includes("image") && props.previewUrl ?
          <>

            {
              mediaSize > 100 ?
                <DSRow style={{ alignItems: 'center', overflow: "hidden", marginBottom: 5, maxWidth: mediaSize }}>
                  <DSH5 style={{ color: DSTheme.SecondaryText, overflow: "hidden", textOverflow: 'ellipsis', }}>
                    {file.name}
                  </DSH5>
                  <DSButton onClick={() => setCollapsed(!collapsed)}
                    style={{
                      ...{ WebkitAppRegion: undefined } as any,
                    }}>
                    {
                      collapsed ?
                        <FaChevronUp size={12} style={{ paddingLeft: 5, color: DSTheme.SecondaryText }} />
                        :
                        <FaChevronDown size={12} style={{ paddingLeft: 5, color: DSTheme.SecondaryText }} />
                    }
                  </DSButton>
                </DSRow>
                :
                undefined
            }
            {
              collapsed ?
                null
                :
                <DSButton href={props.url} onClick={openFile}
                  style={{
                    ...{ WebkitAppRegion: undefined } as any,
                  }}>
                  <DSImage
                    key={`${file.name}`}
                    url={props.previewUrl}
                    maxHeight={mediaSize}
                    maxWidth={mediaSize * 2}
                    reqHeight={mediaSize}
                    style={{
                    }}
                    dummyColor="transparent"
                  />
                </DSButton>
            }
          </>
          :
          <>

            <DSButton href={props.url} onClick={openFile}
              style={{
                ...{ WebkitAppRegion: undefined } as any,
              }}
            >
              <DSRow
                key={`${file.name}`}
                style={{
                  borderRadius: DSTheme.BaseBorderRadius,
                  backgroundColor: DSTheme.BoxDarkBackground,
                  alignItems: 'center',

                  padding: "0 10",
                  height: 50,
                  minWidth: 50,
                  maxWidth: 150,
                  overflow: 'hidden'
                }}
              >
                <MimeTypeIcon mimeType={file.type} size={30} style={{ color: 'rgba(255,255,255,0.5)', paddingRight: 10, minWidth: 30 }} />
                <DSColumn style={{ flex: 1, overflow: 'hidden' }}>
                  <DSH5 style={{ overflow: "hidden", textOverflow: 'ellipsis', }}>
                    {file.name}
                  </DSH5>
                  <DSH5 style={{ overflow: "hidden", textOverflow: 'ellipsis', }}>
                    {Math.floor(file.size / 10000) / 100} MB
                  </DSH5>
                </DSColumn>
              </DSRow>
            </DSButton>

          </>
      }

      {
        props.onRemove ?
          <DSCancelRequest
            size={10}
            analyticsEvent="cancelChatFileUpload"
            onClick={props.onRemove}
            outerStyle={{ position: 'absolute', right: -3, top: -3 }}
            style={{
              backgroundColor: DSTheme.DangerColor,
              width: undefined,
              padding: 4,
            }}
          />
          :
          null
      }

      {
        props.uploading ?
          <DSPanel style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
            <DSProgressBar progress={props.progress || 0} height={8} />
          </DSPanel>
          :
          undefined
      }
    </DSPanel>
  )
}
