import { makeObservable, observable } from "mobx";
import { FireDb } from "../fire";
import { Logger } from "@openteam/app-util";
import { ITeamExternalAddress, ITeamExternalChannel, ITeamExternalRoom, ITeamExternalUser, ITeamRoomConfig, TShowMeetingModal } from "@openteam/models";
import { OTGlobals } from "../OTGlobals";
import { OTUITree } from "../OTUITree";
import { meetingRequestAlert } from "../Alert";
import { writeSpaceExternalMeetings } from "../UIDataState";
import { Database } from "firebase/database";
import { ExternalMeeting } from "../ExternalMeeting";

const logger = new Logger("MeetingManager");


export class MeetingManager {
  fbDb: Database;
  teamId: string;
  userId: string;
  sessionToken: string;
  showMeetingModal: TShowMeetingModal;

  @observable address?: string;

  @observable showMenu: boolean = false;
  @observable addressDoc?: ITeamExternalAddress;
  @observable userMeetingsDoc?: ITeamExternalUser;
  @observable showModalDialog?: boolean = false;
  @observable channels?: Record<string, ITeamExternalChannel>;
  @observable rooms?: Record<string, ITeamExternalRoom>;


  @observable externalMeeting?: ExternalMeeting;

  constructor(
    fbDb: Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    showMeetingModal: TShowMeetingModal,
    address?: string
  ) {

    makeObservable(this)

    this.fbDb = fbDb;
    this.teamId = teamId;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.showMeetingModal = showMeetingModal;

    OTUITree.registerMeetingManager(this);

    this.setupAddress(address);
    this.setupUserMeeting();
    this.setupChannelMeeting();
    this.setupRoomMeeting();
  }

  setupAddress = (address: string | undefined) => {
    if (address && this.address != address) {
      logger.debug("watchTeamExternalAddress", address);
      FireDb.watchTeamExternalAddress(this.fbDb, this.teamId, address, this.handleAddress);
    }
    this.address = address;
    writeSpaceExternalMeetings(this.teamId)
  };

  setupUserMeeting = () => {
    FireDb.watchTeamUserExternal(this.fbDb, this.teamId, this.userId, this.handleUserMeetingsDoc);
  }

  setupChannelMeeting = () => {
    FireDb.watchTeamChannelsExternal(this.fbDb, this.teamId, this.handleChannelsMeetingsDoc);
  }

  setupRoomMeeting = () => {
    FireDb.watchTeamRoomsExternal(this.fbDb, this.teamId, this.handleRoomsMeetingsDoc);
  }

  stop = () => {
    if (this.address) {
      FireDb.unwatchTeamExternalAddress(this.fbDb, this.teamId, this.address);
    }
    FireDb.unwatchTeamUserExternal(this.fbDb, this.teamId, this.userId);


  };

  handleAddress = (doc: ITeamExternalAddress) => {
    var hasNew = false;

    Object.keys(doc?.waiting || {}).forEach((userId) => {
      if (!this.addressDoc?.waiting?.[userId]) {
        hasNew = true;
        meetingRequestAlert(this.teamId, userId, doc.waiting[userId].name);
      }
    });

    this.addressDoc = doc;
    logger.debug("handleAddress", doc);

    if (hasNew && !this.showModalDialog) {
      if (this.showMenu) {
        this.toggleShowMenu();
      }
      this.showModal();
    }

    writeSpaceExternalMeetings(this.teamId)
  };

  handleUserMeetingsDoc = (doc: ITeamExternalUser) => {
    var hasNew = false;

    Object.keys(doc?.waiting || {}).forEach((userId) => {
      if (!this.userMeetingsDoc?.waiting?.[userId]) {
        hasNew = true;
        meetingRequestAlert(this.teamId, userId, doc.waiting[userId].name);
      }
    });

    this.userMeetingsDoc = doc;
    logger.debug("handleUserMeetingsDoc", doc);

    if (hasNew && !this.showModalDialog) {
      if (this.showMenu) {
        this.toggleShowMenu();
      }
      this.showModal();
    }

    writeSpaceExternalMeetings(this.teamId)
  };

  handleChannelsMeetingsDoc = (doc: Record<string, ITeamExternalChannel>) => {
    logger.debug("handleChannelsMeetingsDoc", doc);

    this.channels = doc
    writeSpaceExternalMeetings(this.teamId)
  };

  handleRoomsMeetingsDoc = (doc: Record<string, ITeamExternalRoom>) => {
    logger.debug("handleRoomsMeetingsDoc", doc);

    this.rooms = doc
    writeSpaceExternalMeetings(this.teamId)
  };

  showModal = () => {
    logger.info("showing pending Meeting users");
    this.showModalDialog = true;
    this.showMeetingModal(this.teamId, this.hideModal);
  };

  hideModal = () => {
    this.showModalDialog = false;
  };

  toggleShowMenu = () => {
    this.showMenu = !this.showMenu;
  };

  createRoom = async () => {
    logger.debug("creating room");
    var teamData = OTGlobals.getTeamData(this.teamId);
    var meTeamUser = teamData.getTeamUser(this.userId);

    var roomConfigDoc: ITeamRoomConfig = {
      name: `${meTeamUser.name}'s Room`,
      desc: "",
      enabled: true,
      call: true,
      permanent: false,
      external: true,
    };

    var roomId = await FireDb.createRoom(
      this.fbDb,
      this.teamId,
      this.userId,
      this.sessionToken,
      roomConfigDoc
    );

    return roomId;
  };

  acceptReq = async (userId: string, roomId?: string, channelId?: string) => {
    logger.debug("accept req roomId", roomId);

    if (!roomId) {
      roomId = await this.createRoom();
    }

    await FireDb.inviteTeamRoom(this.fbDb, this.teamId, roomId, userId);

    if (this.addressDoc?.waiting?.[userId]) {
      await FireDb.acceptTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId, roomId);
    }

    if (this.userMeetingsDoc?.waiting?.[userId]) {
      await FireDb.updateTeamExternalUserWaiting(this.fbDb, this.teamId, this.userId, userId, 'A', roomId)
    }

    if (channelId && this.channels?.[channelId]?.waiting?.[userId]) {
      await FireDb.updateTeamExternalChannelWaiting(this.fbDb, this.teamId, channelId, userId, 'A', roomId)
    }

    if (roomId && this.rooms?.[roomId]?.waiting?.[userId]) {
      await FireDb.updateTeamExternalRoomWaiting(this.fbDb, this.teamId, roomId, userId, 'A')
    }


    OTGlobals.analytics?.logEvent("meetingManager__acceptExternalUser");
  };

  holdReq = (userId: string, roomId?: string, channelId?: string,) => {

    if (this.addressDoc?.waiting?.[userId]) {
      FireDb.holdTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId);
    }

    if (this.userMeetingsDoc?.waiting?.[userId]) {
      FireDb.updateTeamExternalUserWaiting(this.fbDb, this.teamId, this.userId, userId, 'H')
    }

    if (channelId && this.channels?.[channelId]?.waiting?.[userId]) {
      FireDb.updateTeamExternalChannelWaiting(this.fbDb, this.teamId, channelId, userId, 'H')
    }

    if (roomId && this.rooms?.[roomId]?.waiting?.[userId]) {
      FireDb.updateTeamExternalRoomWaiting(this.fbDb, this.teamId, roomId, userId, 'H')
    }

  };

  rejectReq = (userId: string, roomId?: string, channelId?: string,) => {


    if (this.addressDoc?.waiting?.[userId]) {
      FireDb.rejectTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId);
    }

    if (this.userMeetingsDoc?.waiting?.[userId]) {
      FireDb.updateTeamExternalUserWaiting(this.fbDb, this.teamId, this.userId, userId, 'R')
    }

    if (channelId && this.channels?.[channelId]?.waiting?.[userId]) {
      FireDb.updateTeamExternalChannelWaiting(this.fbDb, this.teamId, channelId, userId, 'R')
    }
    if (roomId && this.rooms?.[roomId]?.waiting?.[userId]) {
      FireDb.updateTeamExternalRoomWaiting(this.fbDb, this.teamId, roomId, userId, 'R')
    }
  };

}
