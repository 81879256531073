import events from "events";
import { autorun, makeObservable, observable, reaction, runInAction, toJS } from "mobx";

import { Logger } from "@openteam/app-util";
import {
  IPeerMsg,
  IOTRoom,
  IOTUser,
  IPTTController,
  ITeamDoc,
  ITeamUser,
  TActionType,
  TShowMeetingModal,
  ITeamRoomConfig,
  ISearchKeys,
  TUserStatus,
  TReceiverStatus,
  KSpaceUserId,
} from "@openteam/models";
import { AwaitLock } from "@openteam/app-util";
import { FireDb, SessionDb, TeamManagerDb } from "./fire";
import { OTUserInterface } from "./OTUserInterface";
import { OTAppCoreData } from "./OTAppCoreData";
import { CallRequestManager, endActiveCall, TDismissModal, TShowCallFeedback, TShowCallRequestModal } from "./CallRequest";
import { AlertManager, doActionAlert, recvAction, sendAction, userOnlineAlert } from "./Alert";
import { OTTeamDataClass } from "./OTTeamDataClass";
import { THandleOpenNotification } from "./AppHome/AppHomeManager";
import { P2PStreamManager } from "./PeerConnection";
import { ChatManager } from "./Chat";
import { CallStateManager } from "./CallStateManager";
import { TeamConnection } from "./TeamConnection";
import { TeamAccessReqManager } from "./TeamAccessReqManager";
import { MeetingManager } from "./Meeting/MeetingManager";
import { RoomManager } from "./RoomManager";
import { OTGlobals } from "./OTGlobals";
import { PTTTeamController } from "./PTTTeamController";
import { PTTController } from "./PTTController";
import { applyObjectUpdate, applyObjectUpdate2 } from "./utils/applyObjectUpdate";
import { userIsOnline } from "./utils/userIsOnline";
import { removeSpace, writeSpace } from "./UIDataState";
import { getSearchKeys } from "./fire/Search";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";
import { OTUITree } from "./OTUITree";

const logger = new Logger("TeamManager");

const NotifyTime = 6 * 60 * 60 * 1000;

var dummyUser: ITeamUser = {
  name: "Unknown User",
  id: "unknown",
  email: "unknownemail",
  imageUrl: null,
};

interface ITeamDetails {
  teamName: string;
}

export class TeamManager extends events.EventEmitter {
  fbDb: Database;
  fsDb: Firestore;
  myUserId: string;
  sessionToken: string;

  teamId: string;

  @observable teamData: OTTeamDataClass;

  hasTeam = true;
  _useTeamServer?: boolean;

  showMeetingModal: TShowMeetingModal;
  showCallRequestModal: TShowCallRequestModal;
  showCallFeedback: TShowCallFeedback;
  dismissModal: TDismissModal;
  handleOpenNotification: THandleOpenNotification;

  @observable _streamManager?: P2PStreamManager;
  pttManager?: IPTTController;
  @observable teamConnection?: TeamConnection;
  callRequestManager!: CallRequestManager;
  @observable chatManager!: ChatManager;
  alertManager!: AlertManager;
  teamAccessReqManager!: TeamAccessReqManager;
  meetingManager!: MeetingManager;

  teamDetails!: ITeamDetails;
  _users: { [id: string]: ITeamUser } = {};
  _adminUsers: { [id: string]: { id: string } } = {};
  @observable callStateManager?: CallStateManager;
  reqStart: boolean = false;
  @observable running: boolean = false;
  @observable loaded: boolean = false;
  @observable searchKeys: ISearchKeys | undefined = undefined;


  _teamDoc?: ITeamDoc;
  idleTime: any;
  _roomManager!: RoomManager;
  _stateLock = new AwaitLock();
  _teamConnectionLock = new AwaitLock();
  _startRoomLock = new AwaitLock();
  _autorun: Record<string, any> = {};

  constructor(
    fbDb: Database,
    fsDb: Firestore,
    userId: string,
    sessionToken: string,
    teamId: string,
    showMeetingModal: TShowMeetingModal,
    showCallRequestModal: TShowCallRequestModal,
    showCallFeedback: TShowCallFeedback,
    dismissModal: TDismissModal,
    handleOpenNotification: THandleOpenNotification
  ) {
    super();

    makeObservable(this)

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.myUserId = userId;
    this.sessionToken = sessionToken;
    this.teamId = teamId;

    this.showMeetingModal = showMeetingModal;
    this.showCallRequestModal = showCallRequestModal;
    this.showCallFeedback = showCallFeedback;
    this.dismissModal = dismissModal;
    this.handleOpenNotification = handleOpenNotification;

    logger.info(`Creating TeamManager for teamId=${teamId}`);

    this.teamData = new OTTeamDataClass(this.fbDb, this.myUserId, this.teamId);
  }

  handleCurrentTeam = () => {
    const teamData = this.teamData;
    if (!teamData) {
      return;
    }
    //logger.debug("handleCurrentTeam", this.teamId)

    if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      const teamFocused = OTGlobals.auth.userManager.currentTeamId == this.teamId;

      if (teamFocused != teamData.isFocused) {
        TeamManagerDb.setTeamBackgroundStatus(this.fbDb, this.myUserId, this.teamId, !teamFocused);
        logger.info(`setting teamId=${this.teamId} teamFocused=${teamFocused}`);
      }
    }
  };

  handleIsIdle = () => {
    logger.debug(`setting idle teamId=${this.teamId} isIdle=${OTGlobals.isIdle}`);
    TeamManagerDb.setIdle(this.fbDb, this.myUserId, this.teamId, OTGlobals.isIdle);
  };

  setCustomStatus = (
    customStatus?: TUserStatus,
    customStatusEmoji?: string,
    customStatusText?: string
  ) => {
    TeamManagerDb.setCustomStatus(this.fbDb, this.myUserId, this.teamId, customStatus, customStatusEmoji, customStatusText);
  }

  start = () => {
    const platformOS = OTUserInterface.platformUtils.PlatformOS;

    if (this.running) {
      return;
    }

    if (!this.loaded) {
      this.reqStart = true;
      return;
    }

    if (platformOS == "mobile") {
      TeamManagerDb.registerDeviceTeamUser(
        this.fbDb,
        this.myUserId,
        this.sessionToken,
        this.teamId
      );
    } else {
      SessionDb.setupTeamPresence(this.fbDb, this.myUserId, this.sessionToken, this.teamId);
    }

    this._roomManager = new RoomManager(this.fbDb, this.myUserId, this.teamId);

    if (platformOS != "mobile") {
      this._autorun["setupCurrentTeam"] = autorun(this.setupTeamConnection);

      // this.noteManager = new NoteManager(this.teamId)
      this.meetingManager = new MeetingManager(
        this.fbDb,
        this.teamId,
        this.myUserId,
        this.sessionToken,
        this.showMeetingModal,
        this._teamDoc?.users?.[this.myUserId].address
      );
    }

    this.callRequestManager = new CallRequestManager(
      this.fbDb,
      this.myUserId,
      this.sessionToken,
      this.teamId,
      this.joinTeamRoom,
      this.showCallRequestModal,
      this.dismissModal
    );

    this.callRequestManager.start();

    this.chatManager = new ChatManager(this.fsDb, this.teamId, this.myUserId);
    this.chatManager.start();

    this.alertManager = new AlertManager(this.fbDb, this.myUserId, this.teamId);
    this.teamAccessReqManager = new TeamAccessReqManager(this.fbDb, this.teamId);

    this._autorun["handleCurrentTeam"] = autorun(this.handleCurrentTeam);

    this._autorun["handleIsIdle"] = autorun(this.handleIsIdle);

    let myRoom: IOTRoom | null = null;

    runInAction(() => {
      if (this._teamDoc) {
        this._writeUsers(this._teamDoc);
        myRoom = this._roomManager.handleDoc(this._teamDoc) || null;
      }
    });

    this._manageCallState(myRoom);

    this._autorun["generateSearchKey"] = reaction(
      () => Object.keys(this.chatManager.channels),
      async (channelIds) => {
        logger.debug(`getSearchKeys: teamId: ${this.teamId} channelIds: ${channelIds}`);
        this.searchKeys = await getSearchKeys(this.teamId)
      },
      { name: "generateSearchKey", fireImmediately: true, delay: 100 }
    )

    this.running = true;

    logger.info("started", this.teamId);
  };

  stop = async () => {
    if (!this.running) return;

    logger.info(`stopping team ${this.teamId}`);

    // await this.leaveTeamRoom();
    await this._manageCallState(null);

    Object.values(this._autorun).map(x => x());
    this._autorun = {}

    this.teamData.stop();

    this.callRequestManager?.stop();

    this.chatManager?.stop();
    this.alertManager?.stop();
    this.teamAccessReqManager?.stop();
    this.meetingManager?.stop();

    this._streamManager && this._streamManager.stop();

    this.teamConnection && this.teamConnection.stop();

    removeSpace(this.teamId)

    this.running = false;
  };

  _autorunSetupCurrentTeam;
  setupTeamConnection = () => {
    const teamUseTeamServer = this.teamData.capabilities.useTeamServer;
    const globalUseTeamServer = OTAppCoreData.useTeamServer;
    this._setupTeamConnection(teamUseTeamServer ?? globalUseTeamServer);
  };

  _setupTeamConnection = async (useTeamServer?: boolean) => {
    await this._teamConnectionLock.acquireAsync();
    try {
      useTeamServer = useTeamServer ? true : false;

      logger.info("_setupTeamConnection called, useTeamServer", useTeamServer, this.teamId);

      if (this._useTeamServer != undefined && this._useTeamServer == useTeamServer) {
        return;
      }

      this._useTeamServer = useTeamServer;

      if (useTeamServer) {
        logger.info("using team server", this.teamId);
        if (this._streamManager) {
          this._streamManager.stop();
          this._streamManager = undefined;
        }

        if (this.pttManager) {
          this.pttManager = undefined;
        }

        if (!this.teamConnection) {
          this.teamConnection = new TeamConnection(this.myUserId, this.teamId);
          this.teamConnection.on("action", this.handleAlert);
        }

        if (!this.pttManager) {
          this.pttManager = new PTTTeamController(
            this.fbDb,
            this.myUserId,
            this.teamId,
            this.teamConnection,
            this._roomManager
          );
          this._teamDoc && this.pttManager && this.pttManager.processDoc(this._teamDoc);
        }
      } else {
        logger.info("using team mesh network", this.teamId);

        if (this.teamConnection) {
          this.teamConnection.stop();
          this.teamConnection = undefined;
        }

        if (this.pttManager) {
          this.pttManager = undefined;
        }

        if (!this._streamManager) {
          this._streamManager = new P2PStreamManager(
            this.fbDb,
            this.myUserId,
            this.sessionToken,
            this.teamId,
            "ptt"
          );

          this._streamManager.on("action", this.handleAlert);
          this._streamManager.start();
          this._streamManager.updateUsers(this._teamDoc?.users || {});
        }

        if (!this.pttManager) {
          this.pttManager = new PTTController(
            this.myUserId,
            this.teamId,
            this._streamManager,
            this._roomManager
          );

          this._teamDoc && this.pttManager && this.pttManager.processDoc(this._teamDoc);
        }
      }
    } finally {
      this._teamConnectionLock.release();
    }
  };

  handleNotification = (notification) => {
    if (notification.data?.type == "CHAT") {
    } else {
      OTUserInterface.toastHandlers.show(
        notification.notification?.title,
        "info",
        notification.notification?.body
      );
    }

    if (notification.data?.type == "KNOCK") {
      OTUserInterface.soundEffects.knock();
    }
  };

  handleAlert = (userId, actionType: TActionType) => {
    recvAction(userId, actionType);
    var teamUserDoc = this.getTeamUser(userId);
    if (teamUserDoc) {
      doActionAlert(this.teamId, teamUserDoc, actionType);

      if (actionType == "PTT_WALKIE" || actionType == "KNOCK") {
        this.pttManager?.setLastPtt(userId);
      }
    }
  };

  handleTeamDoc = async (doc: ITeamDoc, isCached?: boolean) => {
    await this._stateLock.acquireAsync();

    try {
      let myRoom: IOTRoom | null = null;
      // logger.debug("got doc update", doc)

      this._teamDoc = doc;

      if (!doc || !doc.users || !(this.myUserId in doc.users)) {
        return;
      }

      runInAction(() => {
        const teamData = this.teamData;

        this._users = doc.users;
        this._adminUsers = doc.admin || {};

        const meDoc = doc.users[this.myUserId];

        teamData.isAdmin = this.myUserId in this._adminUsers;
        teamData.isFocused = meDoc.status?.inBackground == false;

        teamData.config = doc.config;

        teamData.teamPath = doc.teamPath;
        teamData.teamName = doc.teamName;
        teamData.backgroundImageUrl = doc.backgroundImageUrl;
        teamData.iconImageUrl = doc.iconImageUrl;

        if (!teamData.preferences || !meDoc.preferences) {
          teamData.preferences = meDoc.preferences;
        } else {
          // XX Using recursive update here Causes no users to show up in the dock!?
          applyObjectUpdate(teamData.preferences, meDoc.preferences);
          //logger.debug(`updated teamPrefs`, toJS(teamData.preferences), meDoc.preferences);
        }

        var newSubteams = doc.subteams || {};

        //if (!teamData.subTeams) {
        //  teamData.subTeams = newSubteams;
        //} else {
        //  teamData.subTeams = applyObjectUpdate2(doc.subteams, teamData.subTeams, newSubteams);
        //}

        teamData.subTeams = applyObjectUpdate2(
          newSubteams,
          teamData.subTeams,
          true,
          `subTeams ${this.teamId}`
        );

        teamData.zoomMeetings = applyObjectUpdate2(
          doc.zoomMeetings ?? {},
          teamData.zoomMeetings,
          true,
          `zoomMeetings ${this.teamId}`
        )

        if (!isCached && this._streamManager) {
          this._streamManager.updateUsers(doc.users);
        }

        if (this.meetingManager) {
          var address = doc.users[this.myUserId].address;
          this.meetingManager.setupAddress(address);
        }

        this.teamDetails = {
          teamName: doc.teamName,
        };

        if (this.running && !isCached) {
          myRoom = this._roomManager.handleDoc(doc) || null;
          this._manageRoomRequests(doc)
          this._writeUsers(doc);

          if (!meDoc.dockInit) {
            this.initTeamDock()
          }
        }
      });

      if (this.running && !isCached) {
        await this._manageCallState(myRoom);
      }

      writeSpace(this)

      if (!this.loaded) {
        this.loaded = true;
        this.emit("loaded");
        if (this.reqStart) {
          this.start();
        }
      }

      if (this.running && OTGlobals.auth.userManager.userDoc?.nextTeamId === this.teamId) {
        OTGlobals.auth.userManager.setCurrentTeam(this.teamId)
      }

    } finally {
      this._stateLock.release();
    }
  };

  _writeUsers(doc: ITeamDoc) {
    const now = new Date().getTime();

    this.pttManager && this.pttManager.processDoc(doc);

    const teamData = this.teamData;

    Object.values(doc.users).forEach((user) => {
      const { isOnline, inLeeway } = userIsOnline(user);
      const inBackground = user.status?.inBackground == true;

      var newUserState: IOTUser = {
        userId: user.id,
        name: user.name,
        email: user.email,
        meetingToken: user.meetingToken,
        online: isOnline,
        hasMobile: Object.keys(user.device || {}).length > 0,
        inLeeway: inLeeway,
        idle: user.status?.idle == true,
        inBackground,
        meetingStatus: user.status?.meetingStatus,
        customStatus: user.status?.customStatus,
        customStatusEmoji: user.status?.customStatusEmoji,
        customStatusText: user.status?.customStatusText,
        zoomStatus: user.status?.zoomStatus,
        timezone: user.status?.timezone,
        subTeam: user.subTeam ?? undefined,
        imageUrl: user.imageUrl || undefined,
        isAdmin: user.id in this._adminUsers,
        sessionToken: user.status?.sessionToken || null,
        canPtt: this.pttManager?.canPttUser(user.id) || false,
        dateJoined: user.dateJoined,
        last_changed: user.status?.last_changed,
      };

      if (!teamData.users[user.id]) {
        teamData.users[user.id] = newUserState;
      } else {
        const prevLastChanged = teamData.users[user.id].last_changed;
        const prevOnline = teamData.users[user.id].online;
        const newLastChanged = newUserState.last_changed;

        applyObjectUpdate(teamData.users[user.id], newUserState, true);

        if (
          teamData.capabilities.notifyUserOnline &&
          !teamData.preferences?.disableNotifyUserOnline &&
          user.id != this.myUserId &&
          !prevOnline &&
          newUserState.online &&
          prevLastChanged &&
          newLastChanged &&
          prevLastChanged != newLastChanged
        ) {
          logger.info(
            "user.id ",
            user.id,
            "prevLastChanged",
            prevLastChanged,
            "newLastChanged",
            newLastChanged
          );

          const isRecent = now - newLastChanged < 30 * 60 * 1000;

          if (isRecent && newLastChanged - prevLastChanged > NotifyTime) {
            userOnlineAlert(this.teamId, user.id);
          }
        }
      }
    });

    let userIds = Object.keys(doc.users);
    Object.keys(teamData.users).forEach((userId) => {
      if (!userIds.includes(userId)) {
        delete teamData.users[userId];
      }
    });
  }


  _manageRoomRequests = (doc: ITeamDoc) => {

    for (const roomId in doc.rooms) {
      const room = doc.rooms[roomId]
      const request = room.requests?.[this.myUserId]
      if (request?.active) {
        if (
          request.receiverStatus == 'accepted'
        ) {
          this.joinTeamRoom(roomId == "online" ? null : roomId);
          this.cancelRequestJoinTeamRoom(roomId)
        } else if (
          request.receiverStatus == 'rejected'
        ) {
          const responseUser = request.receiverUserId ? this.getTeamUser(request.receiverUserId) : undefined

          const msg = `Your request to join ${room.config.name} was rejected${responseUser ? ` by ${responseUser.name}` : ''}`

          this.cancelRequestJoinTeamRoom(roomId)
          OTUserInterface.toastHandlers.show(msg, "error");
        }
      }
    }

  }

  getRoomCallState = (roomId: string) => {
    if (roomId == this.callStateManager?.roomId) {
      return this.callStateManager;
    }
  };

  _manageCallState = async (room: IOTRoom | null) => {
    // Must be called with this._stateLock lock held
    const callStateManager = OTGlobals.callStateManager;
    //logger.debug(
    //  `_manageCallState teamId=${this.teamId}, currentTeamId=${OTGlobals.auth.userManager.currentTeamId
    ///  }, callStateTeamId=${callStateManager?.teamId}, room=${toJS(room)}`
    //);

    if (callStateManager && callStateManager.teamId == this.teamId) {
      if (
        callStateManager.roomId != room?.roomId ||
        !room?.config?.call ||
        room?.users[this.myUserId].currentSessionToken != this.sessionToken
      ) {
        logger.info(`Ending call for roomId=${callStateManager.roomId}`);

        const callSecs = await callStateManager.shutdown();

        OTGlobals.analytics?.logEvent("teammanager_leave_call", {
          roomId: callStateManager.roomId,
          timeInCall: callSecs,
        });
        OTGlobals.setCallStateManager(undefined);

        endActiveCall();
      } else {
        callStateManager.updateUsers(room.users);
      }
    }

    if (
      !OTGlobals.callStateManager &&
      room &&
      room.config?.call &&
      room.users[this.myUserId].currentSessionToken == this.sessionToken
    ) {
      logger.debug(`Starting call for teamId=${this.teamId}, ${room.roomId} with ${room.users}`);
      const callStateManager = new CallStateManager(
        this.fbDb,
        this.fsDb,
        this.myUserId,
        this.sessionToken,
        this.teamId,
        room.roomId,
        room.users,
        () => { return this.getRoom(room.roomId) },
        this.showCallFeedback
      );
      callStateManager.on("leavecall", (ev) => this.leaveTeamRoom());

      OTGlobals.setCallStateManager(callStateManager);

      OTGlobals.analytics?.logEvent("teammanager_join_call", {
        roomId: room.roomId,
        numUser: room.users.length,
      });
    }
  };

  getMe = () => {
    return this._users[this.myUserId];
  };

  getTeamUser = (userId: string): ITeamUser => {
    return this._users[userId] || dummyUser;
  };

  setDisplaySubTeam = (subTeam: string, display: boolean) => {
    FireDb.updateTeamUserPreference(this.fbDb, this.teamId, this.myUserId, "displaySubteam", {
      [subTeam]: display ? true : false,
    });
  };

  initTeamDock = () => {
    const meDoc = this.teamData.users[this.myUserId];
    const users = this.teamData.users
    const prefs = this.teamData.preferences?.users

    const recentUsers = Object.keys(users).filter(userId => userId != this.myUserId)
    logger.info("initTeamDock meDoc", meDoc)

    recentUsers.sort(
      (a, b) => (
        ((meDoc.subTeam === users[a].subTeam) != (meDoc.subTeam === users[b].subTeam)) ? (meDoc.subTeam === users[a].subTeam) ? -1 : 1 :
          (users[a].online !== users[b].online) ? users[a].online ? -1 : 1 :
            ((prefs?.[b]?.lastInteracted || 0) > (prefs?.[a]?.lastInteracted || 0) ? 1 : -1)
            ||
            ((users[b].last_changed || 0) > (users[a].last_changed || 0) ? 1 : -1)
      )
    )

    logger.info("initTeamDock recentUsers", recentUsers.map(userId => {

      const user = users[userId];

      return {
        name: user.name,
        subTeam: user.subTeam,
        online: user.online,
        last_changed: user.last_changed
      }
    }))

    this.setPinnedUserIds(recentUsers.slice(0, 5))

    // pin all pods

    Object.keys(this.chatManager.channels)
      .map(podId => this.setPinPod(podId, true))

    TeamManagerDb.updateTeamUser(this.fbDb, this.teamId, this.myUserId, { dockInit: true })

  }

  togglePinned = (userId: string) => {
    const pinnedUserIds = this.teamData.preferences?.pinnedUserIds || [];

    let newPinnedUserIds: string[];

    if (pinnedUserIds.includes(userId)) {
      newPinnedUserIds = pinnedUserIds.filter(x => x !== userId)
    } else {
      newPinnedUserIds = [...pinnedUserIds, userId];
    }

    logger.debug("new pinned users: ", newPinnedUserIds);

    FireDb.setTeamUserPreference(
      this.fbDb,
      this.teamId,
      this.myUserId,
      "pinnedUserIds",
      newPinnedUserIds.filter(userId => userId !== this.myUserId),
    )
  };

  setPinnedUserIds = (pinnedUserIds: string[]) => {
    const uniqueUserIds = pinnedUserIds
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(x => x in this._users);

    logger.debug("setPinnedUserIds: ", uniqueUserIds);

    FireDb.setTeamUserPreference(
      this.fbDb,
      this.teamId,
      this.myUserId,
      "pinnedUserIds",
      uniqueUserIds.filter(userId => userId !== this.myUserId)
    )
  }

  setUserInteracted = (userId: string, lastInteracted?: Date) => {

    if (!lastInteracted) {
      lastInteracted = new Date()
    }

    FireDb.updateTeamUserContactPreferences(this.fbDb, this.teamId, this.myUserId, userId, {
      lastInteracted: lastInteracted.getTime()
    });
  };

  setPinPod = (podId: string, pinned: boolean) => {
    FireDb.updateTeamUserPodPreferences(this.fbDb, this.teamId, this.myUserId, podId, {
      pinned: pinned
    });
  };

  startPtt = async (roomId?: string, subTeam?: string, userId?: string) => {
    return await this.pttManager?.startPtt(roomId, subTeam, userId);
  };

  stopPtt = async () => {
    return await this.pttManager?.stopPtt();
  };

  knockUser = (userId) => {
    const user = this.teamData.users[userId];
    const failsSilently = !user.online && user.hasMobile;
    this.sendAction(
      userId,
      "KNOCK",
      () => {
        OTUserInterface.soundEffects.knock();
      },
      () => {
        if (!failsSilently) {
          OTUserInterface.toastHandlers.show("Unable to deliver knock, user offline?", "error");
        }
      }
    );
    OTGlobals.analytics?.logEvent("sent_knock", { userId: userId });
  };

  knockSubTeam = (subTeam: string) => {
    const teamData = this.teamData;
    const room = teamData.rooms["online"];

    var targets = Object.keys(room.subteams![subTeam]);

    targets.forEach((userId) => userId != this.myUserId && this.sendAction(userId, "KNOCK"));

    OTUserInterface.soundEffects.knock();
    OTGlobals.analytics?.logEvent("sent_knock_subteam", { subteam: subTeam });
  };

  callSubTeam = (subTeam: string, subTeamName: string) => {
    const callRequestManager = this.callRequestManager;

    const teamData = this.teamData;
    const room = teamData.rooms["online"];

    var targets = Object.keys(room.subteams![subTeam]).filter((userId) => userId != this.myUserId);

    var cancel = subTeam in callRequestManager.callsByKey;

    if (cancel) {
      targets.forEach((userId) => callRequestManager.sendCancelCall(userId, true));
    } else {
      targets.forEach((userId) => callRequestManager?.sendCallUser(userId, subTeam, subTeamName));
    }

    OTGlobals.analytics?.logEvent("call_subteam", { subteam: subTeam });
  };

  getRoom = (roomId) => this.teamData.rooms[roomId];

  toggleRoomLock = (roomId: string) => {
    logger.info("setting room lock ", !this.getRoom(roomId).config?.isLocked);

    FireDb.updateRoomConfig(this.fbDb, this.teamId, roomId, {
      isLocked: !this.getRoom(roomId).config?.isLocked,
    });
  };

  leaveTeamRoom = async (roomId?: string) => {

    if (roomId) {
      await FireDb.leaveTeamRoom(
        this.fbDb,
        this.teamId,
        this.myUserId,
        this.sessionToken,
        roomId
      );
    } else {
      await this.joinTeamRoom(null);
    }
  };

  joinTeamRoom = async (roomId: string | null, ignoreLock = false) => {
    const currentUser = this.teamData.getTeamUser(this.myUserId);

    const room = this.getRoom(roomId);

    const inRoom = this.myUserId in (room?.users || {})
    const isAdmin = currentUser.isAdmin;
    const isOwner = room?.config?.ownerUserId === this.myUserId;

    if (!ignoreLock && room && room.config?.isLocked && !isAdmin && !isOwner) {
      return OTUserInterface.toastHandlers.show("room is locked", "info");
    }

    if (!inRoom) {

      await FireDb.joinTeamRoom(
        this.fbDb,
        this.teamId,
        this.myUserId,
        this.sessionToken,
        roomId,
        FireDb.getRoomUser(currentUser)
      );

      logger.debug("joinTeamRoom: joined room ", roomId)

    } else {
      logger.debug("joinTeamRoom: already in room ", roomId)

    }

  };

  startFocusRoom = async (roomId: string, roomConfigDoc: ITeamRoomConfig) => {
    const newRoomId = await FireDb.createRoom(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      roomConfigDoc,
      roomId
    );

    return newRoomId
  }

  requestJoinTeamRoom = async (roomId: string | null) => {
    const currentUser = this.teamData.getTeamUser(this.myUserId);

    const room = this.getRoom(roomId);

    const inRoom = this.myUserId in (room?.users || {})

    if (!inRoom) {

      await FireDb.requestJoinTeamRoom(
        this.fbDb,
        this.teamId,
        this.myUserId,
        this.sessionToken,
        roomId,
        FireDb.getRoomUser(currentUser)
      );

      logger.debug("requestJoinTeamRoom: requested to join room ", roomId)

    } else {
      logger.debug("requestJoinTeamRoom: already in room ", roomId)

    }

  };

  cancelRequestJoinTeamRoom = async (roomId: string | null) => {

    await FireDb.cancelRequestJoinTeamRoom(
      this.fbDb,
      this.teamId,
      this.myUserId,
      roomId,
    );

    logger.debug("cancelRequestJoinTeamRoom: requested to join room ", roomId)

  };

  respondRequestJoinCall = async (roomId: string, userId: string, response: TReceiverStatus) => {

    await FireDb.respondRequestJoinCall(
      this.fbDb,
      this.teamId,
      userId,
      roomId,
      response
    );

    logger.debug("cancelRequestJoinTeamRoom: requested to join room ", roomId)

  };

  startUserRoom = async (meetingName?: string) => {
    const roomConfigDoc: ITeamRoomConfig = {
      name: meetingName || "Meeting Room",
      desc: "",
      enabled: true,
      call: true,
      permanent: false,
    };

    const roomId = await FireDb.createRoom(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      roomConfigDoc
    );

    return roomId
  };

  startRoomForChannel = async (roomName: string | undefined, channelId: string, topicId: string) => {
    await this._startRoomLock.acquireAsync();

    try {
      logger.debug("startRoomForChannel:", this.teamId, channelId, topicId)

      await this.chatManager.startChannelRoom(
        channelId,
        topicId,
        async (roomId: string | undefined, roomConfig: ITeamRoomConfig) => {

          logger.debug("startRoomForChannel: got roomId", roomId)

          const roomDoc = roomId && await FireDb.getRoom(this.fbDb, this.teamId, roomId)
          logger.debug("startRoomForChannel: got roomDoc", roomDoc)

          if (roomDoc && roomId) {
            if (!roomDoc.users?.[this.myUserId]) {


              const currentUser = this.teamData.getTeamUser(this.myUserId);

              await FireDb.joinTeamRoom(
                this.fbDb,
                this.teamId,
                this.myUserId,
                this.sessionToken,
                roomId,
                FireDb.getRoomUser(currentUser)
              );
              logger.debug("startRoomForChannel: joined room ", roomId)
            } else {
              logger.debug("startRoomForChannel: already in room ", roomId)
            }


          } else {

            logger.debug("startRoomForChannel: creating room ", roomConfig)


            roomId = await FireDb.createRoom(
              this.fbDb,
              this.teamId,
              this.myUserId,
              this.sessionToken,
              roomConfig,
            );
            logger.debug("startRoomForChannel: created room ", roomId)

          }

          return roomId
        }
      )
    } finally {
      this._startRoomLock.release();
    }

  };

  sendMessage = (
    userId,
    message: IPeerMsg,
    onSuccess?: () => void,
    onError?: (errorCode) => void
  ) => {
    if (this.teamConnection) {
      this.teamConnection.sendMessage(userId, message, (responseData) => {
        if (responseData.status == "OK") {
          onSuccess && onSuccess();
        } else {
          onError && onError(responseData.error);
        }
      });
    }

    if (this._streamManager) {
      this._streamManager.sendMessage(userId, message);
    }
  };

  sendAction = (
    userId: string,
    actionType: TActionType,
    onSuccess?: () => void,
    onError?: (errorCode) => void
  ) => {
    sendAction(this.fbDb, this.myUserId, this.teamId, userId, actionType);
    if (this.teamConnection) {
      this.teamConnection.sendAction(userId, actionType, (responseData) => {
        if (responseData.status == "OK") {
          onSuccess && onSuccess();
        } else {
          onError && onError(responseData.error);
        }
      });
    }

    if (this._streamManager) {
      this._streamManager.sendMessage(userId, {
        msgType: "ACTION",
        actionType: actionType,
      });
      onSuccess && onSuccess();
    }
  };

  acceptCall = () => {
    this.callRequestManager.recvRespondToCall("accepted");
  };

  rejectCall = () => {
    this.callRequestManager.recvRespondToCall("rejected");
  };

  leaveTeam = async () => {
    await this.removeUserFromTeam(this.myUserId)
  };

  removeUserFromTeam = async (userId: string) => {
    if (this.teamData.isAdmin || userId === OTUITree.auth.userId) {

      logger.info(`removing user from team ${this.teamId}`, userId);
      await TeamManagerDb.removeTeamUser(this.teamId, this.myUserId);

    }else {
      logger.info(`error removing user from team ${this.teamId}, not admin user`);
    }
  }
}
