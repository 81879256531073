
import {
  Firestore,
  Timestamp,
  serverTimestamp,
  collection,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  runTransaction,
  arrayUnion,
  arrayRemove,
  updateDoc,
  writeBatch,
  deleteDoc,
  deleteField,
  orderBy,
  startAfter,
  limit,
  startAt,
} from "firebase/firestore";
import {
  IChannelUser,
  IChannels,
  IChannel,
  IMessage,
  IMessageFile,
  IOTChatMessage,
  TChatType,
  ITopic,
  IChatMessage,
  IPinnedResource,
  ILinkPreview,
  IFileAttachment,
  TChatSystemType,
  ITeamRoomConfig,
} from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { collectStoredAnnotations } from "mobx/dist/internal";

const logger = new Logger("ChatDb");

const mentionRegex = /\[([^\[]+)\](\(mention:\/\/user\/([\w\d./?=#]+)\))/gm

const mentionParseRegex = /^\[([@\w\s\d]+)\]\(mention:\/\/user\/([\w\d./?=#]+)\)$/

const stripUndefined = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([a, b]) => b !== undefined))

export function getMentions(message?: string) {
  const mentions: string[] = []

  if (message) {
    const mentionMatches = message.match(mentionRegex)

    for (const mention of (mentionMatches || [])) {
      const mentionArgs = mention.match(mentionParseRegex)

      if (mentionArgs) {
        const userId = mentionArgs[2]
        if (!mentions.includes(userId))
          mentions.push(userId)
      }
    }
  }

  return mentions
}

function firestoreTimestampToDate(val: any): Date | null {
  if (val && (val instanceof Timestamp)) {
    return val.toDate();
  } else if (val instanceof Date) {
    return val;
  }
  return null;
}

function convertFBToIChannel(doc): IChannel {
  doc.crDate = firestoreTimestampToDate(doc.crDate);
  doc.lastUpdate = firestoreTimestampToDate(doc.lastUpdate);

  for (const topicId in doc.topics || {}) {
    if (doc.topics[topicId]?.crDate) {
      doc.topics[topicId].crDate = firestoreTimestampToDate(doc.topics[topicId].crDate);
    }

    if (doc.topics[topicId]?.lastUpdate) {
      doc.topics[topicId].lastUpdate = firestoreTimestampToDate(doc.topics[topicId].lastUpdate);
    }

    if (doc.topics[topicId]?.lastMessage) {
      doc.topics[topicId].lastMessage = convertFBToIMessage(doc.topics[topicId].lastMessage);
    }
  }
  return doc;
}

function convertFBToIChannelUser(doc): IChannelUser {
  doc.crDate = firestoreTimestampToDate(doc.crDate);

  for (const topicId in doc.topics || {}) {
    if (doc.topics[topicId]?.lastTyping) {
      doc.topics[topicId].lastTyping = firestoreTimestampToDate(doc.topics[topicId].lastTyping);
    }
  }

  return doc;
}

export function convertFBToIMessage(doc): IMessage {
  doc.crDate = firestoreTimestampToDate(doc.crDate);
  doc.editDate = firestoreTimestampToDate(doc.editDate);
  if (doc.replyMessage) {
    doc.replyMessage = convertFBToIMessage(doc.replyMessage);
  }
  return doc;
}

export class ChatDb {
  static checkAccess = async (fsDb: Firestore, teamId: string): Promise<boolean> => {
    try {
      const snapshot = await getDocs(
        query(collection(fsDb, `chats/${teamId}/channels`), where("chatType", "==", "channel"))
      );
      return true;
    } catch (err) {
      return false;
    }
  };

  static watchUserChannelList = (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callback: (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => void
  ) => {
    //fsDb
    //  .collection("config")
    //  .doc("config")
    //  .get()
    //  .then((moo) => logger.debug(" firedbconfig", moo.data()));

    const channelusers = query(
      collectionGroup(fsDb, "channelusers"),
      where("teamId", "==", teamId),
      where("userId", "==", userId)
    );

    const unsubscribe = onSnapshot(channelusers, (snapshot) => {
      const added: IChannelUser[] = [];
      const edited: IChannelUser[] = [];
      const deleted: string[] = [];

      // logger.debug("watchUserChannelList", snapshot, snapshot?.docChanges());

      snapshot?.docChanges().forEach((change) => {
        let doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);

        if (change.type === "added") {
          added.push(doc);
        }
        if (change.type === "modified") {
          edited.push(doc);
        }
        if (change.type === "removed") {
          deleted.push(doc.channelId);
        }
      });
      //logger.debug(
      //  `watchUserChannelList teamId: ${teamId} userId: ${userId} added, edited, deleted`,
      //  added,
      //  edited,
      //  deleted
      //);
      callback(added, edited, deleted);
    });

    return unsubscribe;
  };

  static watchJoinedChannels = (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callback: (added: IChannel[], edited: IChannel[], deleted: string[]) => void
  ) => {
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels`),
      where("userIds", "array-contains", userId),
      where("archived", "==", false)
    );
    const unsubscribe = onSnapshot(
      qry,
      (snapshot) => {
        const added: IChannel[] = [];
        const edited: IChannel[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannels", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            var doc = convertFBToIChannel(change.doc.data() as IChannel);

            added.push(doc);
          }
          if (change.type === "modified") {
            var doc = convertFBToIChannel(change.doc.data() as IChannel);
            edited.push(doc);
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
        //logger.debug(
        //  `watchChannels teamId: ${teamId} userId: ${userId} added, edited, deleted`,
        //  added,
        //  edited,
        //  deleted
        //);
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchChannels", error)
    );

    return unsubscribe;
  };

  static getChannel = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string
  ): Promise<IChannel> => {
    const snapshot = await getDoc(doc(fsDb, `chats/${teamId}/channels/${channelId}`));

    if (snapshot) {
      const doc = convertFBToIChannel(snapshot.data() as IChannel);
      return doc;
    } else {
      throw Error("Channel not found");
    }
  };

  static watchChannel = (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    callback: (doc: IChannel) => void
  ) => {
    const unsubscribe = onSnapshot(
      doc(fsDb, `chats/${teamId}/channels/${channelId}`),
      (snapshot) => {
        // logger.debug("watchChannel", snapshot);

        if (snapshot) {
          const data = convertFBToIChannel(snapshot.data() as IChannel);
          data && callback(data);
        }
      },
      (error) => logger.error("watchChannel", error)
    );

    return unsubscribe;
  };

  static watchChannelUsers = (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    callback: (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => void
  ) => {
    const _ref = collection(fsDb, `chats/${teamId}/channels/${channelId}/channelusers`);
    const unsubscribe = onSnapshot(
      _ref,
      (snapshot) => {
        const added: IChannelUser[] = [];
        const edited: IChannelUser[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannelUsers", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            const doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);

            added.push(doc);
          }
          if (change.type === "modified") {
            const doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);
            edited.push(doc);
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
        //logger.debug(
        //  `watchChannelUsers teamId: ${teamId} channelId: ${channelId} added`,
        //  added,
        //  `edited`,
        //  edited,
        //  `deleted`,
        //  deleted
        //);
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchChannelUsers", error)
    );

    return unsubscribe;
  };

  static watchChannelDirectory = (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callback: (added: IChannel[], edited: IChannel[], deleted: string[]) => void
  ) => {
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels`),
      where("chatType", "==", "channel"),
      where("archived", "==", false)
    );

    const unsubscribe = onSnapshot(
      qry,
      (snapshot) => {
        const added: IChannel[] = [];
        const edited: IChannel[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannelUsers", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            added.push(change.doc.data() as IChannel);
          }
          if (change.type === "modified") {
            edited.push(change.doc.data() as IChannel);
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
        logger.debug(
          `watchChannelDirectory teamId: ${teamId} userId: ${userId} added`,
          added,
          `edited`,
          edited,
          `deleted`,
          deleted
        );
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchChannelDirectory", error)
    );

    return unsubscribe;
  };

  static getChannels = async (fsDb: Firestore, teamId: string): Promise<IChannels> => {
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels`),
      where("chatType", "==", "channel")
    );
    const snapshot = await getDocs(qry);

    const channels = {};
    snapshot.forEach((doc) => {
      channels[doc.id] = convertFBToIChannel(doc.data() as IChannel);
    });
    return channels;
  };

  static joinChannel = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string
  ) => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );

    const user: IChannelUser = {
      teamId: teamId,
      channelId: channelId,
      userId: userId,
      crDate: serverTimestamp() as any,
      bookmarked: true,
      id: userId,
      topics: {
        default: {
          messageNum: 0,
          messageId: 0,
        },
      },
    };
    logger.info("joinChannel", channelId);

    await runTransaction(fsDb, async (transaction) => {
      const channelSnap = await transaction.get(channelRef);

      if (channelSnap && !channelSnap.exists()) {
        throw "Document does not exist!";
      }

      const channelDoc = channelSnap.data()! as IChannel;

      if (!user.topics) {
        user.topics = {};
      }

      user.topics.default.messageNum = channelDoc.topics?.default.messageNum || 0;
      user.topics.default.messageId = channelDoc.topics?.default.messageId || 0;

      transaction.set(channelUserRef, user);

      transaction.update(channelRef, { userIds: arrayUnion(userId) });
    });
  };

  static leaveChannel = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string
  ) => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );

    logger.info("leaveChannel", channelId);
    await runTransaction(fsDb, async (transaction) => {
      const channelDoc = await transaction.get(channelRef);
      if (channelDoc && !channelDoc.exists()) {
        throw "Document does not exist!";
      }

      let userIds: string[] = (channelDoc.data()?.userIds || []).filter(
        (channelUserId) => channelUserId != userId
      );

      transaction.update(channelRef, { userIds: arrayRemove(userId) });
      transaction.delete(channelUserRef);
    });
  };

  static bookmarkChat = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    bookmarked: boolean
  ) => {
    logger.info("bookmarkChat", channelId, bookmarked);
    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );
    await updateDoc(channelUserRef, {
      bookmarked: bookmarked || null,
    });
  };

  static bookmarkChatTopic = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    bookmarked: boolean
  ) => {
    logger.info("bookmarkChat", channelId, bookmarked);

    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );
    await updateDoc(channelUserRef, {
      ["topics." + topicId + ".bookmarked"]: bookmarked || null,
    });
  };

  static markChatRead = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    messageNum: number,
    messageId: number
  ) => {
    logger.info("marking as read", userId, channelId, messageNum, messageId);

    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );
    await updateDoc(channelUserRef, {
      ["topics." + topicId + ".messageNum"]: messageNum,
      ["topics." + topicId + ".messageId"]: messageId,
      ["topics." + topicId + ".hasMention"]: null,
    });
  };

  static addChannel = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    userIds: string[],
    name?: string,
    symbol?: string,
    color?: string,
    desc?: string,
    chatType: TChatType = "channel",
    teamDefault: boolean = false,
    roomConfig?: Partial<ITeamRoomConfig>
  ) => {
    // duplicated in firebase functions


    if (!userIds.includes(userId)) {
      userIds.push(userId);
    }

    const channelId = await runTransaction(fsDb, async (transaction) => {


      let chatKey: string | null = null;


      if (chatType === "chat") {
        chatKey = userIds.sort().join("|");

        const chatsnap = await getDocs(
          query(
            collection(fsDb, `chats/${teamId}/channels`),
            where("userIds", "array-contains", userId),
            where("archived", "==", false),
            where("chatType", "==", "chat"),
            where("chatKey", "==", chatKey)
          )
        );

        if (chatsnap && chatsnap.docs && chatsnap.docs.length > 0) {
          return chatsnap.docs[0].id;
        }
      }

      const docRef = doc(collection(fsDb, `chats/${teamId}/channels`));
      const newChannelId = docRef.id;

      const msg: IChannel = {
        id: newChannelId,
        teamId: teamId,
        userIds: userIds,
        createdBy: userId,
        chatType: chatType,
        chatKey: chatKey,
        teamDefault: teamDefault,
        crDate: serverTimestamp() as any,
        lastUpdate: serverTimestamp() as any,
        archived: false,
        topics: {
          default: {
            crDate: serverTimestamp() as any,
            lastUpdate: serverTimestamp() as any,
            createdBy: userId,
            messageNum: 0,
            messageId: 0,
            name: "default",
          },
        },
        roomConfig: roomConfig
      };

      if (name) {
        msg.name = name;
      }

      if (symbol) {
        msg.symbol = symbol;
      }

      if (desc) {
        msg.desc = desc;
      }

      if (color) {
        msg.color = color;
      }

      transaction.set(docRef, msg);

      for (let uid of userIds) {

        const channelUsersRef = doc(
          fsDb,
          `chats/${teamId}/channels/${newChannelId}/channelusers/${uid}`
        );

        let user: IChannelUser = {
          id: uid,
          channelId: newChannelId,
          teamId: teamId,
          userId: uid,
          crDate: serverTimestamp() as any,
          topics: {
            default: {
              messageNum: 0,
              messageId: 0,
            },
          },
        };

        if (userId == uid) {
          user.bookmarked = true;
        }
        transaction.set(channelUsersRef, user);
      }

      return newChannelId

    });

    return channelId;
  };

  static updateChannel = async (
    fsDb: Firestore,
    channelId: string,
    teamId: string,
    addUserIds: string[],
    name: string,
    symbol?: string,
    color?: string,
    desc?: string,
    isPrivate?: boolean,
    teamDefault?: boolean,
    roomConfig?: Partial<ITeamRoomConfig>
  ) => {
    logger.info("updating DB channel", channelId, name, desc, isPrivate, teamDefault);
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);

    await runTransaction(fsDb, async (transaction) => {
      const channelDoc = await transaction.get(channelRef);
      if (channelDoc && !channelDoc.exists()) {
        throw "Document does not exist!";
      }
      logger.info("made it here");

      for (let uid of addUserIds) {
        let user: IChannelUser = {
          id: uid,
          channelId: channelId,
          teamId: teamId,
          userId: uid,
          crDate: serverTimestamp() as any,
          topics: {
            default: {
              messageNum: 0,
              messageId: 0,
            },
          },
        };

        transaction.set(
          doc(fsDb, `chats/${teamId}/channels/${channelId}/channelusers/${uid}`),
          user
        );
      }
      logger.info("before");

      const channelData = channelDoc.data() as IChannel;

      logger.info("channelData", channelData);

      transaction.update(channelRef, {
        userIds: arrayUnion(...addUserIds),
        name: name,
        symbol: symbol,
        color: color,
        desc: desc,
        chatType:
          isPrivate != undefined
            ? isPrivate
              ? "private_channel"
              : "channel"
            : channelData.chatType,
        teamDefault: teamDefault ?? channelData.teamDefault,
        lastUpdate: serverTimestamp() as any,
        roomConfig: roomConfig,
      });

      logger.info("im here");
    });
  };

  static removeChannelUser = async (
    fsDb: Firestore,
    channelId: string,
    teamId: string,
    userId: string
  ) => {
    ChatDb.leaveChannel(fsDb, teamId, userId, channelId);
  };

  static addDirectChannel = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    userIds: string[]
  ) => {
    const channelId = await ChatDb.addChannel(
      fsDb,
      teamId,
      userId,
      userIds,
      undefined,
      undefined,
      undefined,
      undefined,
      "chat",
      false
    );

    return channelId;
  };

  static createTopic = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    name: string
  ) => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    const topicRef = doc(
      collection(fsDb, `chats/${teamId}/channels/${channelId}/topics`),
    );
    const topicId = topicRef.id;

    const topicDoc: ITopic = {
      createdBy: userId,
      crDate: serverTimestamp() as any,
      messageId: 0,
      messageNum: 0,
      lastUpdate: serverTimestamp() as any,
      name: name,
    };

    await updateDoc(channelRef, {
      ["topics." + topicId]: topicDoc,
    });

    return topicId;
  };

  static editTopic = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    name: string
  ) => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    await updateDoc(channelRef, {
      ["topics." + topicId + ".name"]: name,
      ["topics." + topicId + ".lastUpdate"]: serverTimestamp() as any,
    });
    return;
  };

  static archiveTopic = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    archived: boolean
  ) => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    await updateDoc(channelRef, {
      ["topics." + topicId + ".archived"]: archived,
    });
    return;
  };

  static setIsTyping = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    isTyping: boolean
  ) => {
    const lastTyping = isTyping ? serverTimestamp() as any : null;
    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );
    await updateDoc(channelUserRef, {
      ["topics." + topicId + ".lastTyping"]: lastTyping,
    });
  };

  static addChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    message: string,
    attachments?: Record<string, IFileAttachment>,
    replyMessage?: IOTChatMessage,
    linkPreview?: ILinkPreview,
    linkPreviews?: Record<string, ILinkPreview>,
    isSystem?: boolean,
    systemType?: TChatSystemType | null,
    systemMessage?: string | null,
  ): Promise<IChatMessage> => {
    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    const channelUserRef = doc(channelRef, "channelusers", userId);

    const snapshot = await getDoc(channelRef);

    if (!snapshot) {
      throw Error("Channel not found")
    }

    const channelDoc = snapshot.data() as IChannel

    const messageRef = doc(collection(channelRef, "topics", topicId, "messages"));

    const id = messageRef.id;
    const mentions: string[] = getMentions(message);

    const isResource = true;

    // const attachments: Record<string, IPendingFile> = {}

    const msg: IChatMessage = {
      channelId: channelId,
      topicId: topicId,
      msgType: "CHAT",
      id: id,
      teamId: teamId,
      crDate: serverTimestamp() as any,
      isSystem: !!isSystem,
      systemType: systemType || null,
      systemMessage: systemMessage || null,
      userId: userId, //sender
      message: message || "",
      messageNum: (channelDoc.topics?.[topicId].messageNum || 0) + 1,
      messageId: (channelDoc.topics?.[topicId].messageId || 0) + 1,
      reprocessMessageId: true,
      replyMessage: replyMessage || null,
      linkPreview: linkPreview ? { ...linkPreview, isResource } : null,
      linkPreviews: linkPreviews
        ? Object.fromEntries(
          Object.entries(linkPreviews).map(([k, linkPreview]) => [
            k,
            { ...linkPreview, isResource },
          ])
        )
        : null,
      linkPreviewFetched: true,
      attachments: attachments || null,
      mentions: mentions || null,
    };

    ChatDb.setIsTyping(fsDb, teamId, userId, channelId, topicId, false);

    setDoc(messageRef, msg);

    return {
      ...msg,
      crDate: new Date()
    };
  }


  static getMessageRef = (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string
  ) => {
    return doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/topics/${topicId}/messages/${messageId}`
    );
  }

  static updateChatMessagePendingFile = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    file: IMessageFile
  ) => {
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);

    const updates = {}
    if (file.completed) {

      const messageFile = {
        name: file.file.name,
        type: file.file.type,
        size: file.file.size,
        url: file.downloadUrl || null,
        uploaded: true,
        isResource: true,
        order: file.index!,
      }

      updates['attachments.' + file.id] = messageFile
    } else if (file.failed) {
      updates['attachments.' + file.id] = deleteField()

    } else {

      updates['attachments.' + file.id + ".progress"] = file.progress
    }

    updateDoc(docRef, updates);
  };

   static updateChatMessageFiles = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    files: IMessageFile[]
  ) => {
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);

    const updates = {}

    updates["files"] = files.map((cu) => ({
      name: cu.file.name,
      type: cu.file.type,
      size: cu.file.size,
      url: cu.downloadUrl!,
      isResource: true,
    }))

    updateDoc(docRef, updates);
  };

  static updateChatMessageLinkPreviews = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    linkPreviews: Record<string, ILinkPreview>
  ) => {
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);

    const isResource = true;
    const newLinkPreviews = Object.fromEntries(
      Object.entries(linkPreviews).map(([k, linkPreview]) => [k, { ...linkPreview, isResource }])
    );

    updateDoc(docRef, newLinkPreviews);
  };

  static editChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    message: string
  ) => {
    const mentions: string[] = getMentions(message);

    const msg = {
      message: message || "",
      editDate: serverTimestamp() as any,
      mentions: mentions,
    };

    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);
    updateDoc(docRef, msg);
  };

  static deleteChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string
  ) => {
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);
    deleteDoc(docRef);
  };

  static muteChatNotify = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    muted?: boolean
  ) => {
    logger.info("muteChatNotify", channelId, muted);

    const channelUserRef = doc(
      fsDb,
      `chats/${teamId}/channels/${channelId}/channelusers/${userId}`
    );
    await updateDoc(channelUserRef, {
      ["topics." + topicId + ".muteNotify"]: muted || null,
    });
  };

  static updateResource = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    resourceType: "link" | "attachment",
    updates: Partial<{
      isResource: boolean;
      isPinned: boolean;
      name: string;
    }>,
    url?: string,
    linkId?: string,
    fileId?: string,
  ) => {
    logger.debug(
      "updateResource ",
      teamId,
      channelId,
      topicId,
      messageId,
      resourceType,
      url,
      linkId,
      fileId,
      updates
    );
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);

    if (resourceType === "link") {
      const updatedFields = {};

      if (linkId === undefined) {
        Object.entries(updates).forEach(([key, value]) => {
          if (value !== undefined)
            updatedFields[`linkPreview.${key === "name" ? "title" : key}`] = value;
        });
      } else {
        // unlike files, this is an object!
        Object.entries(updates).forEach(([key, value]) => {
          if (value !== undefined)
            updatedFields[`linkPreviews.${linkId}.${key === "name" ? "title" : key}`] = value;
        });
      }
      logger.debug("updateResource fields: ", updatedFields);

      updateDoc(docRef, updatedFields);
    } else if (resourceType === "attachment") {
      const updatedFields = {};

      // unlike files, this is an object!
      Object.entries(updates).forEach(([key, value]) => {
        if (value !== undefined)
          updatedFields[`attachments.${fileId}.${key === "name" ? "title" : key}`] = value;
      });
      logger.debug("updateResource fields: ", updatedFields);

      updateDoc(docRef, updatedFields);

    } else {
      throw new Error(`invalid resourceType: ${resourceType}`);
    }
  };

  static savePinnedResources = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    pinnedResources: IPinnedResource[]
  ) => {
    logger.debug("savePinnedResources", teamId, channelId, stripUndefined);

    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    updateDoc(channelRef, {
      pinnedResources: pinnedResources.map((x) => stripUndefined(x)),
    });
  };

  static saveRecentResources = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    recentResources: Record<string, IPinnedResource>
  ) => {
    logger.debug("saveRecentResources", teamId, channelId, recentResources);

    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);
    return updateDoc(channelRef, {
      recentResources: Object.fromEntries(
        Object.entries(recentResources).map(([k, v]) => [k, stripUndefined(v)])
      ),
    });
  };

  static watchChannelMessages = (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: number,
    callback: (added: IMessage[], edited: IMessage[], deleted: string[]) => void
  ) => {
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels/${channelId}/topics/${topicId}/messages`),
      where("messageId", ">=", messageId),
      orderBy("messageId", "asc")
    );

    const unsubscribe = onSnapshot(
      qry,
      (snapshot) => {
        const added: IMessage[] = [];
        const edited: IMessage[] = [];
        const deleted: string[] = [];

        // logger.debug("watchChannelMessages", snapshot, snapshot?.docChanges());

        snapshot?.docChanges().forEach((change) => {
          if (change.type === "added") {
            added.push(convertFBToIMessage(change.doc.data() as IMessage));
          }
          if (change.type === "modified") {
            added.push(convertFBToIMessage(change.doc.data() as IMessage));
          }
          if (change.type === "removed") {
            deleted.push(change.doc.id);
          }
        });
        logger.debug(
          `watchChannelMessages teamId: ${teamId} channelId: ${channelId} added`,
          added,
          `edited`,
          edited,
          `deleted`,
          deleted
        );
        callback(added, edited, deleted);
      },
      (error) => logger.error("watchChannelMessages", error)
    );
    return unsubscribe;
  };

  static getChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string
  ): Promise<IMessage> => {
    const docRef = ChatDb.getMessageRef(fsDb, teamId, channelId, topicId, messageId);

    const _doc = await getDoc(docRef);
    const data = convertFBToIMessage(_doc.data() as IMessage);
    logger.info("getChatMessage ", channelId, data);

    return data;
  };

  static getChatMessagesSince = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    sinceMessageId: number
  ) => {
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels/${channelId}/topics/${topicId}/messages`),
      orderBy("messageId", "asc"),
      startAfter(sinceMessageId)
    );

    const snapshot = await getDocs(qry);

    const messages = {};
    snapshot.forEach((doc) => {
      messages[doc.id] = convertFBToIMessage(doc.data() as IMessage);
    });

    logger.info("getChatMessagesSince ", channelId, sinceMessageId, messages);

    return messages;
  };

  static getMoreChatMessages = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    lastMessageId: number,
    pageSize: number = 50,
    direction?: "forwards" | "backwards"
  ) => {
    logger.info(
      `getMoreChatMessages channelId: ${channelId} lastMessageId: ${lastMessageId} direction: ${direction} pagesize: ${pageSize} `
    );

    const qryDirection = direction === "backwards" ? "desc" : "asc";
    const qry = query(
      collection(fsDb, `chats/${teamId}/channels/${channelId}/topics/${topicId}/messages`),
      orderBy("messageId", qryDirection),
      startAt(lastMessageId),
      limit(pageSize)
    );

    const snapshot = await getDocs(qry);
    const messages = {};

    const docs = snapshot.docs;

    if (direction === "backwards") docs.reverse();

    docs.forEach((doc, index) => {
      messages[doc.id] = convertFBToIMessage(doc.data() as IMessage);
    });

    return messages;
  };

  static startRoom = async (
    fsDb: Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    startRoomCallback: (roomId: string | undefined, roomConfig: ITeamRoomConfig) => Promise<string | undefined>
  ) => {
    logger.debug("startRoom", teamId, channelId, topicId);

    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);

    await runTransaction(fsDb, async (transaction) => {
      logger.debug("startRoom runTransaction");

      const channelSnap = await transaction.get(channelRef);

      logger.debug("startRoom channelSnap", channelSnap);

      if (channelSnap && !channelSnap.exists()) {
        throw "Document does not exist!";
      }

      const channelDoc = channelSnap.data()! as IChannel;

      logger.debug("startRoom channelSnap", channelDoc);

      const channelRoomId: string | undefined = channelDoc.roomId;

      const roomConfig =  {
        name: channelDoc.name || "Meeting Room",
        channelId: channelId,
        topicId: topicId,
        desc: "",
        enabled: true,
        call: true,
        permanent: false,
        ...channelDoc.roomConfig
      }

      const roomId = await startRoomCallback(channelRoomId, roomConfig);

      transaction.update(channelRef, {
        roomId: roomId,
      });
    });
  };

  static archiveChannel = async (fsDb: Firestore, teamId: string, channelId: string) => {
    logger.info("archiving channel", teamId, channelId);

    const channelRef = doc(fsDb, `chats/${teamId}/channels/${channelId}`);

    await runTransaction(fsDb, async (transaction) => {
      const channelDoc = await transaction.get(channelRef);
      if (channelDoc && !channelDoc.exists()) {
        throw "Document does not exist!";
      }

      const channelData = channelDoc.data() as IChannel;

      const userIds = channelData.userIds || [];

      userIds.map((userId) => {
        transaction.delete(
          doc(fsDb, `chats/${teamId}/channels/${channelId}/channelusers/${userId}`)
        );
      });

      transaction.update(channelRef, {
        archived: true,
        userIds: [],
      });
    });
  };
}
