import React, { cloneElement, CSSProperties, useCallback, useState } from "react";
import { DSTheme } from '../DesignSystem/DSTheme';
import { validate } from 'validate.js';
import { debounce } from "throttle-debounce";
import Input from "../Components/Input";
import { Icons } from "@openteam/design-system";

const validationConstraints = {
  emailField: {
    email: {
      message: "^Please enter a valid email address"
    }
  },
};

export const isEmailValid = (e: string) => !validate({ emailField: e }, validationConstraints)?.emailField;

export const EmailField: React.FC<{
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>,
  onEnter?: (email: string) => void,
  onChangeValid?: (isValidEmail: boolean) => void;
  style?: CSSProperties
}> = (props) => {

  const [email, _setEmail] = useState<string>('');
  const [emailValid, _setEmailValid] = useState<boolean | undefined>(undefined);

  const setEmailValid = (v: boolean | undefined) => {
    _setEmailValid(v);
    props.onChangeValid && props.onChangeValid(!!v);
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    _setEmail(e.target.value)

    if (!emailValid) setEmailValid(undefined);

    debouncedValidate(e.target.value);

    props.onChange && props.onChange(e);
  }, [props.onChange]);

  const debouncedValidate = useCallback(debounce(500, (e: string) => {
    const result = e.length ? isEmailValid(e) : undefined;
    setEmailValid(result);
  }), []);

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
    if (e.key === "Enter") {
      const isValid = isEmailValid(email);
      setEmailValid(isValid);

      if (isValid) {
        props.onEnter && props.onEnter(email);
      }
    }

    props.onKeyPress && props.onKeyPress(e);
  }, [props.onKeyPress, email]);

  return (
    <div style={{ position: "relative", display: 'flex', alignItems: 'center', flex: 1, ...props.style}} >
      <Input
        style={{
          flex: 1,
          borderRadius: 4,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderColor: "#ccc",
          padding: 8,
        }}
        value={email}
        placeholder={"Email"}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {
        emailValid === true ?
          (cloneElement(Icons.tick, {
            size: 16,
            color: DSTheme.SuccessColor,
            style: {
              position: "absolute",
              right: 8,
              opacity: 0.6
            }
          })
          ) : null
      }
    </div>
  );

}
