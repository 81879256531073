import { IFile, IMessageFile, IOTChatMessage, TChatType } from "@openteam/models";
import { computed, makeObservable, observable } from "mobx";
import { ChatManager } from "./ChatManager";

export class ChatUIState {
  _chatmanager: ChatManager;

  @observable showChatMenu: boolean = false;

  constructor(chatmanager: ChatManager) {
    makeObservable(this)
    this._chatmanager = chatmanager;
  }

  // proxy
  getChannels = async () => this._chatmanager.getChannels();
  joinChannel = (channelId: string) => this._chatmanager.joinChannel(channelId);
  leaveChannel = (channelId: string) => this._chatmanager.leaveChannel(channelId);

  loadChannel = async (channelId: string) => this._chatmanager.loadChannel(channelId);
  goChannel = (channelId: string, topicId: string) => {
    this._chatmanager.goChannel(channelId, topicId);
    this.showChatMenu = true;
  };
  closeChannel = (channelId?: string, topicId?: string) =>
    this._chatmanager.closeChannel(channelId, topicId);

  createChannel = async (
    userIds: string[],
    name: string,
    symbol?: string,
    desc?: string,
    chatType: TChatType = "channel",
    teamDefault: boolean = false
  ) => this._chatmanager.createChannel(userIds, name, symbol, desc, chatType, teamDefault);

  updateChannel = async (channelId: string, userIds: string[], name: string, symbol?: string, desc?: string) =>
    this._chatmanager.updateChannel(channelId, userIds, name, symbol, desc);

  removeChannelUser = async (channelId: string, userId: string) =>
    this._chatmanager.removeChannelUser(channelId, userId);

  markChatRead = (channelId: string, topicId: string) =>
    this._chatmanager.markChatRead(channelId, topicId);

  muteChatNotify = (channelId: string, topicId: string, muted: boolean) =>
    this._chatmanager.muteChatNotify(channelId, topicId, muted);

  sendChatMessage = async (
    channelId: string,
    topicId: string,
    text,
    files,
    replyMessage?: IOTChatMessage
  ) => this._chatmanager.sendChatMessage(channelId, topicId, text, files, replyMessage);

  editChatMessage = async (channelId: string, topicId: string, messageId: string, text) =>
    this._chatmanager.editChatMessage(channelId, topicId, messageId, text);

  deleteChatMessage = async (channelId: string, topicId: string, messageId: string) =>
    this._chatmanager.deleteChatMessage(channelId, topicId, messageId);

  addDraftFiles = (channelId: string, topicId: string, files: FileList | File[] | IFile[]| null) =>
    this._chatmanager.addDraftFiles(channelId, topicId, files);

  bookmarkChat = (channelId: string, topicId: string, bookmarked: boolean) =>
    this._chatmanager.bookmarkChat(channelId, topicId, bookmarked);

  // computed in chatmanager
  get channels() {
    return this._chatmanager.channels;
  }
  get userId() {
    return this._chatmanager.userId;
  }
  get teamId() {
    return this._chatmanager.teamId;
  }
  get focusedChannelTopic() {
    return this._chatmanager.focusedChannelTopic;
  }
  get draftMessages() {
    return this._chatmanager.draftMessages;
  }
  get draftReplyMessage() {
    return this._chatmanager.draftReplyMessage;
  }
  get messageManager() {
    return this._chatmanager.messageManager;
  }
  // get chatUserIsTyping() {
  //   return this._chatmanager.chatUserIsTyping;
  // }
  get draftFiles() {
    return this._chatmanager.draftFiles;
  }
  get pendingMessages() {
    return this._chatmanager.pendingMessages;
  }

  setDraftFiles = (channelId: string, topicId: string, draftFiles: IMessageFile[]) =>
    this._chatmanager.setDraftFiles(channelId, topicId, draftFiles);

  setDraftReplyMessage = (channelId: string, topicId: string, message: IOTChatMessage) =>
    this._chatmanager.setDraftReplyMessage(channelId, topicId, message);

  deleteDraftReplyMessage = (channelId: string, topicId: string) =>
    this._chatmanager.deleteDraftReplyMessage(channelId, topicId);

  setIsTyping = async (channelId: string, topicId: string, isTyping: boolean) =>
    this._chatmanager.setIsTyping(channelId, topicId, isTyping);

  @computed get channelsByUser() {
    const myUserId = this.userId;
    const channels = this._chatmanager.channels;

    return Object.keys(channels)
      .filter((x) => channels[x].chatType == "chat")
      .reduce(function (obj, x) {
        const users = channels[x].userIds?.filter((userId) => userId != myUserId);
        if (users?.length == 1) {
          const userId = users[0];

          obj[userId] = x;
        }
        return obj;
      }, {});
  }

  @computed get channelChats() {
    const channels = this._chatmanager.channels;

    return Object.keys(channels)
      .filter(
        (channelId) =>
          channels[channelId]?.chatType == "channel" ||
          channels[channelId]?.chatType == "private_channel"
      )
      .sort((a, b) => ("" + channels[a].name).localeCompare(channels[b].name!));
  }

  @computed get directChats() {
    const channels = this._chatmanager.channels;
    const userChannels = this._chatmanager.userChannels;

    return Object.keys(channels)
      .filter(
        (channelId) =>
          (channels[channelId].chatType || "chat") == "chat" && userChannels[channelId]?.bookmarked
      )
      .sort((a, b) => {
        return this._chatmanager
          .getChatName(channels[a])
          .localeCompare(this._chatmanager.getChatName(channels[b]));
      });
  }

  @computed get unreadCount() {
    const channels = this._chatmanager.channels;
    const userChannels = this._chatmanager.userChannels;

    const unreadCount = {};

    for (const channelId in userChannels) {
      const channel = channels[channelId];
      const userChannel = userChannels[channelId];

      if (!(channelId in unreadCount)) {
        unreadCount[channelId] = {};
      }

      for (const topicId in channel?.topics || {}) {
        unreadCount[channelId][topicId] =
          (channel?.topics?.[topicId]?.messageNum || 0) -
          (userChannel?.topics?.[topicId]?.messageNum || 0);
      }
    }
    return unreadCount;
  }

  @computed get keyChats() {
    const channels = this._chatmanager.channels;

    return Object.keys(channels)
      .filter((channelId) => (channels[channelId].chatType || "chat") == "chat")
      .reduce(function (obj, x) {
        const key = getChatKey(channels[x].userIds || []);

        obj[key] = x;
        return obj;
      }, {});
  }

  getChannel = (channelId) => {
    return this._chatmanager.getChannel(channelId);
  };

  getUserChannel = (channelId) => {
    return this._chatmanager.getUserChannel(channelId);
  };
  // getChat = (channelId) => {
  //   return this.chats && this.chats[channelId];
  // };

  getUserChatByUser = (userId) => {
    return this.getUserChannel(this.channelsByUser[userId]);
  };

  // getChatByUser = (userId) => {
  //   return this.getChat(this.chatsByUser[userId]);
  // };

  getChatByUsers = (userIds) => {
    return this.keyChats[getChatKey(userIds)];
  };

  goDirectChannel = async (userId) => {
    const channelId = await this.startChannel([userId]);
    this.goChannel(channelId!, "default");
  };

  startChannel = async (userIds: string[]) => {
    let channelId: string | null = null;

    channelId = this.getChatByUsers([...userIds, this.userId]);

    if (!channelId) {
      channelId = await this._chatmanager.addDirectChannel(userIds);
    }

    return channelId;
  };

  toggleShowChats = () => {
    this.showChatMenu = !this.showChatMenu;
  };

  createTopic = async (channelId: string, name: string) => {
    return this._chatmanager.createTopic(channelId, name);
  };

  editTopic = async (channelId: string, topicId: string, name: string) => {
    return this._chatmanager.editTopic(channelId, topicId, name);
  };

  archiveTopic = async (channelId: string, topicId: string, archive: boolean) => {
    return this._chatmanager.archiveTopic(channelId, topicId, archive);
  };
}

const getChatKey = (userIds: string[]) => userIds.sort().join(",");
