import { MediaDeviceManager, OTGlobals, TestStream } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { H4, Panel, Row, Spinner } from "@openteam/design-system";
import { IMeetingRequestStatus, IMeetingTokenDetails } from '@openteam/models';
import { getAuth } from "firebase/auth";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Popover } from "react-tiny-popover";
import { CallVideo } from "../../Components/Call/CallVideo";
import { DSButton } from "../../DesignSystem/DSButton";
import { DSInput } from "../../DesignSystem/DSInput";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { Menu } from "../../Util/Menu";
import { getDeviceOptions } from "./CallButtons/callButtonUtil";



const logger = new Logger("MeetConnect")


interface Props {
  status?: IMeetingRequestStatus
  meetingData?: IMeetingTokenDetails
  requestMeeting: (name) => void
}

export const MeetConnect = observer((props: Props) => {
  const [name, setName] = useState<string>("");
  const [testStream, setTestStream] = useState<TestStream>();



  useEffect(() => {

    const getTestStream = async () => {
      if (props.meetingData && !testStream) {
        logger.debug("getTestStream")
        const auth = getAuth();

        const userId = auth.currentUser?.uid!
        const teamId = props.meetingData.teamId

        const _testStream = new TestStream(teamId, userId, OTGlobals.mediaDevices);
        await _testStream.enableAudio();
        await _testStream.enableVideo();
        setTestStream(_testStream)

      }
    }

    getTestStream();


    return () => {
      testStream?.shutdown();
    }
  }, [props.meetingData, testStream])


  const requestMeeting = () => {
    if (!name) {
      return
    }
    props.requestMeeting(name)
  }

  return (
    <>
      <H4>
        Your name
      </H4>
      <Row style={{ marginTop: 8, marginBottom: 16 }} >
        <DSInput
          style={{
            borderRadius: 8,
            width: 220,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderColor: "#ccc",
            padding: 8,
          }}
          value={name}
          placeholder={"Name"}
          onChange={(e) => setName(e.target.value)}
        />
      </Row>

      <Panel style={{
        height: 242, width: 242,
        overflow: 'hidden',
        borderRadius: DSTheme.BaseBorderRadius,
        marginBottom: 6
      }}>
        <CallVideo
          track={testStream?.stream.getVideoTracks()?.[0]}
          flip={true}
          hasVideo={true}
        />
      </Panel>
      <SettingsButton />
      {
        !props.status ?


          <Row style={{ justifyContent: 'flex-end', marginTop: 16 }}>
            <DSButton
              onClick={requestMeeting}
              analyticsEvent="requestAccess"
              disabled={name.length === 0}
              style={{
                backgroundColor: "#393B3C",
                color: 'white',
                padding: 10,
                borderRadius: 8,
                marginBottom: 16,
                width: 240,
              }}
              title={"Request Access"}
            >
            </DSButton>
          </Row>
          :
          props.status == 'P' ?
            <>
              <Spinner />
              <H4>Requesting Access</H4>
            </> :
              props.status == 'H' ?
              <H4 style={{ textAlign: 'center', padding: 10, }}>
                  Your host has noticed your request and will admit you shortly.
                </H4>
                :
                undefined
      }
    </>
  );
})

export const SettingsButton = observer(() => {
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false)

  const openMenu = React.useCallback(async () => {
    await MediaDeviceManager.updateMediaDevices();

    setPopoverOpen(!isPopoverOpen)
  }, [isPopoverOpen, setPopoverOpen]);


  return (
    <Popover
      padding={8}
      isOpen={isPopoverOpen}
      onClickOutside={() => setPopoverOpen(false)}
      content={<Menu
        template={[
          { enabled: false, label: `Select a Microphone` },
          ...getDeviceOptions("audioinput"),
          { type: "separator" },
          { enabled: false, label: `Select a Speaker` },
          ...getDeviceOptions("audiooutput"),
          { type: "separator" },
          { enabled: false, label: `Select a Camera` },
          ...getDeviceOptions("videoinput"),
        ]}
      />
      }
    >
      <div>
        <DSButton style={{ width: 230, color: 'black' }} onClick={openMenu}>
          <H4 style={{ flex: 1, }}>
            Device Settings
          </H4>
          <FaChevronDown size={12} />
        </DSButton>
      </div>
    </Popover>
  )
})
