import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import Logo from '../assets/logoo.svg?component';
import { DSImage } from '../DesignSystem/DSImage';
import {
  useLocation,
  useHistory
} from "react-router-dom";
import { useCallback } from 'react';
import { PanelError, PanelLoading } from './Panels';
import * as Analytics from "../Util/Analytics";
import Page from "../Util/Page";
import { Button, H1, H2, H3, Panel, Row, useTheme } from "@openteam/design-system";
import { Logger } from "@openteam/app-util";

const logger = new Logger('Invite');

type InvitePageProps = {
  inviteToken: string | null
};

export const InvitePage = (props: React.PropsWithChildren<InvitePageProps>) => {
  let location = useLocation();
  let history = useHistory();
  const theme = useTheme()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inviteDetails, setInviteDetails] = useState<any>()

  const loadInvite = useCallback(async () => {
  logger.debug("loadInvite inviteToken", props.inviteToken)
    const functions = getFunctions();
    const checkInviteToken = httpsCallable(functions, 'checkInviteToken');
    const result: any = await checkInviteToken({
      inviteToken: props.inviteToken,
    })

    logger.debug("loadInvite answer", result)

    if (!result.data.valid) {
      setError(true)
    }

    setInviteDetails(result.data)
    setLoading(false)
  }, [props.inviteToken])

  useEffect(() => {
    Analytics.setCurrentScreen("InvitePage");
    loadInvite()

  }, [loadInvite])


  const acceptInvite = () => {
    const acceptLocation = {
      pathname: '/appLogin',
      search: location.search,
      state: { run: true }
    }
    history.push(acceptLocation)
  }
  const inviteUser = inviteDetails?.inviterName
  const imageUrl = inviteDetails?.imageUrl
  const teamName = inviteDetails?.teamName

  return (
    <Page>
      {
        loading ? (
          <PanelLoading text={"Loading invite details"} />
        ) :
          error ? (
            <PanelError>
              <H2 style={{ marginBottom: 24, fontSize: 18, textAlign: "center" }}>
                Error loading invite
              </H2>
            </PanelError>
          ) : (
            <Panel style={{ alignItems: 'center' }}>
              {
                imageUrl ?
                  <DSImage
                    key={`teamimage`}
                    url={imageUrl}
                    maxWidth={80}
                    maxHeight={80}
                    style={{
                      marginTop: 5,
                      marginBottom: 30
                    }}
                  />
                  :
                  null
              }
              <H1 wrap style={{
                marginBottom: 40, fontSize: 32, maxWidth: 500,
                textAlign: "center"
              }}>
                You've been invited to join <span style={{ color: theme.palette.primary.main }}>{teamName}</span>
              </H1>
              <Button
                onClick={acceptInvite}
                analyticsEvent="AcceptInvite"
                startIcon={<Logo style={{ height: 16, width: 16 }} />}
                color="secondary"
                size="medium"
              >
                Accept invite
              </Button>
            </Panel>
          )}
    </Page>
  );
};
