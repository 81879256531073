import React, { CSSProperties, useRef, useState } from "react";
import { observer } from "mobx-react";
import { DSPanel, DSRow, DSWrappedRow } from "../../DesignSystem/DSLayout";
import { ICallDetails, KCallId, KSpaceId, KSpaceUserId } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { DSBody, DSH3, DSH4, DSPrint } from "../../DesignSystem/DSText";
import { DSButton, DSCircleIconButton, DSLongButton } from "../../DesignSystem/DSButton";
import UserIcon from "../User/UserIcon";
import { useEffect } from "react";

import { CallDetailsDb } from "../../../../core/src/fire/CallDetailsDB";
import { getDuration, getFriendlyDate, getFullDate, timeSince } from "../../Util/DateFormat";
import { DSImage } from "../../DesignSystem/DSImage";
import { IPluginConfig, OTGlobals, OTUITree } from "@openteam/app-core";
import { DataState } from "../../Data/DataState";
import { FaChevronDown, FaChevronLeft, FaPlus } from "react-icons/fa";
import { BiAddToQueue } from "react-icons/bi";
import { getFirestore } from "firebase/firestore";

const logger = new Logger("ChatCallSummary")


type Props = {
  spaceId: KSpaceId
  callId: KCallId
  callDetails?: ICallDetails
  loadCallSummary?: (callId) => void
  loadCallChat?: (callId) => void
  sendURL?: (text: string, systemMessage: string) => void
}

export const ChatCallSummary = observer(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { callDetails } = props

    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const openLink = (url: string) => {
    }

    const savePluginToChat = props.sendURL ? async (pluginId: string, pluginURL: string) => {
      await CallDetailsDb.savePluginResourceToChat(
        getFirestore(),
        props.spaceId,
        props.callId,
        pluginId,
      );
      const systemMessage = `added from call @ ${getFriendlyDate(callDetails?.crDate)}`;

      props.sendURL?.(pluginURL, systemMessage);
      props.loadCallSummary?.(props.callId);
    } : undefined


    if (!callDetails) {
      return null
    }

    return (
      <DSPanel
        className="panel"
        style={{
          backgroundColor: DSTheme.ChatCallSummaryBackground,
          borderRadius: DSTheme.BaseBorderRadius,
        }}>
        <DSRow style={{
          padding: "4 14",
          borderBottomStyle: 'solid',
          borderBottomColor: DSTheme.ChatBodyColor,
          borderBottomWidth: 2,
          alignItems: 'center',
          borderRadius: isExpanded ? undefined : DSTheme.BaseBorderRadius,
        }}>
          <DSH3 style={{}}>
            Call with
          </DSH3>
          <DSWrappedRow style={{ flex: 1 }}>
            {Object.keys(callDetails.users).map(userId =>
              <CallSummaryUser
                key={userId}
                spaceId={props.spaceId}
                userId={userId}
                name={callDetails.users[userId].name}
                imageUrl={callDetails.users[userId].imageUrl}
              />
            )}
          </DSWrappedRow>
          <DSButton
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ marginLeft: 8 }}
          >
            {isExpanded ? <FaChevronDown size={16} /> : <FaChevronLeft size={16} />}
          </DSButton>
        </DSRow>
        {isExpanded && (
          <DSPanel style={{
            padding: "8 14",
          }}>
            <DSH3 wrap>
              Started: <span style={{ fontWeight: 400 }}>
                {getFullDate(callDetails.crDate)}
                {
                  callDetails.endDate ?
                    `, for a duration of ${getDuration(callDetails.crDate, callDetails.endDate)}.`
                    :
                    undefined
                }
              </span>

            </DSH3>
            {
              callDetails.resources ?
                <DSRow style={{ paddingTop: 2 }}>
                  <DSH3 style={{ marginTop: 4 }}>
                    Resources:
                  </DSH3>
                  <DSWrappedRow>
                    {
                      Object.entries(callDetails.resources)
                        .filter(([pluginId, plugin]) => plugin.pluginType)
                        .sort(([pluginId1, plugin1], [pluginId2, plugin2]) => plugin1.crDate - plugin2.crDate)
                        .map(([pluginId, plugin]) => {
                          const pluginConfig = OTGlobals.pluginConfigList[plugin.pluginType] as IPluginConfig | undefined;
                          logger.debug("pluginConfig", pluginConfig, plugin)

                          const pluginURL = plugin.pluginURL;

                          return (
                            <DSButton key={plugin.pluginId} onClick={() => plugin.pluginURL && openLink(plugin.pluginURL || plugin.args.url)} style={{ margin: 4, marginLeft: 16 }}>
                              {plugin.linkPreview?.favicon ?
                                <DSImage height={20} width={20} url={plugin.linkPreview?.favicon} style={{ borderRadius: 4 }} /> :
                                pluginConfig?.icon({ color: pluginConfig.iconColour, size: 20 })
                              } <DSBody style={{ marginLeft: 12, marginRight: 8 }}>{plugin.linkPreview?.title || pluginConfig?.name || plugin.pluginType}</DSBody>

                              {pluginURL && (
                                !plugin.savedToChat && savePluginToChat ? (

                                  <DSCircleIconButton
                                    onClick={() => { savePluginToChat(pluginId, pluginURL) }}
                                    style={{ padding: 4 }}
                                    icon={
                                      <BiAddToQueue size={12} style={{ color: DSTheme.PanelContrastColor }} />
                                    }
                                  />
                                ) : null
/*                                   <div style={{
                                    backgroundColor: DSTheme.SuccessColor,
                                    color: "white",
                                    borderRadius: 16,
                                    padding: 4,
                                  }}>
                                    <BiAddToQueue size={12} />
                                  </div>
                                )
 */                              )}
                            </DSButton>
                          );
                        })
                    }
                  </DSWrappedRow>
                </DSRow>

                :
                undefined
            }

            <DSRow style={{ justifyContent: 'flex-end' }}>
              {
                callDetails.users[OTUITree.auth.userId] && callDetails.messageNum > 0 ?
                  <DSButton
                    style={{
                      marginTop: 0,
                      padding: 4,
                      borderRadius: 4,
                      backgroundColor: DSTheme.EmphasisColor,
                    }}
                    onClick={() => props.loadCallChat?.(props.callId)}>
                    <DSPrint>
                      Show Chat
                    </DSPrint>


                  </DSButton>
                  :
                  null
              }
            </DSRow>
          </DSPanel>
        )}
      </DSPanel>
    );
  }))


type CallSummaryUserProps = {
  spaceId: KSpaceId
  userId: KSpaceUserId
  name: string
  imageUrl?: string | null
}

export const CallSummaryUser = observer(
  React.forwardRef<HTMLDivElement, CallSummaryUserProps>((props, ref) => {
    const space = DataState.spaces[props.spaceId]

    const canOpenChat = props.userId in space.users && props.userId !== OTUITree.auth.userId
    const onUserClick = () => {
    }

    return <DSLongButton
      style={{
        marginLeft: 6,
        marginTop: 2,
        marginBottom: 2,
        padding: "4 6",
        backgroundColor: DSTheme.PanelLighterColor,
      }}
      onClick={canOpenChat ? onUserClick : undefined}
      disabled={!canOpenChat}
    >
      <UserIcon
        key={props.userId}
        size={16}
        user={{
          id: props.userId,
          name: props.name,
          imageUrl: props.imageUrl
        }}
        hideStatusDot={true}
      />
      <DSH4 style={{
        paddingLeft: 6
      }}>
        {
          props.name || "Unknown"
        }
      </DSH4>

    </DSLongButton>

  }))