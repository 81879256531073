import { Column, Row, H3, H2, Button } from "@openteam/design-system";
import { isWindows, isMacOs, isMobile } from "react-device-detect";
import { FaApple, FaWindows, FaLinux } from "react-icons/fa";
import { DSButton } from "../DesignSystem/DSButton";

const DOWNLOAD_URL = {
  'MacOS': "https://download.ehlo.space/latest/Ehlo.dmg",
  'Linux': "https://snapcraft.io/ehlo",
  'Windows': "https://download.ehlo.space/latest/EhloSetup.exe",
}


const DownloadApp: React.FC = (props) => {
  const isUnknownOS = !isWindows && !isMacOs;

  const downloadApp = async (platform: "MacOS" | "Linux" | "Windows") => {
    window.open(DOWNLOAD_URL[platform], '_blank');
  }
  return (
    <>

      {!isMobile ?
        <Column style={{ alignItems: "center"}}>
          {(isMacOs) &&
            <Button
              onClick={() => downloadApp('MacOS')}
              analyticsEvent="DownloadMacOS"
              startIcon={<FaApple />}
              color="secondary"
              fullWidth
              size="medium"
            >
              Download for MacOS
            </Button>
          }
          {(isWindows) &&
            <Button
              onClick={() => downloadApp('Windows')}
              analyticsEvent="DownloadWindows"
              startIcon={<FaWindows />}
              color="secondary"
              fullWidth
              size="medium"
            >
              Download for Windows
            </Button>
          }
          {(isUnknownOS) &&
            <Button
              onClick={() => downloadApp('Linux')}
              analyticsEvent="DownloadLinux"
              startIcon={<FaLinux />}
              color="secondary"
              fullWidth
              size="medium"
            >
              Download for Linux
            </Button>
          }
        </Column>
        :
        undefined
      }
      <Row style={{ alignItems: 'center', marginTop: 30 }}>
        <H2 style={{ color: "black", fontSize: 18, textAlign: "center" }}>
          Available for:
        </H2>
      </Row>
      <Row style={{ alignItems: 'center', color: "#393B3C", }}>
        <DSButton
          onClick={() => downloadApp('MacOS')}
          analyticsEvent="DownloadMacOS"
          style={{
            marginTop: 16,
            padding: "10px 14px",
            borderRadius: 8,
            marginBottom: 6
          }}>
          <Column style={{ alignItems: 'center' }}>
            <FaApple style={{ height: 24, width: 24 }} />
            <H2 style={{ fontSize: 18, margin: 10, textAlign: "center" }}>
              MacOS
            </H2>
          </Column>
        </DSButton>
        <DSButton
          onClick={() => downloadApp('Windows')}
          analyticsEvent="DownloadWindows"
          style={{
            marginTop: 16,
            padding: "10px 14px",
            borderRadius: 8,
            marginBottom: 6
          }}>
          <Column style={{ alignItems: 'center' }}>
            <FaWindows style={{ height: 24, width: 24 }} />
            <H2 style={{ fontSize: 18, margin: 10, textAlign: "center" }}>
              Windows
            </H2>
          </Column>
        </DSButton>
        <DSButton
          onClick={() => downloadApp('Linux')}
          analyticsEvent="DownloadLinux"
          style={{
            marginTop: 16,
            padding: "10px 14px",
            borderRadius: 8,
            marginBottom: 6
          }}>
          <Column style={{ alignItems: 'center' }}>
            <FaLinux style={{ height: 24, width: 24 }} />
            <H2 style={{ fontSize: 18, margin: 10, textAlign: "center" }}>
              Linux
            </H2>
          </Column>
        </DSButton>
      </Row>
    </>
  );
}

export default (DownloadApp);
