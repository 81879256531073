import events from "events";
import * as mediasoupClient from "mediasoup-client";
import { types as MSTypes } from "mediasoup-client/lib";
import protooClient from "protoo-client";
import { ITeamUser, IPeerMsg, IStreamUser, IPeerInfo } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { LocalStreamDetails, RemoteStream } from "./MediaDeviceManager";
import { AwaitLock } from "@openteam/app-util";
import { FireUtils } from "./fire";
import { OTUserInterface } from "./OTUserInterface";
import { OTGlobals } from "./OTGlobals";
import { OTAppCoreData } from "./OTAppCoreData";

const VIDEO_CONSTRAINS = {
  qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
};

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

const VIDEO_SIMULCAST_ENCODINGS = [
  // { scaleResolutionDownBy: 4 },
  { scaleResolutionDownBy: 2 }, // Scale full resolution down to tile size
  // { scaleResolutionDownBy: 8/3 }, // Scale full resolution down to tile size
  { scaleResolutionDownBy: 1 },
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS = [{ scalabilityMode: "S2T3_KEY" }];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS = [{ scalabilityMode: "S3T3", dtx: true, active: true }];
const CAMERA_CODEC = "h264";
const SCREEN_SHARE_CODEC = "h264";


interface IConsumerScore {
    score: number
    producerScore: number
    producerScores: number[]
}

interface ILayers {
  spatialLayer: number;
  temporalLayer?: number;
}
interface IConsumer {
  consumer: MSTypes.Consumer;
  peerId: string;
  streamType: string;
  kind: "audio" | "video";
  codec: string;
  currentLayer: ILayers | null;
  requestedLayers?: ILayers;
  score?: IConsumerScore
  spatialLayers: number;
  temporalLayers: number;
  receivedAt: number;
}

let __instance = 0;

const _STATS_INTERVAL_MS = 5 * 1000;
const _PING_INTERVAL_MS = 2 * 1000;

export default class MediaSoupStreamManager extends events.EventEmitter {
  roomState!: string;

  _userStreams: { [userId: string]: { [streamType: string]: RemoteStream } } = {};

  teamId: string;
  roomId: string;
  stateLock = new AwaitLock();

  _myUserId: string;
  _sessionToken: string;
  _closed: boolean;

  _mediasoupDevice!: MSTypes.Device;
  _sendTransport!: MSTypes.Transport | null;
  _recvTransport!: MSTypes.Transport | null;

  _websocketTransport!: MSTypes.Transport | null;
  _peer: protooClient.Peer;

  _users: { [id: string]: ITeamUser } = {};
  _focusedUsers: string[] = [];

  _producers: Map<string, MSTypes.Producer>;
  _producerRequestedLayers: Map<string, number>;

  _dataProducer!: MSTypes.DataProducer | null;
  _state: any = {};

  _consumers: Map<string, IConsumer>;
  _dataConsumers: Map<string, MSTypes.DataConsumer>;
  _statsRequested: Set<string>;
  _peers: Map<string, IPeerInfo>;

  _produce: boolean = true;

  _consume: boolean = true;

  _forceTcp: boolean = false;

  _useDataChannel: boolean = true;

  _pingTimedOut = false;
  _bandwidthLow = false;

  _lastPingResp;
  _pingTimer;
  _statsTimer;
  _logger;

  constructor(userId: string, sessionToken: string, teamId: string, roomId: string) {
    super();
    this._logger = new Logger("MSRoom~" + __instance++);
    this._closed = false;

    this._myUserId = userId;
    this._sessionToken = sessionToken;

    this.teamId = teamId;
    this.roomId = roomId;

    this._mediasoupDevice = new mediasoupClient.Device();
    if (!this._mediasoupDevice) {
      throw new Error("Unable to create mediasoup device");
    }
    this._producers = new Map();
    this._producerRequestedLayers = new Map();
    this._consumers = new Map();
    this._dataConsumers = new Map();
    this._statsRequested = new Set();
    this._peers = new Map();
  }

  get connectedUsers() {
    return Array.from(this._peers.keys());
  }

  get teamData() {
    return OTGlobals.getTeamData(this.teamId);
  }

  updateUsers = async (users: { [id: string]: IStreamUser }) => {};

  setCallUsers(users: string[]) {}

  clearCallUsers() {}

  getUserStreams = (userId) => {
    if (this._userStreams[userId]) {
      return Object.values(this._userStreams[userId]);
    }
    return [];
  };

  start = async () => {
    let url;

    try {
      url = await getProtooURL(this._myUserId, this._sessionToken, this.teamId, this.roomId);
      this._logger.debug(`Connecting to`, url);
    } catch (err) {
      this._logger.warn(`Failed to generate room url, retrying`, err);
      setTimeout(() => {
        this.start();
      }, 5000);
      return;
    }

    if (this._peer) {
      this._logger.warn(`Already started`);
      return;
    }

    this._logger.info(`Starting MediaSoup Room: ${this.roomId} connecting to websocket`);

    this._websocketTransport = new protooClient.WebSocketTransport(url, {
      retry: {
        retries: 10,
        factor: 1.5,
        minTimeout: 0.5 * 1000,
        maxTimeout: 2 * 1000,
      },
    });

    this._peer = new protooClient.Peer(this._websocketTransport);
    this.roomState = "connecting";

    this._peer.on("open", () => this._joinRoom());

    this._peer.on("failed", () => {
      this._logger.info(`Websocket connection failed ${this.roomId}`);
    });

    this._peer.on("disconnected", () => {
      this._logger.error(`Websocket disconnected, cleaning up transports ${this.roomId}`);

      if (this._sendTransport) {
        this._sendTransport.close();
        this._sendTransport = null;
      }

      if (this._recvTransport) {
        this._recvTransport.close();
        this._recvTransport = null;
      }

      this.emit("disconnected");
    });

    this._peer.on("close", () => {
      this._logger.info(`Websocket onclose, stopping ${this.roomId}`);

      if (this._closed) {
        return;
      }

      this.stop();
    });

    this._peer.on("request", this._handleRequest);

    this._peer.on("notification", this._handleNotification);
  };

  stop = async () => {
    this.stateLock.acquireAsync();

    try {
      if (this._closed) {
        this._logger.warn(`MediaSoup Room already closed ${this.roomId}`);
        return;
      }

      this._logger.info(`Stopping MediaSoup Room ${this.roomId}`);

      if (this._pingTimer) {
        clearInterval(this._pingTimer);
      }

      if (this._statsTimer) {
        clearInterval(this._statsTimer);
      }

      if (this._sendTransport) {
        this._sendTransport.close();
      }

      if (this._recvTransport) {
        this._recvTransport.close();
      }

      if (this._peer) {
        this._peer.close();
      }
      if (this._websocketTransport) {
        this._websocketTransport.close();
      }
    } finally {
      this.roomState = "closed";
      this._closed = true;
      this._pingTimer = null;
      this._statsTimer = null;
      this._sendTransport = null;
      this._recvTransport = null;
      this._websocketTransport = null;
      this._peer = null;

      this.stateLock.release();
      this.emit("disconnected");
    }
  };

  _handleRequest = async (request, accept, reject) => {
    this._logger.debug('proto "request" event [method:%s, data:%o]', request.method, request.data);

    switch (request.method) {
      case "newConsumer": {
        const { peerId, producerId, id, kind, rtpParameters, type, appData, producerPaused } =
          request.data;

        const { streamType } = appData;

        if (this._consumers.has(id)) {
          this._logger.warn(`Already have consumer ${id} ${streamType}:${kind} of ${peerId}`);
          accept();
        } else if (!this._recvTransport) {
          this._logger(`Can't consume no recvTransport`);
          reject(500, "No transport");
        } else {
          try {
            const consumer = await this._recvTransport!.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              appData: { ...appData, peerId }, // Trick.
            });

            // Store in the map.

            const { spatialLayers, temporalLayers } = mediasoupClient.parseScalabilityMode(
              consumer.rtpParameters.encodings?.[0].scalabilityMode || ""
            );

            const currentLayer = null;
            const codec = consumer.rtpParameters.codecs[0].mimeType.split("/")[1];
            this._consumers.set(consumer.id, {
              consumer,
              peerId,
              streamType,
              kind,
              codec,
              currentLayer,
              spatialLayers,
              temporalLayers,
              receivedAt: new Date().getTime(),
            });

            consumer.on("transportclose", () => {
              this._removeConsumer(consumer.id);
            });

            this._logger.info(
              `New ${streamType}:${kind} consumer ${consumer.id} for ${peerId}:${producerId}, ${codec} S${spatialLayers}T${temporalLayers}`
            );

            try {
              const settings = consumer.track.getSettings();
              this._logger.debug(`settings are`, settings);
            } catch {}

            if (!this._userStreams[peerId]) {
              this._userStreams[peerId] = {};
            }

            if (!this._userStreams[peerId][streamType]) {
              const streamDetails = new RemoteStream(this.teamId, this.roomId, peerId, streamType);

              if (streamType === "camera") {
                streamDetails.audioGroupId = this._peers.get(peerId)?.audioGroupId ?? null;
                this._logger.info(
                  `Adding audioGroupId ${JSON.stringify(this._peers.get(peerId))} to new stream `
                );
              }

              streamDetails.addTrack(consumer.track);
              this._userStreams[peerId][streamType] = streamDetails;
              if (streamType == "screen") {
                this.emit("screenshare", peerId, streamDetails.stream!.id);
              }
            } else {
              this._userStreams[peerId][streamType].addTrack(consumer.track);
            }
            this._userStreams[peerId][streamType].enable(kind, producerPaused == false);

            accept();

            this.emit("streamsupdated");
          } catch (error) {
            this._logger.error(
              `"newConsumer" for ${peerId}:${producerId} request failed:%o`,
              error
            );
            reject(503, error);
          }
        }

        break;
      }
      case "newDataConsumer": {
        if (!this._consume) {
          reject(403, "I do not want to data consume");

          break;
        }

        if (!this._useDataChannel) {
          reject(403, "I do not want DataChannels");

          break;
        }

        const {
          peerId, // NOTE: Null if bot.
          dataProducerId,
          id,
          sctpStreamParameters,
          label,
          protocol,
          appData,
        } = request.data;

        if (this._dataConsumers.has(id)) {
          this._logger.warn(`Already have dataConsumer ${id} of ${peerId}`);
          accept();
          return;
        }

        try {
          const dataConsumer = await this._recvTransport!.consumeData({
            id,
            dataProducerId,
            sctpStreamParameters,
            label,
            protocol,
            appData: { ...appData, peerId }, // Trick.
          });

          // Store in the map.
          this._dataConsumers.set(dataConsumer.id, dataConsumer);

          dataConsumer.on("transportclose", () => {
            this._dataConsumers.delete(dataConsumer.id);
          });

          dataConsumer.on("open", () => {
            this._logger.debug('DataConsumer "open" event', peerId);
          });

          dataConsumer.on("close", () => {
            this._logger.debug('DataConsumer "close" event', peerId);
            this._dataConsumers.delete(dataConsumer.id);

            //store.dispatch(requestActions.notify(
            //    {
            //        type: 'error',
            //        text: 'DataConsumer closed'
            //    }));
          });

          dataConsumer.on("error", (error) => {
            this._logger.error('DataConsumer "error" event:%o', error);

            //store.dispatch(requestActions.notify(
            //    {
            //        type: 'error',
            //        text: `DataConsumer error: ${error}`
            //    }));
          });

          dataConsumer.on("message", (data) => {
            this._logger.debug(
              'DataConsumer "message" event [streamId:%d]',
              dataConsumer.sctpStreamParameters.streamId
            );

            switch (dataConsumer.label) {
              case "general": {
                const message = JSON.parse(data);
                this.recvMessage(peerId, message);

                break;
              }

              case "bot": {
                this.emit("bot-message", data);
                break;
              }
            }
          });

          // We are ready. Answer the protoo request.
          accept();
        } catch (error) {
          this._logger.error('"newDataConsumer" request failed:%o', error);

          throw error;
        }

        break;
      }
      default: {
        this._logger.debug(`method ${request.method} unhandled`);
      }
    }
  };

  _handleNotification = (notification) => {
    if (!["activeSpeaker", "ping"].includes(notification.method)) {
      this._logger.debug(
        'proto "notification" event [method:%s, data:%o]',
        notification.method,
        notification.data
      );
    }

    try {
      switch (notification.method) {
        case "ping": {
          // We recieved a ping, nothing to do
          break;
        }
        case "newPeer": {
          const { id: peerId, ...peerInfo } = notification.data;

          const isReconnect = this._peers.has(peerId);
          if (this._peers.has(peerId)) {
            this._logger.info(`Peer ${peerId} reconnected`);
          } else {
            this._logger.info(`New Peer: ${peerId}`);
          }

          this._peers.set(peerId, peerInfo);
          this._logger.info(`Set peerInfo for ${peerId} to ${this._peers.get(peerId)}`);
          this.emit("peerconnected", peerId, peerInfo, isReconnect);
          break;
        }
        case "peerClosed": {
          this._logger.info(`Peer Closed: ${notification.data.peerId}`);
          this._peers.delete(notification.data.peerId);
          this.emit("peerdisconnected", notification.data.peerId);
          break;
        }
        case "consumerClosed": {
          const { consumerId } = notification.data;
          this._removeConsumer(consumerId);
          break;
        }
        case "dataConsumerClosed": {
          break;
        }
        case "consumerPaused": {
          const { consumerId } = notification.data;
          this._enableConsumer(consumerId, false);
          break;
        }
        case "consumerResumed": {
          const { consumerId } = notification.data;
          this._enableConsumer(consumerId, true);
          break;
        }
        case "activeSpeaker": {
          const { peerId: speakerPeerId, volume: volumeDb } = notification.data;

          if (this.teamData.capabilities.reduceNonSpeakerVolume === true) {
            const volumeFactor = Math.min(
              1,
              OTAppCoreData.remoteConfig?.NonSpeakerVolumeFactor ?? 0.3
            );
            const isSpeaking = speakerPeerId !== undefined && volumeDb > -40;

            this._peers.forEach((peerInfo, peerId) => {
              let newVolume = peerInfo.volume;

              if (peerId === speakerPeerId) {
                newVolume = undefined;
              } else if (peerInfo.isAudioGroupSpeaker === undefined) {
                newVolume = isSpeaking ? volumeFactor : undefined;
              }

              if (peerInfo.volume !== newVolume) {
                peerInfo.volume = newVolume;
                this._logger.debug(`peerInfo updated ${peerId} to ${JSON.stringify(peerInfo)}`);
                this.emit("peerupdated", peerId, peerInfo);
              }
            });
          }

          if (this._userStreams[speakerPeerId] && this._userStreams[speakerPeerId]["camera"]) {
            this.emit("speaker", this._userStreams[speakerPeerId]["camera"]);
          } else if (!speakerPeerId) {
            this.emit("speaker", null);
          }
          break;
        }
        case "producerScore": {
          const { producerId, score } = notification.data;
          this._setProducerScore(producerId, score);
          break;
        }
        case "consumerScore": {
          const { consumerId, score } = notification.data;
          this._setConsumerScore(consumerId, score);
          break;
        }
        case "consumerLayersChanged": {
          const { consumerId, spatialLayer, temporalLayer } = notification.data;
          this._setConsumerLayers(consumerId, spatialLayer, temporalLayer);
          break;
        }
        case "producerRequestedLayer": {
          const { producerId, spatialLayer } = notification.data;
          if (this._producers.get(producerId)?.kind === "video") {
            this._producerRequestedLayers.set(producerId, spatialLayer);
            this.updateSenderResolution();
          }
          break;
        }
        case "bandwidth": {
          this._bandwidthLow = notification.data.status !== "ok";
          this.emit("bandwidth", notification.data);
          this.setFocusedUsers(this._focusedUsers, true);
          break;
        }
        case "peerNetworkStatus": {
          this._setPeerNetworkStatus(notification.data);
          break;
        }
        case "peerInfoUpdate": {
          const { peerId, info } = notification.data;
          const peerInfo = this._peers.get(peerId);

          if (peerId === this._myUserId) {
            Object.entries(info).forEach(([key, val]) => {
              if (key === "audioGroupId") {
                this._logger.info(`emitting audio group ${val}`);
                this.emit("audiogroup", val);
              }
            });
          } else if (peerInfo) {
            Object.entries(info).forEach(([key, val]) => {
              if (val === undefined || val === null) {
                delete peerInfo[key];
                if (key === "audioGroupId") {
                  delete peerInfo.isAudioGroupSpeaker;
                  delete peerInfo.volume;
                }
              } else if (val instanceof Object && peerInfo[key] instanceof Object) {
                peerInfo[key] = { ...peerInfo[key], ...val };
              } else {
                peerInfo[key] = val;
              }
            });

            this._logger.debug(`peerInfo updated ${peerId} to ${JSON.stringify(peerInfo)}`);
            this.emit("peerupdated", peerId, peerInfo);
          } else {
            this._logger.error(`No peerInfo found for ${peerId} in ${this.connectedUsers}`);
          }

          break;
        }
        case "audioGroupSpeaker": {
          const { audioGroupId, peerId: speakerPeerId } = notification.data;
          const volumeFactor = OTAppCoreData.remoteConfig?.AGNonSpeakerVolumeFactor ?? 0.1;

          this._peers.forEach((peerInfo, peerId) => {
            if (peerInfo.audioGroupId === audioGroupId) {
              this._logger.debug(`${peerId} in audioGroup, is speaker ${speakerPeerId === peerId}`);
              let isAudioGroupSpeaker = speakerPeerId ? speakerPeerId === peerId : undefined;
              let volume = isAudioGroupSpeaker === false ? volumeFactor : 1;

              if (isAudioGroupSpeaker !== peerInfo.isAudioGroupSpeaker) {
                peerInfo.isAudioGroupSpeaker = isAudioGroupSpeaker;
                peerInfo.volume = volume;
                this._logger.debug(`peerInfo updated ${peerId} to ${JSON.stringify(peerInfo)}`);
                this.emit("peerupdated", peerId, peerInfo);
              }
            }
          });
          break;
        }
        default:
          this._logger.debug(`notification ${notification.method} unhandled`);
      }
    } catch (err) {
      this._logger.error(`Error Processing ${notification.method}`, err);
    }
  };

  _pinger = () => {
    const handlePingTimeout = (timedOut: boolean) => {
      if (this._pingTimedOut != timedOut) {
        this._logger.warn(`Ping timeout ${timedOut ? "timed out" : "recovered"}`);
        this._pingTimedOut = timedOut;
        this._updateNetworkStatus();
      }

      if (timedOut && this._sendTransport?.connectionState == "disconnected") {
        this._logger.info(`Disconnecting websocket`);
        this._websocketTransport?.close();
      }
    };

    const nowMs = new Date().getTime();

    if (this._lastPingResp && this._lastPingResp + _PING_INTERVAL_MS * 2 < nowMs) {
      handlePingTimeout(true);
    }

    if (this._peer && !this._peer.closed) {
      // this._logger.debug(`Checking connection`)
      this._peer
        .request("ping", { intervalMs: _PING_INTERVAL_MS })
        .then(() => {
          this._lastPingResp = new Date().getTime();
          handlePingTimeout(false);
        })
        .catch((err) => {
          if (this._websocketTransport && this._websocketTransport.closed === false) {
            this._logger.warn(`Ping error ${err}, disconnecting websocket`);
            this._websocketTransport?.close();
            //this.emit("disconnected");
          }
        });
    }
  };

  _updateNetworkStatus = () => {
    let status = "connected";
    let source = "none";

    if (this._pingTimedOut) {
      status = "disconnected";
      source = "ping";
    } else if (
      this._producers.size &&
      this._sendTransport &&
      this._sendTransport.connectionState !== "connecting"
    ) {
      status = this._sendTransport.connectionState == "connected" ? "connected" : "disconnected";
      source = "sendTransport";
    } else if (
      this._consumers.size &&
      this._recvTransport &&
      this._recvTransport.connectionState !== "connecting"
    ) {
      status = this._recvTransport.connectionState == "connected" ? "connected" : "disconnected";
      source = "recvTransport";
    }

    this._logger.info(`Network status ${status} (${source})`);

    this.emit("network", { status });
  };

  _joinRoom = async () => {
    this._pingTimer = setInterval(this._pinger, _PING_INTERVAL_MS);
    this._logger.info(`Connected to roomserver, joining room`);
    try {
      const routerRtpCapabilities = await this._peer.request("getRouterRtpCapabilities");

      await this._mediasoupDevice.load({ routerRtpCapabilities });

      // Create mediasoup Transport for sending (unless we don't want to produce).
      if (this._produce) {
        const transportInfo = await this._peer.request("createWebRtcTransport", {
          forceTcp: this._forceTcp,
          producing: true,
          consuming: false,
          sctpCapabilities: this._useDataChannel
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
        });

        const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: OTGlobals.config.RTCConfig.iceServers as RTCIceServer[],
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        });

        this._sendTransport.on(
          "connect",
          (
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            this._logger.info(`sendTransport received connect event`);
            this._peer
              .request("connectWebRtcTransport", {
                transportId: this._sendTransport!.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );

        this._sendTransport.on(
          "produce",
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._peer.request("produce", {
                transportId: this._sendTransport!.id,
                kind,
                rtpParameters,
                appData,
              });

              this._logger.info(`Created producer for ${appData.streamType}:${kind}, ${id}`);

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        this._sendTransport.on(
          "producedata",
          async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
            this._logger.debug(
              '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
              sctpStreamParameters,
              appData
            );

            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._peer.request("produceData", {
                transportId: this._sendTransport!.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        this._sendTransport.on("connectionstatechange", (connectionState) => {
          this._logger.info(`sendTransport network state ${connectionState}`);
          this._updateNetworkStatus();
          this.emit("network", { status: connectionState });
        });
      }

      // Create mediasoup Transport for sending (unless we don't want to consume).
      if (this._consume) {
        const transportInfo = await this._peer.request("createWebRtcTransport", {
          forceTcp: this._forceTcp,
          producing: false,
          consuming: true,
          sctpCapabilities: this._useDataChannel
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
        });

        const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

        this._recvTransport = this._mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: OTGlobals.config.RTCConfig.iceServers as RTCIceServer[],
        });

        this._recvTransport.on(
          "connect",
          (
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            this._logger.info(`recvTransport received connect event`);
            this._peer
              .request("connectWebRtcTransport", {
                transportId: this._recvTransport!.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );

        this._recvTransport.on("connectionstatechange", (connectionState) => {
          this._logger.info(`recvTransport state ${connectionState}`);
          this._updateNetworkStatus();
        });
      }

      // Join now into the room.
      // NOTE: Don't send our RTP capabilities if we don't want to consume.
      const { peers, state } = await this._peer.request("join", {
        displayName: "display-name",
        device: "device-info",
        rtpCapabilities: this._consume ? this._mediasoupDevice.rtpCapabilities : undefined,
        sctpCapabilities:
          this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
      });

      this._state = state;
      this.roomState = "connected";

      this._logger.info(`Joined room with ${peers.length} peers, state`, state);

      this._setupDataProducer();

      this.emit("connected");

      peers.forEach(({ id: peerId, ...peerInfo }) => {
        this._logger.info(`Adding peer ${peerId} with, ${JSON.stringify(peerInfo)}`);
        this._peers.set(peerId, peerInfo as IPeerInfo);
        this.emit("peerconnected", peerId, peerInfo);
      });
    } catch (error) {
      this._logger.error("_joinRoom() failed:%o", error);
      this.stop();
    }
  };

  addStream = (stream: LocalStreamDetails) => {
    const streamType = stream.streamType;

    if (this.roomState == "connected") {
      this._logger.debug(
        `Adding ${streamType}:${stream.stream.id} stream with ${stream.tracks.size} tracks`
      );
      stream.tracks.forEach((track) => this._addTrack(stream, track));

      stream.on("muted", (track: MediaStreamTrack) => {
        setTimeout(() => {
          const producer = this._findProducerFromTrack(track);

          if (stream.muted && producer) {
            this._logger.info(`Pausing local ${track.kind} track, producer ${producer.id}`);
            this._peer
              .request("pauseProducer", { producerId: producer.id })
              .catch((err) => this._logger.error(`Error pausing producer ${producer.id}`, err));
          } else {
            this._logger.info(`Not pausing track, muted ${track.muted}, producer ${producer?.id}`);
          }
        }, 500);
      });

      stream.on("unmuted", (track) => {
        const producer = this._findProducerFromTrack(track);

        if (producer) {
          this._logger.info(`Resuming local ${track.kind} track, producer ${producer.id}`);
          this._peer
            .request("resumeProducer", { producerId: producer.id })
            .catch((err) => this._logger.error("Error resuming producer", err));
        }
      });

      stream.on("trackadded", (track) => {
        this._logger.debug(`${track.kind} track added to stream`);
        this._addTrack(stream, track);
      });

      stream.on("trackremoved", (track) => {
        this._logger.debug(`${track.kind} track removed from stream`);
        this._removeTrack(track);
      });
    } else {
      this._logger.debug("Not yet connected, unable to send stream");
    }
  };

  _findProducerFromTrack = (track: MediaStreamTrack) => {
    return Array.from(this._producers.values()).find((p) => p.track?.id == track.id);
  };

  sendStream = (stream: LocalStreamDetails, users: string[]) => {
    this._logger.error("Mediasoup stream manager does not support sendStream(...)");
  };

  cancelStream = (stream: LocalStreamDetails) => {
    stream.tracks.forEach((track) => {
      this._removeTrack(track);
    });
  };

  _addTrack = async (stream: LocalStreamDetails, track: MediaStreamTrack) => {
    const streamType = stream.streamType;
    const appData: any = { streamType };
    const producerOptions: MSTypes.ProducerOptions = { track, stopTracks: false, appData };

    if (track.kind == "video") {
      let codec;
      var width = 640;
      var height = 480;
      try {
        let settings = track.getSettings();
        width = settings.width ?? width;
        height = settings.height ?? height;
      } catch {}
      const codecs =
        this._mediasoupDevice!.rtpCapabilities!.codecs?.filter((c) => c.kind === "video") ?? [];

      this._logger.debug(
        `Video codecs`,
        codecs.map((c) => c.mimeType)
      );
      let preferredVideoCodec;

      if (streamType == "camera") {
        const teamData = OTGlobals.getTeamData(this.teamId);
        preferredVideoCodec =
          teamData.capabilities.forceVideoCodec ??
          OTAppCoreData.remoteConfig?.forceVideoCodec ??
          CAMERA_CODEC;
      } else {
        preferredVideoCodec = SCREEN_SHARE_CODEC;
      }

      codec =
        codecs.find(
          (c) => c.mimeType.toLowerCase() === `video/${preferredVideoCodec}`.toLowerCase()
        ) ?? codecs[0];

      if (codec.mimeType.toLowerCase() === "video/vp9") {
        producerOptions.encodings =
          streamType == "camera" ? VIDEO_KSVC_ENCODINGS : VIDEO_SVC_ENCODINGS;
        appData.resolutions = [
          { width: width / 2, height: height / 2 },
          { width, height },
        ];
      } else {
        if (streamType == "camera") {
          producerOptions.codecOptions = {
            videoGoogleStartBitrate: 800,
          };

          if (OTGlobals.localUserSettings.videoSimulcastEnabled) {
            // Scale to tile size or by at least 1/2
            const scaleFactor = Math.max(height! / 180, 2);
            producerOptions.encodings = [
              { scaleResolutionDownBy: scaleFactor },
              { scaleResolutionDownBy: 1 },
            ];
          }
        }

        if (producerOptions.encodings) {
          appData.resolutions = producerOptions.encodings.map((enc) => {
            return {
              width: Math.round(width! / enc.scaleResolutionDownBy!),
              height: Math.round(height! / enc.scaleResolutionDownBy!),
            };
          });
        } else {
          appData.resolutions = [{ width, height }];
        }
      }

      producerOptions.codec = codec;

      this._logger.debug(`Using ${producerOptions.codec!.mimeType} codec for ${streamType} stream`);
    } else {
      producerOptions.codecOptions = {
        opusStereo: false,
        opusDtx: true,
        opusFec: true,
      };
    }

    try {
      producerOptions.appData.createdAt = new Date().getTime();

      const producer = await this._sendTransport!.produce(producerOptions);
      this._logger.debug(`producing with appData ${JSON.stringify(producerOptions.appData)}`);

      producer.on("transportclose", () => {
        this._producers.delete(producer.id);
      });

      producer.on("trackended", () => {
        this._removeTrack(track);
      });

      this._producers.set(producer.id, producer);
      this._statsRequested.add(`producer:${producer.id}`);
    } catch (err) {
      this._logger.error(`Failed to create ${streamType}.${track.kind} producer`, err);
    }
  };

  _removeTrack = async (track) => {
    const producer = this._findProducerFromTrack(track);

    if (producer && this._producers.delete(producer.id)) {
      producer.close();

      try {
        this._logger.info(
          `Closing producer ${producer.appData.streamType}:${producer.kind} ${producer.id}`
        );
        await this._peer.request("closeProducer", { producerId: producer.id });
      } catch (err) {
        this._logger.debug("Error closing producer", err);
      }
    }
  };

  shutdownStreams = () => {
    this._logger.debug("shutdownStreams is not implemented");
  };

  get amScreenSharing() {
    return (
      Array.from(this._producers.values()).find(
        (p) => p.kind === "video" && p.appData.streamType === "screen"
      ) instanceof MSTypes.Producer
    );
  }

  get numVideoConsumers() {
    return Array.from(this._consumers.values()).filter(
      ({ consumer: c }) => c.kind === "video" && !c.paused && !c.closed
    ).length;
  }

  _senderRetryTimeout?: ReturnType<typeof setTimeout>;
  _senderLastChange = 0;

  updateSenderResolution = async (forceToLayer?: number, fromTimer?: boolean) => {
    if (fromTimer) {
      this._senderRetryTimeout = undefined;
    } else if (this._senderRetryTimeout) {
      clearTimeout(this._senderRetryTimeout);
    }

    const producer = Array.from(this._producers.values()).find(
      (p) => p.kind === "video" && p.appData.streamType === "camera"
    );

    if (!producer) {
      return;
    }

    const now = new Date().getTime();

    if (now > producer.appData.createdAt + 5000 && now > this._senderLastChange + 5000) {
      let requestedLayer = forceToLayer ?? this._producerRequestedLayers.get(producer.id) ?? 1;
      //this._logger.info(`Wanted layer ${requestedLayer} (force ${forceToLayer})`);

      if (forceToLayer === undefined) {
        if (this.connectedUsers.length < 1) {
          //this._logger.info(`reducing requested layer as no users connected`, this.connectedUsers)
          requestedLayer = 0;
        } else {
          if (
            requestedLayer > 0 &&
            producer.appData.score?.scores &&
            (producer.maxSpatialLayer ?? 0) >= requestedLayer
          ) {
            while (requestedLayer > 0 && producer.appData.score.scores[requestedLayer] === 0) {
              this._logger.info(`reducing requested layer due to low score`);
              requestedLayer -= 1;
            }
          }
        }
      }

      if (requestedLayer !== producer.maxSpatialLayer) {
        this._logger.info(
          `Setting producer.maxSpatialLayer = ${requestedLayer} wanted (${this._producerRequestedLayers.get(
            producer.id
          )})`
        );
        producer.setMaxSpatialLayer(requestedLayer);

        const codec = producer.rtpParameters.codecs[0].mimeType.split("/")[1];
        if (codec.toLowerCase() !== 'vp9') {
          if (requestedLayer == 0) {
            //@ts-ignore
            producer.setRtpEncodingParameters({ maxFramerate: 12 });
          } else {
            //@ts-ignore
            producer.setRtpEncodingParameters({ maxFramerate: 24 });
          }
        }
      }
      this._senderLastChange = now;
    } else {
      this._senderRetryTimeout = setTimeout(
        () => this.updateSenderResolution(forceToLayer, true),
        Math.max(0, producer.appData.createdAt + 5000 - now, this._senderLastChange + 5000 - now)
      );
    }
    return;
  };

  setFocusedUsers = (userIds: string[], forceUpdate: boolean = false) => {
    let focusUsers = [...userIds];
    focusUsers.sort();

    if (forceUpdate || focusUsers.join(":") != this._focusedUsers.join(":")) {
      Array.from(this._consumers.values()).forEach((consumerInfo) => {
        const { consumer, peerId, streamType, kind, codec, spatialLayers, temporalLayers } =
          consumerInfo;

        if (spatialLayers > 1 && streamType == "camera" && kind == "video") {
          let spatialLayer = 0;
          let temporalLayer = Math.max(0, temporalLayers - 2);

          if (!this._bandwidthLow && focusUsers.includes(peerId)) {
            spatialLayer = spatialLayers - 1;
            temporalLayer = temporalLayers - 1;
          }

          const layerParams: any = { consumerId: consumer.id, spatialLayer };

          if (codec.toLowerCase() !== "vp9") {
            layerParams.temporalLayer = temporalLayer;
          }
          this._requestConsumerLayers(consumer.id, layerParams);
        } else {
          this._logger.debug(
            `consumer ${streamType}, ${kind} has ${spatialLayers} layers, skipping`
          );
        }
      });

      this._focusedUsers = focusUsers;
      this.updateSenderResolution();
    }
  };

  _requestConsumerLayers = (consumerId, layerParams: ILayers) => {
    const consumerInfo = this._consumers.get(consumerId);
    if (consumerInfo) {
      const { spatialLayer: rSpatialLayer, temporalLayer: rTemportalLayer } =
        consumerInfo.requestedLayers ?? {};
      const { spatialLayer, temporalLayer } = layerParams;

      if (spatialLayer !== rSpatialLayer || temporalLayer !== rTemportalLayer) {
        const layerKey = `S${spatialLayer}T${temporalLayer}`;
        this._logger.info(`Requesting layer ${layerKey} for ${consumerInfo.peerId}`);

        // if the peer has been closed we will get a peer closed Error
        this._peer
          .request("setConsumerPreferredLayers", { consumerId, ...layerParams })
          .catch((err) => {});

        consumerInfo.requestedLayers = layerParams;
      } else {
        //this._logger.debug(`Already have requsted layers for ${consumerInfo.peerId}, want ${JSON.stringify(layerParams)}, have ${JSON.stringify(consumerInfo.requestedLayers)}`)
      }
    }
  };

  sendMessageToAll(message: IPeerMsg) {
    if (this._dataProducer) {
      try {
        this._dataProducer.send(JSON.stringify(message));
      } catch (error) {
        this._logger.error("Error sending chat message", error);
      }
    } else {
      this._logger.error("Unable to send message DataProducer not yet setup");
    }
  }

  sendMessage = (userId: string, message: IPeerMsg) => {
    this._logger.warn("P2P messages are sent to all participants and filtered out client side");
    if (this._dataProducer) {
      var targetContainer = {
        containerType: "USER",
        userId: userId,
        message: message,
      };
      try {
        this._dataProducer.send(JSON.stringify(targetContainer));
      } catch (error) {
        this._logger.error("Error sending chat message", error);
      }
    } else {
      this._logger.warn("Unable to send message DataProducer not yet setup", message);
    }
  };

  recvMessage = (peerId: string, message) => {
    this._logger.debug("Recieved message", message);

    // either a message or a target container
    // var targetContainer = {
    //     containerType: 'USER',
    //     userId: userId,
    //     message: message
    // }

    if (message.containerType == "USER") {
      if (message.userId == this._myUserId) {
        this.emit("message", peerId, message.message);
      }
    } else {
      this.emit("message", peerId, message);
    }
  };

  _removeConsumer = (consumerId) => {
    const consumerInfo = this._consumers.get(consumerId);

    if (consumerInfo && this._consumers.delete(consumerId)) {
      const { consumer, peerId, streamType, kind } = consumerInfo;

      this._logger.info(
        `Removing consumer ${consumerId} (${streamType}.${kind}) for peer ${peerId}:${consumer.producerId}`
      );

      if (this._userStreams[peerId][streamType]) {
        this._userStreams[peerId][streamType].removeTrackKind(consumer.track.kind);
        if (this._userStreams[peerId][streamType].isEmpty()) {
          delete this._userStreams[peerId][streamType];
        }
      }

      this.emit("streamsupdated");
    }
  };

  _enableConsumer = (consumerId, enable: boolean) => {
    const consumerInfo = this._consumers.get(consumerId);

    const action = enable ? "Resuming" : "Pausing";
    if (consumerInfo) {
      const { peerId, streamType, kind, consumer } = consumerInfo;
      this._logger.info(
        `${action} consumer ${consumerId} (${streamType}.${kind}) from ${peerId}:${consumer.producerId}`
      );
      try {
        this._getConsumerStream(consumerId)?.enable(kind, enable);
        this.emit("streamsupdated");
      } catch (err) {
        this._logger.error(
          `Error ${action} consumer ${consumerId} (${streamType}.${kind}) from ${peerId}`,
          err
        );
      }
    } else {
      this._logger.warn(`Unable to ${action} consumer ${consumerId}, consumer not found`);
    }
  };

  _setProducerScore = (producerId, scores) => {
    const producer = this._producers.get(producerId);

    if (producer && scores.length >= 1) {
      const { streamType } = producer.appData;
      producer.appData.score = scores;
      const layer = producer.maxSpatialLayer ?? scores.length - 1;
      const score = scores[layer];

      //this._logger.debug(
      //  `Producer Score for ${streamType}, S${producer.maxSpatialLayer} ${producer.track!.kind}, ${JSON.stringify(score)}`
      //);

      this.emit("producerscore", streamType, producer.track!.kind, score.score);

      if (layer > 0 && score.score === 0) {
        this.updateSenderResolution();
      }
    }
  };

  _setConsumerScore = (consumerId, score: IConsumerScore) => {
    const stream = this._getConsumerStream(consumerId);
    const consumerInfo = this._consumers.get(consumerId);

    if (stream && consumerInfo) {
      stream.setScore(consumerInfo.kind, score.producerScore);
      consumerInfo.score = score;

      // wait for things to settle down before we got friggin with stuff.
      const age = (new Date().getTime() - consumerInfo.receivedAt) / 1000;
      if (age > 5 && score.producerScore === 0 && Math.max(0, ...score.producerScores) > 0) {
        const { spatialLayer } = consumerInfo.currentLayer ?? {};

        this._logger.warn(
          `Peer ${consumerInfo.peerId} producer score is ${score.producerScore}, ` +
            `current layer ${spatialLayer} ` +
            `score ${spatialLayer ? score.producerScores[spatialLayer] : undefined} ` +
            `requested layers ${consumerInfo.requestedLayers}` +
            `paused: ${consumerInfo.consumer.paused}`
        );
        this._checkConsumer(consumerId);
      }
    }
  };

  _checkConsumer = (consumerId) => {
    const consumerInfo = this._consumers.get(consumerId);

    if (consumerInfo) {
      const { score, currentLayer, spatialLayers, temporalLayers } = consumerInfo;
      const badScore = score?.producerScore == 0 && Math.max(0, ...score.producerScores) > 0;
      const badLayer = currentLayer?.spatialLayer === null && !this._bandwidthLow;

      if (badScore || badLayer) {
        const layerParams = {
          spatialLayer: spatialLayers - 1,
          temporalLayer: temporalLayers - 1,
        };

        this._logger.info(
          `Consumer is stalled badScore ${badScore}, badLayer ${badLayer} ` +
            `requesting S${layerParams.spatialLayer}T${layerParams.temporalLayer} to recover stream`
        );

        this._requestConsumerLayers(consumerId, layerParams);

        setTimeout(() => this.setFocusedUsers(this._focusedUsers, true), 5000);
      }
    }
  };

  frigTrack(userId) {
    const info = Array.from(this._consumers.values()).filter(
      (c) => c.peerId == userId && c.streamType == "camera" && c.kind == "video"
    )[0];

    if (info) {
      const stream = this._getConsumerStream(info.consumer.id);
      if (stream) {
        if (stream.has("video")) {
          stream.removeTrackKind(info.consumer.track.kind);
        } else {
          if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
            stream.addTrack(info.consumer.track);
          } else {
            stream.addTrack(info.consumer.track.clone());
          }
        }
      }
    }
  }

  _setConsumerLayers = (consumerId, spatialLayer, temporalLayer) => {
    const stream = this._getConsumerStream(consumerId);
    if (stream) {
      const info = this._consumers.get(consumerId)!;
      const { peerId, streamType, kind } = info;

      //if (spatialLayer === null && stream.has(info.kind)) {
      //    stream.removeTrackKind(info.kind);
      //} else if (spatialLayer != null && !stream.has(info.kind)) {
      //    stream.addTrack(info.consumer.track.clone());
      //}

      if (spatialLayer === null) {
        this._logger.info(
          `Recieved null layer for ${streamType}:${kind} from ${peerId}, (requested: ${info.requestedLayers})`
        );
        info.currentLayer = null;
        this._checkConsumer(consumerId);
      } else {
        if (info.currentLayer?.spatialLayer === null) {
          this._logger.info(
            `layer recovered to S${spatialLayer}T${temporalLayer} for ${streamType}:${kind} from ${peerId}`
          );
        }
        info.currentLayer = { spatialLayer, temporalLayer };
      }
    }
  };

  _setPeerNetworkStatus({ peerId, networkStatus }) {
    if (this._userStreams[peerId]) {
      Object.values(this._userStreams[peerId]).forEach((stream) => {
        stream.setNetworkStatus(networkStatus);
      });
      if (networkStatus === "disconnected") {
        this.emit("peerdisconnected", peerId);
      }
    }
  }

  setSameRoom = async (otherUserId: string | null) => {
    try {
      return await this._peer.request("setSameRoom", { otherUserId });
    } catch (err) {
      this._logger.warn(`Failed to set same room: ${err}`);
      throw err;
    }
  };

  _getConsumerStream(consumerId) {
    const consumerInfo = this._consumers.get(consumerId);

    if (consumerInfo) {
      const { peerId, streamType, kind } = consumerInfo;
      if (this._userStreams[peerId]) {
        if (this._userStreams[peerId][streamType]) {
          return this._userStreams[peerId][streamType];
        }
      }
    }
  }

  _setupDataProducer = async () => {
    this._logger.debug("setupDataProducer()");

    if (!this._useDataChannel) {
      return;
    }

    try {
      // Create chat DataProducer.
      this._dataProducer = await this._sendTransport!.produceData({
        ordered: false,
        maxRetransmits: 5,
        label: "general",
      });

      this._dataProducer.on("transportclose", () => {
        this._dataProducer = null;
      });

      this._dataProducer.on("open", () => {
        this._logger.debug('DataProducer "open" event');
      });

      this._dataProducer.on("close", () => {
        this._logger.error('DataProducer "close" event');
        this._dataProducer = null;
      });

      this._dataProducer.on("error", (error) => {
        this._logger.error('chat DataProducer "error" event:%o', error);
      });

      this._dataProducer.on("bufferedamountlow", () => {
        this._logger.debug('chat DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      this._logger.error("setupDataProducer() | failed:%o", error);
      throw error;
    }
  };

  requestStats = (userId, streamType) => {
    this._getStatsKey(userId, streamType).forEach((statsKey) => {
      this._statsRequested.add(statsKey);
    });
    this._getStats();
  };

  cancelStats = (userId, streamType) => {
    this._getStatsKey(userId, streamType).forEach((statsKey) => {
      if (statsKey.startsWith("consumer")) {
        // Don't cancel producer stats, we want to get them always
        this._statsRequested.delete(statsKey);
      }
    });
    this._getStats();
  };

  _getStatsKey = (userId, streamType): string[] => {
    if (userId === this._myUserId) {
      if (this._producers.size == 0) {
        this._logger.info(`No producers found for ${userId} ${streamType}`);
      }

      return Array.from(this._producers.values())
        .filter((producer) => producer.appData.streamType === streamType)
        .map((producer) => `producer:${producer.id}`);
    } else {
      if (this._consumers.size == 0) {
        this._logger.info(`No consumers found for ${userId} ${streamType}`);
      }

      return Array.from(this._consumers.values())
        .filter((consumerInfo) => consumerInfo.streamType === streamType)
        .map((consumerInfo) => `consumer:${consumerInfo.consumer.id}`);
    }
  };

  _getStats = () => {
    for (let statsKey of this._statsRequested) {
      const [type, id] = statsKey.split(":", 2);
      //this._logger.info(`requesting ${type} stats for ${id}`)
      if (type === "producer") {
        const producer = this._producers.get(id);
        if (!producer) {
          this._statsRequested.delete(statsKey);
        } else {
          this._getProducerStats(id, producer);
        }
      } else if (type === "consumer") {
        const consumerInfo = this._consumers.get(id);
        if (!consumerInfo) {
          this._statsRequested.delete(statsKey);
        } else {
          this._getConsumerStats(id, consumerInfo);
        }
      }
    }

    if (this._statsRequested.size && !this._statsTimer) {
      this._statsTimer = setInterval(this._getStats, _STATS_INTERVAL_MS);
    } else if (this._statsTimer && !this._statsRequested.size) {
      clearInterval(this._statsTimer);
      this._statsTimer = null;
    }
  };

  _getProducerStats = (producerId, producer) => {
    this._peer
      .request("getProducerStats", { producerId })
      .then((statsList) => {
        const { streamType, resolutions } = producer.appData;
        const dispStats: any[] = [];
        const scores: any[] = [];
        statsList.forEach((stats) => {
          const codec = stats.mimeType?.split("/", 2)[1];
          //this._logger.debug("Producer stats: ", JSON.stringify(stats))
          if (codec.toLowerCase() === "vp9") {
            let layerNum = 0;
            Object.keys(stats.bitrateByLayer).forEach((layer) => {
              const spatial = parseInt(layer.split(":", 1)[0]);
              const resolution = resolutions ? resolutions[spatial] : undefined;
              const enabled = spatial <= (producer.maxSpatialLayer ?? 2)
              //this._logger.debug(
              //  `Layer ${layer} enabled ${enabled}, maxSpatial (${producer.maxSpatialLayer})`
              //);
              dispStats[layerNum] = {
                layer: layer,
                resolution,
                jitter: stats.jitter,
                bitrate: stats.bitrateByLayer[layer],
                codec,
                roundTripTime: stats.roundTripTime,
                score: stats.score,
                enabled,
              };
              scores.push({ encodingIdx: layerNum, rid: layer, score: stats.score });
              layerNum += 1;
            });
          } else {
            const layerNum = stats.rid ? parseInt(stats.rid.slice(1)) : 0;
            const resolution = resolutions ? resolutions[layerNum] : undefined;
            dispStats[layerNum] = {
              layer: stats.rid,
              resolution,
              jitter: stats.jitter,
              bitrate: stats.bitrate,
              codec,
              roundTripTime: stats.roundTripTime,
              score: stats.score,
              enabled: layerNum <= (producer.maxSpatialLayer ?? 2),
            };
            scores.push({ encodingIdx: layerNum, rid: stats.rid, score: stats.score });
          }
        });

        this.emit("producerstats", streamType, producer.kind, dispStats);

        if (scores.length) {
          this._setProducerScore(producerId, scores);
        }
      })
      .catch(() => {});
  };

  _getConsumerStats = (consumerId, consumerInfo) => {
    const { consumer, peerId, streamType, kind, currentLayer } = consumerInfo;
    this._peer
      .request("getConsumerStats", { consumerId })
      .then((statsList) => {
        const { resolutions } = consumer.appData;
        const layerNum = currentLayer?.spatialLayer ?? 0;
        const resolution = resolutions ? resolutions[layerNum] : undefined;
        //this._logger.debug("Consumer stats: ", JSON.stringify(statsList))
        //this._logger.debug(
        //  `layerNum ${layerNum} resolution ${resolution}, resolutions`,
        //  resolutions
        //);
        const formatLayer = currentLayer
          ? `S${currentLayer?.spatialLayer}T${currentLayer?.temporalLayer}`
          : "";
        statsList.forEach((stats) => {
          if (stats.type == "inbound-rtp") {
            if (this._userStreams[peerId]) {
              if (this._userStreams[peerId][streamType]) {
                this._userStreams[peerId][streamType].setStats(kind, [
                  {
                    layer: formatLayer,
                    resolution,
                    jitter: stats.jitter,
                    bitrate: stats.bitrate,
                    codec: stats.mimeType.split("/", 2)[1],
                    roundTripTime: stats.roundTripTime,
                    score: stats.score,
                  },
                ]);
              }
            }
          }
        });
      })
      .catch(() => {});
  };
}


async function getProtooURL(userId: string, sessionToken: string, teamId: string, roomId: string) {
  const token = await FireUtils.getAuthToken();

  const baseURL = OTAppCoreData.ScalableSFUUrl || undefined;

  const url = new URL(`/room/${teamId}:${roomId}`, baseURL);

  url.searchParams.append("token", token as string);
  url.searchParams.append("peerId", userId);
  url.searchParams.append("sessionToken", sessionToken);

  return url.toString();
}
