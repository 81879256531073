import { Logger } from "@openteam/app-util";
import { ICallDetails, ICallMessage, IFileAttachment, ILinkPreview, IMessage, IMessageFile, IOTChatMessage } from "@openteam/models";
import { Firestore, getDoc, doc, onSnapshot, collection, orderBy, query, updateDoc, deleteField, runTransaction, deleteDoc, serverTimestamp } from "firebase/firestore";
import { convertFBToIMessage, firestoreTimestampToDate, getMentions } from "..";

const logger = new Logger("CallDetailsDb");


function convertFBToICallDetails(doc): ICallDetails {
  doc.crDate = firestoreTimestampToDate(doc.crDate);
  doc.lastUpdate = firestoreTimestampToDate(doc.lastUpdate);
  doc.endDate = firestoreTimestampToDate(doc.endDate);

  return doc;
}



export class CallDetailsDb {

  static getCallDetails = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
  ): Promise<ICallDetails | undefined> => {

    const snapshot = await getDoc(doc(fsDb, `teams/${teamId}/callDetails/${callId}`))

    if (snapshot && snapshot.exists()) {
      return convertFBToICallDetails(snapshot.data())
    }
    return undefined
  }

  static savePluginResourceToChat = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    pluginId: string,
  ) => {

    logger.debug("savePluginResourceToChat", { teamId, callId, pluginId });

    const docRef = doc(
      fsDb,
      `teams/${teamId}/callDetails/${callId}`
    );

    await updateDoc(docRef, {
      [`resources.${pluginId}.savedToChat`]: true
    })
  }

  static watchCallDetails = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    callback: (doc: ICallDetails) => void
  ) => {
    const docRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}`);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot && snapshot.exists()) {
          const data = convertFBToICallDetails(snapshot.data());
          data && callback(data);
        }
      },
      (error) => logger.error("watchCallDetails", error)
    );

    return unsubscribe;
  };

  static watchCallMessages = (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    callback: (added: IMessage[], edited: IMessage[], deleted: string[]) => void
  ) => {

    const qry = query(
      collection(fsDb, `teams/${teamId}/callDetails/${callId}/messages`),
      orderBy("messageId", "asc")
    );

    const unsubscribe =
      onSnapshot(qry,
        (snapshot) => {
          const added: IMessage[] = [];
          const edited: IMessage[] = [];
          const deleted: string[] = [];

          // logger.debug("watchChannelMessages", snapshot, snapshot?.docChanges());

          snapshot?.docChanges().forEach((change) => {
            if (change.type === "added") {
              added.push(convertFBToIMessage(change.doc.data() as IMessage));
            }
            if (change.type === "modified") {
              added.push(convertFBToIMessage(change.doc.data() as IMessage));
            }
            if (change.type === "removed") {
              deleted.push(change.doc.id);
            }
          });
          logger.debug(
            `watchCallMessages teamId: ${teamId} callId: ${callId} added`,
            added,
            `edited`,
            edited,
            `deleted`,
            deleted
          );
          callback(added, edited, deleted);
        },
        (error) => logger.error("watchChannelMessages", error)
      );
    return unsubscribe;
  };

  static setIsTyping = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callId: string,
    isTyping: boolean
  ) => {
    const now = new Date();
    const lastTyping = isTyping ? now : null;

    await updateDoc(doc(fsDb, `teams/${teamId}/callDetails/${callId}`), {
      ["users." + userId + ".lastTyping"]: lastTyping,
    });
  };

  static markChatRead = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callId: string,
    messageNum: number,
    messageId: number
  ) => {
    logger.info("marking call chat as read", userId, callId, messageNum, messageId);

    await updateDoc(doc(fsDb, `teams/${teamId}/callDetails/${callId}`), {
      ["users." + userId + ".messageNum"]: messageNum,
      ["users." + userId + ".messageId"]: messageId,
    });
  };


  static addChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    userId: string,
    callId: string,
    message: string,
    attachments?: Record<string, IFileAttachment>,
    replyMessage?: IOTChatMessage,
    linkPreview?: ILinkPreview,
    linkPreviews?: Record<string, ILinkPreview>
  ) => {

    const callDetailsRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}`);
    const messageRef = doc(collection(fsDb, `teams/${teamId}/callDetails/${callId}/messages`));
    const id = messageRef.id;

    const mentions: string[] = getMentions(message)

    const msg: ICallMessage = {
      callId: callId,
      msgType: 'CALL',
      id: id,
      teamId: teamId,
      crDate: serverTimestamp() as any,
      isSystem: false,
      userId: userId, //sender
      message: message || "",
      messageNum: 0,
      messageId: 0,
      replyMessage: replyMessage || null,
      linkPreview: linkPreview || null,
      linkPreviews: linkPreviews || null,
      linkPreviewFetched: true,
      attachments: attachments,
      mentions: mentions,
    };
    logger.debug("sending message", msg)


    const messageNum = await runTransaction(fsDb, async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.

      const callDetailsSnap = await transaction.get(callDetailsRef);
      if (!callDetailsSnap.exists()) {
        throw "Document does not exist!";
      }

      const callDetailsDoc = callDetailsSnap.data() as ICallDetails;

      logger.debug("sending callDetailsDoc", callDetailsDoc)


      let messageId = (callDetailsDoc?.messageId ?? 0) + 1;
      let messageNum = (callDetailsDoc?.messageNum ?? 0) + 1;
      msg.messageNum = messageNum;
      msg.messageId = messageId;


      logger.debug("update callDetailsDoc", {
        lastMessage: msg,
        messageNum: messageNum,
        messageId: messageId,
        ["users." + userId + ".messageNum"]: messageNum,
        ["users." + userId + ".messageId"]: messageId,
      })


      transaction.update(callDetailsRef, {
        lastMessage: msg,
        messageNum: messageNum,
        messageId: messageId,
        ["users." + userId + ".messageNum"]: messageNum,
        ["users." + userId + ".messageId"]: messageId,
      });


      logger.debug("set messageRef", msg)

      transaction.set(messageRef, msg);

      // logger.debug("setIsTyping", msg)


      // CallDetailsDb.setIsTyping(fsDb, teamId, userId, callId, false);

      return messageNum;
    });

    return id;
  };

  static updateChatMessagePendingFile = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    messageId: string,
    file: IMessageFile,
  ) => {
    const docRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}/messages/${messageId}`);

    const updates = {}
    if (file.completed) {

      const messageFile = {
        name: file.file.name,
        type: file.file.type,
        size: file.file.size,
        url: file.downloadUrl || null,
        uploaded: true,
        isResource: true,
        order: file.index!,
      }

      updates['attachments.' + file.id] = messageFile
    } else if (file.failed) {
      updates['attachments.' + file.id] = deleteField()

    } else {

      updates['attachments.' + file.id + ".progress"] = file.progress
    }

    updateDoc(docRef, updates);
  };


  static updateChatMessageLinkPreviews = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    messageId: string,
    linkPreviews: Record<string, ILinkPreview>
  ) => {
    const docRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}/messages/${messageId}`);
    updateDoc(docRef, linkPreviews)
  }

  static saveLinkPreviewToChat = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    id: string,
    linkId?: string,
  ) => {

    const docRef = doc(
      fsDb,
      `teams/${teamId}/callDetails/${callId}/messages/${id}`
    );

    const key = linkId ? `linkPreviews.${linkId}` : `linkPreview`;

    updateDoc(docRef, {
      [`${key}.savedToChat`]: true
    })
  }
  static saveAttachmentToChat = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    id: string,
    fileId: string,
  ) => {
    const docRef = doc(
      fsDb,
      `teams/${teamId}/callDetails/${callId}/messages/${id}`
    );

    const key = `attachments.${fileId}`;

    updateDoc(docRef, {
      [`${key}.savedToChat`]: true
    })
  }

  static editChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    messageId: string,
    message: string
  ) => {

    const mentions: string[] = getMentions(message)

    const msg = {
      message: message || "",
      editDate: serverTimestamp() as any,
      mentions: mentions
    };

    const docRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}/messages/${messageId}`);
    updateDoc(docRef, msg)
  };

  static deleteChatMessage = async (
    fsDb: Firestore,
    teamId: string,
    callId: string,
    messageId: string
  ) => {
    const docRef = doc(fsDb, `teams/${teamId}/callDetails/${callId}/messages/${messageId}`);
    deleteDoc(docRef);
  };

}


