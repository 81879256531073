import { Database } from 'firebase/database'
import { Firestore, QuerySnapshot, DocumentData } from "firebase/firestore";

import { computed, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { Logger } from "@openteam/app-util";
import { CalendarDb, FireDb } from "../fire";
import { CalEvent, ICalCalendarListEntry, ICalEvent, ICalEvSummary, ICalUser } from "@openteam/models";
import { getFunctions, httpsCallable } from "firebase/functions";
import { debounce } from 'throttle-debounce';
import { b64encode, getIntervalTree, getStartOfDay, toSecs } from '../utils';
import DataIntervalTree from "node-interval-tree";


export const CALENDAR_AUTH_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events',
];

const logger = new Logger("calendarManager");

export class CalendarManager {
  fbDb: Database;

  fsDb: Firestore;
  userId: string;

  @observable doc: ICalUser | undefined;
  @observable connecting: boolean;
  @observable disconnecting: boolean;
  @observable loaded: boolean;

  @observable eventsById: Record<string, ICalEvent>;
  @observable calendarsById: Record<string, ICalCalendarListEntry>;

  @observable currentEvent: { event: ICalEvent, start: number } | undefined;
  @observable nextEvent: { event: ICalEvent, start: number } | undefined;

  _eventTimer: ReturnType<typeof setTimeout> | undefined;
  _scheduleTimer: ReturnType<typeof setTimeout> | undefined;

  _reaction: Record<string, any>;

  @observable schedule: [
    Record<string, ICalEvent>,
    DataIntervalTree<{
      evId: string;
      start: number;
    }>
  ] | undefined;


  constructor(
    fbDb: Database,
    fsDb: Firestore,
    userId: string
  ) {
    makeObservable(this);

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.userId = userId;
    this.connecting = false;
    this.disconnecting = false;
    this.loaded = false;
    this.doc = undefined;

    this.calendarsById = {};
    this.eventsById = {};

    this.currentEvent = undefined;
    this.nextEvent = undefined;

    this._eventTimer = undefined;
    this._scheduleTimer = undefined;

    this.schedule = undefined;

    this._reaction = {};
  }

  start = async () => {
    logger.debug(`starting calendar userId:${this.userId}`);
    this.watchCalendar();
    this.watchCalendarEvents();
    this.watchCalendarList();

    this._reaction['startCheckNextEvent'] = reaction(
      () => [
        this.isBusy,
        this.isAuthorised,
        this.doc,
        this.status?.current,
        this.status?.next,
        this.eventsById
      ],
      () => this.startCheckNextEvent()
    );

    this._reaction['startSchedule'] = reaction(
      () => [
        this.isBusy,
        this.isAuthorised,
        this.eventsById,
        this.calendarsById
      ],
      () => this.startSchedule()
    );
  };

  stop = async () => {
    logger.debug(`stopping calendar userId:${this.userId}`);

    if (this.unsubscribeWatch) this.unsubscribeWatch();
    this.unsubscribeWatch = undefined;

    if (this.unsubscribeEvents) this.unsubscribeEvents();
    this.unsubscribeEvents = undefined;

    if (this.unsubscribeCalendarList) this.unsubscribeCalendarList();
    this.unsubscribeCalendarList = undefined;

    this.stopCheckNextEvent();
    this.stopSchedule();
    Object.values(this._reaction).map((x) => x());

  };

  unsubscribeWatch: (() => void) | undefined = undefined;
  watchCalendar = () => {
    if (this.unsubscribeWatch) {
      return;
    }
    this.unsubscribeWatch = CalendarDb.watchCalendar(this.fsDb, this.userId, (data) =>
      this.syncCalendar(data as ICalUser)
    );
  }

  unsubscribeEvents: (() => void) | undefined = undefined;
  watchCalendarEvents = () => {
    if (this.unsubscribeEvents) {
      return;
    }
    this.unsubscribeEvents = CalendarDb.watchCalendarEvents(this.fsDb, this.userId, (snapshot) =>
      this.debouncedSyncCalendarEvents(snapshot)
    )
  };

  debouncedSyncCalendarEvents = debounce(500, (snapshot: QuerySnapshot<DocumentData>) => {
    this.syncCalendarEvents(snapshot)
  });

  unsubscribeCalendarList: (() => void) | undefined = undefined;
  watchCalendarList = () => {
    if (this.unsubscribeCalendarList) {
      return;
    }
    this.unsubscribeCalendarList = CalendarDb.watchCalendarList(this.fsDb, this.userId, (snapshot) =>
      this.syncCalendarList(snapshot)
    )
  };

  syncCalendar = (data: ICalUser | undefined) => {
    runInAction(() => {
      this.doc = data;
    });
  };

  syncCalendarEvents = (snapshot: QuerySnapshot<DocumentData>) => {
    const eventDocs: ICalEvent[] = [];
    /*     snapshot.docChanges().forEach(change => {
          logger.debug(`${change.type}: `, change.doc.data());
        });
     */
    snapshot.forEach(doc => {
      const data = doc.data() as ICalEvent;
      eventDocs.push(data);
    });
    runInAction(() => {
      this.eventsById = Object.fromEntries(
        eventDocs.map(ev => ([
          CalEvent.key(b64encode(ev.meta.calendarId)!, ev.id!),
          ev
        ]))
      );
      this.loaded = true
      logger.debug("syncCalendarEvents: numEvents=%d", eventDocs.length);
    });
  };

  syncCalendarList = (snapshot: QuerySnapshot<DocumentData>) => {

    const calendarsById: Record<string, ICalCalendarListEntry> = {};
    snapshot.forEach(doc => {
      const data = doc.data() as ICalCalendarListEntry;
      if (!data.deleted)
        calendarsById[data.id!] = data;
    });

    logger.debug("syncCalendarList: numCalendars: ", Object.keys(calendarsById).length);

    runInAction(() => {
      this.calendarsById = calendarsById;
    });
  };

  // https://firebase.google.com/docs/functions/callable#web-v8_2
  startCalendar = async (code: string, onSuccess: () => void, onError: (error: Error) => void) => {
    logger.debug("connecting calendar...");
    const startCalendar = httpsCallable(getFunctions(), "cal_start");

    runInAction(() => {
      this.connecting = true;
      this.loaded = false;
    });

    startCalendar({ authCode: code })
      .then((result) => {
        logger.info("connected calendar...");
        runInAction(() => {
          this.connecting = false;
        });
        onSuccess();
      })
      .catch((error) => {
        logger.error("failed to connect calendar...%o", error);
        runInAction(() => {
          this.connecting = false;
        });
        onError(error);
      });
  };

  stopCalendar = async (onSuccess: () => void, onError: (error: Error) => void) => {
    logger.debug("disconnecting calendar...");
    runInAction(() => {
      this.disconnecting = true;
    });

    httpsCallable(getFunctions(), "cal_disconnect")()
      .then((result) => {
        logger.info("disconnected calendar...");
        runInAction(() => {
          this.disconnecting = false;
          this.loaded = false;
        });
        onSuccess();
      })
      .catch((error) => {
        logger.error("failed to disconnect calendar...");
        runInAction(() => {
          this.disconnecting = false;
        });
        onError(error);
      });
  };

  setCalendarVisible = async (b64CalendarId: string, visible: boolean) => {
    logger.debug(`setting ${b64CalendarId} visible: ${visible}`);

    await CalendarDb.updateUser(this.fsDb, this.userId, {
      [`calendars.${b64CalendarId}.visible`]: visible
    });
  }

  parseMeetingToken = async (token: string) => FireDb.loadMeetingToken(token);

  setNotify = async (b64CalendarId: string, notify: boolean) => {
    await CalendarDb.updateUser(this.fsDb, this.userId, {
      [`calendars.${b64CalendarId}.notifyEvents`]: notify
    });
  }

  setIsPublic = async (b64CalendarId: string, isPublic: boolean) => {
    await CalendarDb.updateUser(this.fsDb, this.userId, {
      [`calendars.${b64CalendarId}.isPublic`]: isPublic
    });
  }

  @computed
  get isBusy() {
    return this.connecting || this.disconnecting
  }

  @computed
  get isAuthorised() {
    return this.doc && !!this.doc?.auth?.tokens;
  }

  @computed
  get hasCalendars() {
    return this.calendarsById && Object.keys(this.calendarsById).length > 0;
  }

  @computed
  get selectedCalendars() {
    return Object.fromEntries(
      Object.entries(this.doc?.calendars || {})
        .filter(([_, cal]) => cal.sync && !cal.error && !cal.deleted)
    );
  }

  @computed
  get notifyCalendars() {
    return Object.fromEntries(
      Object.entries(this.doc?.calendars || {})
        .filter(([_, cal]) => cal.sync && !cal.error && cal.notifyEvents)
    );
  }

  @computed
  get myCalendars() {
    const cals = this.selectedCalendars;
    const cmp = (a, b) => a[1].summary.localeCompare(b[1].summary);
    const primary = Object.entries(cals).filter(([k, v]) => v.primary).sort(cmp).map(([k, v]) => k);
    const primaryId = primary[0];

    return [
      primaryId,
      ...Object.entries(cals)
        .filter(([k, v]) => !v.primary && v.accessRole === 'owner')
        .sort(cmp)
        .map(([k, v]) => k)
    ].filter(x => !!x);
  }

  @computed
  get otherCalendars() {
    const cals = this.selectedCalendars;
    const cmp = (a, b) => a[1].summary.localeCompare(b[1].summary);

    return Object.entries(cals)
      .filter(([k, v]) => !v.primary && v.accessRole !== 'owner')
      .sort(cmp)
      .map(([k, v]) => k);

  }

  @computed
  get email() {
    return this.doc?.auth?.email;
  }

  @computed
  get status() {
    return this.doc?.status || undefined;
  }

  getEvent = (calendarId: string, eventId: string): ICalEvent | undefined => {
    const k = CalEvent.key(b64encode(calendarId)!, eventId);

    if (this.eventsById?.[k])
      return this.eventsById[k];

    logger.debug("getEvent: event not found: %s", k);
    return undefined;
  }

  @computed
  get events() {
    return Object.values(this.eventsById ?? {});
  }

  getAccessToken = async () => {

    const tokens = this.doc?.auth?.tokens;

    if (!tokens)
      throw new Error("not authorised");

    logger.debug(`tokens.expiry_date: ${new Date(tokens.expiry_date!)}}`)
    if (tokens.expiry_date && tokens.expiry_date < new Date().getTime() - (60 * 1000)) {
      logger.info("attempting to generate new access token");
      const accessToken = httpsCallable(getFunctions(), "cal_accessToken");

      const result = await accessToken() as any
      return result.data.access_token;

    } else {
      logger.debug("use existing token")
    }

    return tokens?.access_token;
  }

  stopCheckNextEvent = () => {
    if (this._eventTimer) {
      clearTimeout(this._eventTimer);
      this._eventTimer = undefined;
    }
  }

  startCheckNextEvent = () => {

    let curEv: { event: ICalEvent, start: number } | undefined = undefined;
    let nextEv: { event: ICalEvent, start: number } | undefined = undefined;

    if (this._eventTimer)
      clearTimeout(this._eventTimer);

    if (this.isAuthorised && !this.isBusy) {

      const dtNow = new Date();

      const current = this.status?.current;
      const next = this.status?.next;

      if (next) {
        const nextEvent = this.getEvent(next.calendarId, next.eventId);
        if (nextEvent && CalEvent.isCurrentEvent(dtNow, nextEvent, next.start)) {
          curEv = { event: nextEvent, start: next.start };
        } else if (nextEvent) {
          nextEv = { event: nextEvent, start: next.start };
        }
      }

      if (!curEv && current) {
        const event = this.getEvent(current.calendarId, current.eventId);

        if (event && CalEvent.isCurrentEvent(dtNow, event, current.start)) {
          curEv = { event: event, start: current.start };
        }
      }

      const eventBoundaries = (evSummary: ICalEvSummary, start: number) => {
        const ev = this.getEvent(evSummary.calendarId, evSummary.eventId);
        if (ev)
          return [
            start,
            start + CalEvent.getEnd(ev) - CalEvent.getStart(ev),
          ];
        else
          return [];
      }

      const boundaries = [
        ...(current ? eventBoundaries(current, current.start) : []),
        ...(next ? eventBoundaries(next, next.start) : []),
      ];

      //logger.debug(`boundaries ${boundaries}. current,next`, toJS(current), toJS(next))
      if (boundaries.length) {

        const dtNowSecs = Math.floor(dtNow.getTime() / 1000);
        const lowerBound = Math.min(...boundaries.filter(x => x > dtNowSecs));

        // Don't overflow the timer
        const timeout = Math.min(60 * 60, lowerBound - dtNowSecs)
        logger.debug(`setting eventTimer for: ${timeout} seconds.  Time now`, new Date());

        this._eventTimer = setTimeout(this.startCheckNextEvent, timeout * 1000);

      } else {
        logger.debug("no current or next event timer started")
      }
    }

    runInAction(() => {
      this.currentEvent = curEv;
      this.nextEvent = nextEv;
    });

  };


  stopSchedule = () => {
    if (this._scheduleTimer) {
      clearTimeout(this._scheduleTimer);
      this._scheduleTimer = undefined;
    }
    this.schedule = undefined;
  }

  startSchedule = () => {

    const numDays = 2;

    if (this._scheduleTimer)
      clearTimeout(this._scheduleTimer);

    let schedule: [
      Record<string, ICalEvent>,
      DataIntervalTree<{
        evId: string;
        start: number;
      }>
    ] | undefined = undefined;

    if (this.isAuthorised && !this.isBusy) {

      const dtNow = new Date();

      const timeMin = getStartOfDay(dtNow);
      const timeMax = timeMin + (numDays * 24 * 60 * 60);

      const [byId, tree] = getIntervalTree(
        this.events,
        timeMin,
        timeMax,
      )
      schedule = [byId, tree];

      const nextBound = timeMin + (24 * 60 * 60);
      const nextTimer = nextBound - toSecs(dtNow);

      logger.debug("setting scheduleTimer for: ", nextTimer, " secs.  Time now", new Date());

      this._scheduleTimer = setTimeout(this.startSchedule, nextTimer * 1000);

    }
    runInAction(() => { this.schedule = schedule; });
  }

  setEventNotifiable = async (calendarId: string, evId: string, isNotifiable: boolean) => {

    const b64CalendarId = b64encode(calendarId)
    const compoundEvId = CalEvent.key(b64CalendarId, evId);

    if (this.eventsById?.[compoundEvId])
      this.eventsById[compoundEvId].meta.isNotifiable = isNotifiable;

    CalendarDb.setEventNotifiable(this.fsDb, this.userId, compoundEvId, isNotifiable);

    // this forces recalculation of the users status on the server.
    await CalendarDb.updateUser(this.fsDb, this.userId, {
      [`calendars.${b64CalendarId}.version`]: (this.doc?.calendars?.[b64CalendarId]?.version || 0) + 1,
    });
  }

}
