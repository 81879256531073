import { ILinkPreview } from "@openteam/models"
import { OTGlobals } from "..";
import { Logger } from "@openteam/app-util";
import { makeObservable, observable } from "mobx";
const logger = new Logger("LinkPreviewManager");

const urlCache: Record<string, URLPreview> = {}

export class URLPreview {
  url: string
  loadDate: number
  @observable loaded: boolean = false
  @observable loader?: Promise<ILinkPreview | null>
  @observable preview?: ILinkPreview | null
  constructor(url: string) {
    makeObservable(this)

    this.url = url;
    this.loadDate = Date.now();
    this.loadURL();
  }

  loadURL = () => {
    this.loaded = false;
    this.loadDate = Date.now();
    this.loader = OTGlobals.getLinkPreview(this.url)

    this.loader.then((result) => {
      this.preview = result
      this.loaded = true;
      logger.debug("loaded preview for", this.url, this.preview)
    })
  }

  update = () => {
    if (Date.now() - this.loadDate > 30 * 1000) {
      this.loadURL()
    }
  }
}

export const getLinkPreview = (url: string) => {
  logger.debug("get link preview for", url)

  if (urlCache[url]) {
    logger.debug("found link preview for url in cache", url, urlCache[url])
    urlCache[url].update()

    return urlCache[url]
  }

  urlCache[url] = new URLPreview(url)

  return urlCache[url]
}