import React from "react";
import { IOTChatMessage, IChatMessage, KSpaceId, IPinnedResource, ILinkPreview, IPendingMessage, IFile, ICallMessage, IUISpaceUser, IUIUserMinimal } from '@openteam/models';
import { createResourceFromAttachment, createResourceFromLink } from '@openteam/app-core'
import { Logger } from "@openteam/app-util";
import { DSBody } from "../../DesignSystem/DSText";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { ChatMessage } from "./ChatMessage";
import { ChatFile } from "./ChatFile";
import { ChatLinkPreview } from "./ChatLinkPreview";
import { ChatMarkdownView } from "./ChatMarkdownView";
import { observer } from "mobx-react";
import { ChatFileUpload } from "./ChatFileUpload";
import { DSColumn, DSPanel, DSWrappedRow } from "../../DesignSystem/DSLayout";
import { MimeTypeIcon } from "../../Util/MimeTypeIcon";
import { FaUpload } from "react-icons/fa";
import { DataState } from "../../Data/DataState";

const logger = new Logger("ChatMessageBody")

type Props = {
  spaceId: KSpaceId
  message: IOTChatMessage
  isReply?: boolean
  updateResource?: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>
  pendingMessage?: IPendingMessage
  saveLinkToChat?: (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => Promise<void>
  saveAttachmentToChat?: (id: string, file: IFile, fileId: string) => Promise<void>
  getUser?: (userId) => IUIUserMinimal | undefined
}


export const ChatMessageBody = observer((props: Props) => {
  const { message, updateResource, saveLinkToChat, getUser } = props
  const space = DataState.spaces[props.spaceId]

  const user = space?.users[message.userId] || getUser?.(message.userId) || {
    id: message.userId,
    name: "Unknown User",
  }

  const isMedia = (type: string) => {
    return type.includes("image")
  }




  const renderFiles = (filterMedia: boolean) => {
    const attachmentIds = Object.keys(message.attachments || {}).sort().filter(fileId => isMedia(message.attachments![fileId].type) ? filterMedia : !filterMedia)
    return (
      <DSWrappedRow style={{ alignItems: "flex-start" }}>
        {

            attachmentIds.map((fileId, index) => {
              const attachment = message.attachments![fileId]
              const pendingFile = props.pendingMessage?.attachmentFiles?.[fileId]

              const filesIndex = message.files?.findIndex(file => file.url == attachment.url)

              const resourceFile = message.files && filesIndex != undefined ? message.files[filesIndex] : undefined

              return <ChatFile
                key={`${fileId}`}
                file={attachment}
                url={attachment.url || pendingFile?.downloadUrl || pendingFile?.getPreviewURL()}
                previewUrl={attachment.url || pendingFile?.downloadUrl || pendingFile?.getPreviewURL()}
                uploading={(pendingFile && !pendingFile.completed) || !attachment.uploaded}
                progress={pendingFile?.progress || attachment.progress}

                onRemove={pendingFile && !pendingFile.completed ? () => pendingFile.stop() : undefined}
                resourceParams={resourceFile}
              />
            })
        }
      </DSWrappedRow>
    )
  }
  const mySaveLinkToChat = saveLinkToChat ? async (
    linkPreview: ILinkPreview,
    linkId: string | undefined
  ) => {
    const m = message as unknown as ICallMessage;
    saveLinkToChat(m.id, linkPreview, linkId);
  } : undefined;

  return (
    <>
      {
        message.replyMessage && !props.isReply ?
          <ChatMessage
            spaceId={props.spaceId}
            message={message.replyMessage}
            isReply={true}
            updateResource={updateResource}
            getUser={getUser}
          />
          : null
      }

      {renderFiles(true)}
      {renderFiles(false)}

      {
        message.isSystem ?
          <DSBody style={{ color: DSTheme.SystemMessageColor, fontStyle: 'italic' }}>
            {message.message}
          </DSBody>
          :
          <ChatMarkdownView key={message.id} message={message.message + (message.editDate ? "\n #edited#" : "")} spaceId={props.spaceId} />
      }
      {
        message.linkPreviews ?
          Object.keys(message.linkPreviews).map(linkid =>
            <ChatLinkPreview
              key={linkid}
              channelId={(message as any).channelId}
              messageId={message.id}
              linkId={linkid}
              linkPreview={message.linkPreviews![linkid]}
            />
          )
          :
          message.linkPreview ?
            <ChatLinkPreview
              messageId={message.id}
              channelId={(message as any).channelId}
              linkPreview={message.linkPreview}
            />
            :
            null
      }
    </>
  )
})
