import { action } from 'mobx';
import { OTGlobals } from "../OTGlobals";
import { Logger } from "@openteam/app-util";
import { TStreamType } from '@openteam/models';

const logger = new Logger("setStream");

export const setStream = action((
  teamId: string,
  roomId: string,
  userId: string,
  streamType: TStreamType,
  streamId: string | null = null
) => {
  let streamHolder;

  const callStateManager = OTGlobals.callStateManager;

  if (callStateManager?.callState.roomId == roomId) {
    // All room streams (Remote + Local)
    streamHolder = callStateManager?.callState.streams;
  } else {
    logger.error(`Unable to set streams on room ${roomId}, not current room`);
  }

  if (streamHolder) {
    logger.debug(`setting stream for [room:${roomId}] [user:${userId}] ${streamType} to ${streamId}`);

    if (streamId) {
      if (!streamHolder[userId]) {
        streamHolder[userId] = {};
      }

      if (streamId) {
        streamHolder[userId][streamType] = streamId;
      } else if (streamHolder[userId][streamType]) {
        delete streamHolder[userId][streamType];
      }
    } else {
      if (streamHolder[userId] && streamHolder[userId][streamType])
        delete streamHolder[userId][streamType];
    }

    if (streamHolder[userId] && Object.keys(streamHolder[userId]).length == 0) {
      delete streamHolder[userId];
    }
  } else {
    logger.warn(`No stream holder found for [room:${roomId}]`);
  }
})
