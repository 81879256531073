import { Logger } from "@openteam/app-util";
import { Panel, H3, useTheme } from "@openteam/design-system";
import { IMeetingTokenDetails } from '@openteam/models';
import { observer } from "mobx-react";
import React from "react";
import Page from "../../Util/Page";
import { meetingMode } from "./Meet";


const logger = new Logger("MeetConnect")

interface Props {
  meetingData?: IMeetingTokenDetails
  mode?: meetingMode
}

export const MeetFinished = observer((props: Props) => {
  const theme = useTheme()

  return (
    <Page>
      <Panel style={{
        alignItems: 'center',
        flex: 1,
      }}>
        {
          props.mode === 'rejected' ?
            <H3 style={{ textAlign: 'center', color: 'red' }}>
              Your request to join the meeting has been rejected.{<br />}
              Please contact the person who invited you.

            </H3>
            :
            <H3 style={{ textAlign: 'center', }}>
              The meeting has ended, {<br />}
              thank you for using <span style={{ color: theme.palette.primary.main }}>ehlo</span>.
            </H3>
        }

      </Panel>
    </Page >
  );
})
