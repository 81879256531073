import { AppHomeManager, FireAuth, FireDb, OTAppCoreData, OTGlobals, OTUITree, OTUserInterface } from "@openteam/app-core";
import * as config from "../config";
import * as Sentry from "@sentry/react";

import { InfluxSender, Logger, setLogSender } from "@openteam/app-util";
import * as Analytics from '../Util/Analytics'
import { v4 as uuidv4 } from "uuid";
import { osName } from "react-device-detect";
import React from "react";
import { runInAction } from "mobx";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { IOTRemoteConfig } from "@openteam/models";


const logger = new Logger("app");


export const setup = async () => {
  logger.info("app startup")
  Analytics.logEvent("AppStart")

  loadRemoteConfig()

  OTAppCoreData.version = React.version; //app.getVersion();
  OTAppCoreData.deviceId = uuidv4();

  OTGlobals.createCache(OTAppCoreData.version!);
  OTGlobals.registerAnalytics({
    logEvent: Analytics.logEvent,
    identifyUser: (userId) => {
      Analytics.identifyUser(userId);
    }
  });
  // OTGlobals.pluginConfigList = getPluginConfig();
  // OTGlobals.actionTypeDetails = getActionTypeConfig();
  OTGlobals.registerSentry(Sentry);
  OTGlobals.registerGetDisplayMedia(navigator.mediaDevices.getDisplayMedia);
  OTGlobals.setConfig(config);
  OTGlobals.registerGetLinkPreview(async () => { return null });

  // OTUserInterface.setRingtones({
  //   getIncoming: getIncomingRingtone,
  //   getOutgoing: getOutgoingRingtone,
  // });
  OTUserInterface.setSoundEffects({
    globalAlert: () => { },
    alert: () => { },
    videoBell: () => { },
    knock: () => { },
    newMessage: () => { },
    unmute: () => { },
    screenShareStart: () => { },
    pluginOpened: () => { },
  });
  OTUserInterface.registerForegroundService({ startCall: async () => { }, stopCall: async () => { } });
  // OTUserInterface.registerParseEmojis(parseEmojis);
  OTUserInterface.registerPlatformUtils({
    Platform: "web",
    PlatformOS: osName,
    allowDisplaySleep: () => { },
    focusWindow: (subWin: Window, ref: string) => { },
    getOpenWindows: () => { return {} },
    openWindow: (url: string, ref: any, options: string) => { return null },
    preventDisplaySleep: () => { },
    createTray: () => { },
    destroyTray: () => { }
  });
  OTUserInterface.registerToastHandlers({ show: () => { }, hide: () => { } });
  OTUserInterface.registerShowNotification(() => { });
  OTUserInterface.registerHideNotification(() => { });
  OTUserInterface.registerNavigate(() => { });

  logger.debug("done setup")
};


const loadRemoteConfig = () => {

  FireDb.watchConfig(getDatabase(), (data: IOTRemoteConfig) => {
    logger.info("loaded remoteConfig data=%o", data);
    if (data) {
      OTAppCoreData.remoteConfig = data;
    }
  });
}
