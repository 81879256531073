import { makeObservable, observable } from "mobx";
import { debounce } from "throttle-debounce";

export class DimensionManager {
  @observable windowWidth: number = window.innerWidth;
  @observable windowHeight: number = window.innerHeight;

  constructor() {
    makeObservable(this)
    this.start();
  }

  start = () => {
    window.addEventListener('resize', this.getDimensions);
  };

  stop = () => {
    window.removeEventListener('resize', this.getDimensions);
  };

  getDimensions = debounce(50, () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  });
}
