import { IOTRemoteConfig } from "@openteam/models";
import { computed, makeObservable, observable } from "mobx";

export class OTAppCoreDataClass {
  @observable version: string | null | undefined = undefined;
  @observable remoteConfig?: IOTRemoteConfig = undefined;
  @observable deviceId?: string = undefined;

  constructor() {
    makeObservable(this)
  }

  @computed get useTeamServer() {
    return this.remoteConfig?.useTeamServer;
  }

  @computed get TeamServerUrl() {
    return this.remoteConfig?.TeamServerUrl;
  }

  @computed get SFUUrl() {
    return this.remoteConfig?.SFUEnabled && this.remoteConfig?.SFUUrl;
  }

  @computed get ScalableSFUUrl() {
    return this.remoteConfig?.SFUEnabled && this.remoteConfig?.ScalableSFUUrl;
  }

  @computed get ScalableSFU() {
    return this.remoteConfig?.ScalableSFUUrl && this.remoteConfig?.ScalableSFU === true;
  }

  @computed get useSFU() {
    return this.remoteConfig?.SFUEnabled && this.remoteConfig?.SFUUrl ? true : false;
  }
}

export const OTAppCoreData = new OTAppCoreDataClass();
