import React, { useState, CSSProperties } from 'react';
import { ISearchFileRecord } from '@openteam/models';
import { FaLink } from 'react-icons/fa';
import { DSImage } from '../DesignSystem/DSImage';
import { MimeTypeIcon } from '../Util/MimeTypeIcon';


export const FavIcon: React.FC<{ favicon: string; size?: number; style: CSSProperties; }> = ({ favicon, size = 30, style, ...props }) => {

  const [fallback, setFallback] = useState<boolean>(false);

  if (fallback)
    return <FaLink size={size - 6} style={style} />;

  else
    return <DSImage maxWidth={size} height={size} url={favicon} style={style} onError={(e) => { setFallback(true); }} />;
};


export const FileIcon: React.FC<{
  hit: ISearchFileRecord;
  size?: number;
  borderRadius?: number;
  allowPreview?: boolean;
}> = ({ hit, size = 30, borderRadius = 8, allowPreview }) => {
  const iconColor = 'rgba(255,255,255,0.5)';

  if (hit.recordType === "link") {
    let favIcon = getFavIcon(hit.url, hit.favicon);

    return favIcon ? (
      <FavIcon favicon={favIcon} size={size} style={{
        marginTop: 5, marginBottom: 5, borderRadius, filter: "drop-shadow(0px 0px 0.5px #aaa)", minWidth: size, color: iconColor
      }} />
    ) : hit.type ? (
      <MimeTypeIcon mimeType={hit.type} size={size} />
    ) : (
      <FaLink size={size - 6} style={{ color: iconColor, marginTop: 5, marginBottom: 5, minWidth: size }} />
    );
  } else {
    if (allowPreview && hit.type?.startsWith("image")) {
      return <DSImage width={30} height={30} url={hit.url} dummyColor="transparent" style={{ borderRadius, objectFit: 'cover' }} />;
    }
    return <MimeTypeIcon mimeType={hit.type} size={30} style={{
      color: iconColor, minWidth: size
    }} />;
  }
};
const getFavIcon = (url: string, hitFavIcon?: string) => {
  let favIcon = hitFavIcon;
  if (url) {
    for (const site in favIconOverride) {
      if (url.match(favIconOverride[site].regex)) {
        favIcon = favIconOverride[site].favIcon;
        break;
      }
    }
  }

  return favIcon;
};
const favIconOverride = {
  figma: {
    regex: /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
    favIcon: "https://static.figma.com/app/icon/1/favicon.svg"
  },
  github: {
    regex: /https:\/\/github\.com/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgithublight.png?alt=media&token=263da7b8-ccb8-4169-9107-3a1b0fb1cdb1"
  },
  googledocs: {
    regex: /https:\/\/docs\.google\.com\/document/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgoogledocs.png?alt=media&token=822b0e88-e533-4510-b9c8-ae4c13a317fd"
  },
  googlesheets: {
    regex: /https:\/\/docs\.google\.com\/spreadsheets/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgooglesheets.png?alt=media&token=507738a4-4ebf-484e-8187-22fdc6b36f63"
  },
  googleslides: {
    regex: /https:\/\/docs\.google\.com\/presentation/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgoogleslides.png?alt=media&token=717d22b4-f416-4cb6-90d4-58babebfe547"
  },
  googledrive: {
    regex: /https:\/\/drive\.google\.com/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgoogledrive.png?alt=media&token=e08c65fa-865e-4d06-b10b-cf1d60817945"
  },
  googlecalendar: {
    regex: /https:\/\/calendar\.google\.com/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgooglecalendar.png?alt=media&token=c756e32d-35ab-41a6-9d36-e6f382440049"
  },
  googlekeep: {
    regex: /https:\/\/keep\.google\.com/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgooglekeep.png?alt=media&token=1a64b796-8951-4240-b724-2a7068a49e52"
  },
  googleforms: {
    regex: /https:\/\/docs\.google\.com\/forms/,
    favIcon: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fshareicons%2Fgoogleforms.png?alt=media&token=43749f03-d1ca-464c-a4a2-b3dc6b8471a0"
  },
  clickup: {
    regex: /https:\/\/app\.clickup\.com/,
    favIcon: "https://app-cdn.clickup.com/assets/images/brand/clickup-symbol_color.svg"
  },
};
