import { animated, useSpring, config } from '@react-spring/web';
import React, { useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'react';
import { DSPrint, DSH5, DSH3, DSH4 } from "./DSText"
import { DSTheme } from './DSTheme';
import { Logger } from "@openteam/app-util";
import { FaChevronDown } from 'react-icons/fa';
import { useCallback } from 'react';
import { HoverIntentProps, useHoverIntent } from '../Util/HoverIntent';
import { DSBadgeView, TBadgePosition } from './DSBadge';
import useResizeObserver from 'use-resize-observer';
import * as Analytics from '../Util/Analytics'
import { observer } from 'mobx-react';
import { DSRow } from './DSLayout';
const logger = new Logger("DSButton");

export interface IDSButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  xlateCode?: string;
  title?: any;
  style?: CSSProperties;
  fontSize?: string | number;
  onHovered?: (hovered: boolean) => void;
  onMouse?: (mouseDown: boolean) => void
  hoverEffect?: string;
  clearHoverOnClick?: boolean;
  analyticsEvent?: string;
  analyticsArgs?: Record<string, string | null | undefined>;
  href?: string;
  hoverSettings?: HoverIntentProps
  stopPropagation?: Partial<StopPropagation>
  disabled?: boolean;
  emphasis?: boolean;
  allowDuringTutorial?: boolean;
}

type StopPropagation = {
  onClick: boolean
  onPointerDown: boolean
}

export const DSButton = React.forwardRef<HTMLDivElement, IDSButtonProps>((props, _) => {
  const {
    analyticsEvent,
    analyticsArgs,
    onClick,
    onHovered,
    onMouse,
    title,
    children,
    style,
    color: propColor,
    stopPropagation: _stopPropagation,
    hoverSettings,
    allowDuringTutorial = false,
    disabled = false,
    emphasis = false,
    hoverEffect = "brightness(110%)",
    clearHoverOnClick = true,
    ...otherProps
  } = props

  const stopPropagation: StopPropagation = {
    onClick: true,
    onPointerDown: true,
    ..._stopPropagation
  };


  const [hovered, ref, clearHover] = useHoverIntent<HTMLDivElement>({
    timeout: 100,
    //label: "button",
    ...hoverSettings
  });
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const [isMouseDown, setIsMouseDown] = React.useState(false);

  const enabled = !disabled && (onClick || props.onMouseDown)

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  const onDSButtonClick = React.useCallback((e) => {
    if (stopPropagation.onClick) {
      e.stopPropagation();
    }

    onClick && onClick(e)
    clearHoverOnClick && clearHover()
    props.analyticsEvent && Analytics.logEvent(`Button_${props.analyticsEvent}`, { interaction: 'button', ...props.analyticsArgs })
  }, [stopPropagation, onClick, props.analyticsEvent, clearHoverOnClick, clearHover])


  const onPointerDown = useCallback((e) => {
    //logger.debug(`pointerdown on `, e.target, e.currentTarget, ref?.current)
    if (ref && e.currentTarget === ref.current) {
      if (stopPropagation.onPointerDown)
        e.stopPropagation();
      setIsMouseDown(true)
    }
  }, [stopPropagation, setIsMouseDown, ref])

  const onPointerUp = useCallback((e) => {
    if (ref && e.currentTarget === ref.current) {
      setIsMouseDown(false)
    }
  }, [stopPropagation, setIsMouseDown, ref])

  useEffect(() => {
    onHovered && onHovered(hovered);
  }, [onHovered, hovered])

  useEffect(() => {
    onMouse && onMouse(isMouseDown);
  }, [onMouse, isMouseDown])

  const opacity = 1;
  const filter = enabled && hovered && !isMouseDown ? hoverEffect : undefined

  const color = propColor ?? (disabled ? DSTheme.SecondaryText : (emphasis ? DSTheme.EmphasisColor : style?.color || DSTheme.MainText));

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: enabled ? "pointer" : "default",
        pointerEvents: "initial",
        WebkitUserSelect: "none",
        ...DSTheme.NoDrag,
        opacity,
        filter,
        transition: "filter .15s ease-in-out",
        width: "fit-content",
        ...style,
        color,
      }}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onClick={(enabled && onClick) ? onDSButtonClick : undefined}
      {...otherProps}
    >
      {children}
      {
        (typeof title === "string" ?
          <DSH4
            style={{
              color,
              cursor: 'inherit',
              ...props.fontSize && { fontSize: props.fontSize }
            }}>
            {title}
          </DSH4>
          : title
        )
      }
    </div>
  );
});

export const DSOutlineButton = React.forwardRef<HTMLDivElement, IDSButtonProps>((props, ref) => {
  const { style, color, ...otherProps } = props

  return <DSButton
    ref={ref}
    color={color}
    {...otherProps}
    style={{
      borderRadius: DSTheme.BaseBorderRadius,
      borderColor: color ?? DSTheme.MainText,
      borderWidth: 2,
      borderStyle: 'solid',
      padding: "4 6 4 6",
      ...style
    }}
  />
})
export interface ShadedButtonProps {
  style?: CSSProperties;
  mouseDownStyle?: CSSProperties
  hoverStyle?: CSSProperties;
  disabledStyle?: CSSProperties;
  disabled?: boolean
  hovered?: boolean
  mouseDown?: boolean
  selected?: boolean
}


export const ShadedButton: React.FC<ShadedButtonProps> = observer(props => {
  const {
    style,
    disabled,
    hovered,
    selected,
    mouseDown: _mouseDown,
    disabledStyle,
    hoverStyle,
    mouseDownStyle,
  } = props;

  const [mouseDown, setMouseDown] = React.useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (_mouseDown) {
      setMouseDown(true)
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setMouseDown(false);
        timeout.current = undefined;
      }, 500);
    }
  }, [_mouseDown, setMouseDown])

  let effectStyles = {
    backgroundColor: style?.backgroundColor ?? DSTheme.ButtonBackgroundColor
  };

  if (disabled) {
    effectStyles = {
      backgroundColor: DSTheme.DisabledBackgroundColor,
      ...disabledStyle
    }
  } else if (mouseDown || selected) {
    effectStyles = {
      backgroundColor: DSTheme.EmphasisColor,
      ...mouseDownStyle
    }
  } else if (hovered) {
    effectStyles = {
      backgroundColor: DSTheme.EmphasisBackgroundColor,
      ...hoverStyle
    }
  }
  const clickStyles = useSpring(effectStyles)

  return (
    <animated.div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 8,
        borderRadius: 999,
        ...style,
        ...clickStyles
      }}
    >
      {props.children}
    </animated.div>
  );
})

export interface IDSCircleIconButtonProps extends IDSButtonProps, ShadedButtonProps {
  selected?: boolean;
  icon?: JSX.Element;
  showLabel?: boolean
  lozenge?: boolean;
  outerStyle?: CSSProperties
  badgeOverride?: string | JSX.Element;
  badgeNum?: number;
  hideBadge?: boolean;
  badgePosition?: TBadgePosition;
  hoverTitle?: string;
  hoverBadgeOverride?: string | JSX.Element;
}

export const DSCircleIconButton = observer(React.forwardRef<HTMLDivElement, IDSCircleIconButtonProps>(
  (props, ref) => {
    const {
      style,
      outerStyle,
      showLabel = false,
      lozenge = false,
      icon,
      title,
      selected,
      disabled,
      mouseDownStyle,
      hoverStyle,
      hoverTitle,
      hoverBadgeOverride,
      disabledStyle,
      badgeOverride,
      badgeNum,
      badgePosition,
      hideBadge,
      onHovered,
      ...otherProps
    } = props;

    const [hovered, setHovered] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);

    useEffect(() => {
      if (onHovered) {
        onHovered(hovered)
      }
    }, [onHovered, hovered])

    return (
      <DSButton
        ref={ref}
        data-tooltip={showLabel || lozenge ? undefined : title}
        {...otherProps}
        onHovered={setHovered}
        hoverEffect="none"
        disabled={disabled}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
        style={{
          flexDirection: "column",
          color: DSTheme.ButtonIconColor,
          ...outerStyle,
        }}
      >
        <ShadedButton
          disabled={disabled}
          hovered={hovered}
          mouseDown={mouseDown}
          selected={selected}
          disabledStyle={disabledStyle}
          hoverStyle={hoverStyle}
          mouseDownStyle={mouseDownStyle}
          style={{
            position: "relative",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: 8,
            borderRadius: 999,
            aspectRatio: "1/1",
            ...style,
            ...lozenge ? {
              padding: "5px 8px",
              width: undefined,
              height: undefined,
              aspectRatio: undefined,
            } : {},
          }}
        >
          {icon}
          {
            lozenge ?
              <>
                <DSH5 style={{
                  paddingLeft: 6,
                }}>
                  {hovered && hoverTitle ? hoverTitle : title}
                </DSH5>
              </>

              :
              null
          }
          {props.children}
          <DSBadgeView
            badgeNum={badgeNum}
            badgeOverride={hovered && hoverBadgeOverride ? hoverBadgeOverride : badgeOverride}
            badgePosition={badgePosition}
            show={hideBadge}
          />
        </ShadedButton>
        {showLabel && title ? <DSH5 style={{ marginTop: 4 }}>{title}</DSH5> : null}
      </DSButton>
    );
  })
);

interface LongButtonProps extends IDSCircleIconButtonProps, ShadedButtonProps { }

export const DSLongButton = observer(React.forwardRef<HTMLDivElement, LongButtonProps>(
  (props, ref) => {
    const {
      style,
      disabled,
      mouseDownStyle,
      hoverStyle,
      disabledStyle,
      badgeNum,
      badgePosition,
      hideBadge,
      onHovered,
      children,
      title,
      ...otherProps
    } = props;
    const [hovered, setHovered] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);

    useEffect(() => {
      if (onHovered) {
        onHovered(hovered)
      }
    }, [onHovered, hovered])

    return (
      <DSButton
        ref={ref}
        {...otherProps}
        onHovered={setHovered}
        hoverEffect="none"
        disabled={disabled}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
      >
        <ShadedButton
          disabled={disabled}
          hovered={hovered}
          mouseDown={mouseDown}
          disabledStyle={disabledStyle}
          hoverStyle={hoverStyle}
          mouseDownStyle={mouseDownStyle}
          style={{
            padding: "2 4",
            borderRadius: 999,
            backgroundColor: DSTheme.ButtonBackgroundColor,
            ...style,
          }}
        >
          {title}
          {children}
        </ShadedButton>
      </DSButton>
    );
  }
));
export interface IDSPillButtonProps extends IDSButtonProps {
  icon?: JSX.Element;
  vertical?: boolean;
  button1Props?: any;
  button2Props?: any;
}

export const DSPillButton: React.FC<IDSPillButtonProps> = (props) => {
  const { style, title, icon, stopPropagation, vertical = false, button1Props, button2Props, ...otherProps } = props
  const [hovered1, setHovered1] = React.useState(false);
  const [mouseDown1, setMouseDown1] = React.useState(false);
  const [hovered2, setHovered2] = React.useState(false);
  const [mouseDown2, setMouseDown2] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        borderRadius: 999,
        //backgroundColor: DSTheme.ButtonBackgroundColor,
        height: 'fit-content',
        overflow: 'hidden',
        width: 'fit-content',
        ...style
      }}
    >
      <DSButton
        stopPropagation={stopPropagation}
        onHovered={setHovered1}
        hoverEffect="none"
        onMouseDown={() => setMouseDown1(true)}
        onMouseUp={() => setMouseDown1(false)}
        {...otherProps}
        {...button1Props}
      >
        <ShadedButton hovered={hovered1} mouseDown={mouseDown1} style={{ padding: 7, borderRadius: 0 }}>
          {icon}
        </ShadedButton>
      </DSButton>
      <div style={{ background: "rgba(0,0,0,0.7)", width: vertical ? 'auto' : 1, height: vertical ? 1 : 'auto' }} />
      <DSButton
        stopPropagation={stopPropagation}
        onHovered={setHovered2}
        hoverEffect="none"
        onMouseDown={() => setMouseDown2(true)}
        onMouseUp={() => setMouseDown2(false)}
        {...otherProps}
        {...button2Props}
      >
        <ShadedButton hovered={hovered2} mouseDown={mouseDown2} style={{ padding: 7, borderRadius: 0 }}>
          <FaChevronDown size={DSTheme.IconSize} />
        </ShadedButton>
      </DSButton>
    </div>
  );
}

export interface IDSExpandButtonProps extends Omit<IDSButtonProps, 'title'> {
  icon?: JSX.Element;
  mode?: "hover" | "press" | "none"
  expandedStyle?: CSSProperties
  strong?: boolean
  title: JSX.Element | string
  titleWidth?: number
}
export const DSExpandButton: React.FC<IDSExpandButtonProps> = (props) => {
  const { style, icon, title, onClick, mode = "press", expandedStyle, strong, titleWidth: _titleWidth, ...otherProps } = props
  const { ref, width } = useResizeObserver()
  const [expanded, setExpanded] = useState(false);
  const titleWidth = useRef<any>(_titleWidth ?? 'auto')

  const _onClick: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    // logger.debug(`onClick expanded ${expanded}`)
    if (!expanded && mode != "none") {
      setExpanded(true)
    } else {
      onClick && onClick(e)
    }
  }, [mode, expanded, setExpanded, onClick])

  const _onHovered = useCallback((hovered) => {
    // logger.debug(`onHover ${hovered}`)

    if (!hovered) {
      setExpanded(false)
    } else if (mode === "hover") {
      setExpanded(true)
    }
  }, [setExpanded])

  const expandStyle = useSpring(
    {
      to:
      {
        width: expanded ? titleWidth.current : 0,
        opacity: expanded ? 1 : 0,
        marginLeft: expanded ? 4 : 0
      },
      config: { ...config.stiff, clamp: true }
    })

  useEffect(() => {
    if (width && (titleWidth.current === "auto" || width > titleWidth.current)) {
      titleWidth.current = width
    }
  }, [width, titleWidth])

  return (
    <DSButton
      onClick={_onClick}
      onHovered={_onHovered}
      hoverSettings={{ timeout: 750 }}
      clearHoverOnClick={false}
      style={{
        display: "flex",
        borderRadius: 999,
        backgroundColor: DSTheme.ButtonBackgroundColor,
        padding: 7,
        ...style,
        ...expanded && expandedStyle
      }}
      {...otherProps}
    >
      {icon}
      <animated.div ref={ref} style={{ ...expandStyle, overflow: "hidden" }}>
        {
          (typeof title === "string" ?
            <DSPrint strong={strong} style={{ cursor: "inherit" }}>{title}</DSPrint>
            : title
          )
        }
      </animated.div>
    </DSButton>
  )
}

type ButtonSegment = {
  node: React.ReactNode
  onClick?: () => void
}
interface IDSSegmentButtonGroupProps {
}

export const DSSegmentButtonGroup: React.FC<IDSSegmentButtonGroupProps> = observer(({ children }) => {
  return (
    <DSRow
      style={{
        borderRadius: 999,
        overflow: "hidden",
        padding: 0,
        backgroundColor: DSTheme.ButtonBackgroundColor
      }}>
      {children}
    </DSRow>
  )
})

interface IDSSegmentButtonProps extends ShadedButtonProps {
  selected?: boolean
  onClick?: () => void;
}

export const DSSegmentButton: React.FC<IDSSegmentButtonProps> = observer(
  ({ style, selected = false, onClick, ...props }) => {
    const [hovered, setHovered] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);

    return (
      <DSButton
        onClick={onClick}
        onHovered={setHovered}
        hoverEffect="none"
        disabled={selected}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
      >
        <ShadedButton
          disabled={selected}
          hovered={hovered}
          mouseDown={mouseDown}
          disabledStyle={{ backgroundColor: DSTheme.EmphasisColor, color: DSTheme.MainText }}
          style={{ color: DSTheme.SecondaryText, backgroundColor: "transparent", borderRadius: 999, padding: "6", ...style }}
          {...props}
        />
      </DSButton>
    );
  }
);

export const DSFilledButton: React.FC<IDSButtonProps> = observer(
  ({ style, ...props }) => {

    return (
      <DSButton
        {...props}
        style={{
          backgroundColor: DSTheme.EmphasisColor,
          color: DSTheme.PanelContrastColor,
          borderRadius: 6,
          padding: "0 8",
          height: 26,

          ...style
        }}
      />
    );
  }
);