import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Page from "../Util/Page";
import { PanelLoading } from './Panels';
import { isMobile } from "react-device-detect";
import * as Analytics from "../Util/Analytics";
import { DSTheme } from "../DesignSystem/DSTheme";
import { LoginState } from '../Controllers/UIState';
import { Logger } from "@openteam/app-util";
import DownloadApp from "./DownloadApp";
import AccountSetup from "./AccountSetup";
import OpenApp from "./OpenApp";
import { Body, H1, Panel, useTheme } from "@openteam/design-system";
import TeamSetup from "./TeamSetup";
import InviteUsers from "./InviteUsers";
import AcceptInvite from "./AcceptInvite";

const logger = new Logger('appLogin')

type AppLoginPageProps = {
  redirectURL?: string | null
  inviteToken?: string | null
};

const DOWNLOAD_URL = {
  'MacOS': "https://download.ehlo.space/latest/Ehlo.dmg",
  'Linux': "https://snapcraft.io/ehlo",
  'Windows': "https://download.ehlo.space/latest/EhloSetup.exe",
}

export const AppLoginPage: React.FC<AppLoginPageProps> = observer(({inviteToken}) => {
  const theme = useTheme()
  const [inviteAccepted, setInviteAccepted] = useState(false)

  useEffect(() => {
    logger.debug(`AppLogin with inviteToken ${inviteToken}`)
    Analytics.setCurrentScreen("AppLoginPage");
  }, [])


  let state: 'initial' | 'accountSetup' | 'acceptInvite'| 'teamSetup' | 'inviteUsers' | 'ready' = 'ready';

  if (!LoginState.initialised) {
    state = 'initial';
  } else if (!LoginState.user) {
    state = 'accountSetup'
  } else if (inviteToken && !inviteAccepted) {
    state = 'acceptInvite'
  } else if (!LoginState.teams) {
    state = 'teamSetup'
  } else if (LoginState.needsInvite) {
    state = 'inviteUsers'
  }

  useEffect(() => {
    logger.debug(`state: ${state}, hasInvite: ${!!inviteToken} inviteAccepted: ${inviteAccepted}`)
  }, [state, inviteToken, inviteAccepted])

  if (isMobile) {
    return (
      <Page style={{color: theme.palette.text.primary}}>
        <Panel style={{ alignItems: 'center' }}>

          <H1 style={{ color: "black", marginBottom: 24, fontWeight: 700, fontSize: 32, textAlign: "center" }}>
            Download <span style={{ color: DSTheme.EmphasisColor }}>ehlo</span>
          </H1>

          <Body wrap style={{ textAlign: 'center' }}>
            <span style={{ color: DSTheme.EmphasisColor }}>ehlo</span> isn't available for mobile yet, please download it on your desktop. {<br />}{<br />}We've sent you an email with instructions on how to do this.
          </Body>
          <DownloadApp />
        </Panel>
      </Page>
    )
  } else if (state === 'initial') {
    return <Page><PanelLoading text={'Checking authentication...'} /></Page>
  } else if (state === 'accountSetup') {
    return <Page><AccountSetup /></Page>
  } else if (state === 'acceptInvite') {
    return <Page><AcceptInvite inviteToken={inviteToken!} onAccepted={() => setInviteAccepted(true)}/></Page>
  } else if (state === 'teamSetup') {
    return <Page><TeamSetup /></Page>
  } else if (state === 'inviteUsers') {
    return <Page><InviteUsers /></Page>
  } else {
    return <Page><OpenApp /></Page>
  }
});
