import React, { Children, CSSProperties, ReactNode } from "react";

interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string
    reverse?: boolean
}

export const DSPanel = React.forwardRef<HTMLDivElement, PanelProps>(({ style, children, ...props }, ref) => {
    return (
        <div
            ref={ref}
            style={{
                alignItems: 'stretch',
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                ...style
            }}
            {...props}
        >
            {children}
        </div>
    )
})

export const DSPanelScrollable = React.forwardRef<HTMLDivElement, PanelProps>(({ style, children, ...props }, ref) => {
    return (
        <DSPanel
            ref={ref}
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                outline: "none",
                ...style
            }}
            {...props}
        >
            {children}
        </DSPanel>
    )
})

interface RowColProps extends PanelProps {
    spacing?: number
    separator?: ReactNode
}

export const DSRow = React.forwardRef<HTMLDivElement, RowColProps>(
    (
        { style = {}, reverse = false, spacing, separator, children, ...props },
        ref
    ) => {
        const arrayChildren = Children.toArray(children);

        return (
            <DSPanel
                ref={ref}
                style={{ ...style, flexDirection: reverse ? "row-reverse" : "row" }}
                {...props}
            >
                {Children.map(arrayChildren, (child, index) => (
                    index && (spacing || separator) ? (
                        <>
                            {spacing ? <DSHSpacer size={spacing} /> : null}
                            {separator ? separator : null}
                            {(spacing && separator) ?  <DSHSpacer size={spacing} /> : null}
                            {child}
                        </>
                    ) : child
                ))}
            </DSPanel>
        )
    })

export const DSWrappedRow = React.forwardRef<HTMLDivElement, RowColProps>(({ style = {}, children, ...props }, ref) => {
    return (
        <DSRow
            ref={ref}
            style={{ ...style, flexWrap: 'wrap' }}
            {...props}
        >
            {children}
        </DSRow>
    )
})

export const DSColumn = React.forwardRef<HTMLDivElement, RowColProps>(
    (
        { style = {}, reverse = false, spacing, separator,  children, ...props },
        ref
    ) => {

        const arrayChildren = Children.toArray(children);
        return (
            <DSPanel
                ref={ref}
                style={{ ...style, flexDirection: reverse ? "column-reverse" : "column" }}
                {...props}
            >
                {Children.map(arrayChildren, (child, index) => (
                    index && (spacing || separator) ? (
                        <>
                            {spacing ? <DSVSpacer size={spacing} /> : null}
                            {separator ? separator : null}
                            {(spacing && separator) ?  <DSVSpacer size={spacing} /> : null}
                            {child}
                        </>
                    ) : child
                ))}
            </DSPanel>
        )
    })

export const DSWrappedColumn = React.forwardRef<HTMLDivElement, RowColProps>(({ style = {}, children, ...props }, ref) => {
    return (
        <DSColumn
            ref={ref}
            style={{ ...style, flexWrap: 'wrap' }}
            {...props}
        >
            {children}
        </DSColumn>
    )
})


interface LabelledBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    legend: ReactNode;
}

export const DSLabelledBox = React.forwardRef<HTMLFieldSetElement, LabelledBoxProps>(({ style = {}, children, legend }, ref) => {
    return (
        <fieldset
            ref={ref}
            style={{
                border: "white 1px solid",
                borderRadius: 10,
                marginTop: 4,
                marginBottom: 4,
                ...style
            }}>
            <legend style={{
                fontSize: '16px',
                fontWeight: 600,
                color: "white",
                padding: "0px 10px",
            }}>
                {legend}
            </legend>
            {children}
        </fieldset>
    )
})

export const DSHSpacer: React.FC<{ size: number, style?: CSSProperties }> = ({ size, style }) =>
    <div style={{ display: "flex", width: size, flexGrow: 0, flexShrink: 0, flexBasis: size, ...style }} />;

export const DSVSpacer: React.FC<{ size: number, style?: CSSProperties }> = ({ size, style }) =>
    <div style={{ display: "flex", height: size, flexGrow: 0, flexShrink: 0, flexBasis: size, ...style }} />;
