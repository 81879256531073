import { isGenericEmail, teamNameConstraints } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { Body, Button, CircularProgress, Column, H2, Panel, Print, Row, Spinner, styled, ThemeProvider, useTheme, VSpacer } from "@openteam/design-system";
import { useCallback, useEffect, useRef, useState } from "react";
import Input from "../Components/Input";
import Title from "../Components/Title";
import { acceptInvite, createTeam, getCurrentUser, TInviteDetails, verifyInvite } from "../Controllers/Login";
import { DSImage } from "../DesignSystem/DSImage";
import { DSPrint } from "../DesignSystem/DSText";
import Separator from "./Separator";
import * as Analytics from "../Util/Analytics";
import { PanelLoading } from "./Panels";
import validate from "validate.js";

const logger = new Logger('TeamSetup')

type Props = { }


const TeamSetup: React.FC<Props> = () => {
  const theme = useTheme()

  const [workspaceLink, setWorkspaceLink] = useState('')
  const [workspaceName, setWorkspaceName] = useState('')
  const [busyState, setBusyState] = useState<'checking' | 'verifying' | 'accepting' | 'creating' | null>(null);
  const [linkError, setLinkError] = useState(false);
  const [inviteData, setInviteData] = useState<TInviteDetails>()

  const mounted = useRef(false)



  useEffect(() => {
    Analytics.setCurrentScreen("TeamSetupPage");

    const { email } = getCurrentUser();

    if (!isGenericEmail(email)) {
      const domain = email.match(/@([^\.]*)\..*$/)?.[1];
      if (domain) {
        setWorkspaceName(domain);
      }
    }

    if (!mounted.current) {
      mounted.current = true;
      return () => { mounted.current = false }
    }
  }, [])

  const createWorkspace = useCallback(async () => {
    await validate.async({ teamName: workspaceName }, teamNameConstraints);

    setBusyState('creating');
    await createTeam(workspaceName);
    mounted.current && setBusyState(null);
  }, [workspaceName])

  const verifyInviteLink = useCallback(async (token?: string) => {
    setBusyState(token ? 'checking' : 'verifying');
    const result = await verifyInvite(token ?? workspaceLink);
    if (mounted.current) {
      setBusyState(null);
      if (!result.valid) {
        setLinkError(true);
      } else {
        setInviteData(result)
      }
      setWorkspaceLink('')
    }
  }, [workspaceLink])

  const _acceptInvite = useCallback(async () => {
    if (inviteData) {
      setBusyState('accepting');
      await acceptInvite(inviteData.inviteToken)
      mounted.current && setBusyState(null);
    }
  }, [inviteData])

  if (busyState === 'checking') {
    return (
      <PanelLoading text={"Please hold on for a few seconds whilst we check your invite"} />
    )
  } else if (busyState === 'accepting') {
    return (
      <PanelLoading text={`Joining workspace ${inviteData!.teamName}...`} />
    )
  } else if (inviteData) {
    return (
      <Column spacing={24}>
        {inviteData.imageUrl ? <DSImage url={inviteData.imageUrl} height={100} style={{ objectFit: 'contain' }} /> : null}
        <Title>
          Join {inviteData.teamName}?
        </Title>
        <Body>
          {inviteData.inviterName} has invited you to join {inviteData.teamName}.
        </Body>
        <Row style={{ justifyContent: 'space-between' }}>
          <Button disabled={busyState !== null} color="error" onClick={() => setInviteData(undefined)}>Cancel</Button>
          <Button disabled={!!busyState} onClick={_acceptInvite}>Accept</Button>
        </Row>
      </Column>
    )
  } else {

    return (
      <Column spacing={24}>
        <Title>
          Join your first workspace!
        </Title >
        <Column spacing={12}>
          <H2>Join an existing workspace</H2>
          <Body>
            Paste your invite link here. Ask your workspace admin for an invite if you don't have one yet.
          </Body>
          <Input placeholder="Paste link here" value={workspaceLink} onChange={(e) => { setWorkspaceLink(e.target.value); setLinkError(false) }} />

          <Row style={{ justifyContent: 'space-between' }}>
            <DSPrint color={theme.palette.error.main}>
              {linkError ? "Oops that link didn't work" : null}
            </DSPrint>
            <Button
              disabled={!workspaceLink || busyState !== null}
              onClick={() => verifyInviteLink()}
              startIcon={busyState === 'verifying' ? <CircularProgress size={14} color="secondary" /> : undefined}
            >
              {busyState === 'verifying' ? 'Verifying Link' : 'Join workspace'}
            </Button>
          </Row>
        </Column>
        <Row spacing={25}>
          <Separator />
          <Print>Or</Print>
          <Separator />
        </Row>
        <Column spacing={12}>
          <H2>Create a new workspace</H2>
          <Input placeholder="Enter workspace name" value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)} />
        </Column>
        <Row style={{ justifyContent: 'end' }}>
          <Button
            disabled={busyState !== null}
            onClick={createWorkspace}
            startIcon={busyState === 'creating' ? <CircularProgress size={14} color="secondary" /> : undefined}
          >
            Create workspace
          </Button>
        </Row>
      </Column >
    )
  }

}

export default TeamSetup;
