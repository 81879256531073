import { Logger } from "@openteam/app-util";
const logger = new Logger('setAlpha');

export default function (colour: string, newAlpha: number | string) {
  let match = colour.match(/rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*(\d+)\s*)?\)/);
  if (match) {
    const [r, g, b] = match.slice(1, 4);
    //logger.debug(`${colour} => rgba(${r},${g},${b},${newAlpha})`);
    return `rgba(${r},${g},${b},${newAlpha})`;
  }

  match = colour.match(/#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/);
  if (match) {
    const [r, g, b] = match.slice(1, 4);
    return `rgba(${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(b, 16)},${newAlpha})`;
  }

  return colour;
}
