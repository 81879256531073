import { Logger } from "@openteam/app-util";
import { Database, ref, onValue, DataSnapshot, Query, DatabaseReference } from "firebase/database";

const logger = new Logger("FireWatch");

export const _watches: Record<string, () => void> = {};

export const watch = (
  fbDb: Database,
  path: string,
  callback: (doc: any) => void,
  onCancel?: (error: Error) => void
) => {
  return watchQuery(ref(fbDb, path), callback, onCancel);
};

export const unWatch = (
  fbDb: Database,
  path: string
) => {
  unWatchQuery(ref(fbDb, path));
};

export const watchQuery = (
  qry: Query | DatabaseReference,
  callback: (doc: any) => void,
  onCancel?: (error: Error) => void
) => {
  const path = qry.toString();
  if (_watches[path]) {
    logger.error(`watch already exists: ${path}`);
  } else {
    _watches[path] = onValue(qry, (snapshot) => callback(snapshot.val()) , onCancel);
    //logger.info(`Watching ${path}`, qry)
  }
  return _watches[path];
};

export const unWatchQuery = (qry: Query | DatabaseReference) => {
  const path = qry.toString();
  //logger.info(`UnWatching ${path}`, qry)
  if (_watches[path]) {
    try {
      _watches[path]();
    } catch (err) {
      logger.error(`Error calling watch disploser for ${path}, ${err}`)
    }
  } else {
    logger.error(`watch doesn't exist: ${path}`);
  }
  delete _watches[path];
};
