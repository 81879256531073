
import { makeObservable, observable } from "mobx";
import { IFile, IMessageFile } from "@openteam/models";
import * as uuid from "uuid";

export class WebFile implements IMessageFile {
  id: string;
  file: IFile;

  @observable progress = 100;
  @observable completed = true;
  @observable error?: string;
  @observable downloadUrl?: string;

  constructor(file: IFile) {
    makeObservable(this)

    this.id = uuid.v1();

    this.file = file;
    this.downloadUrl = file.url
  }

  getPreviewURL = () => {
    return this.file.url
  }

  complete = async () => {};

  stop = () => {};
}
