import React, { CSSProperties, MouseEventHandler, ReactNode, useImperativeHandle, useMemo } from 'react';
import { IUIUserMinimal } from '@openteam/models';
import { observer } from 'mobx-react';
import { Logger } from '@openteam/app-util';
import StatusDot from './StatusDot';
import { getStringColor } from '../../Util/StringColor';
import { DSButton, IDSButtonProps } from '../../DesignSystem/DSButton';
import { DSH1, DSText } from '../../DesignSystem/DSText';
import { getInitials } from '../../Util/UserInitials';
import { DSImage } from '../../DesignSystem/DSImage';
import { animated } from '@react-spring/web';
import { IDragWrapper } from '../DragAndDrop';
import useBoop, { IBoopStyle } from '../../Util/useBoop';
import { CirclePosition } from '../../Util/CirclePosition';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { DSBadgeView } from '../../DesignSystem/DSBadge';

const logger = new Logger("UserIcon")

interface IUserIconProps extends React.HTMLAttributes<HTMLDivElement> {
  user: IUIUserMinimal
  size?: number,
  dotSize?: number,
  style?: any
  outerStyle?: any
  onClick?: () => void
  onContextMenu?: MouseEventHandler<HTMLDivElement>
  badgeNum?: number
  badgeOverride?: string
  hideBadge?: boolean
  hideStatusDot?: boolean
  statusIcon?: JSX.Element
  showCustomEmoji?: boolean
  pinnedIcon?: JSX.Element
  userOutlineColor?: string
  dragWrapper?: IDragWrapper
  stopPropagation?: IDSButtonProps["stopPropagation"]
  boopStyle?: IBoopStyle | undefined
  showTooltip?: boolean
}

export const UserIcon = observer(
  React.forwardRef((props: IUserIconProps, ref) => {

    const backgroundColor = useMemo(() => getStringColor(props.user.name), [props.user.name])

    const initials = useMemo(() => getInitials(props.user?.name), [props.user?.name])

    const userOffline = props.user.status ? !props.user.status.online : false

    const statusIcon = props.statusIcon ?? (!props.hideStatusDot && !userOffline && (
      <StatusDot
        user={props.user}
        size={props.dotSize}
        style={{ borderColor: props.userOutlineColor ?? DSTheme.BackgroundColor }}
      />
    ))

    const renderUserImage = () => {
      const childProps = {
        backgroundColor,
        initials,
        style: props.style,
        user: props.user,
        size: props.size,
        disable: userOffline
      };

      return props.boopStyle ?
        <BoopUserImage ref={ref} boopStyle={props.boopStyle} {...childProps} /> :
        <UserImage {...childProps} />;
    }

    return (
      <DSButton
        key={props.user.id}
        data-tooltip={props.showTooltip === true ? (props['data-tooltip'] ?? props.user.name) : undefined}

        onClick={props.onClick}
        analyticsEvent="UserIconClick"
        analyticsArgs={{ userId: props.user.id }}
        onContextMenu={(e) => { e.preventDefault(); props.onContextMenu && props.onContextMenu(e); }}
        style={{
          borderRadius: '50%',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          width: props.size || "100%",
          height: props.size || "100%",
          backgroundColor: props.user.imageUrl ? undefined : backgroundColor,
          aspectRatio: "1",
          ...props.outerStyle,
        }}
        stopPropagation={props.stopPropagation}
      >

        {props.dragWrapper !== undefined ? (
          props.dragWrapper(renderUserImage())
        ) : (
          renderUserImage()
        )}

        {statusIcon && <CirclePosition positionDegrees={45} offsetFactor={1.05}>{statusIcon}</CirclePosition>}
        {props.showCustomEmoji && props.user?.status?.customStatusEmoji && <CirclePosition positionDegrees={135} offsetFactor={1.05} >
          {
            <DSText style={{ margin: 2, fontSize: props.dotSize ? props.dotSize + 12 : 12 }}>
              {props.user.status.customStatusEmoji}
            </DSText>
          }
        </CirclePosition>}
        <DSBadgeView badgeNum={props.badgeNum} badgeOverride={props.badgeOverride} show={!props.hideBadge} />

        {props.children}
      </DSButton>
    )
  })
);

interface IBoopUserImageProps extends IUserImageProps {
  boopStyle: IBoopStyle;
}

const BoopUserImage = React.forwardRef(
  ({ boopStyle, ...props }: IBoopUserImageProps, ref) => {

    const [springStyle, trigger] = useBoop(boopStyle);

    useImperativeHandle(ref, () => ({ trigger }));

    return (
      <animated.div
        style={{
          display: "flex",
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          width: props.size || "100%",
          height: props.size || "100%",
          ...springStyle
        }}>
        <UserImage {...props} />
      </animated.div>
    );
  }
);

interface IUserImageProps {
  user: IUIUserMinimal;
  backgroundColor: string;
  size?: number;
  style?: CSSProperties;
  initials: string;
  disable?: boolean
}

const UserImage: React.FC<IUserImageProps> = ({ user, backgroundColor, size, style, initials, disable }) => (
  <div
    style={{
      display: "flex",
      position: 'relative',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      width: size || "100%",
      height: size || "100%",
      aspectRatio: "1",
      backgroundColor: user.imageUrl ? undefined : backgroundColor,
      ...disable && {
        filter: DSTheme.UserInactiveFilter
      }
    }}
  >
    {user.imageUrl ? (
      <DSImage
        url={user.imageUrl}
        width={size || "100%"}
        height={size || "100%"}
        onError={() => {
          logger.error("onError userIcon failed to load image", user.id)
        }}
        onAbort={() => {
          logger.error("onAbort userIcon failed to load image", user.id)
        }}
        onEmptied={() => {
          logger.error("onEmptied userIcon failed to load image", user.id)
        }}
        onStalled={() => {
          logger.error("onStalled userIcon failed to load image", user.id)
        }}
        onSuspend={() => {
          logger.error("onSuspend userIcon failed to load image", user.id)
        }}
        style={{
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          margin: 0,
          aspectRatio: "1",
          backgroundColor: backgroundColor,
          objectFit: 'cover',
          overflow: 'hidden',
          ...style,
        }}
      />
    ) : <DSH1 strong style={{ fontSize: size ? size / 2 : '18px' }}>{initials}</DSH1>
    }
  </div>
);

export default UserIcon