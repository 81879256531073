import { getFunctions, httpsCallable } from "firebase/functions";

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  RouteProps,
  useHistory,
  useLocation
} from "react-router-dom";
import { LoginState } from "../Controllers/UIState";
import { FaEnvelope, FaGoogle } from "react-icons/fa";
import { EmailField } from './EmailField';
import { PanelError, PanelLoading } from './Panels';
import * as Analytics from "../Util/Analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, useDeviceLanguage } from "firebase/auth";
import { Body, Button, Column, H1, H3, Panel, Row, Spinner, StrongPrint, useTheme } from "@openteam/design-system";
import Page from "../Util/Page";
import { Logger } from "@openteam/app-util";
const logger = new Logger('Login')

interface pageProps extends RouteProps {
  mode?: 'signup' | 'login'
}

export const LoginPage = observer((props: pageProps) => {
  const { mode } = props;

  const theme = useTheme()
  const [state, setState] = useState<"login" | "enterEmail" | "authEmailSent">("login");
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const history = useHistory();
  const location = useLocation();

  const redirectTo = (location.state as any)?.from
  const passthroughURL = redirectTo ? `${redirectTo.pathname}${redirectTo.search}` : undefined

  const modeText = mode == 'signup' ? 'Sign up' : "Log in"

  const reset = () => {
    setState("login");
    setEmail("");
    setEmailValid(false);
    setEmailSubmitted(false);
  }

  const signInWithGooglePopup = () => {
    // https://firebase.google.com/docs/auth/web/google-signin#web-version-8_4
    const auth = getAuth()
    const provider = new GoogleAuthProvider();

    useDeviceLanguage(auth);
    signInWithPopup(auth, provider).then((result) => {

      if (result.user) {
        console.debug("User signed in", result.user)
        console.log("passthroughURL: ", passthroughURL);
        history.push(passthroughURL || "/applogin");
      }
    }).catch((error) => {
      const errorCode = error.code;
      console.log("error: ", errorCode);
      if (errorCode === 'auth/popup-blocked') {
        Analytics.logError(errorCode);
        // I've not seen this error, but seems sensible to put something in for it.
        setError("The popup window was blocked by the browser, please disable any popup blockers and try again.");

      } else if (errorCode === 'auth/popup-closed-by-user') {
        console.log("ignoring: auth/popup-closed-by-user");

      } else {
        Analytics.logError(errorCode);
        setError("There was an error signing in, please try again.");
      }
    });
  }

  const signInWithEmailLink = () => {
    window.localStorage.setItem('emailForSignIn', email);
    setEmailSubmitted(true);

    const continuePath = passthroughURL ? passthroughURL : '/appLogin';

    //const url = new URL(`${window.origin}${}`)
    const { protocol, hostname, port } = window.location;
    const continueUrl = `${protocol}//${hostname}${port ? ":" + port : ""}${continuePath}`;
    console.log("continueUrl: ", continueUrl);
    const functions = getFunctions();

    httpsCallable(functions, 'auth_sendSignInEmailLink')({
      email,
      continueUrl,
    }).then(
      (result) => {
        console.log("result", result);
        Analytics.logEvent("authEmailSent", { email });
        setState("authEmailSent");

      }
    ).catch((error) => {
      console.log("We have an error", error.message, error.code, JSON.stringify(error));
      setEmailSubmitted(false);
    });
  }

  useEffect(() => {
    Analytics.setCurrentScreen("LoginPage");
  }, []);

  useEffect(() => {
    logger.info(`passthroughUrl: ${passthroughURL}`)
  }, [passthroughURL])

  return (
    <Page>
      {state !== "authEmailSent" && LoginState.isSignedIn && !LoginState.isAnonymous ? (
        passthroughURL ?
          <Redirect
            to={passthroughURL}
          />
          :
          <Redirect
            to={"/applogin"}
          />
      ) : !!error ? (
        <PanelError>
          <Body style={{ marginBottom: 24, textAlign: "center" }}>
            {error}
          </Body>
        </PanelError>
      ) : (
        <Panel style={{ alignItems: 'center' }}>

          {state === 'enterEmail' ? (

            <Column style={{ width: 300, alignItems: 'center' }} spacing={32}>

              <H1 wrap style={{ fontSize: 32, textAlign: "center" }}>
                {modeText} with email
              </H1>

              <Row style={{ minHeight: 72, width: '80%', justifyContent: 'center' }}>

                {
                  emailSubmitted ?
                    <Spinner size={40} />
                    :
                    <EmailField
                      onChange={(e) => setEmail(e.target.value)}
                      onEnter={() => signInWithEmailLink()}
                      onChangeValid={(valid) => setEmailValid(valid)}
                    />
                }
              </Row>


              <Row style={{ justifyContent: "flex-end", width: "100%" }} spacing={8}>
                <Button
                  onClick={() => setState("login")}
                  analyticsEvent="Login"
                  color="secondary"
                  disabled={emailSubmitted}
                >
                  Back
                </Button>
                <Button
                  onClick={signInWithEmailLink}
                  analyticsEvent="SignInWithEmailLink"
                  disabled={emailSubmitted || !emailValid}
                >
                  Next
                </Button>
              </Row>

            </Column>
          ) : state === 'authEmailSent' ? (
            <Column
              style={{ backgroundColor: "white", borderRadius: 8, width: 300 }}>
              <Row style={{ justifyContent: "center" }}>
                <H1 wrap style={{ color: "black", marginBottom: 24, fontWeight: 700, fontSize: 32, textAlign: "center" }}>
                  {modeText} with email
                </H1>
              </Row>
              <H3 wrap style={{ textAlign: "center", margin: 20, color: "black" }}>An email with additional instructions was sent to:</H3>
              <H3 style={{ textAlign: "center", color: "black" }}>{email}</H3>
              <H3 wrap style={{ textAlign: "center", margin: 20, color: "black" }}>Check your email to complete your sign in.  Make sure to check your spam.</H3>

              <Row style={{ justifyContent: "center", flexGrow: 1, width: "100%" }}>
                <Button
                  onClick={() => window.close()}
                  analyticsEvent="CloseWindow"
                  style={{
                    marginBottom: 16,
                    marginRight: 10,
                  }}
                  size="medium"
                  color="secondary"
                >
                  Close Window
                </Button>
              </Row>
            </Column>
          ) : (
            <Column spacing={32} style={{ alignItems: 'center' }}>
              <H1 wrap style={{ fontSize: 32, textAlign: "center" }}>
                {modeText} to <span style={{ color: theme.palette.primary.main }}>ehlo</span>
              </H1>
              <Button
                onClick={signInWithGooglePopup}
                analyticsEvent="SignInWithGoogle"
                startIcon={<FaGoogle />}
                size="medium"
                color="secondary"
              >
                {modeText} with Google
              </Button>


              <Button
                onClick={() => setState("enterEmail")}
                analyticsEvent="SignInWithEmail"
                startIcon={<FaEnvelope />}
                size="medium"
                color="secondary"
              >
                {modeText} with Email
              </Button>

              {
                mode == 'signup' ?

                  <Button variant="text" color="secondary" onClick={() => { history.push('login'); }} >
                    <Body style={{ fontWeight: 400 }}>If you already have an account, <H3>Log in</H3></Body>
                  </Button>
                  :
                  <Button variant="text" color="secondary" onClick={() => { history.push('signup'); }} >
                    <Body style={{ fontWeight: 400 }}>Don’t have an account? <H3>Sign up</H3></Body>
                  </Button>
              }

            </Column>
          )}
          <Body style={{ marginTop: 60, textAlign: 'center' }}>
            By continuing, you agree to ehlo's <a href="https://ehlo.space/terms-conditions" target="_blank">Terms and Conditions</a> and <a href="https://ehlo.space/privacy-policy" target="_blank">Privacy Policy</a>
          </Body>
        </Panel>
      )}
    </Page >
  );
});
