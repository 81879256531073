import { ButtonProps } from "@mui/material";
import react, { MouseEventHandler, useCallback, useMemo } from "react";
import { logEvent } from "./Analytics";

export interface ClickableProps extends ButtonProps {
  onClick?: MouseEventHandler<HTMLElement>
  onPointerDown?: MouseEventHandler<HTMLElement>
  analyticsEvent?: string
  analyticsArgs?: Object
  interaction?: string
  stopPropagation?: StopPropagation
}

type StopPropagation = {
  onClick?: boolean
  onPointerDown?: boolean
}

const useClickable = ({
  onClick: _onClick,
  onPointerDown: _onPointerDown,
  analyticsEvent,
  analyticsArgs,
  stopPropagation: _stopPropagation,
  interaction='button',
  ...otherProps
}: ClickableProps) => {

  const stopPropagation: StopPropagation = useMemo(() => (
    {
      onClick: true,
      onPointerDown: true,
      ..._stopPropagation
    }
  ), [_stopPropagation?.onClick, _stopPropagation?.onPointerDown]);

  const onClick = useCallback((ev: any) => {
    if (stopPropagation.onClick) {
      ev.stopPropagation();
    }

    _onClick && _onClick(ev)

    const prefix = interaction[0].toUpperCase() + interaction.substring(1);

    analyticsEvent && logEvent(
      `${prefix}_${analyticsEvent}`,
      { interaction, ...analyticsArgs }
    )
  }, [_onClick, analyticsEvent, analyticsArgs, stopPropagation])

  const onPointerDown = useCallback((ev: any) => {
    if (stopPropagation.onPointerDown) {
      ev.stopPropagation();
    }

    _onPointerDown && _onPointerDown(ev)

  }, [_onPointerDown, stopPropagation])

  return {onClick, onPointerDown, otherProps}
}

export default useClickable;
