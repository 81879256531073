import { action, observable } from "mobx";

import {Logger } from "@openteam/app-util";
import { IDataState } from '@openteam/models';
import { UIDataState } from "@openteam/app-core";

const logger = new Logger("DataState");


export let DataState: IDataState = UIDataState
