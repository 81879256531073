import { ITeamDoc } from "@openteam/models";
import { OTAppCoreData } from "../OTAppCoreData";
import { OTUserInterface } from "../OTUserInterface";
import { Database, get, ref, remove, serverTimestamp, update } from "firebase/database";

import { Logger } from "@openteam/app-util";
import { unWatch, watch } from "./FireWatch";

const logger = new Logger("AppHomeManagerDb");

export class AppHomeManagerDb {
  static watchTeamList = (
    fbDb: Database,
    userId: string,
    callback: (doc: { [teamIds: string]: boolean }) => void
  ) => {
    watch(fbDb, `/userteams/${userId}`, callback);
  };

  static unwatchTeamList = (fbDb: Database, userId: string) => {
    unWatch(fbDb, `/userteams/${userId}`);
  };

  static watchTeam = (
    fbDb: Database,
    teamId: string,
    callback: (teamId: string, doc: ITeamDoc) => void,
    onError: (err) => void
  ) => {
    watch(fbDb, `/teams/${teamId}`, (doc) => callback(teamId, doc), onError)
  };

  static unwatchTeam = (fbDb: Database, teamId: string) => {
    unWatch(fbDb, `/teams/${teamId}`);
  };

  static registerDeviceUser = (
    fbDb: Database,
    userId: string,
    sessionToken: string
  ) => {
    var deviceRef = ref(fbDb, `/users/${userId}/device/${OTAppCoreData.deviceId}`)

    // const device = {
    //   manufacturer: Device.manufacturer,
    //   modelName: Device.modelName,
    //   deviceName: Device.deviceName,
    //   osName: Device.osName,
    //   osVersion: Device.osVersion,
    // };
    update(deviceRef, {
      last_changed: serverTimestamp(),
      appVersion: OTAppCoreData.version,
      sessionToken: sessionToken,
      platform: OTUserInterface.platformUtils.PlatformOS,
      os: OTUserInterface.platformUtils.Platform.OS,
      // device: device,
    });
  };

  static unregisterDeviceUser = async (fbDb: Database, userId: string) => {
    await remove(ref(fbDb, `/users/${userId}/device/${OTAppCoreData.deviceId}`));
  };

  static getTeam = async (fbDb: Database, teamId: string): Promise<ITeamDoc> => {
    const snapshot = await get(ref(fbDb, `/teams/${teamId}`));

    return snapshot.val();
  };
}
