import { FireUtils } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { Panel, VSpacer, H2, Row, Button, Icons, IconActionButton, Spinner } from "@openteam/design-system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import Input from "../Components/Input";
import Title from "../Components/Title";
import { SelfieCam } from "../Components/User/Selfie";
import { getCurrentUser, setupUser } from "../Controllers/Login";
import { DSImage } from "../DesignSystem/DSImage";
import * as Analytics from "../Util/Analytics";

const logger = new Logger('AccountSetup')

const AccountSetup: React.FC = (props) => {
  const authUser = getCurrentUser()
  const [name, setName] = useState(authUser.name ?? '')
  const [imageUrl, setImageUrl] = useState(authUser.imageUrl)
  const [showSelfieCam, setShowSelfieCam] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    Analytics.setCurrentScreen("AccountSetupPage");
  }, [])

  const onFiles = useCallback(async (e) => {
    const files = e.target.files;
    const file = files[0];

    if (!file) {
      return;
    }
    logger.debug("uploading new profile image", file)

    setUploading(true)

    const url = await FireUtils.uploadUserFile(
      null,
      authUser.userId,
      "profile",
      file,
    )
    setUploading(false);
    setImageUrl(url);
    setShowSelfieCam(false);
  }, [setUploading])

  const onPhoto = useCallback(async (image?: Blob) => {
    if (image) {
      const url = await FireUtils.uploadUserBlob(
        null,
        authUser.userId,
        "profile",
        null,
        image,
      )
      setUploading(false);
      setImageUrl(url);
    }
    setShowSelfieCam(false);
  }, [])

  const setupAccount = useCallback(() => {
    if (name) {
      setupUser({ name, imageUrl })
    }
  }, [name, imageUrl])

  return (
    <Panel>
      <Title>
        Welcome to ehlo,<br />let's get started!
      </Title>
      <VSpacer size={34} />
      <H2>Your name</H2>
      <VSpacer size={12} />
      <Input value={name ?? ''} placeholder={'Enter your name'} onChange={(ev) => setName(ev.target.value)} />

      <Row style={{ marginTop: 27, marginBottom: 6, alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <H2>Upload photo</H2>
        <Row spacing={4}>

          {
            showSelfieCam ? (
              <Button
                startIcon={Icons.reject}
                color='error'
                onClick={() => setShowSelfieCam(false)}
                title="Cancel"
              />
            ) : (
              <IconActionButton
                data-tooltip="Take a selfie"
                analyticsEvent="OnboardingOpenSelfieCam"
                onClick={() => {
                  setShowSelfieCam(true)
                }}>
                {Icons.camera}
              </IconActionButton>
            )
          }
          <input
            ref={fileInput}
            style={{ display: 'none' }}
            type="file"
            onChange={onFiles}
            accept="image/x-png,image/gif,image/jpeg" />

          {
            !showSelfieCam && (
              uploading ?
                <Spinner size={20} />
                :
                <IconActionButton
                  data-tooltip={"Upload a new profile image"}
                  disabled={showSelfieCam}
                  color='secondary'
                  analyticsEvent="OnboadingOpenFilePicker"
                  onClick={() => {
                    fileInput.current?.click();
                  }}>
                  {Icons.upload}
                </IconActionButton>
            )
          }
        </Row>
      </Row>
      <VSpacer size={12} />
      <Row style={{ justifyContent: 'center' }}>

        {
          showSelfieCam ? (
            <SelfieCam
              size={170}
              onClose={onPhoto}
            />
          ) : imageUrl ? (
            <DSImage
              url={imageUrl}
              width={170}
              height={170}
              style={{ borderRadius: 999, objectFit: 'cover' }} />
          ) : <FaUserCircle size={170} color={'rgba(221, 220, 220, 1)'} />
        }
      </Row>
      <VSpacer size={12} />
      <Row style={{ justifyContent: 'flex-end' }} >
        <Button
          disabled={!name || showSelfieCam}
          analyticsEvent="OnboardingPage1Next"
          onClick={setupAccount}
        >
          Next
        </Button>
      </Row>

    </Panel>
  )
}

export default AccountSetup;
