import { DSTheme } from "../DesignSystem/DSTheme";

export function getStringColor (text) {
  let sumChars = 0;

  if (text) {
    for (let i = 0; i < text.length; i++) {
      sumChars += text.charCodeAt(i);
    }
  }

  const colors = DSTheme.PodColors;

  return colors[sumChars % colors.length];
}


export function getStringBackground(text: string, onLeftSide: boolean=true) {
  let sumChars = 0;

  if (text) {
    for (let i = 0; i < text.length; i++) {
      sumChars += text.charCodeAt(i);
    }
  }

  const colors = DSTheme.PodBackgrounds;

  return colors[sumChars % colors.length][onLeftSide ? 0 : 1];
}