import { H1, H3, Panel, Row, Button, useTheme, Column } from "@openteam/design-system"
import { LINKPROTOCOL } from "../config"
import DownloadApp from "./DownloadApp"
import Logo from '../assets/logoo.svg?component';
import { useEffect, useState } from "react";
import { getCustomLoginToken } from "../Controllers/Login";
import * as Analytics from "../Util/Analytics";
import { PanelLoading } from "./Panels";

const OpenApp: React.FC = () => {
  const [token, setToken] = useState<string>()
  const theme = useTheme()

  useEffect(() => {
    Analytics.setCurrentScreen("OpenAppPage");
    loadToken()
  }, [])

  const loadToken = async () => {
    setToken(await getCustomLoginToken())
  }

  useEffect(() => {
    if (token) {
      redirectToApp()
    }
  }, [token])

  const redirectToApp = async () => {
    var protocolURL = `${LINKPROTOCOL}:login`

    protocolURL = protocolURL + `?token=${token}`

    console.info("protocolURL", protocolURL)
    window.location.href = protocolURL
  }

  return token ? (
    <Column style={{ alignItems: 'center' }} spacing={24}>
      <H1 style={{  fontSize: 32, textAlign: "center" }}>
        Opening <span style={{ color: theme.palette.primary.main }}>ehlo</span> app...
      </H1>

      <Button
        onClick={redirectToApp}
        analyticsEvent="OpenApp"
        startIcon={<Logo style={{ height: 16, width: 16 }} />}
        color='secondary'
        size="medium"
      >
        Open ehlo app
      </Button>

      <hr style={{ borderWidth: 0, borderTop: "2px solid #000", width: 150 }} />

      <DownloadApp />
    </Column>
  ) : (
    <PanelLoading text={"This may take a few seconds whilst we get things ready for you..."} />
  )
}

export default OpenApp;
