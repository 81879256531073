import React from 'react';
import { DSInput, IDSInputProps} from "../DesignSystem/DSInput"

const Input: React.FC<IDSInputProps> = ({ style, ...props }) => {
  return (
    <DSInput
      style={{
        border: '0.5px solid rgba(180, 180, 180, 1)',
        padding: 6,
        //fontSize: 14,
        ...style
      }}
      {...props} />
  )
}

export default Input;
