import { Database, endAt, equalTo, get, limitToLast, orderByChild, orderByKey, push, query, ref, remove, serverTimestamp, set, update } from "firebase/database";

import { ICallFeedback, IFeedback, IFeedbackMessage } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { unWatch, unWatchQuery, watch, watchQuery } from "./FireWatch";

const logger = new Logger("Fire");
export class FeedbackDb {
  static watchMyFeedback = (
    fbDb: Database,
    userId: string,
    callback: (doc: { [id: string]: IFeedback }) => void
  ) => {
    const qry = query(ref(fbDb, `/feedback/`), orderByChild(`followers/${userId}/following`), equalTo(true))
    watchQuery(qry, callback)

  };
  
  static unwatchMyFeedback = (
    fbDb: Database,
    userId: string,
  ) => {
    const qry = query(ref(fbDb, `/feedback/`), orderByChild(`followers/${userId}/following`), equalTo(true))
    unWatchQuery(qry)
  };

  static getMoreFeedback = async (fbDb: Database, lastId, pageSize) => {
    let queryOptions = [orderByKey()];

    if (lastId) {
      queryOptions.push(endAt(lastId));
    }
    queryOptions.push(limitToLast(pageSize));

    const doc = await get(query(ref(fbDb, `/feedback/`), ...queryOptions))

    logger.info("getMoreFeedback ", pageSize, doc.val());
    return doc.val();
  };

  static getFeedback = async (fbDb: Database, feedbackId) => {
    var doc = await get(ref(fbDb,`/feedback/${feedbackId}`))
    var feedbackDoc = doc.val();

    return feedbackDoc;
  };

  static markReadFeedback = async (
    fbDb: Database,
    userId: string,
    feedbackId: string,
    lastRead: string
  ) => {
   set(ref(fbDb, `/feedback/${feedbackId}/followers/${userId}/lastRead`), lastRead);
  };

  static watchFeedbackLastId = async (
    fbDb: Database,
    callback: (doc) => void
  ) => {
    watch(fbDb, `/global/feedbackLastId`, callback);
  };

  static unwatchFeedbackLastId = async (fbDb: Database) => {
    unWatch(fbDb, `/global/feedbackLastId`);
  };

  static markFeedbackLastId = async (
    fbDb: Database,
    userId: string,
    feedbackId
  ) => {
    set(ref(fbDb, `/users/${userId}/feedbackLastId`), feedbackId);
  };

  static watchFeedback = async (
    fbDb: Database,
    feedbackId: string,
    callback: (doc: IFeedback) => void
  ) => {
    watch(fbDb, `/feedback/${feedbackId}`, callback)
  };

  static unwatchFeedback = async (fbDb: Database, feedbackId) => {
    unWatch(fbDb, `/feedback/${feedbackId}`);
  };

  static addFeedback = async (
    fbDb: Database,
    userId: string,
    username: string,
    imageUrl: string | null,
    title,
    desc,
    type,
    files
  ) => {
    var feedbackId = push(ref(fbDb,`/feedback/`)).key!;

    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    var msg: IFeedback = {
      feedbackId: feedbackId,
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      crDate: serverTimestamp(),
      followers: {
        [userId]: {
          following: true,
        },
      },
      votes: {
        [userId]: true,
      },
      title: title,
      desc: desc,
      type: type,
      status: "active",
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    set(ref(fbDb,`/feedback/${feedbackId}`),msg);

    FeedbackDb.markFeedbackLastId(fbDb, userId, feedbackId);

    return feedbackId;
  };

  static updateFeedback = async (
    fbDb: Database,
    userId: string,
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    title,
    desc,
    type,
    status,
    files
  ) => {
    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    var msg: IFeedback = {
      feedbackId: feedbackId,
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      crDate: serverTimestamp(),
      title: title,
      desc: desc,
      type: type,
      status: status,
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    update(ref(fbDb, `/feedback/${feedbackId}`), msg);
  };

  static voteFeedback = async (
    fbDb: Database,
    userId: string,
    feedbackId: string,
    voted: boolean
  ) => {
    set(ref(fbDb, `/feedback/${feedbackId}/votes/${userId}`), voted || null);
    FeedbackDb.followFeedback(fbDb, userId, feedbackId, true);
  };

  static followFeedback = async (
    fbDb: Database,
    userId: string,
    feedbackId: string,
    follow: boolean
  ) => {
    var followDoc: any = null;
    if (follow) {
      followDoc = {
        following: true,
      };
    }
    set(ref(fbDb, `/feedback/${feedbackId}/followers/${userId}`), followDoc);
  };

  static commentFeedback = async (
    fbDb: Database,
    userId: string,
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    message,
    files,
    setFollow
  ) => {
    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    const messageId = push(ref(fbDb, `/feedback/${feedbackId}/comments`)).key!;

    const msg: IFeedbackMessage = {
      crDate: serverTimestamp(),
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      message: message,
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    set(ref(fbDb, `/feedback/${feedbackId}/comments/${messageId}`), msg);

    if (setFollow) {
      FeedbackDb.followFeedback(fbDb, userId, feedbackId, true);
    }
  };

  static archiveFeedback = async (fbDb: Database, feedbackId: string) => {
    var doc = await get(ref(fbDb, `/feedback/${feedbackId}`))
    var feedbackDoc = doc.val();
    set(ref(fbDb, `/feedbackarchive/${feedbackId}`), feedbackDoc);
    remove(doc.ref);
  };

  static addCallFeedback = async (fbDb: Database, teamId: string, userId: string, roomId: string, startTime, endTime, rating, comment) => {

    const msg: ICallFeedback = {
      crDate: Date.now(),
      teamId: teamId,
      userId: userId,
      roomId: roomId,
      startTime: startTime,
      endTime: endTime,
      rating: rating,
      comment: comment,
    };

    await push(ref(fbDb, `/callfeedback/`), msg);
  }

}
