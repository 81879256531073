import { observer } from "mobx-react";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { IOTChatMessage, IPendingMessage, IPinnedResource, KSpaceId, ILinkPreview, IFile, ICallDetails, IUIUserMinimal } from '@openteam/models';
import { DataState } from "../../Data/DataState";

import { DSColumn, DSPanel, DSPanelScrollable, DSRow } from "../../DesignSystem/DSLayout"
import { ChatMessage } from "./ChatMessage";
import { Logger } from "@openteam/app-util";
import { ChatFileUpload } from "./ChatFileUpload";
import { DSH3, DSH5 } from "../../DesignSystem/DSText";
import { ChatPendingMessage } from "./ChatPendingMessage";
import { FaChevronDown } from "react-icons/fa";
import { DSCircleIconButton } from "../../DesignSystem/DSButton";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { ChatCallSummary } from "./ChatCallSummary";

const logger = new Logger("ChatMessages");

type Props = {
  spaceId: KSpaceId
  messages: Record<string, IOTChatMessage>
  atStart?: boolean
  pendingMessages: Record<string, IPendingMessage>
  callSummaries?: Record<string, ICallDetails>

  header?: JSX.Element
  messageId?: number
  lastReadMessageId?: number
  messageNum?: number
  messageNumRead?: number
  numUnread?: number
  highlightMessageId?: number
  isWatching?: boolean
  readOnly?: boolean

  style?: React.CSSProperties,

  loadCallSummary?: (callId: string) => void
  loadMoreMessages?: (direction: "forwards" | "backwards") => void
  restart?: () => void
  deleteMessage?: (messageId) => void
  editMessage?: (messageId, messageDraft) => void
  setReply?: (message?: IOTChatMessage) => void
  focusInput?: () => void
  updateResource?: (r: IPinnedResource, isPinned?: boolean, isResource?: boolean, name?: string) => Promise<void>
  saveLinkToChat?: (id: string, linkPreview: ILinkPreview, linkId: string | undefined) => Promise<void>
  saveFileToChat?: (id: string, file: IFile, fileIndex: number) => Promise<void>
  sendURL?: (text: string, systemMessage: string) => void

  loadCallChat?: (callId: string) => void
  getUser?: (userId) => IUIUserMinimal | undefined

}

export const ChatMessages: React.FC<Props> = observer((props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const scrollTo = React.useRef<HTMLDivElement>(null)
  const atEnd = React.useRef(true)

  const space = DataState.spaces[props.spaceId]

  // const chat = space.channels[props.channelId]

  // const messages = space.channels[props.channelId].messages
  // const pendingMessages = space.channels[props.channelId].pendingMessages

  const messageIds = Object.keys(props.messages)

  const handleScroll = (e) => {
    const t = e.target;

    // this is deliberately a + instead of a - as the div is column-reversed"
    const loadBackwards = t.scrollHeight - t.scrollTop <= t.clientHeight * 2;
    const loadForwards = t.scrollTop <= t.clientHeight;

    //logger.debug(`loadBackwards: ${loadBackwards} loadForwards: ${loadForwards}`);
    //    logger.debug(`scrollHeight-scrollTop: ${Math.round(t.scrollHeight - t.scrollTop)} scrollTop: ${Math.round(t.scrollTop)} scrollHeight: ${Math.round(t.scrollHeight)} clientHeight: ${Math.round(t.clientHeight)}`)

    atEnd.current = e.target.scrollTop === 0;

    if (loadBackwards) {
      props.loadMoreMessages?.("backwards")
    }
    if (loadForwards) {
      props.loadMoreMessages?.("forwards")
    }
  }

  useEffect(() => {
    if (atEnd.current && ref.current) {
      ref.current.scrollTo({ top: 0 })
    }
  })

  const _invertedWheelEvent = e => {
    if (ref.current) {
      ref.current.scrollTop -= e.deltaY;
      e.preventDefault();
    }
  };

  useEffect(() => {
    ref.current?.addEventListener("wheel", _invertedWheelEvent);
    return () => {
      ref.current?.removeEventListener("wheel", _invertedWheelEvent);
    }
  })

  useEffect(() => {
    scrollTo.current && scrollTo.current.scrollIntoView();
  }, [props.highlightMessageId]);

  const highlightMessageId = props.highlightMessageId;

  //useLayoutEffect(() => logger.debug(`rendering messages`));

  const renderMessage = useCallback((messageId: string, index: number, item: IOTChatMessage, prevItem?: IOTChatMessage, nextItem?: IOTChatMessage) => {

    if (item.isSystem && item.systemType == 'CALLSUMMARY' && item.systemId) {
      return <DSPanel
        style={{
          position: 'relative',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          transition: "background-color 0.1s ease-in-out",
          flex: 1
        }}>
        <ChatCallSummary
          spaceId={props.spaceId}
          callId={item.systemId}
          callDetails={props.callSummaries?.[item.systemId]}
          loadCallSummary={props.loadCallSummary}
          loadCallChat={props.loadCallChat}
          sendURL={props.sendURL}
        />
      </DSPanel>

    }

    const prevGrouped = prevItem
      && item.userId == prevItem.userId
      && item.userId == prevItem.userId
      && !item.isSystem
      && !prevItem.isSystem
      && ((item.crDate?.getTime() || Date.now()) - (prevItem.crDate?.getTime() || Date.now()) < 60 * 1000);


    const nextGrouped = nextItem
      && item.userId == nextItem.userId
      && !item.isSystem
      && !nextItem.isSystem
      && ((nextItem.crDate?.getTime() || Date.now()) - (item.crDate?.getTime() || Date.now()) < 60 * 1000)

    return <ChatMessage
      ref={item.messageId === highlightMessageId ? scrollTo : undefined}
      key={messageId}
      spaceId={props.spaceId}
      message={item}
      prevGrouped={prevGrouped}
      nextGrouped={nextGrouped}
      isLastRead={item.messageId != undefined
        && props.lastReadMessageId == item.messageId
        && item.messageId < (props.messageId || 0)}
      canEdit={!props.readOnly}

      messageNumRead={props.messageNumRead}

      deleteMessage={props.deleteMessage}
      editMessage={props.editMessage}
      setReply={props.setReply}
      focusInput={props.focusInput}
      updateResource={props.updateResource}
      saveLinkToChat={props.saveLinkToChat}
      saveFileToChat={props.saveFileToChat}
      loadCallChat={props.loadCallChat}
      pendingMessage={props.pendingMessages[item.id]}
      sendURL={props.sendURL}
      getUser={props.getUser}
    />
  }, [props, props.messages])

  //logger.debug(`isWatching: ${space.channels[props.channelId].isWatching}`);

  return (
    <>
      <DSPanelScrollable ref={ref} onScroll={handleScroll} style={{
        flex: 1,
        minHeight: 0,
        flexDirection: 'column',
        transform: "scaleY(-1)",
        minWidth: 0,
        padding: 5,
        // background: "linear-gradient(#23292B 33%, rgba(35,41,43, 0)), linear-gradient(rgba(35,41,43, 0), #23292b 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0, 0.5), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0, 0.5), rgba(0,0,0,0)) 0 100%",
        // backgroundColor: "#23292B",
        // backgroundRepeat: "no-repeat",
        // backgroundAttachment: "local, local, scroll, scroll",
        // backgroundSize: "100% 45px, 100% 45px, 100% 15px, 100% 15px",
        ...props.style
      }}>
        <DSPanel style={{
          maxWidth: "100%",
          transform: "scaleY(-1)",
        }}>
          {props.atStart || (messageIds.length < 20) ? props.header : undefined}

          {messageIds.map((messageId, index) => renderMessage(
            messageId,
            index,
            props.messages[messageId],
            index > 0 ? props.messages[messageIds[index - 1]] : undefined,
            (index + 1) < messageIds.length && props.messages[messageIds[index + 1]] || undefined
          ))}
        </DSPanel>
      </DSPanelScrollable>
      {!props.isWatching &&
        <div className="endButton" style={{ position: "relative" }}>
          <DSCircleIconButton
            outerStyle={{
              position: "absolute",
              bottom: 0,
              left: "calc(50% - 30px)",
              zIndex: 10
            }}
            style={{
              backgroundColor: DSTheme.EmphasisColor,
              color: DSTheme.InputTextColor,
              padding: 6,
            }}
            icon={<FaChevronDown size={14} style={{ paddingRight: 2, color: DSTheme.MainIcon }} />}
            onClick={() => props.restart?.()}
            badgeNum={props.numUnread}
            badgePosition={"TopRight"}
          >
            <DSH3 style={{ color: "white", paddingLeft: 5, paddingRight: 5, whiteSpace: 'nowrap' }} >
              Latest
            </DSH3>
          </DSCircleIconButton>
        </div>
      }
    </>
  )
})
