import { getScreenShareContraints, OTGlobals, setCallWidgetHovered, UIDataState } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { action } from "mobx";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { MdOutlineScreenShare } from "react-icons/md";
import { FaTimesCircle } from "react-icons/fa";
import { DSButton } from "../../../DesignSystem/DSButton";
import { DSPrint } from "../../../DesignSystem/DSText";
import { DSTheme } from "../../../DesignSystem/DSTheme";
import { ButtonProps } from "../CallButtons/callButtonUtil";
import { ICapturerSource } from "@openteam/models";
import { observer } from "mobx-react";
import { DSBadgeView } from "../../../DesignSystem/DSBadge";

const logger = new Logger("ScreenShareButton")

interface ScreenShareButtonProps extends ButtonProps {
  show?: boolean;
}

type ScreenShare = {
  stream: MediaStream;
  source: ICapturerSource;
};
export const ScreenShareButton: React.FC<ScreenShareButtonProps> = observer((props) => {
  const ref = useRef(null)
  const [hovered, setHovered] = useState(false);
  const [tooltip, setTooltip] = useState<string>()

  const { style, size, color, show = true, ...otherProps } = props;


  const call = UIDataState.activeCall;

  const sharing = call?.sharingScreen ?? false;
  const openPlugins = Object.keys(call?.plugins ?? {})

  const onClick = async () => {
    if (call?.sharingScreen) {
      UIDataState.activeCall?.actions.shareScreen()
    } else {

      const stream = await navigator.mediaDevices.getDisplayMedia(
        {
          audio: true,
          video: getScreenShareContraints('video')
        });

      UIDataState.activeCall?.actions.shareScreen(stream);
    }
  }

  const opacity = show ? 1 : 0;
  const transition = opacity ? "opacity 0.2s ease-out" : "opacity 0.3s ease-in";
  const backgroundColor = (hovered ? DSTheme.EmphasisBackgroundColor : call?.sharingScreen ? DSTheme.CancelColor : DSTheme.ButtonBackgroundColor);

  return (
    <div ref={ref} style={{ opacity, transition, display: "flex", }}>
      <DSButton
        data-tooltip={tooltip}
        analyticsEvent="CallScreenShare"
        style={{
          borderRadius: 999,
          flexDirection: "row",
          backgroundColor,
          ...style,
        }}
        onHovered={setHovered}
        hoverSettings={{ timeout: sharing ? 800 : 100 }}
        color={DSTheme.MainIcon}
        onMouseDown={onClick}

        {...otherProps}
      >
        <MdOutlineScreenShare
          size={size ?? DSTheme.IconSize + 4}
          style={{ pointerEvents: "none", margin: 5 }}
        />
        <DSBadgeView
          badgeNum={openPlugins.length}
          badgePosition="TopRight"
          style={{ backgroundColor: DSTheme.SuccessColor }} />
      </DSButton>
    </div>
  );
});
