import { ITeamCall, TReceiverStatus } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import * as uuid from "uuid";
import { Database, get, onChildAdded, onChildChanged, ref, set, update } from "firebase/database";


const logger = new Logger("CallRequestDb");

export class CallRequestDb {
  static _callWatches:(() => void)[] = [];

  static watchCalls(
    fbDb: Database,
    teamId: string,
    callback: (recvUserId: string, doc: ITeamCall) => void
  ) {
    const teamCallPath = `/teams/${teamId}/calls`;

    CallRequestDb._callWatches.push(
      onChildAdded(ref(fbDb, teamCallPath), (snapshot) => callback(snapshot.key!, snapshot.val()))
    );

    CallRequestDb._callWatches.push(
      onChildChanged(ref(fbDb, teamCallPath), (snapshot) => callback(snapshot.key!, snapshot.val()))
    );
  }

  static unwatchCalls(fbDb: Database, teamId: string) {
    CallRequestDb._callWatches.forEach(f => f())
    CallRequestDb._callWatches = []
  }

  static cancelCall = async (
    fbDb: Database,
    myUserId: string,
    teamId: string,
    userId: string
  ) => {
    const snapshot = await get(ref(fbDb, `/teams/${teamId}/calls/${userId}`))
    const callData: ITeamCall = snapshot.val();
    logger.debug("call", callData);

    if (callData.userId != myUserId) {
      return;
    }

    var callDoc = {
      senderStatus: "cancelled",
      active: false,
    };
    await update(ref(fbDb, `/teams/${teamId}/calls/${userId}`), callDoc);
  };

  static getCall = async (
    fbDb: Database,
    teamId: string,
    userId: string
  ): Promise<ITeamCall> => {
    const snapshot = await get(ref(fbDb, `/teams/${teamId}/calls/${userId}`))
    const callData: ITeamCall = snapshot.val();

    return callData;
  };

  static callUser = async (
    fbDb: Database,
    myUserId: string,
    mySessionToken: string,
    teamId: string,
    userId: string,
    currentRoomId?: string,
    callKey?: string,
    callName?: string
  ) => {
    var callDoc: ITeamCall = {
      callId: uuid.v1(),
      userId: myUserId,
      senderSessionToken: mySessionToken,
      active: true,
      roomId: currentRoomId || null,
      callKey: callKey || null,
      callName: callName || null,
      senderStatus: "calling",
      receiverStatus: "waiting",
    };

    await set(ref(fbDb, `/teams/${teamId}/calls/${userId}`), callDoc);
  };

  static respondToCall = async (
    fbDb: Database,
    mySessionToken: string,
    teamId: string,
    userId: string,
    receiverStatus: TReceiverStatus
  ) => {
    const nonTerminal = ["accepted", "waiting", "holdon"];
    const path = `/teams/${teamId}/calls/${userId}`;

    if (!(nonTerminal.includes(receiverStatus))) {
      await update(ref(fbDb, path), {
        receiverStatus: receiverStatus,
        active: false,
      });
    } else {
      await update(ref(fbDb, path), {
        receiverStatus: receiverStatus,
        receiverSessionToken: mySessionToken,
      });
    }

    // var callDoc: ITeamCall = {
    //   userId: OTUITree.auth.userId,
    //   active: true,
    //   senderStatus: "calling",
    //   receiverStatus: 'waiting'
    // }

    // getRTDB().ref('/teams/'+teamId + '/rooms/').push(roomObj).key
  };


  static joinCall = async (fbDb: Database, teamId: string, userId: string) => {
    await update(ref(fbDb, `/teams/${teamId}/calls/${userId}`), {
      active: false,
    });
  };
}
