import { ExternalMeeting, OTAuth, OTGlobals, OTUITree, UIDataState } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  RouteProps, useParams
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import { OTAppCoreData, OTGlobals, FireDb, OTUITree, OTAuth } from "@openteam/app-core";
import { LoginState } from '../../Controllers/UIState';
import * as Analytics from "../../Util/Analytics";
import { MeetCall } from "./MeetCall";
import { MeetFinished } from "./MeetFinished";
import { MeetOpen } from "./MeetOpen";


const logger = new Logger("Meet")

export type meetingMode = 'ext' | 'finished' | 'rejected'
interface pageParams {
  meetingToken: string
}

interface pageProps extends RouteProps {
  mode?: meetingMode 
}

export const MeetPage = observer((props: pageProps) => {
  let { meetingToken } = useParams<pageParams>();
  const { mode } = props;
  const [externalManager, setExternalManager] = useState<ExternalMeeting>();

  if (!OTGlobals._auth) {
    // the check on OTGlobals._auth is for hot reloading

    const auth = new OTAuth(getDatabase(), getFirestore(), () => { });
    logger.debug("created auth")
    OTGlobals.registerAuth(auth);
    logger.debug("registered auth")

  }

  useEffect(() => {
    Analytics.setCurrentScreen("MeetPage");


  }, []);

  useEffect(() => {

    if (LoginState.initialised && !LoginState.isSignedIn) {
      logger.debug("signing in anonymously")
      const auth = getAuth();
      signInAnonymously(auth)
    }
  }, [LoginState.initialised, LoginState.isSignedIn])

  useEffect(() => {
    if (LoginState.isSignedIn && !externalManager) {
      logger.debug("signed in, creating external meeting")
      const auth = getAuth();

      OTUITree.auth.onAuthStateChanged(auth.currentUser)

      const userId = auth.currentUser?.uid!

      const newMeetingManager = new ExternalMeeting(
        getDatabase(),
        getFirestore(),
        userId,
        uuidv4(),
        meetingToken
      )

      setExternalManager(newMeetingManager)

    }

  }, [LoginState.isSignedIn, externalManager])


  const requestMeeting = (name: string) => {
    if (!name || !externalManager) {
      return
    }
    externalManager.requestJoin(name)
  }

  return (
    UIDataState.activeCall ?
      <MeetCall
      />
      :
      externalManager?.status == 'F' || mode === 'finished' || mode === 'rejected' ?
        <MeetFinished
          meetingData={externalManager?.meetingData}
          mode={mode}
        />

        :
        <MeetOpen
          meetingToken={meetingToken}
          meetingData={externalManager?.meetingData}
          status={externalManager?.status}
          requestMeeting={requestMeeting}
          skipOpen={mode === 'ext'}
        />
  )


});

