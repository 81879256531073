export function getFriendlyDate(date?: Date) {

  if (!date || !(date instanceof Date)) {
    return ""
  }

  const today = new Date()

  let dateString = ""

  if (today.toDateString() == date.toDateString()) {
    dateString = date.toLocaleString('en-GB', { hour: "numeric", minute: "numeric" })

  } else {
    dateString = date.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })
  }

  return dateString
}

export const isToday = (dt: Date) => (new Date()).toDateString() == dt.toDateString();

export function timeSince(date: Date, postfix = "") {


  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  let interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d" + postfix;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h" + postfix;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "m" + postfix;
  }
  return "just now";
}

export function getDuration(fromDate: Date, toDate: Date) {
  let distance = Math.abs(fromDate.getTime() - toDate.getTime());

  let durationString = ""

  const hours = Math.floor(distance / 3600000);

  if (hours > 0) {
    durationString += `${hours}h `
  }
  distance -= hours * 3600000;
  const minutes = Math.floor(distance / 60000);
  durationString += `${minutes}m`

  return durationString;
}

export function getFullDate(date?: Date) {

  if (!date || !(date instanceof Date)) {
    return ""
  }

  const today = new Date()

  let dateString = ""

  const dateFormat: any = { weekday: 'long', month: 'long', day: 'numeric', hour: "numeric", minute: "numeric" }

  if (today.getFullYear() !== date.getFullYear()) {
    dateFormat.year = 'numeric'
  }
  dateString = date.toLocaleString('en-GB', dateFormat)

  return dateString
}