import { Panel, Column, H1, Body } from '@openteam/design-system';
import React from 'react';
import Page from '../Util/Page';


const ZoomError: React.FC = () => {
  return (
    <Page>
      <Panel style={{ margin: 20, alignItems: 'center' }}>
        <Column>
          <H1 style={{ color: "black", marginBottom: 24, fontWeight: 700, fontSize: 40}}>
            Error
          </H1>
          <Body style={{ color: "black", marginBottom: 24, fontSize: 16 }}>
            Unfortunately we were unable to link to your Zoom account.
            <br/>
            <br/>
            Please try again later or email us at <a href="mailto:support@ehlo.space">support@ehlo.space</a> to investigate
          </Body>

        </Column>

      </Panel>
    </Page>
  );
}

export default ZoomError;
