import { IOTRoom, ITeamRoom } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { RoomManager } from "./RoomManager";
import { CallStateManager } from "./CallStateManager";
import { reaction, runInAction, toJS } from "mobx";
import { FireDb, ExternalMeetingDb } from "./fire";
import { OTGlobals } from "./OTGlobals";
import { OTTeamDataClass } from "./OTTeamDataClass";
import { TShowCallFeedback } from "./CallRequest";
import { Database } from "firebase/database";
import { Firestore } from "firebase/firestore";

const logger = new Logger("ExternalMeetingManager");

export class ExternalMeetingManager {
  fbDb: Database;
  fsDb: Firestore;
  userId: string;
  sessionToken: string;
  teamId: string;
  roomId: string | undefined;
  _roomManager!: RoomManager;
  teamData: OTTeamDataClass;
  room?: IOTRoom | null;

  setMeeting: (roomId) => void;
  showCallFeedback: TShowCallFeedback;

  constructor(
    fbDb: Database,
    fsDb: Firestore,
    userId: string,
    sessionToken: string,
    teamId: string,
    setMeeting: (currentRoomId) => void,
    showCallFeedback: TShowCallFeedback
  ) {
    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.teamId = teamId;
    this.setMeeting = setMeeting;
    this.showCallFeedback = showCallFeedback;

    this.teamData = new OTTeamDataClass(fbDb, this.userId, this.teamId);

    logger.info("registering getTeamData to externalMeetingManager");
    OTGlobals.registerGetTeamData((teamId: string) => this.teamData);
  }

  setupRoom = async (roomId: string, name: string) => {
    this.roomId = roomId;

    logger.info(`Creating ExternalMeeting for teamId=${this.teamId}, roomId=${roomId}, name=${name}`);

    this._roomManager = new RoomManager(this.fbDb, this.userId, this.teamId);

    await FireDb.joinTeamRoom(this.fbDb, this.teamId, this.userId, this.sessionToken, this.roomId, {
      id: this.userId,
      name
    });

    await ExternalMeetingDb.removeInviteTeamRoom(this.fbDb, this.teamId, this.roomId, this.userId);

    ExternalMeetingDb.watchRoom(this.fbDb, this.teamId, this.roomId, this.hdlRoom);

    OTGlobals.analytics?.logEvent("externalMeetingManager__JoinRoom");
  };

  shutdown = () => {
    ExternalMeetingDb.unwatchRoom(this.fbDb, this.teamId, this.roomId!);
  };

  hdlRoom = async (roomId: string, roomDoc: ITeamRoom) => {
    logger.info("ExternalMeeting hdlRoom", roomId, roomDoc);
    var myRoom: IOTRoom | null = null;

    runInAction(() => {
      var teamDoc = {
        rooms: {
          [roomId]: roomDoc,
        },
        users: {},
      };

      myRoom = this._roomManager.handleDoc(teamDoc) || null;
    });

    await this._manageCallState(myRoom);

    // @ts-ignore: myRoom may be set in the runInAction call above
    this.setMeeting(myRoom ? myRoom!.roomId : undefined);
  };

  getRoom = () => {
    return this.room
  }

  _manageCallState = async (room: IOTRoom | null) => {
    this.room = room
    const callStateManager = OTGlobals.callStateManager;

    if (callStateManager) {
      if (callStateManager.roomId != room?.roomId || !room?.config?.call) {
        logger.info(`Ending call for roomId=${callStateManager.roomId}`);

        const callSecs = await callStateManager.shutdown();

        OTGlobals.setCallStateManager(undefined);
      } else {
        callStateManager.updateUsers(room.users);
      }
    }

    if (!OTGlobals.callStateManager && room && room.config?.call) {
      logger.debug(`Starting call for ${room.roomId} with ${room.users}`);
      const callStateManager = new CallStateManager(
        this.fbDb,
        this.fsDb,
        this.userId,
        this.sessionToken,
        this.teamId,
        room.roomId,
        room.users,
        this.getRoom,
        this.showCallFeedback
      );
      callStateManager.setFocusRoom(true);
      callStateManager.on("callkicked", (ev) => this.leaveTeamRoom());

      OTGlobals.setCallStateManager(callStateManager);
    }
  };

  leaveTeamRoom = async () => {
    this.shutdown();
    FireDb.leaveTeamRoom(this.fbDb, this.userId, this.sessionToken, this.teamId, this.roomId!);
    this.setMeeting(null);
  };

  getCurrentCallState = () => {
    return OTGlobals.callStateManager;
  };

  _devicesChangedReaction = reaction(
    () => {
      return [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video];
    },
    async () => {
      logger.info("_devicesChangedReaction");

      const callState = this.getCurrentCallState();
      if (callState) {
        logger.info("camera settings changed", toJS(OTGlobals.mediaDevices));
        if (callState.myStreams["camera"]) {
          await callState.myStreams["camera"].updateSettings(OTGlobals.mediaDevices);
        } else {
          logger.debug("Camera stream not found, recreating");
          callState._updateStream("camera", callState._wantAudio, callState._wantVideo);
        }
      }
    }
  );

  _simulcastChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.videoSimulcastEnabled;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["camera"];
        logger.debug("simulcast change for", stream);
        if (stream) {
          stream.bumpTrack("video");
        }
      }
    }
  );

  _cameraQualityChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.cameraQuality;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["camera"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    }
  );

  _screenShareQualityChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.screenshareQuality;
    },
    () => {
      const callState = this.getCurrentCallState();
      if (callState) {
        const stream = callState.myStreams["screen"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    }
  );
}
