import React, { CSSProperties, useCallback, useRef } from "react";
import { DSTheme } from "./DSTheme";
import { Logger } from "@openteam/app-util";

const logger = new Logger("DSText");

interface IDSTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  strong?: boolean
  wrap?: boolean
  selectable?: boolean
  maxLines?: number
  text?: string
}

export function DSText(props: IDSTextProps) {
  const { style, color, strong, wrap, selectable, maxLines=0, text, ...otherProps} = props
  const ref = useRef(null)

  const selectStyle = selectable ? {
      cursor: "text",
      WebkitUserSelect: "text"
    } as CSSProperties : null

  const stopPropagation: React.PointerEventHandler<HTMLSpanElement> = useCallback((e) => {
    if (selectable && e.currentTarget === ref.current) {
      e.stopPropagation()
    }
  }, [selectable])

  return (
    <span
      ref={ref}
      onPointerDown={stopPropagation}
      {...otherProps}
      style={{
        // fontFamily: 'Inter, sans-serif',
        // fontSize: '14px',
        color,
        cursor: "inherit",
        fontWeight: strong ? 700 : undefined,
        whiteSpace: (wrap || maxLines >= 1) ? undefined : "nowrap",
        overflowWrap: (wrap || maxLines > 1) ? "anywhere" : undefined,
        // @ts-ignore
        ...(maxLines >= 1 && {
          display: "-webkit-box",
          WebkitLineClamp: `${maxLines}`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        } as CSSProperties),
        ...selectStyle,
        ...style
      }}
    >
      {text}
      {otherProps.children}
    </span>
  );
}

export function DSHeaderText(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      {...otherProps}
      style={{
        fontFamily: 'Manrope',
        ...style
      }}
    />
  )
}



export function DSBadgeText(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      {...otherProps}
      style={{
        fontSize: '10px',
        ...style
      }}
    />
  )
}


export function DSSmallPrint(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      {...otherProps}
      style={{
        fontSize: '11px',
        ...style
      }}
    />
  )
}

export function DSPrint(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      {...otherProps}
      style={{
        fontSize: '12px',
        ...style
      }}
    />
  )
}

export function DSStrongPrint(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      {...otherProps}
      style={{
        fontSize: '12px',
        fontWeight: 500,
        ...style
      }}
    />
  )
}
export function DSLargePrint(props: IDSTextProps) {
  const { style, ...otherProps } = props

  return (
    <DSText
      wrap
      {...otherProps}
      style={{
        fontSize: '13px',
        ...style
      }}
    />
  )
}

export function DSBody(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSText
      wrap
      {...otherProps}
      style={{
        fontSize: '14px',
        lineHeight: '18px',
        ...style
      }}
    />
  )
}

export function DSH5(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSHeaderText
      {...otherProps}
      style={{
        fontSize: '11px',
        fontWeight: 700,
        ...style
      }}
    />
  )
}

export function DSH4(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSHeaderText
      {...otherProps}
      style={{
        fontSize: '12px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}

export function DSH3(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSHeaderText
      {...otherProps}
      style={{
        fontSize: '14px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}

export function DSH2(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSHeaderText
      {...otherProps}
      style={{
        fontSize: '16px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}


export function DSH1(props: IDSTextProps) {
  const { style, ...otherProps} = props

  return (
    <DSHeaderText
      {...otherProps}
      style={{
        fontSize: '18px',
        fontWeight: 800,
        ...style
      }}
    />
  )
}


export function DSPanelHead(props: IDSTextProps) {
  const { style, wrap=false, ...otherProps} = props
  return (
    <DSH3
      wrap={wrap}
      style={{
        //maxWidth: "100%",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...style
      }}
      {...otherProps}
    />
  )
}



interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  strong?: boolean
  htmlFor: string
}

export function DSLabel(props: LabelProps) {
  const { style, color, strong, htmlFor, ...labelProps} = props
  return (
    <label
      htmlFor={htmlFor}
      style={{
        // fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        fontWeight: strong ? 700 : undefined,
        WebkitUserSelect: "none",
        ...style
      }}
      {...labelProps}
    />

  )
}
