import { CSSProperties } from "react";

export default {
    absolute: {
      position: "absolute",
      inset: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    } as CSSProperties,
  };
  