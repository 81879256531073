import { computed, makeObservable, observable } from "mobx";
import { Logger } from "@openteam/app-util";
import { FireDb, CallRequestDb } from "../fire";
import { IAudioContainer, ITeamCall, TReceiverStatus } from "@openteam/models";
import { OTGlobals } from "../OTGlobals";
import { sendAction, recvAction } from "../Alert";
import { OTUITree } from "../OTUITree";
import { CallRequestUIState } from "./CallRequestUIState";
import { OTUserInterface } from "../OTUserInterface";
import { writeCallRequests } from "../UIDataState";
import { Database } from "firebase/database";

const logger = new Logger("CallRequestManager");

export interface IExtCall {
  teamId: string;
  userId: string;
  callId: string;
  answer?: TReceiverStatus;
  endCall: () => void;
}

const callRegister: { [id: string]: IExtCall } = {};
var activeCallId: string | undefined;

export async function registerCall(
  fbDb: Database,
  callId: string,
  teamId: string,
  userId: string,
  endCall: () => void
) {
  if (!callRegister[callId]) {
    callRegister[callId] = {
      teamId: teamId,
      userId: userId,
      callId: callId,
      endCall: endCall,
    };
  }

  var callDoc = await CallRequestDb.getCall(fbDb, teamId, userId);

  console.log("callDoc", callDoc);

  if (
    callDoc.callId != callId ||
    callDoc.userId != userId ||
    !(callDoc.active && callDoc.senderStatus == "calling" && callDoc.receiverStatus == "waiting")
  ) {
    endCall();
  }
}

export async function endActiveCall() {
  if (activeCallId) {
    callRegister[activeCallId]?.endCall();
    activeCallId = undefined;
  }
}

export function respondToCall(
  callId: string,
  response: TReceiverStatus,
  getCallRequestUIState: (teamId: string) => CallRequestUIState
) {
  const { teamId } = callRegister[callId];

  if (callRegister[callId].answer) {
    return;
  }

  callRegister[callId].answer = response;
  console.log(`set response for ${callId} to ${response}`);

  getCallRequestUIState(teamId).recvRespondToCall(response);
}

export function getCall(callId) {
  return callRegister[callId];
}

export function unregisterCallDisplay(callId) {
  delete callRegister[callId];
}

export type TShowCallRequestModal = (
    callRequestManager: CallRequestManager,
    teamId: string
  ) => Promise<unknown>;
export type TDismissModal = () => Promise<unknown>;

export type TShowCallFeedback =
  (
  teamId: string,
  userId: string,
  roomId: string,
  startTime: number,
  endTime: number,
)
 => void;

export class CallRequestManager {
  fbDb: Database;
  userId: string;
  sessionToken: string;
  teamId: string;
  joinTeamRoom: (roomId: string | null, ignoreLock?: boolean) => Promise<void>;
  showCallRequestModal: TShowCallRequestModal;
  dismissModal: TDismissModal;
  @observable call?: ITeamCall = undefined;
  ringtoneAudioOutgoing?: IAudioContainer;

  // @observable callingUsers?: {[id: string]: boolean} = {}

  @observable calls: { [id: string]: ITeamCall } = {};

  @observable incomingCallUserId?: string;

  constructor(
    fbDb: Database,
    userId: string,
    sessionToken: string,
    teamId: string,
    joinTeamRoom: (roomId: string | null, ignoreLock?: boolean) => Promise<void>,
    showCallRequestModal: TShowCallRequestModal,
    dismissModal: TDismissModal
  ) {
    makeObservable(this)

    this.fbDb = fbDb;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.teamId = teamId;
    this.joinTeamRoom = joinTeamRoom;
    this.showCallRequestModal = showCallRequestModal;
    this.dismissModal = dismissModal;

    OTUITree.registerCallRequestManager(this);
  }

  start = () => {
    CallRequestDb.watchCalls(this.fbDb, this.teamId, this.handleCalls);
  };
  stop = () => {
    CallRequestDb.unwatchCalls(this.fbDb, this.teamId);
  };

  @computed get callsFromMe() {
    return Object.fromEntries(
      Object.entries(this.calls).filter(
        ([targetUserId, call]) => call.userId == this.userId && call.active
      )
    );
  }

  handleCalls = async (recvUserId: string, doc: ITeamCall) => {
    const teamData = OTGlobals.getTeamData(this.teamId);

    this.calls[recvUserId] = doc;

    // if I'm the caller
    if (doc.userId == this.userId && doc.senderSessionToken == this.sessionToken) {
      if (doc.receiverStatus == "accepted" && doc.senderStatus != "started") {
        await this.sendStartCall(recvUserId, doc.roomId ?? undefined);
        OTGlobals.analytics?.logEvent("callmanager__call_accepted");
      }

      if (doc.receiverStatus == "rejected" && doc.senderStatus != "cancelled") {
        var recvUser = teamData.getTeamUser(recvUserId);

        OTUserInterface.toastHandlers.show(`${recvUser.name} declined your call`, "error");
        await this.sendCancelCall(recvUserId);
        OTGlobals.analytics?.logEvent("callmanager__call_rejected");
      }

      if (doc.receiverStatus == "busy" && doc.senderStatus != "cancelled") {
        var recvUser = teamData.getTeamUser(recvUserId);

        OTUserInterface.toastHandlers.show(`${recvUser.name} is currently in a call`, "error");
        await this.sendCancelCall(recvUserId);
        OTGlobals.analytics?.logEvent("callmanager__call_busy");
      }

      if (doc.active && (doc.receiverStatus == "waiting" || doc.receiverStatus == "holdon" )) {
        // if (!this.ringtoneAudioOutgoing) {
        //   this.ringtoneAudioOutgoing = OTUserInterface.ringtones.getOutgoing();
        //   this.ringtoneAudioOutgoing.play();
        // }
      } else {
        // if (this.ringtoneAudioOutgoing) {
        //   this.ringtoneAudioOutgoing.pause();
        //   this.ringtoneAudioOutgoing = undefined;
        // }
      }
    }

    // im receiving call
    if (recvUserId == this.userId) {
      this.call = doc;
      if (doc.active && doc.senderStatus == "calling" && (doc.receiverStatus == "waiting" || doc.receiverStatus == "holdon" )) {
        recvAction(doc.userId, "CALL");

        if (OTGlobals.appHomeManager.inCall || Object.keys(this.callsFromMe).length > 0) {
          await this.recvRespondToCall("busy");
          return;
        } else if (callRegister[doc.callId]?.answer) {
          console.log(`already got answer for ${doc.callId} : ${callRegister[doc.callId]?.answer}`);

          await this.recvRespondToCall(callRegister[doc.callId].answer!);
          return;
        }

        if (this.incomingCallUserId != doc.userId) {
          this.showCallRequestModal(this, this.teamId);
          this.incomingCallUserId = doc.userId;
        }
      } else {
        if (this.incomingCallUserId == doc.userId) {
          this.incomingCallUserId = undefined;
          this.dismissModal();
        }
      }

      var dialingUser = teamData.getTeamUser(doc.userId);

      if (doc.senderStatus == "cancelled" && doc.receiverStatus != "rejected") {
        OTUserInterface.toastHandlers.show(`${dialingUser.name} cancelled call`, "error");
        this.recvRespondToCall("rejected");
        callRegister[doc.callId]?.endCall();
      }

      if (
        doc.receiverStatus == "accepted" &&
        doc.active &&
        doc.roomId &&
        doc.senderStatus == "started" &&
        doc.receiverSessionToken == this.sessionToken
      ) {
        await this.recvJoinCall(this.userId, doc.roomId);
        activeCallId = doc.callId;
      }
    }

    writeCallRequests(this)
  };

  sendCallUser = async (userId: string, callKey?: string, callName?: string, roomId?: string) => {
    logger.info(`calling ${userId}`);
    var teamData = OTGlobals.getTeamData(this.teamId);

    if (!teamData) {
      logger.warn("no teamData");
      return;
    }

    if (this.calls[userId]?.active || this.call?.active) {
      if (userId in this.callsFromMe) {
        logger.warn("Already calling, cancelling");
        this.sendCancelCall(userId, true);
      } else if (this.call?.active) {
        OTUserInterface.toastHandlers.show("Someone is calling you", "error");

      } else {
        logger.warn("User on a call");
        OTUserInterface.toastHandlers.show("Someone is already calling that user", "error");
      }
      return;
    }

    var recvUser = teamData.getTeamUser(userId);

    if (!roomId) {
      var roomId: string | undefined = teamData.currentRoomId;

      if (roomId && (!teamData.rooms[roomId]?.config?.call || teamData.rooms[roomId]?.config?.focusRoom)) {
        roomId = undefined;
      }
    }

    await CallRequestDb.callUser(
      this.fbDb,
      this.userId,
      this.sessionToken,
      this.teamId,
      userId,
      roomId,
      callKey,
      callName
    );
    OTUserInterface.toastHandlers.show(`Calling ${recvUser.name}`, "success");

    OTGlobals.analytics?.logEvent("callmanager__call_user", { userId: userId });
  };

  sendCancelCall = async (userId: string, userInitiated = false) => {
    var teamData = OTGlobals.getTeamData(this.teamId);
    if (this.calls[userId].userId == this.userId) {
      await CallRequestDb.cancelCall(this.fbDb, this.userId, this.teamId, userId);

      if (userInitiated) {
        var recvUser = teamData.getTeamUser(userId);
        OTUserInterface.toastHandlers.show(`Call to ${recvUser.name} cancelled`, "error");
        sendAction(this.fbDb, this.userId, this.teamId, userId, "CALL");
      }

      OTGlobals.analytics?.logEvent("callmanager__cancel_call", { userId: userId });
    } else {
      logger.warn(`Can't cancel call not calling ${userId}`);
    }
  };

  recvRespondToCall = async (response: TReceiverStatus) => {
    await CallRequestDb.respondToCall(this.fbDb, this.sessionToken, this.teamId, this.userId, response);
    OTGlobals.analytics?.logEvent(`callmanager_call_${response}`);
  };

  sendStartCall = async (userId: string, roomId?: string) => {
    var teamData = OTGlobals.getTeamData(this.teamId);

    if (!roomId) {
      var roomId = teamData.currentRoomId as string | undefined;
      if (roomId && (!teamData.rooms[roomId]?.config?.call || teamData.rooms[roomId]?.config?.focusRoom)) {
        roomId = undefined;
      }
    } else {
      if (roomId != teamData.currentRoomId) {
        await this.joinTeamRoom(roomId, true);
      }
    }

    FireDb.startCall(this.fbDb, this.teamId, this.userId, this.sessionToken, userId, roomId);
  };

  recvJoinCall = async (userId, roomId) => {
    OTGlobals.auth.userManager.setCurrentTeam(this.teamId);
    await this.joinTeamRoom(roomId, true);
    await CallRequestDb.joinCall(this.fbDb, this.teamId, userId);
  };

  @computed get callingUsers() {
    const calls: Record<string, boolean> = {};
    Object.keys(this.calls)
      .filter(
        (recvUserId) =>
          this.calls[recvUserId].active && this.calls[recvUserId].userId == this.userId
      )
      .forEach((recvUserId) => {
        calls[recvUserId] = true;
      });
    return calls;
  }

  @computed get callsByKey() {
    const callKeys = {};

    Object.keys(this.callingUsers).forEach((targetUserId) => {
      let call = this.calls[targetUserId];
      if (call.userId == this.userId && call.callKey) {
        if (!(call.callKey in callKeys)) {
          callKeys[call.callKey] = {};
        }
        callKeys[call.callKey][targetUserId] = call;
      }
    });

    return callKeys;
  }
}
