import React from "react";
import {styled} from '@mui/material'
import DS2BaseButton, {DS2BaseButtonProps} from "./DS2BaseButton";

export interface DS2IconActionButtonProps extends DS2BaseButtonProps {
};

const DS2IconActionButton = styled(DS2BaseButton)(({theme}) => ({
  fontSize: '16px',
  padding: 6,
  width: 'fit-content'
}))

export default DS2IconActionButton;
