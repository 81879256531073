import { runInAction } from "mobx";
import { PluginDb } from "./fire";
import { Logger } from "@openteam/app-util";
import { ILinkPreview, IPinnedResource, IPluginResource } from "@openteam/models";
import { OTGlobals } from "./OTGlobals";
import { OTUserInterface } from "./OTUserInterface";
import { TypedEmitter } from 'tiny-typed-emitter';
import { getLinkPreview } from "./Chat/LinkPreviewManager";
import { Database } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";

const logger = new Logger("PluginManager");

export interface IPluginConfig {
  name: string;
  multi: boolean;
  component?: any;
  start?: (x: IPluginResource) => void;
  setupFunc?: (x: PluginManager) => void;
  icon: any;
  iconColour?: string;
  aspectRatio?: number;
  webInline?: boolean;
  backgroundColor?: string;
  canHandleUrl?: (url) => any;
  urlPriority?: number;
}

interface Events {
  'pluginupdated': (pluginId: string, pluginData: IPluginResource) => void
  'mediaplaying': (pluginId: string, playing: boolean) => void
  'plugindeleted': (pluginId: string) => void
}

export class PluginManager extends TypedEmitter<Events> {
  fbDb: Database;
  userId: string;
  _teamId: string;
  _roomId: string;

  plugins: Record<string, IPluginResource> = {};


  unsupportedPlugins = new Set();
  _autorun: Record<string, any> = {};

  constructor(fbDb: Database, userId: string, teamId: string, roomId: string) {
    super();

    this.fbDb = fbDb;
    this.userId = userId;
    this._teamId = teamId;
    this._roomId = roomId;

  }

  start = () => {
    PluginDb.watchRoomPlugins(this.fbDb, this._teamId, this._roomId, this.handlePluginData);
  }

  stop = () => {
    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };

  static getMiroToken = async () => {
    var getMiroToken = httpsCallable(getFunctions(), "getMiroToken");
    var result = await getMiroToken();

    logger.info("getMiroToken", (result.data as any).authToken);

    return (result.data as any).authToken;
  };

  generateId = () => {
    return PluginDb.genPluginId(this.fbDb, this._teamId, this._roomId);
  };

  handlePluginData = (msg: IPluginResource, deleted: boolean) => {
    logger.info("received plugindata message", msg, "deleted", deleted);

    runInAction(() => {
      if (deleted) {
        this.closePlugin(msg.pluginId);
      } else if (msg.status == "A") {
        if (!(msg.pluginType in OTGlobals.pluginConfigList)) {
          if (!this.unsupportedPlugins.has(msg.pluginId)) {
            OTUserInterface.toastHandlers.show(
              `This version of OpenTeam doesn't support plugin ${msg.pluginType} please upgrade`,
              "error"
            );
            this.unsupportedPlugins.add(msg.pluginId);
          }
          return;
        }

        this.plugins[msg.pluginId] = msg;
        this.emit('pluginupdated', msg.pluginId, msg);
      }
    });
  };

  getPlugins = () => {
    return Object.values(this.plugins).filter((plugin) => plugin.status == "A");
  };

  getPlugin = (pluginId) => {
    return this.plugins[pluginId];
  };

  getPluginConfig = (pluginType) => {
    return OTGlobals.pluginConfigList[pluginType];
  };

  hasOpenPlugin = (pluginType) => {
    var existingPlugins = Object.values(this.plugins).filter(
      (plugin) => plugin.pluginType == pluginType
    );
    return existingPlugins.length > 0;
  };

  setupPlugin = (pluginType: string) => {
    var pluginConfig: IPluginConfig = OTGlobals.pluginConfigList[pluginType];

    if (!pluginConfig.multi) {
      if (this.hasOpenPlugin(pluginType)) {
        OTUserInterface.toastHandlers.show(
          `${pluginConfig.name} can only be opened once and is already open`,
          "error"
        );
        logger.info("this plugin only allows one instance");
        return;
      }
    }
    if (!pluginConfig.setupFunc) {
      this.createPlugin(pluginType);
    } else {
      pluginConfig.setupFunc(this);
    }
  };

  createPlugin = async (pluginType: string, pluginURL?: string, args?: any, prevPluginId?: string) => {
    let pluginId = prevPluginId || this.generateId();

    let linkPreview: ILinkPreview | undefined = undefined
    if (pluginURL) {
      const linkPreviewLoader = getLinkPreview(pluginURL)
      await linkPreviewLoader.loader
      if (linkPreviewLoader.preview) {
        linkPreview = linkPreviewLoader.preview
      }
    }

    this.plugins[pluginId] = {
      pluginType: pluginType,
      pluginId: pluginId,
      pluginURL: pluginURL || null,
      linkPreview: linkPreview || null,
      crDate: Date.now(),
      userId: this.userId,
      status: "A",
      args: args || {},
    };

    this.savePluginState(pluginId);

    OTGlobals.analytics?.logEvent("plugin_add", { pluginId: pluginId, pluginType: pluginType });
    return this.plugins[pluginId].status == "A";
  };

  savePluginState = (pluginId) => {
    logger.info(`Saving plugin state for ${pluginId}`);
    PluginDb.setRoomPlugin(
      this.fbDb,
      this._teamId,
      this._roomId,
      pluginId,
      this.plugins[pluginId] || null
    );
  };

  updatePluginArgs = (pluginId: string, args: {}, sync: boolean) => {
    this.plugins[pluginId].args = { ...this.plugins[pluginId].args, ...args };
    this.plugins[pluginId].userId = this.userId;

    if (sync) {
      this.savePluginState(pluginId);
    }
  };

  onPlaying = (pluginId: string, playing: boolean = true) => {
    logger.debug(`emitting: mediaplaying ${pluginId}, playing ${playing}`);
    this.emit("mediaplaying", pluginId, playing);
  };

  closePlugin = (pluginId: string) => {
    this.emit("plugindeleted", pluginId);

    delete this.plugins[pluginId];

    this.savePluginState(pluginId);

    OTGlobals.analytics?.logEvent("plugin_close", { pluginId: pluginId });
  };

  getUrlHandler = (url) => {
    let matchedType;
    let matchedArgs;
    let matchedPriority = -1;

    Object.entries(OTGlobals.pluginConfigList).forEach(([pluginType, pluginConfig]) => {
      if (pluginConfig.canHandleUrl) {
        const args = pluginConfig.canHandleUrl(url);
        const priority = pluginConfig.urlPriority ?? 0;

        if (args) {
          logger.info(`${pluginType} can handle url ${url}, priority: ${priority}`);
          if (priority > matchedPriority) {
            matchedPriority = priority;
            matchedArgs = args;
            matchedType = pluginType;
          }
        }
      }
    });

    return { pluginType: matchedType, pluginArgs: matchedArgs };
  };

  getResourceHandler = (resource: IPinnedResource) => {

    if (resource.recordType === "attachment") {
      const pluginArgs = {
        isResource: true,
        name: resource.name,
        contentType: resource.type,
        url: resource.url
      }
      return { pluginType: "link", pluginArgs };
    } else {
      return this.getUrlHandler(resource.url);
    }
  };
}
