import { KeyBindings } from 'remirror';

import { extension, InputRule, PlainExtension, plainInputRule } from '@remirror/core';
import { baseKeymap, chainCommands as pmChainCommands } from "prosemirror-commands";
import { convertCommand } from "remirror"
export interface EhloEmojiOptions {}

const SHORTCUTS: Array<[RegExp, string]> = [
  // Dash
  [/\B--\B/, '—'],
  // ellipsis
  [/\B\.{3}\B/, '…'],
  // leftArrow
  [/\B<-\B/, '←'],
  // rightArrow
  [/\B->\B/, '→'],
  // copyright
  [/\B\(c\)\B/, '©'],
  // trademark
  [/\B\(tm\)\B/, '™'],
  // registeredTrademark
  [/\B\(r\)\B/, '®'],
  // oneHalf
  [/\b1\/2\b/, '½'],
  // plusMinus
  [/\B\+\/-\B/, '±'],
  // notEqual
  [/\B!=\B/, '≠'],
  // laquo
  [/\B<<\B/, '«'],
  // raquo
  [/\B>>\B/, '»'],
  // superscriptTwo
  [/\B\^2\b/, '²'],
  // superscriptThree
  [/\B\^3\b/, '³'],
  // oneQuarter
  [/\b1\/4\b/, '¼'],
  // threeQuarters
  [/\b3\/4\b/, '¾'],
  [/\B<3\b/, '❤️'],
  [/\B<\/3\b/, '💔'],
  [/\b8\)\B/, '😎'],
  [/\bD:\B/, '😧'],
  [/\B:'\(\B/, '😢'],
  [/\B:o\)\B/, '🐵'],
  [/\B(:\*|:-\*)\B/, '😘'],
  [/\B(=\)|=-\))\B/, '😀'],
  [/\B(:D|:-D|:d)\b/, '😄'],
  [/\B(;\)|;-\))\B/, '😉'],
  [/\B(:>|:->)\B/, '😆'],
  [/\B(:\||:-\|)\B/, '😐'],
  [/\B(:o|:-o|:O)\b/, '😮'],
  [/\B(>:\(|>:-\()\B/, '😠'],
  [/\B(:\)|:-\)|\(:)\B/, '🙂'],
  [/\B(:\(|\):|:-\()\B/, '😞'],
  [/\B(:\/|:-\/|:\\|:=\\)\B/, '😕'],
  [/\B(:p|:P|:-p|:-P|:b|:B|:-b|:-B)\b/, '😛'],
  [/\B(;p|;P|;-p|;-P|;b|;B|;-b|;-B)\b/, '😜'],


];


@extension<EhloEmojiOptions>({
  defaultOptions: {},
})
export class EhloEmojiExtension extends PlainExtension<EhloEmojiOptions> {
  get name() {
    return 'shortcuts' as const;
  }

  /**
   * Manage input rules for keyboard shortcuts
   */
  createInputRules(): InputRule[] {
    return SHORTCUTS.map(([regexp, replace]) => {
      return plainInputRule({
        regexp,
        transformMatch: () => replace,
      });
    });
  }
}



interface CustomKeymapExtensionOptions {
  onSubmit?: null | (() => void);
}

@extension<CustomKeymapExtensionOptions>({ defaultOptions: { onSubmit: null } })
export class CustomKeymapExtension extends PlainExtension<CustomKeymapExtensionOptions> {
  get name() {
    return 'customKeymap' as const;
  }

  /**
   * Injects the baseKeymap into the editor.
   */
  public createKeymap(): KeyBindings {
    const { onSubmit } = this.options;

    return {
      "Enter": ({ next }) => {
        if (onSubmit) {
          // No other commands will be run
          onSubmit();
          return true;
        }

        next(); // Runs all lower priority commands

        return true;
      },

      "Shift-Enter": convertCommand(pmChainCommands(baseKeymap.Enter))
    };
  }
}
