import events from 'events';
import hark from 'hark';
import { action, makeObservable, observable, reaction, runInAction, toJS} from 'mobx';
import { AwaitLock, Logger } from "@openteam/app-util";
import { TStreamType, TNetworkStatus } from "@openteam/models";
import { hashObject } from 'react-hash-string';
import { OTGlobals } from './OTGlobals';
import { applyObjectUpdate } from './utils/applyObjectUpdate';
import { OTUserInterface } from './OTUserInterface';
import { setStream } from './utils/setStream';
import { updateParticipantStream, writeDeviceSettings } from './UIDataState';
//import VideoBGBlur from './VideoBGBlur';

const logger = new Logger("MediaDeviceManager")

export interface IQualityContraint {
  label: string
  comment: string
  width: number
  height: number
}

export interface StreamDetails {
  id: string
  userId: string
  stream: MediaStream
  streamType: TStreamType
  hasAudio: boolean
  hasVideo: boolean
  muted: boolean
  volume: number
  speaking: boolean
  volumeAvg: number
  score: number | undefined
  stats: any
  tracks: Map<string, MediaStreamTrack>
  networkStatus: TNetworkStatus

  mute: () => Promise<boolean>
  unmute: () => Promise<boolean>
  enable: (kind: 'audio' | 'video', enable: boolean) => Promise<boolean | undefined>
  on: (event: string, callback: any) => void
  off: (event: string, callback: any) => void
  setScore: (kind: 'audio' | 'video', score: number) => void
  setStats: (kind: 'audio' | 'video', stats: any) => void
  setNetworkStatus: (networkStatus: TNetworkStatus) => void
}

export interface LocalStreamDetails extends StreamDetails {
  enableAudio: () => Promise<boolean>
  disableAudio: () => Promise<boolean>
  enableVideo: () => Promise<boolean>
  disableVideo: () => Promise<boolean>
  updateSettings: (settings: any) => void
  bumpTrack: (kind: string) => void
  applyConstraints: (kind: string) => void
  shutdown: () => void
}

export interface RemoteStreamDetails extends StreamDetails {
  isBroadcasting?: boolean
  isPushTalking?: boolean
  isEmpty: () => boolean
  addTrack: (track: MediaStreamTrack) => void
  removeTrack: (track: MediaStreamTrack) => void
  removeTrackKind: (kind: 'audio' | 'video') => void
}
export class HarkStreamDetails extends events.EventEmitter implements StreamDetails {
  stream: MediaStream;
  userId: string;
  teamId: string;
  streamType: TStreamType = 'camera';
  muted: boolean = true;
  networkStatus: TNetworkStatus= 'ok'

  _tracks: Map<string, MediaStreamTrack>;
  _harkStream: MediaStream | null = null;
  _hark: hark.Harker | null = null;
  volume: number = 0
  volumeAvg = 0
  _volumeList: number[] = []
  _scores: Map<string, number>
  _stats: Map<string, any[]>
  _audioGroupId: string | null = null;

  constructor(userId: string, teamId: string, streamType: TStreamType, stream?: MediaStream) {
    super()
    this.userId = userId
    this.teamId = teamId
    this.streamType = streamType
    this.stream = stream ?? new MediaStream()
    this._tracks = new Map()
    this._scores = new Map()
    this._stats = new Map()
  }

  get id () {
    return `${this.userId}-${this.streamType}`
  }

  get hasAudio () {
    return this._tracks.has('audio')
  }

  get hasVideo () {
    return this._tracks.has('video')
  }

  set audioGroupId (audioGroupId: string | null) {
    this._audioGroupId = audioGroupId ?? null;
    this._writeState()
  }

  has (kind) {
    return this._tracks.has(kind);
  }

  async mute () {
    if (this._tracks.has('audio') && !this.muted) {
      this._tracks.get('audio')!.enabled = false;
      this.muted = true;
      this.emit("muted", this._tracks.get('audio'), this)
    } else {
      logger.debug(`mute nothing to do has: ${this._tracks.has('audio')} && muted ${this.muted}`)
    }
    this._writeState()
    return this.muted;
  }

  async unmute () {
    if (this._tracks.has('audio') && this.muted) {
      this._tracks.get('audio')!.enabled = true;
      this.muted = false;
      this.emit("unmuted", this._tracks.get('audio'), this)
    } else {
      logger.debug(`unmute nothing to do has: ${this._tracks.has('audio')} && muted ${this.muted}`)
    }
    this._writeState()
    return this.muted;
  }

  enable = async (kind: 'audio' | 'video', enable: boolean = true) => {
    if (kind == 'audio') {
      return (enable ? await this.unmute() : await this.mute())
    } else if (this._tracks.has(kind) && this._tracks.get(kind)!.enabled != enable) {
      logger.debug(`Setting ${this.userId} ${this.streamType} ${kind} track enabled:`, enable)
      this._tracks.get(kind)!.enabled = enable;
    }
  }

  setScore (kind: string, score: number) {
    this._scores.set(kind, score)
    this._writeState()
  }

  setStats (kind: string, stats: any[]) {
    this._stats.set(kind, stats)
    this._writeState()
  }

  get tracks () {
    return this._tracks;
  }

  get speaking () {
    return this._hark ? this._hark.speaking : false;
  }

  get score () {
    if (this._scores.has('video')) {
      return this._scores.get('video')
    } else if (this._scores.has('audio')) {
      return this._scores.get('audio')
    }
  }

  get stats () {
    return Object.fromEntries(this._stats.entries())
  }

  bumpTrack (kind) {

    try {
      logger.debug(`Bumping ${kind} track from`, this.tracks)
      if (this.tracks.has(kind)) {
        const newTrack = this.tracks.get(kind)!.clone()
        this._addTrack(newTrack);
      }
    } catch {

    }
  }

  setNetworkStatus(networkStatus: TNetworkStatus) {
    this.networkStatus = networkStatus
    this._writeState()
  }

  _addTrack = (track: MediaStreamTrack) => {

    if (this._tracks.has(track.kind)) {
      this.removeTrackKind(track.kind)
    }

    this.stream?.addTrack(track)
    this._tracks.set(track.kind, track)

    track.onended = () => {
      logger.debug(`${this.streamType} track ${track.kind} ended`)
      this._removeTrack(track)
    }

    if (track.kind == 'audio') {
      this._setupHark()
      this.muted = (track.enabled == false)
    }

    logger.debug(`Stream ${this.streamType} added ${track.kind} track ${track.id}`)

    this.setScore(track.kind, 0)
    this._writeState()
    this.emit("trackadded", track, this)
  }

  _removeTrack = (track: MediaStreamTrack) => {

    if (track.readyState != "ended") {
      logger.info("stopping track", track.id, track)
      track.stop()
    }

    this.stream?.removeTrack(track)

    if (this._tracks.get(track.kind)?.id == track.id) {
      this._tracks.delete(track.kind)
      this._scores.delete(track.kind)
      this._stats.delete(track.kind)

      if (track.kind == 'audio') {
        this._shutdownHark()
      }

      this._writeState()
      this.emit("trackremoved", track, this)
    } else {
      logger.warn("Removed track not in _tracks", track, this._tracks)
    }
  }

  removeTrackKind = (kind: string) => {
    if (this._tracks.has(kind)) {
      this._removeTrack(this._tracks.get(kind)!)
    }
  }

  _setupHark = () => {
    if (this.hasAudio && OTUserInterface.platformUtils.PlatformOS != "mobile") {
      this._shutdownHark();
      this._harkStream = new MediaStream(this.stream!.getAudioTracks().map((t) => t.clone()));
      this._hark = hark(this._harkStream, { interval: 350 });
      this._hark.on("volume_change", (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exaggerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        this._volumeList.push(dBs);
        if (this._volumeList.length > 6) {
          this._volumeList.shift();
        }
        this.volumeAvg = this._volumeList.reduce((x, y) => x + y) / this._volumeList.length;

        let volume = Math.round(Math.pow(10, dBs / 75) * 10);

        if (volume === 1) {
          volume = 0;
        }

        if (volume != this.volume) {
          this.volume = volume;
          this._writeState();
          this.emit("volume_change", this.volume, this.stream);
        }
      });
      this._hark.on("speaking", () => {
        this._writeState();
        this.emit("speaking", true, this.stream);
      });
      this._hark.on("stopped_speaking", () => {
        this._writeState();
        this.emit("speaking", false, this.stream);
      });
    }
  }

  _shutdownHark = () => {
    if (this._hark) {
      this._hark.stop()
      this.volume = 0
      this.volumeAvg = 0
      this._volumeList = []
      this._harkStream!.getTracks().forEach(t => t.stop())
      this._hark = null
      this._harkStream = null
    }
  }

  _writeState() {
    if (this.streamType == 'test') {
      return
    }
    var teamData = OTGlobals.getTeamData(this.teamId);
    if (this._tracks.size > 0) {
      var newState = {
        userId: this.userId,
        streamId: this.stream.id,
        streamType: this.streamType,
        stream: this.stream,
        hasAudio: this.hasAudio,
        hasVideo: this.hasVideo,
        muted: this.muted,
        volume: this.volume,
        speaking: this.speaking,
        score: this.score ?? 0,
        stats: this.stats,
        networkStatus: this.networkStatus,
        audioGroupId: this._audioGroupId,
      }

      if (!teamData.streams[this.stream.id]) {
        teamData.streams[this.stream.id] = newState
      } else {
        //logger.debug(`Setting stream ${this.stream.id}`)
        var stream = teamData.streams[this.stream.id]

        applyObjectUpdate(stream, newState)
      }

      updateParticipantStream(this.userId, this.streamType, this)
    } else {
      //logger.debug(`Removing stream ${this.stream.id}`)
      delete teamData.streams[this.stream.id]
      updateParticipantStream(this.userId, this.streamType)
    }
  }
}


export class RemoteStream extends HarkStreamDetails implements RemoteStreamDetails {
  _roomId: string
  _inOTData: boolean = false

  constructor(teamId: string, roomId, userId: string, streamType: TStreamType, stream?: MediaStream) {
    super(userId, teamId, streamType, stream)
    this._roomId = roomId
  }

  mute = action(async () =>  {
    this.muted = true
    this._writeState();
    return this.muted
  })

  unmute = action(async () => {
    this.muted = false
    this._writeState();
    return this.muted
  })

  isEmpty = () => {
    return !(this.hasAudio || this.hasVideo)
  }

  addTrack = action((track: MediaStreamTrack) => {
    logger.debug(`Adding ${track.kind} track to Remote stream`);
    this._addTrack(track)
  })

  removeTrack = action((track: MediaStreamTrack) => {
    this._removeTrack(track)
  })

  _writeState () {
    super._writeState()

    if (this.isEmpty() && this._inOTData) {
      this._inOTData = false
      setStream(this.teamId, this._roomId, this.userId, this.streamType)
    } else if (!this.isEmpty() && !this._inOTData) {
      this._inOTData = true
      setStream(this.teamId, this._roomId, this.userId, this.streamType, this.stream.id);
    }
  }
}

export class WebcamStream extends HarkStreamDetails implements LocalStreamDetails {
  _settings: Map<string, {}>;
  _wants: { audio: boolean; video: boolean } = { audio: false, video: false };
  _stopping: boolean = false
  //_bgblur: VideoBGBlur

  constructor(teamId: string, userId: string, settings: {}, streamType: TStreamType = "camera") {
    super(userId, teamId, streamType);
    this._settings = new Map(Object.entries(settings));
    //this._bgblur = new VideoBGBlur();
  }

  enableAudio = action(async () => {
    this._wants.audio = true;
    if (MediaDeviceManager.hasAudio) {
      if (!this.hasAudio) {
        this.muted = false;
        await this._getTrack("audio");
      } else if (this.muted) {
        await this.unmute();
      }
    }
    return this.hasAudio && !this.muted;
  });

  disableAudio = action(async () => {
    this._wants.audio = false;
    this.removeTrackKind("audio");
    this.muted = true;
    return this.hasAudio && !this.muted;
  });

  async unmute() {
    if (!this.hasAudio) {
      await this.enableAudio();
      return this.muted;
    } else {
      return await super.unmute();
    }
  }

  enableVideo = action(async () => {
    this._wants.video = true;
    if (MediaDeviceManager.hasVideo && !this.hasVideo) {
      await this._getTrack("video");
    }
    return this.hasVideo;
  });

  disableVideo = action(async () => {
    this._wants.video = false;
    this.removeTrackKind("video");
    return this.hasVideo;
  });

  updateSettings = async (settings) => {
    logger.info("webcam updateSettings", settings)
    for (const kind of ["audio", "video"]) {
      if (settings[kind]) {
        this._settings.set(kind, settings[kind]);
        if (this._wants[kind]) {
          try {
            await this._getTrack(kind);
          } catch (err) {
            logger.error(`Failed to get ${kind} track`, err);
          }
        }
      }
    }
  };

  applyConstraints = async (kind) => {
    if (this._wants[kind]) {
      await this._getTrack(kind);
    }
  };

  shutdown = () => {
    this._stopping = true

    logger.debug(
      `Shutting down my stream ${this.stream?.id} with ${this._tracks.size} tracks`,
      this._tracks,
      this.stream.getTracks()
    );
    this._tracks.forEach((track) => this.removeTrackKind(track.kind));
  };

  _getTrack = async (kind: string) => {
    if (kind == "audio" && this.muted) {
      // If we're re-getting a track as a result of settings change, respect muted
      logger.info("audio is muted, not adding track");
    } else {
      logger.debug(`__getTrack(${kind})`);
      try {
        const track = await MediaDeviceManager.getTrack(kind, this._settings.get(kind));
        //if (track.kind === 'video') {
        //  this.addTrack(this._bgblur.setTrack(track));
        //} else {
          this.addTrack(track);
        //}
      } catch (e) {
        const err = <Error>e
        OTUserInterface.toastHandlers.show(`Failed to open ${kind} device: ${err.message}`, "error");
      }
    }
  };

  addTrack = action((track: MediaStreamTrack) => {

    if (this._stopping) {
      logger.error("_addTrack called, but stopping", track)

      if (track.readyState != "ended") {
        logger.info("stopping track", track.id, track)
        track.stop()
      }
      return
    }

    this._addTrack(track);
  })
}


export class TestStream extends WebcamStream {
  constructor(teamId: string, userId: string, settings: {}) {
    super(userId, teamId, settings, "test");
    //this._bgblur = new VideoBGBlur();
  }
  reset = () => {
    this.disableAudio();
    this.disableVideo();
    this.enableAudio();
    this.enableVideo();
  }
}

export class SimpleScreenShareStream extends HarkStreamDetails implements LocalStreamDetails {
  constructor( teamId: string, userId: string, streamType: TStreamType, stream: MediaStream) {
    super(userId, teamId, streamType, stream);
    stream.getTracks().forEach((track) => this._addTrack(track));
  }

  enableAudio = async () => {
    return this.hasAudio;
  };

  disableAudio = async () => {
    return this.hasAudio;
  };

  updateSettings(settings) {}

  enableVideo = async () => {
    return this.hasVideo;
  };

  disableVideo = async () => {
    this.shutdown();
    return this.hasVideo;
  };

  applyConstraints = async (kind) => { };

  shutdown = () => {
    this._tracks.forEach((track) => this._removeTrack(track));
  };
}

export class ScreenShareStream extends HarkStreamDetails implements LocalStreamDetails {
  _settings: any;

  constructor(teamId: string, userId: string, settings?: any) {
    super(userId, teamId, 'screen')
    this._settings = settings || {}
  }

  enableAudio = async () => { return this.hasAudio }

  disableAudio = async () => { return this.hasAudio }

  updateSettings (settings) {
    this._settings = settings || {}
  }

  enableVideo = async () => {
    if (!this.hasVideo) {
      const mediaDevices = navigator.mediaDevices as any;

      var stream = await OTGlobals.getDisplayMedia(
        {
          audio: true,
          video: this._getConstraints('video')
        });

      stream.getTracks().forEach(track => {
        this._addTrack(track);
        try {
          logger.debug( "Got screenshare track with", track.getSettings());
        } catch {

        }

      })
    }
    return this.hasVideo
  }

  disableVideo = async () => {
    this.shutdown()
    return this.hasVideo
  }

  applyConstraints = async (kind) => {
    // Chrome seems to crop the window which makes this pretty useless atm
    //if (this.tracks.has(kind)) {
    //  await this._applyTrackConstraints(this.tracks.get(kind)?.clone()!);
    //}
  }

  _applyTrackConstraints = async (track) => {
    track.applyConstraints(this._getConstraints(track.kind))
      .then(() => this._addTrack(track))
      .catch((err) => {
        logger.error("Error applying constraints: ", err)
      })
  }

  _getConstraints = (kind) => {
    return getScreenShareContraints(kind);
  }

  shutdown = () => {
    this._tracks.forEach(track => this.removeTrackKind(track.kind))
  }
}

export const getScreenShareContraints = (kind) => {
  let constraints = {};

  if (kind == 'video') {
    const quality = SCREENSHARE_SIZES[OTGlobals.localUserSettings.screenshareQuality!];
    constraints = {
      displaySurface: 'monitor',
      logicalSurface: true,
      cursor: true,
      width: { max: quality?.width || 1920 },
      height: { max: quality?.height || 1080 },
      frameRate: { max: 5 }
    }
  }
  return constraints;
}

type TInputKind = 'audioinput' | 'videoinput'
export class MediaDeviceManagerClass {
  @observable devices: { [kind: string]: MediaDeviceInfo[] } = { audioinput: [], videoinput: [], audiooutput: [] };
  _deviceHash: number = 0;
  hasAudio: boolean = false
  hasVideo: boolean = false
  hasOutput: boolean = false
  videoConstraints = {
    aspectRatio: 4 / 3.,
    frameRate: { ideal: 15 }
  }

  audioConstraints = {
    echoCancellation: true,
  }
  _stateLock = new AwaitLock();

  constructor() {
    makeObservable(this)
    this.updateMediaDevices()
  }

  updateMediaDevices = async (ev?: Event) => {
    await this._stateLock.acquireAsync()
    logger.info("updateMediaDevices")
    try {
      const devices = await this.getMediaDevices()
      if (hashObject(devices) != this._deviceHash) {
        runInAction(() => {
          this._deviceHash = hashObject(devices);
          this.devices = devices;
          this.hasAudio = this.devices.audioinput.length > 0
          this.hasVideo = this.devices.videoinput.length > 0
          this.hasOutput = this.devices.audiooutput.length > 0
          if (!ev && navigator.mediaDevices) {
            logger.info("setting up device change listener")

            try {
              navigator.mediaDevices.addEventListener?.('devicechange', this.updateMediaDevices);
              logger.debug("Supported constraints", navigator.mediaDevices.getSupportedConstraints());
            } catch {

            }

          }
        })
        const availableAudioDeviceId = OTGlobals.localUserSettings["audio"]?.deviceId
        const availableVideoDeviceId = OTGlobals.localUserSettings["video"]?.deviceId
        const availableOutputDeviceId = OTGlobals.localUserSettings["output"]?.deviceId
        writeDeviceSettings('audioinput', this.devices['audioinput'], availableAudioDeviceId, availableAudioDeviceId)
        writeDeviceSettings('videoinput', this.devices['videoinput'], availableVideoDeviceId, availableVideoDeviceId)
        writeDeviceSettings('audiooutput', this.devices['audiooutput'], availableOutputDeviceId, availableOutputDeviceId)
        writeDeviceSettings('cameraQuality', VIDEO_SIZES, OTGlobals.localUserSettings.cameraQuality, OTGlobals.localUserSettings.cameraQuality)
        writeDeviceSettings('screenshareQuality', SCREENSHARE_SIZES, OTGlobals.localUserSettings.screenshareQuality, OTGlobals.localUserSettings.screenshareQuality)

      } else {
        logger.debug("Devices unchanged")
      }
    } finally {
      this._stateLock.release();
      logger.debug("getMediaDevices lock released....")
    }
  }

  getMediaDevices = async () => {
    logger.info("getMediaDevices")
    const groupedDevices: { [kind: string]: MediaDeviceInfo[] } = {
      audioinput: [], videoinput: [], audiooutput: []
    }

    if (navigator.mediaDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      logger.debug("enumerateDevices available", devices)

      devices.forEach((device) => {
        if (groupedDevices[device.kind]) {
          groupedDevices[device.kind].push(device)
        }
      })
    }
    logger.debug("devices available", groupedDevices)

    return groupedDevices;
  }

  _updateDeviceSettings = async () => {

    const findDevice = (devices: MediaDeviceInfo[], deviceId: string, groupId?: string) => {
      let device = devices.find(d => d.deviceId === deviceId && (!groupId || d.groupId === groupId));
      if (!device) {
        device = devices.find(d => d.deviceId === "default")
      }
      return device ?? {deviceId: "default"} as MediaDeviceInfo;
    }

    await this._stateLock.acquireAsync()
    try {
      // audio
      var { deviceId, groupId } = OTGlobals.localUserSettings["audio"] ?? {}
      const availableAudioDevice = findDevice(this.devices['audioinput'], deviceId, groupId)

      // video
      const videoDeviceId = OTGlobals.localUserSettings["video"]?.deviceId;
      const availableVideoDevice = findDevice(this.devices['videoinput'], videoDeviceId)

      // speakers
      var { deviceId, groupId } = OTGlobals.localUserSettings["audiooutput"] ?? {}
      const availableOutputDevice = findDevice(this.devices['audiooutput'], deviceId, groupId)

      runInAction(() => {
        const {audio, video, audiooutput } = OTGlobals.mediaDevices;

        if (!audio || audio.deviceId != availableAudioDevice.deviceId || audio.groupId != availableAudioDevice.groupId) {
          logger.info(`setting audio device to ${availableAudioDevice.deviceId}`);
          OTGlobals.mediaDevices.audio = { deviceId: availableAudioDevice.deviceId, groupId: availableAudioDevice.groupId}
        }

        if (video?.deviceId != availableVideoDevice.deviceId) {
         logger.info(`requested videoDeviceId ${videoDeviceId}, setting ${availableVideoDevice.deviceId}`);
          OTGlobals.mediaDevices.video = { deviceId: availableVideoDevice.deviceId }
        }

        if (audiooutput?.deviceId != availableOutputDevice.deviceId || audiooutput?.groupId != availableOutputDevice.groupId) {
          logger.info(`setting speaker device to ${availableOutputDevice.deviceId}, wanted ${audiooutput?.deviceId}`);
          OTGlobals.mediaDevices.audiooutput = { deviceId: availableOutputDevice.deviceId, groupId: availableOutputDevice.groupId }
        }
      })
      writeDeviceSettings('audioinput', this.devices['audioinput'], availableAudioDevice.deviceId, availableAudioDevice.deviceId)
      writeDeviceSettings('videoinput', this.devices['videoinput'], availableVideoDevice.deviceId, availableVideoDevice.deviceId)
      writeDeviceSettings('audiooutput', this.devices['audiooutput'], availableOutputDevice.deviceId, availableOutputDevice.deviceId)
      writeDeviceSettings('cameraQuality', VIDEO_SIZES, OTGlobals.localUserSettings.cameraQuality, OTGlobals.localUserSettings.cameraQuality)
      writeDeviceSettings('screenshareQuality', SCREENSHARE_SIZES, OTGlobals.localUserSettings.screenshareQuality, OTGlobals.localUserSettings.screenshareQuality)
    } finally {
      this._stateLock.release();
      logger.debug("_userDeviceSettingsReaction lock released....")
    }
  }

  _userDeviceSettingsAutoRun = reaction(
    () => {
      var vals = [
        OTGlobals.localUserSettings.videoSimulcastEnabled,
        OTGlobals.localUserSettings.screenshareQuality,
        OTGlobals.localUserSettings.cameraQuality,
        OTGlobals.localUserSettings.audio,
        OTGlobals.localUserSettings.video,
        OTGlobals.localUserSettings.audiooutput,
        this.devices,
      ];
      return JSON.stringify(vals)
    },
    this._updateDeviceSettings
  )

  getTrack = async (kind: string, settings?: MediaDeviceSettings, isRetry: boolean=false): Promise<MediaStreamTrack> => {
    const video =
      kind == "video"
        ? OTUserInterface.platformUtils.PlatformOS == "mobile"
          ? {
              width: 480,
              height: 480,
              aspectRatio: 1,
              frameRate: { max: 24 },
            }
          : {
              ...VIDEO_SIZES[OTGlobals.localUserSettings.cameraQuality],
              ...this.videoConstraints,
              ...remapVideoSettings(settings),
            }
        : false;

    const audio = kind == "audio" ? { ...this.audioConstraints, ...settings } : false;

    try {
      let stream = await navigator.mediaDevices.getUserMedia({ video, audio });
      let track = stream.getTracks()[0];

      if (audio && audio.deviceId === "default" && audio.groupId !== track.getSettings().groupId) {
        logger.info("Chrome gave us the wrong 'default', retrying with deviceId")
        const realDevice = this.devices['audioinput'].find(d => d.groupId === audio.groupId && d.deviceId !== "default")
        stream = await navigator.mediaDevices.getUserMedia({ video, audio: {...audio, deviceId: realDevice?.deviceId} });
        track = stream.getTracks()[0] ?? track;
      }

      try {
        video && logger.debug("Requested video track with", video, "got", track.getSettings());
        audio && logger.debug("Requested audio track with", audio, "got", track.getSettings());
      } catch {}

      return track;

    } catch (err) {
      if (!isRetry && settings?.deviceId !== "default") {
        logger.info(`Failed to get media device, retrying default device`);
        return this.getTrack(kind, {...settings, deviceId: "default" }, true)
      }

      logger.error(`Failed to get media device ${err}, settings:`, { video, audio }, err);
      throw err;
    }
  };
}

interface MediaDeviceSettings extends MediaTrackConstraints {
  deviceId?: any;
  groupId?: string;
  videoSize?: 'hvga' | 'vga' | 'hd'
}

function remapVideoSettings (settings) {
  var obj = {}
  if (settings) {
    Object.keys(settings).forEach(key => {
      if (key == 'videoSize') {
        obj = { ...obj, ...VIDEO_SIZES[settings[key]] }
        console.log("videoSize vals", VIDEO_SIZES[settings[key]])
      } else {
        obj[key] = settings[key]
      }
    })
  }

  return obj;
}

export const VIDEO_SIZES =
{
  //  qvga: { width: null, height: 240 },
  hvga: {
    label: "Low Quality (360p)",
    width: null,
    height: 360
  },
  vga: {
    label: "Medium Quality (480p)",
    width: null,
    height: 480
  },
  hd: {
    label: "High Quality (720p)",
    width: null,
    height: 720
  }
};


export const SCREENSHARE_SIZES: { [id: string]: IQualityContraint } = {
  "720p": {
    label: "HD 720p",
    comment: "For slower computers",
    width: 1280,
    height: 720
  },
  "900p": {
    label: "HD+ 900p",
    comment: "Recommended",
    width: 1600,
    height: 900
  },
  "1080p": {
    label: "FullHD 1080p",
    comment: "For faster computers",
    width: 1920,
    height: 1080
  },
  'UHD': {
    label: "UHD 2160p",
    comment: "Best quality, for fast computers",
    width: 3840,
    height: 2160
  }
}


export var MediaDeviceManager = new MediaDeviceManagerClass()
