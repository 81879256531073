import { animated, useTransition } from '@react-spring/web';
import { observer } from 'mobx-react';
import React, { CSSProperties, useState } from 'react';
import CallTileStyles from './CallTileStyles';

type Props = {
  tileHovered: boolean;
  style?: CSSProperties;
};

const HoverShade: React.FC<Props> = ({ tileHovered, style, children }) => {

  const tx = useTransition(tileHovered ? [true] : [], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 }
  });

  return (
    tx(({ opacity }) => (
      <animated.div
        style={{
          ...CallTileStyles.absolute,
          ...Styles.shade,
          ...style,
          opacity: opacity
        }}
      >
        {children}
      </animated.div>
    ))
  );
};

export default observer(HoverShade);

const Styles: Record<string, CSSProperties> = {
  shade: {
    flexDirection: 'row',
    transition: 'opacity-color .15s ease-in-out',
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
};
