import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState, useRef } from "react";
import { useCallback } from 'react';
import { DSImage } from '../DesignSystem/DSImage';
import { PanelError, PanelLoading } from './Panels';
import * as Analytics from "../Util/Analytics";
import { Body, Panel, H1, H2, Button, Row, H3, useTheme } from "@openteam/design-system";


type Props = {
  inviteToken: string | null
  onAccepted: () => void
};

const AcceptInvite: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inviteDetails, setInviteDetails] = useState<any>()
  const theme = useTheme()

  const acceptInvite = useCallback(async () => {
    console.log("acceptInvite inviteToken", props.inviteToken)
    setLoading(true)

    const functions = getFunctions();

    const joinWithInviteToken = httpsCallable(functions, 'joinWithInviteToken');
    const result: any = await joinWithInviteToken({
      inviteToken: props.inviteToken,
    })

    console.log("loadInvite answer", result)

    if (!result.data.success) {
      Analytics.logError("INVITE_FAILED")
      setError(true)
    }

    setInviteDetails(result.data)
    setLoading(false)
  }, [props.inviteToken])

  useEffect(() => {
    Analytics.setCurrentScreen("AcceptInvitePage");
    acceptInvite()
  }, [acceptInvite])

  const imageUrl = inviteDetails?.imageUrl
  const teamName = inviteDetails?.teamName

  return loading ? (
    <PanelLoading text="Joining workspace..." />
  ) : error ? (
    <PanelError>
      <Body style={{ marginBottom: 24, textAlign: "center" }}>
        {inviteDetails ? "Error accepting invite" : "Error loading invite"}
      </Body>
    </PanelError>
  ) : (
    <Panel style={{ margin: 20, alignItems: "center" }}>
      {
        imageUrl ?
          <DSImage
            key={`teamimage`}
            url={imageUrl}
            maxWidth={80}
            maxHeight={80}
            style={{
              marginTop: 5,
              marginBottom: 30
            }}
          />
          :
          null
      }
      <H1 wrap style={{ marginBottom: 40, fontWeight: 700, fontSize: 32, textAlign: "center", maxWidth: 500 }}>
        Invite accepted!
      </H1>
      <H2 wrap style={{ marginBottom: 40, fontSize: 18, textAlign: "center" }}>
        Congratulations you have joined the <span style={{ color: theme.palette.primary.main}}>{teamName}</span> workspace
      </H2>
      <Button
        onClick={props.onAccepted}
        analyticsEvent="inviteAcceptedNext"
        size="medium"
      >
        Next
      </Button>
    </Panel>
  )
};

export default AcceptInvite;
