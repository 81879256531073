import { action, observable } from "mobx"
import { CSSProperties } from "react";
import { isWindows, isMacOs } from "react-device-detect";

type TTheme = 'DARK' | 'LIGHT'

export let ThemeState: {
  currentTheme: TTheme
} = observable({
  currentTheme:  'LIGHT'
})



interface Theme {
  MainText: string
  MainIcon: string
  SecondaryText: string
  DummyColor: string
  BackgroundColor: string
  BackgroundColorShade: string
  EmphasisColor: string,
  EmphasisBackgroundColor: string,
  SecondaryColor: string,

  DisabledColor: string,
  DisabledBackgroundColor: string,
  InputColor: string
  InputTextColor: string
  InputButtonColor: string
  SystemMessageColor: string
  BadgeColor: string

  BaseBorderRadius: number

  ProgressBarFilled: string
  ProgressBarEmpty: string

  InCallColor: string
  IncomingRequestColor: string

  OnlineColor: string
  AwayColor: string
  BusyColor: string
  OfflineColor: string

  AnswerColor: string
  AnswerHoverColor: string
  CancelColor: string
  DangerColor: string
  SuccessColor: string
  WarningColor: string

  ChatLinkBar: string
  ChatLinkColor: string
  ChatMessageHoverTint: string

  ChatHeaderColor: string,
  ChatBodyColor: string,
  ChatComposeBackground: string
  ChatComposeAltBackground: string
  ChatComposeAssetClose: string
  ChatCallSummaryBackground: string

  DockSize: number
  DockAutoHideSize: number
  DockMinHeight: number
  DockTileMultiplier: number
  DockPodTileMultiplier: number
  DockSpaceTileMultiplier: number
  DockSeparatorHeight: number
  DockSeparatorColor: string
  DockBackgroundColor: string
  DockOtherBackgroundColor: string
  DockIndicatorColor: string
  DockUserTileSpacing: number

  DockCallBackgroundColor: string
  DockCallBorderColor: string

  UserPanelHeight: number
  UserPanelWidth: number
  UserPanelHeightFrig: number
  UserPanelBorderRadius: number
  UserBlankColor: string
  UserInactiveFilter: string

  SpacePanelHeight: number
  SpacePanelWidth: number
  SpacePanelRoomBackground: string
  SpacePanelSectionBackground: string
  SpacePanelInactiveFilter: string

  OngoingCallIconHeight: number
  OngoingCallIconColor: string
  OngoingCallTextColor: string
  CallBackgroundColor: string
  CallBackgroundColor2: string
  CallShadowColor: string
  CallMutedColor: string
  CallIndicatorBackgroundColor: string
  CallResourceOutllineColor: string

  ButtonBackgroundColor: string
  ButtonIconColor: string

  PanelTabColor: string
  PanelHeaderColor: string
  PanelBackgroundColor: string
  PanelContrastColor: string
  PanelDarkerColor: string
  PanelLighterColor: string

  ToolTipBackgroundColor: string
  ToolTipTextColor: string

  BoxDarkBackground: string
  ColorContrastColor: string

  SignalGood: string,
  SignalBG: string,
  SignalPoor: string,
  SignalBad: string,
  Signal3Bar: string,
  Signal2Bar: string,
  Signal1Bar: string,

  IconSize: number

  PodColors: string[]
  PodBackgrounds: Array<[string, string]>;
  UserPanelColor: string
  UserPanelBackground: (string | number)[]
  CurrentCallColor: string
  CurrentCallBackground: (string | number)[]
  NoDrag: any

  TutorialBackgroundColor: string
}

const generateLinearGradient = (color1: string, pct1: number, color2: string, pct2: number, isReverse: boolean) =>
  !isReverse ?
    `linear-gradient(90deg, ${color1} ${pct1}%, ${color2} ${pct2}%)` :
    `linear-gradient(90deg, ${color2} ${pct1}%, ${color1} ${pct2}%)`;

const genGradients = (color1: string, pct1: number, color2: string, pct2: number): [string, string] =>
  [
    generateLinearGradient(color1, pct1, color2, pct2, false),
    generateLinearGradient(color1, pct1, color2, pct2, true)
  ];

const podBackgrounds: Array<[string, number, string, number]> = [
  ["#E8C044", 0, "#F4D982", 100],
  ["#FF7E5F", 0, "#FEB09D", 100],
  ["#F08D71", 0, "#EAC2B7", 100],
  ["#9B7B7B", 0, "#BE8989", 100],
  ["#EB5757", 0, "#BE8989", 100],
  ["#E98BA9", 0, "#DD5E89", 100],
  ["#FFB9CA", 0, "#FF8BAB", 100],
  ["#CEB5EF", 0, "#9796F0", 100],
  ["#5C80A1", 0, "#185A9D", 100],
  ["#395764", 0, "#588A9F", 100],
  ["#3C98F5", 0, "#56CCF2", 100],
  ["#66A6FF", 0, "#B6DAFF", 100],
  ["#83C2BA", 0, "#94DFDA", 100],
  ["#428A7B", 0, "#6F9383", 100],
  ["#137652", 0, "#718679", 100],
];

const BaseTheme: Theme = {
  MainText: 'white',
  MainIcon: 'white',
  SecondaryText: 'rgba(209, 216, 220, 1)',
  DummyColor: '#C4C4C4',
  BackgroundColor: 'rgba(35, 41, 43, 1)',
  BackgroundColorShade: 'rgba(35, 41, 43, 0.9)',

  EmphasisColor: 'rgba(255, 159, 0, 1)',
  EmphasisBackgroundColor: 'rgba(255, 159, 0, 0.5)',

  SecondaryColor: '#5141C7',

  DisabledColor: 'rgba(209, 216, 220, 1)',
  DisabledBackgroundColor: 'rgba(209, 216, 220, 0.5)',

  InputColor: "white",
  InputTextColor: "black",
  InputButtonColor: "rgba(46, 51, 54, 1)",

  SystemMessageColor: "green",
  BadgeColor: "#FF0A00",

  BaseBorderRadius: 4,

  ProgressBarFilled: "#FF9F00",
  ProgressBarEmpty: "#BBB9CC",

  InCallColor: '#FF0A00',
  IncomingRequestColor: '#F7D155',

  OnlineColor: "#84CC84",
  AwayColor: "#F7D155",
  BusyColor: "#EC5D5D",
  OfflineColor: "rgba(209, 216, 220, 1)",

  AnswerColor: "#689D72",
  AnswerHoverColor: "#809828",
  CancelColor: "#EA3624",
  DangerColor: "#FF0A00",
  SuccessColor: "#009150",
  WarningColor: "#F7D155",

  ChatLinkBar: "rgba(0, 174, 249, 1)",
  ChatLinkColor: "rgba(0, 174, 249, 1)",
  ChatMessageHoverTint: "rgba(255,255,255,0.05)",

  ChatHeaderColor: "rgba(46, 51, 54, 1)",
  ChatBodyColor: "rgba(35, 41, 43, 1)",
  ChatComposeBackground: "white",
  ChatComposeAltBackground: "rgba(243, 243, 243, 1)",
  ChatComposeAssetClose: "black",
  ChatCallSummaryBackground: "#3B4346",

  DockSize: 80,
  DockAutoHideSize: 6,
  DockMinHeight: 160,
  DockTileMultiplier: 6. / 8.,
  DockPodTileMultiplier: 5.6 / 8.,
  DockSpaceTileMultiplier: 5. / 8.,
  DockUserTileSpacing: 4,

  DockBackgroundColor: 'rgba(34, 52, 62, 1)',
  DockOtherBackgroundColor: '#FF9F00',
  DockIndicatorColor: 'rgba(241, 243, 244, 1)',
  DockSeparatorHeight: 18,
  DockSeparatorColor: 'white',

  DockCallBackgroundColor: "#5141C7",
  DockCallBorderColor: "rgba(255,255,255,1.0)",

  UserPanelHeight: 84,
  UserPanelWidth: 220,
  UserPanelHeightFrig: 8,
  UserPanelBorderRadius: 36,
  UserBlankColor: '#C4C4C4',
  UserInactiveFilter: "brightness(55%)",

  SpacePanelHeight: 800,
  SpacePanelWidth: 1050,
  SpacePanelRoomBackground: '#1B1D21',
  SpacePanelSectionBackground: '#1E2024',
  SpacePanelInactiveFilter: "brightness(60%)",

  OngoingCallIconHeight: 32,
  OngoingCallIconColor: 'white',
  OngoingCallTextColor: 'black',
  CallBackgroundColor: '#23292b', // Shoul be hex so that we can use as window background
  CallBackgroundColor2: "rgba(46, 51, 54, 1)",
  CallShadowColor: 'rgba(35, 41, 43, 0.12)',
  CallMutedColor: "rgba(255, 86, 79, 1)",
  CallIndicatorBackgroundColor: "rgba(237, 242, 247, 1)",
  CallResourceOutllineColor: "rgba(232, 232, 232, 0.13)",

  ButtonBackgroundColor: 'rgba(0,0,0,0.5)',
  ButtonIconColor: 'white',
  PanelHeaderColor: '#2E3336',
  PanelTabColor: '#1C2122',
  PanelBackgroundColor: '#23292B',
  PanelContrastColor: 'white',
  PanelDarkerColor: '#121415',
  PanelLighterColor: 'rgba(255,255,255,0.5)',

  ToolTipBackgroundColor: 'rgba(59, 67, 70, 1)',
  ToolTipTextColor: 'white',

  BoxDarkBackground: '#1B1D21',
  ColorContrastColor: 'white',

  SignalGood: '#84CB84',
  SignalBG: "rgba(237, 242, 247, 1)",
  SignalPoor: "rgba(244, 174, 81, 1)",
  SignalBad: "rgba(255, 86, 79, 1)",
  Signal3Bar: "rgba(244, 174, 81, 1)",
  Signal2Bar: "rgba(255, 86, 79, 1)",
  Signal1Bar: "rgba(255, 86, 79, 1)",

  IconSize: 14,

  PodColors: podBackgrounds.map(([c1, p1, c2, p2]) => c1),
  PodBackgrounds: podBackgrounds.map(([c1, p1, c2, p2]) => genGradients(c1, p1, c2, p2)),

  UserPanelColor: "#1E2024",
  UserPanelBackground: genGradients("#1E2024", 0, "#1E2024", 100),

  CurrentCallColor: "#ACB4E7",
  CurrentCallBackground: genGradients("#ACB4E7", 0, "#B1E0B0", 100),
  NoDrag: { WebkitAppRegion: "no-drag" },


  TutorialBackgroundColor: "#5141C7",
}



const DarkTheme: Theme = {
  ...BaseTheme,
  DockBackgroundColor: 'rgba(35, 41, 43, 1)',
  DockOtherBackgroundColor: '#FF9F00',
  DockIndicatorColor: 'rgba(241, 243, 244, 1)',
}

const LightTheme: Theme = {
  ...BaseTheme,
  MainText: '#333',
  SecondaryText: '#888',
  MainIcon: '#333',
  CallBackgroundColor: '#DCDCDD',
  BackgroundColor: '#F1F3F4',
  ChatHeaderColor: '#F1F3F4',
  ChatCallSummaryBackground: '#DCDCDD',
  DockBackgroundColor: '#F1F3F4',
  DockOtherBackgroundColor: '#FF9F00',
  DockIndicatorColor: 'rgba(34, 52, 62, 1)',
  DockSeparatorColor: 'black',
  ButtonBackgroundColor: '#DCDCDD',
  ButtonIconColor: '#333',

  UserInactiveFilter: "opacity(55%)",
  UserPanelColor: '#F1F3F4',
  UserPanelBackground: genGradients("#F1F3F4", 0, "#F1F3F4", 100),

  PanelHeaderColor: '#DDDDDD',
  PanelBackgroundColor: '#EEEEEF',
  PanelDarkerColor: '#DCDCDD',
  PanelTabColor: '#F7F7F7',
  PanelContrastColor: 'black',

  SpacePanelRoomBackground: '#DCDCDD',
  SpacePanelSectionBackground: '#DCDCDD',
  SpacePanelInactiveFilter: "opacity(60%)",

}
export var DSTheme: Theme = observable({ ...DarkTheme })

export const setTheme = action((theme: TTheme) => {
  if (theme == 'DARK') {

    Object.assign(DSTheme, DarkTheme)
  } else {
    Object.assign(DSTheme, LightTheme)

  }
  ThemeState.currentTheme = theme
  localStorage.setItem('currentTheme', theme)
})

setTheme(ThemeState.currentTheme)
