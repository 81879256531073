import { useSpring, animated } from "@react-spring/web";
import { Logger } from "@openteam/app-util";
import React, { Children, CSSProperties, useEffect, useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { toDeg } from "./degToPos";

const logger = new Logger("CirclePosition");

export interface CirclePositionProps {
  positionDegrees: number;
  offsetFactor?: number;
  position?: "in" | "out" | "on";
  radius?: number
  style?: CSSProperties,
};

export const CirclePosition: React.FC<CirclePositionProps> = ({
  positionDegrees,
  offsetFactor = 1,
  position = "on",
  radius,
  style,
  children
}) => {
  const { ref: innerRef, height: contentSize = 0 } = useResizeObserver();
  const { ref: outerRef, height: containerSize = 0 } = useResizeObserver();

  const [{ angle }] = useSpring(
    {
      to: { angle: positionDegrees },
      config: { mass: 1, tension: 250, friction: 30, precision: 0.1 },
    },
    [positionDegrees]
  );

  //useEffect(() => {
  //  logger.debug(`Content size ${contentSize}, containerSize ${containerSize}`);
  //}, [contentSize, containerSize]);

  return (
    <>
      <div ref={outerRef} style={{ position: "absolute", inset: 0, pointerEvents: "none" }} />
      <animated.div
        style={{
          position: "absolute",
          top: radius ?? "50%",
          right: radius ?? "50%",
          zIndex: 20,
          opacity: containerSize === 0 ? 0 : 1,
          transition: "opacity 0.1s ease-out",
          ...style,
          transform: angle.to((d) => {
            // don't know how to make this work from outside
            let offset = radius ?? containerSize / 2;
            if (position === "out") {
              offset += contentSize / 2;
            } else if (position === "in") {
              offset -= contentSize / 2;
            }
            return `rotate(${d}deg) translateY(-${offset * offsetFactor}px) rotate(-${d}deg)`;
          }),
        }}
      >
        <div
          ref={innerRef}
          style={{ width: "fit-content", height: "fit-content", margin: -(contentSize / 2) }}
        >
          {children}
        </div>
      </animated.div>
    </>
  );
};

export interface ArcPositionProps extends CirclePositionProps {
  itemSize: number
  spacingFactor?: number
}

export const ArcPosition: React.FC<ArcPositionProps> = ({
  positionDegrees,
  offsetFactor = 1,
  position = "on",
  radius: _radius,
  style,
  itemSize,
  spacingFactor = 1.25,
  children
}) => {
  const { ref: outerRef, height: containerSize = 0 } = useResizeObserver();

  const arrayChildren = Children.toArray(children)


  const radius = _radius ?? containerSize / 2;
  const [{ angle }] = useSpring(
    {
      to: { angle: positionDegrees },
      config: { precision: 0.1, clamp: true },
    },
    [positionDegrees]
  );

  return (
    <>
      {!_radius && <div ref={outerRef} style={{ position: "absolute", inset: 0, pointerEvents: "none" }} />}
      {Children.map(arrayChildren, (item, index) => {
        return (
          <animated.div
            key={index}
            style={{
              position: "absolute",
              top: radius ?? "50%",
              right: radius ?? "50%",
              zIndex: 20,
              opacity: radius === 0 ? 0 : 1,
              transition: "opacity 0.1s ease-out",
              ...style,
              transform: angle.to((d) => {
                // don't know how to make this work from outside
                let offset = radius;
                if (position === "out") {
                  offset += itemSize / 2;
                } else if (position === "in") {
                  offset -= itemSize / 2;
                }
                d += index * spacingFactor * 2 * toDeg(Math.atan(itemSize / 2 / radius));
                return `rotate(${d}deg) translateY(-${offset * offsetFactor}px) rotate(-${d}deg)`;
              }),
            }}
          >
            <div style={{ width: "fit-content", height: "fit-content", margin: -(itemSize / 2) }}>
              {item}
            </div>
          </animated.div>
        );
      })}
    </>
  );
}

