import { action, makeObservable, observable } from "mobx";
import {
  ICallDetails,
  ICallDetailsUser,
  IFile,
  IFileAttachment,
  ILinkPreview,
  IMessage,
  IMessageFile,
  IMessageManager,
  IOTChatMessage,
  IPendingMessage,
  IPinnedResource
} from "@openteam/models";
import { AwaitLock, Logger } from "@openteam/app-util";
import { OTGlobals } from "./OTGlobals";
import { CallDetailsDb } from "./fire/CallDetailsDB";
import { throttle } from "throttle-debounce";
import { CloudUpload, md5hash } from ".";
import { getLinkPreview, URLPreview } from "./Chat/LinkPreviewManager";
import removeMD from "remove-markdown";
import { Firestore } from "firebase/firestore";
import { isSafari } from "react-device-detect";

const logger = new Logger("CallMessageManager");

export class CallMessageManager implements IMessageManager {
  /* copied down from ChatManager */
  teamId: string;
  userId: string;
  roomId: string;
  fsDb: Firestore;

  onLinkDetected?: (url: string, linkPreview: URLPreview, parentId?: string, shareWithEveryone?: boolean) => Promise<void>

  fakeId: number = 0;

  @observable crDate?: Date;
  @observable started: boolean = false;

  @observable viewingRoomMsgs: boolean = false;
  @observable draftMessage: string = "";
  @observable draftReplyMessage?: IOTChatMessage;

  @observable draftFiles: IMessageFile[] = [];
  @observable pendingMessages: Record<string, IPendingMessage> = {};

  @observable messages: { [id: string]: IOTChatMessage } = {};

  @observable messageId?: number;
  @observable lastReadMessageId?: number;
  @observable lastReceivedMessageId?: number;

  @observable messageNum?: number;
  @observable messageNumRead?: number;
  @observable numUnread?: number = 0;

  @observable users: Record<string, ICallDetailsUser> = {};

  @observable chatUserIsTyping: Record<
    string,
    { lastTyping: Date; timeoutId: ReturnType<typeof setTimeout> }
  > = {};

  _messageLock = new AwaitLock();

  unsubscribeList: (() => void)[] = []

  constructor(
    fsDb: Firestore,
    teamId: string,
    userId: string,
    roomId: string,
    onLinkDetected?: (url: string, linkPreview: URLPreview) => Promise<void>
  ) {
    makeObservable(this)
    this.fsDb = fsDb;
    this.teamId = teamId;
    this.userId = userId;
    this.roomId = roomId;
    this.onLinkDetected = onLinkDetected;

    this.start()

  }

  start = async () => {

    try {
      logger.debug("start: checking permission")

      await CallDetailsDb.getCallDetails(
        this.fsDb,
        this.teamId,
        this.roomId
      )

      const unsubscribe = await CallDetailsDb.watchCallDetails(
        this.fsDb,
        this.teamId,
        this.roomId,
        this.handleCallDetails
      )

      this.unsubscribeList.push(unsubscribe)

    } catch (err) {
      logger.error("error in start, retrying in 1s", err)
      setTimeout(this.start, 1000)
    }

  }

  stop = async () => {

    this.unsubscribeList.map(x => x());

  }

  @action
  handleCallDetails = (doc: ICallDetails) => {
    logger.debug("handleCallDetails", doc)

    if (this.userId in doc.users) {
      if (!this.started) {
        this.started = true

        const unsubscribe = CallDetailsDb.watchCallMessages(
          this.fsDb,
          this.teamId,
          this.roomId,
          this.handleWatchMessages
        );
        this.unsubscribeList.push(unsubscribe)
      }

      this.crDate = doc.crDate
      this.users = doc.users
      this.messageId = doc.messageId
      this.lastReadMessageId = doc.users[this.userId].messageId || undefined;

      this.messageNum = doc.messageId
      this.messageNumRead = doc.users[this.userId].messageNum || undefined;

      this.numUnread = Math.max(0, this.messageNum - (this.messageNumRead || 0))

      this.calculateIsTyping(doc.users);
    }
  }

  setViewingRoomMsgs = (isViewing: boolean) => {
    if (isViewing) {
      this.numUnread = 0;
    }
    this.viewingRoomMsgs = isViewing;
  };

  @action
  handleWatchMessages = (added: IMessage[], edited: IMessage[], deleted: string[]) => {
    for (let message of added) {
      this.handleDoc(message);
    }
    for (let message of edited) {
      this.handleDoc(message, true);
    }

    for (let messageId of deleted) {
      this.deleteDoc(messageId);
    }

  };


  handleDoc = (doc: IMessage, hasChanged: boolean = false) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const teamUser = teamData.getTeamUser(doc.userId);

    if (hasChanged && !(doc.id in this.messages)) {
      logger.debug("got a change for a message I don't currently have, ignoring");
      return;
    }

    const otmessage: IOTChatMessage = {
      ...doc,
      name: teamUser.name,
      userImageUrl: teamUser.imageUrl || null,
    };

    this.messages[doc.id] = otmessage;

    if (!hasChanged) {
      if (
        doc.userId == this.userId &&
        !doc.isSystem &&
        (this.lastReadMessageId || 0) < doc.messageId
      ) {
        this.lastReadMessageId = doc.messageId;
      }
      if ((this.lastReceivedMessageId || 0) < doc.messageId) {
        this.lastReceivedMessageId = doc.messageId;
      }
    }
  };

  deleteDoc = (messageId) => {
    logger.debug("deleteDoc", messageId);

    delete this.messages[messageId];
  };


  @action
  setDraftText = (text: string) => {
    this.draftMessage = text;

    if (text) {
      // this.setIsTyping(true);
      this.getDraftLinkPreviews(text)
    }

  };

  getLinkPreviews = (text: string) => {
    const urls = this.getTextURLs(text)

    return urls.map(url => getLinkPreview(url))
  }

  getDraftLinkPreviews = throttle(500, true, this.getLinkPreviews)


  getTextURLs = (text: string) => {
    if (isSafari) {
      return []
    } else {
      const urlRegex = "(?<=[^\[])((https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])";
      const matches = [...text.matchAll(RegExp(urlRegex, "gi"))].map(element => element[0]);
      logger.debug("getLinkPreviews, matches", matches)
      return matches
    }
  }


  @action
  addDraftMessageFile = (messageFile: IMessageFile) => {
    this.draftFiles.push(messageFile)
  }

  @action
  removeDraftMessageFile = (index) => {
    var newDraftFiles = [...this.draftFiles];
    var uploadFiles = newDraftFiles.splice(index, 1);

    uploadFiles.forEach((uf) => uf.stop());

    this.draftFiles = newDraftFiles;

  }
  @action

  addDraftFiles = (files: FileList | File[] | IFile[] | null) => {
    if (!files) {
      return;
    }


    Object.keys(files || {}).forEach((i) => {
      let file = files[i];

      this.addDraftMessageFile(new CloudUpload(this.teamId, undefined, this.userId, "chat", file))

    });

  };

  @action
  setDraftFiles = (draftFiles) => {
    this.draftFiles = draftFiles;
  };

  @action
  setDraftReplyMessage = (message?: IOTChatMessage) => {
    this.draftReplyMessage = message;
  };

  @action
  deleteDraftReplyMessage = () => {
    this.draftReplyMessage = undefined;

  };

  resetDraft = () => {
    this.deleteDraftReplyMessage()
    this.setDraftFiles([])
    this.setDraftText('')

  };

  sendResource = async (resource: IPinnedResource) => {

    const attachments: Record<string, IFileAttachment> = {};
    const linkPreviews: Record<string, ILinkPreview> = {};

    if (resource.recordType === 'attachment') {
      attachments[resource.fileId!] = {
        name: resource.name,
        type: resource.type!,
        size: resource.size!,
        url: resource.url,
        uploaded: true,
        progress: 100,
        order: 0,
      }
    } else {
      linkPreviews[resource.linkId!] = {
        title: resource.name,
        description: resource.description!,
        ...(resource.mediaType ? { mediaType: resource.mediaType} : {}),
        ...(resource.contentType ? { mediaType: resource.contentType} : {}),
        image: resource.image!,
        favicon: resource.favicon!,
        favicons: resource.favicons,
        url: resource.url
      }
    }

    const id = await CallDetailsDb.addChatMessage(
      this.fsDb,
      this.teamId,
      this.userId,
      this.roomId,
      "",
      attachments,
      undefined,
      undefined,
      linkPreviews
    );
    return id;
  }

  sendChatMessage = async (
    text: string,
    files: IMessageFile[],
    replyMessage?: IOTChatMessage,
    parentId?: string,
    shareWithEveryone?: boolean
  ) => {


    const urlPreviews = this.getLinkPreviews(text)

    const linkPreviews: Record<string, ILinkPreview> = {}
    const loadingPreviews: URLPreview[] = []
    let firstPreview: ILinkPreview | undefined = undefined

    for (let preview of urlPreviews) {

      await this.onLinkDetected?.(preview.url, preview, parentId, shareWithEveryone)

      if (preview.loaded && preview.preview) {
        linkPreviews[md5hash(preview.url)] = preview.preview
        if (!firstPreview) {
          firstPreview = preview.preview
        }
      } else {
        loadingPreviews.push(preview)
      }
    }

    for (var i = 0; i < files.length; i++)
      files[i].index = i;

    const filesUploaded = files.every(file => file.completed)
    logger.debug("filesUploaded", filesUploaded, "files", filesUploaded)
    const attachments: Record<string, IFileAttachment> = {}

    for (const file of files) {

      attachments[file.id] = {
        name: file.file.name,
        type: file.file.type,
        size: file.file.size,
        uploaded: file.completed,
        url: file.downloadUrl || null,
        progress: file.progress,
        order: file.index!,
      }
    }

    const id = await CallDetailsDb.addChatMessage(
      this.fsDb,
      this.teamId,
      this.userId,
      this.roomId,
      text,
      attachments,
      replyMessage,
      firstPreview,
      linkPreviews
    );


    if (!filesUploaded) {
      const attachmentFiles: Record<string, IMessageFile> = {}

      for (const file of files) {
        attachmentFiles[file.id] = file
        this.updatePendingFile(this.roomId, id, file)
      }

      const message: IPendingMessage = {
        text,
        attachmentFiles: attachmentFiles,
      };
      this.pendingMessages[id] = message;
    }

    if (loadingPreviews.length) {
      this.updateLinkPreviews(id, loadingPreviews, !firstPreview)
    }

    if (!filesUploaded && files) {
      try {
        await Promise.all(files.map((cu) => cu.complete()));
        logger.debug("all files uploaded saving")

        delete this.pendingMessages[id];
      }
      catch {
        logger.error("failed uploading all files")

      }
    }
    return id;
  };

  updatePendingFile = async (callId: string, id: string, file: IMessageFile) => {
    file.on?.("progress", throttle(2000, async () => {
      await CallDetailsDb.updateChatMessagePendingFile(
        this.fsDb,
        this.teamId,
        callId,
        id,
        file
      );
    }))

    try {
      await file.complete()
    }
    catch {
      logger.error("failed uploading all files")

    }

    await CallDetailsDb.updateChatMessagePendingFile(
      this.fsDb,
      this.teamId,
      callId,
      id,
      file
    );

  }

  updateLinkPreviews = async (id: string, loadingPreviews: URLPreview[], addLinkPreview: boolean) => {
    logger.debug("got more link previews to get", loadingPreviews)
    await Promise.all(loadingPreviews.map((cu) => cu.loader));

    const linkPreviews: Record<string, ILinkPreview> = {}
    let firstPreview: ILinkPreview | undefined = undefined

    for (let preview of loadingPreviews) {
      if (preview.loaded && preview.preview) {
        linkPreviews["linkPreviews." + md5hash(preview.url)] = preview.preview
        if (addLinkPreview && !firstPreview) {
          firstPreview = preview.preview
          linkPreviews["linkPreview"] = preview.preview
        }
      }
    }
    logger.debug("saving them", linkPreviews)

    await CallDetailsDb.updateChatMessageLinkPreviews(
      this.fsDb,
      this.teamId,
      this.roomId,
      id,
      linkPreviews
    );
  }

  saveLinkPreviewToChat = async (id: string, linkId?: string) => {
    await CallDetailsDb.saveLinkPreviewToChat(
      this.fsDb,
      this.teamId,
      this.roomId,
      id,
      linkId,
    );
  }

  saveAttachmentToChat = async (id: string, fileId: string) => {
    await CallDetailsDb.saveAttachmentToChat(
      this.fsDb,
      this.teamId,
      this.roomId,
      id,
      fileId,
    );
  }

  editChatMessage = async (messageId: string, text) => {
    await CallDetailsDb.editChatMessage(this.fsDb, this.teamId, this.roomId, messageId, text);
  };

  deleteChatMessage = async (messageId: string) => {
    await CallDetailsDb.deleteChatMessage(this.fsDb, this.teamId, this.roomId, messageId);
  };


  markChatRead(messageId?: number, messageNum?: number) {
    if (this.started)
      CallDetailsDb.markChatRead(
        this.fsDb,
        this.teamId,
        this.userId,
        this.roomId,
        messageNum || this.messageNum || 0,
        messageId || this.messageId || 0
      );
  }


  setIsTyping = throttle(1000, true, async (isTyping: boolean) => {
    await this._messageLock.acquireAsync();

    try {
      logger.debug("setIsTyping", this.teamId, this.roomId, isTyping);
      await CallDetailsDb.setIsTyping(this.fsDb, this.teamId, this.userId, this.roomId, isTyping);
    } catch (e) {
      logger.error("failed to set IsTyping", e);
    } finally {
      this._messageLock.release();
    }
  })


  calculateIsTyping = (users: Record<string, ICallDetailsUser>) => {

    for (let userId of Object.keys(users)) {
      if (userId == this.userId) {
        continue;
      }

      if (
        users[userId].lastTyping &&
        Date.now() - users[userId].lastTyping?.getTime()! < 5000
      ) {
        if (
          users[userId].lastTyping?.getTime() !=
          this.chatUserIsTyping[userId]?.lastTyping.getTime()
        ) {
          const age = Date.now() - users[userId].lastTyping?.getTime()!;

          if (this.chatUserIsTyping[userId]) {
            clearTimeout(this.chatUserIsTyping[userId].timeoutId);
          }

          const timeoutId = setTimeout(() => {
            delete this.chatUserIsTyping[userId];
          }, 5000 - age);

          this.chatUserIsTyping[userId] = {
            timeoutId,
            lastTyping: users[userId].lastTyping!,
          };
        }
      } else if (this.chatUserIsTyping[userId]) {
        clearTimeout(this.chatUserIsTyping[userId].timeoutId);
        delete this.chatUserIsTyping[userId];
      }
    }

  };


}
