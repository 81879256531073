import { Body, Button, CircularProgress, Column, H3, HSpacer, IconActionButton, IconButton, Icons, lighten, Print, Row, SmallPrint, Spinner, useTheme } from '@openteam/design-system';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Title from '../Components/Title';
import { LoginState } from '../Controllers/UIState';
import { EmailField } from './EmailField';
import { FireDb } from '@openteam/app-core';
import Separator from './Separator';
import { action } from 'mobx';
import { Logger } from '@openteam/app-util';
import * as Analytics from "../Util/Analytics";

const logger = new Logger("InviteUsers")

const InviteUsers: React.FC = () => {
  const teamId = Object.keys(LoginState.user?.teams ?? {})[0]
  const teamName = LoginState.teamName ?? LoginState.user?.teams?.[teamId];
  const [inviteLink, setInviteLink] = useState()
  const [linkCopied, setLinkCopied] = useState(false);
  const [sending, setSending] = useState(false)

  const [emails, setEmails] = useState<EmailState[]>([{ email: '' }, { email: '' }, { email: '' }]);
  const validEmails = emails.filter((e) => (e.valid && e.email?.length));
  const mounted = useRef(false);

  const theme = useTheme()

  useEffect(() => {
    mounted.current = true;
    Analytics.setCurrentScreen("InviteUsersPage");

    (async () => {
      const url = await FireDb.getInviteLink(teamId);
      if (mounted.current) {
        setInviteLink(url);
      }
    })()

    return () => {mounted.current = false}
  }, [])

  const copyLink = useCallback(() => {
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink).then(() => setLinkCopied(true))
    }
  }, [inviteLink])

  const emailUpdated = useCallback((index, email) => {
    setEmails(emails => {
      emails.splice(index, 1, { email, valid: false })
      return [...emails]
    })
  }, [])

  const emailValid = useCallback((index, valid) => {
    setEmails(emails => {
      const val = emails[index]
      emails.splice(index, 1, { ...val, valid })
      return [...emails]
    })
  }, [])

  const addEntry = () => {
    setEmails([...emails, { email: '' }])
  }

  const next = useCallback(action(() => {
    LoginState.needsInvite = false;
  }), [])

  const sendInvites = useCallback(async () => {
    setSending(true)
    logger.debug(`Inviting...`, validEmails)
    await Promise.all(validEmails.map(e => FireDb.inviteUser(teamId, e.email)));
    setSending(false)
    next()
  }, [teamId, validEmails])

  useEffect(() => {
    logger.debug(`emails now:`, emails)
  }, [emails])

  return (
    <Column spacing={24} style={{maxWidth: 300}}>
      <Title>
        Setup your team!
      </Title>
        <Body>
          Invite your teammates to join <b>{teamName}</b>
        </Body>

      <Column spacing={12}>
        <Row style={{alignItems: 'center', justifyContent: 'space-between'}} spacing={8}>
          <Button
            fullWidth
            color="secondary"
            disabled={!inviteLink}
            startIcon={inviteLink ? Icons.copy : <CircularProgress size={14} />}
            onClick={copyLink}
          >
            {inviteLink ? "Copy Invite Link!" : "Generating Link"}
          </Button>
        </Row>
        <SmallPrint style={{fontWeight: 500, opacity: 0.6}} wrap>
          Share this link with your teammates via email, it is valid for 48 hours.
        </SmallPrint>
        <Row style={{justifyContent: 'flex-end', alignItems: 'center'}}>
          {linkCopied ? (
            <>
              <Print>Link Copied</Print>
              <HSpacer size={8} />
              <Button onClick={next}>Next</Button>
            </>
          ): null
          }
        </Row>
      </Column>
      <Row spacing={25}>
        <Separator />
        <Print>Or</Print>
        <Separator />
      </Row>
      <Column spacing={12}>
      <H3>Invite by email</H3>
        {
          emails.map((emailState, index, arr) => (
            <EmailField
              key={`email-${index}`}
              onChange={(e) => emailUpdated(index, e.target.value)}
              onChangeValid={(valid) => emailValid(index, valid)}
              onEnter={() => index === arr.length - 1 && addEntry()}
            />
          ))
        }
        <Row style={{justifyContent: 'center'}}>
          <IconButton
            color='secondary'
            onClick={addEntry}
            disabled={validEmails.length != emails.length}
          >{Icons.create}</IconButton>
        </Row>
        <Row style={{justifyContent: 'space-between'}}>
          <Button variant="text" color="secondary" onClick={next}>Do this later</Button>
          <Button startIcon={sending ? <CircularProgress size={14} color='secondary' /> : null}  disabled={!validEmails && !sending} onClick={sendInvites}>Send Invites</Button>
        </Row>
      </Column>

    </Column>
  )
}

type EmailState = {
  email: string
  valid?: boolean
}

const validationConstraints = {
  emailField: {
    email: {
      message: "Please enter a valid email address"
    }
  },
};

export default observer(InviteUsers);
