import React, { Component, useEffect, useState, useRef } from 'react';

import * as Analytics from '../../Util/Analytics'

import { MediaDeviceManager, OTGlobals, OTUITree, WebcamStream } from "@openteam/app-core";

import { Logger } from '@openteam/app-util';
import { DSImage } from '../../DesignSystem/DSImage';
import { DSTheme } from '../../DesignSystem/DSTheme';
import { CallVideo } from '../Call/CallVideo';
import { DSAccept, DSAnswerCall, DSReject, DSRejectCall, DSSelfieButton } from '../../DesignSystem/DSIconButtons';
import { Column, H1, HSpacer, Panel, Print, Row, Spinner } from '@openteam/design-system';

const logger = new Logger("Selfie")

interface ISelfieProps {
  size: number
  onClose: (image?: Blob) => void
}

export const SelfieCam = (props: ISelfieProps) => {
  const _countdownSecs = 3;
  // @ts-ignore
  const imageCapture = useRef<ImageCapture>();
  const countdownTimer = useRef<ReturnType<typeof setTimeout>>();
  const [capturing, setCapturing] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>();
  const videoTrack = useRef<MediaStreamTrack | undefined>(undefined);
  const [streamVersion, setStreamVersion] = useState<number>(0);

  const [image, setImage] = useState<Blob>();
  const mounted = useRef<boolean>(false);
  // @ts-ignore

  useEffect(() => {
    mounted.current = true;
    initCamera()
    // return cancel
    return () => { mounted.current = false; cancel(); }
  }, [])

  const initCamera = async () => {
    logger.debug("init camera")

    if (mounted.current) {
      videoTrack.current = await MediaDeviceManager.getTrack('video', { width: 360, height: 360, aspectRatio: 1 })
      // @ts-ignore
      imageCapture.current = new ImageCapture(videoTrack.current);
      imageCapture.current.takePhoto()

      logger.debug("setting stream")

      setStreamVersion(streamVersion + 1);

    } else {
      logger.debug("shutting down new stream");
    }
  }

  const stop = () => {
    logger.debug("stopping")

    if (videoTrack.current) {
      logger.debug("shutting down stream");
      videoTrack.current.stop()
      videoTrack.current = undefined;
    }

    if (countdownTimer.current) {
      clearTimeout(countdownTimer.current)
      countdownTimer.current = undefined
    }
    props.onClose(image)
  }

  const cancel = () => {
    stop()
    Analytics.logEvent("selfie_cancelled")
  }

  const startTimer = () => {
    setCountdown(_countdownSecs)
    setImage(undefined)
    countdownTimer.current = setInterval(() => setCountdown(curCountdown => countdownDec(curCountdown)), 1000)

  }

  const countdownDec = (curCountdown) => {
    const newCountdown = curCountdown - 1

    if (newCountdown == 0) {
      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current)
        countdownTimer.current = undefined
      }
      _takePhoto()
    }

    return newCountdown
  }


  const clearPhoto = () => {
    setImage(undefined)
  }

  const _takePhoto = async () => {

    try {

      if (!videoTrack.current || !imageCapture.current) {
        throw Error("No stream")
      }
      setCountdown(undefined)
      setCapturing(true)
      logger.debug("Capturing photo")


      let blob = await imageCapture.current.takePhoto()
      setCapturing(false)
      setImage(blob)

      Analytics.logEvent("selfie_taken")
    } catch (err) {
      logger.warn("Error capturing photo", err)
    }
  }

  return (
    <Panel style={{
      position: 'relative',
      borderRadius: DSTheme.BaseBorderRadius,
      width: props.size,
      height: props.size,
      overflow: 'hidden',
    }}>
      {
        image ?
          <DSImage url={URL.createObjectURL(image)}
            style={{
              borderRadius: DSTheme.BaseBorderRadius,
              overflow: 'hidden',
              objectFit: 'cover',
              width: props.size,
              height: props.size,
            }}
          />
          :
          <Panel style={{
            backgroundColor: 'black',
            borderRadius: DSTheme.BaseBorderRadius,
            overflow: 'hidden',
            position: 'relative',
            height: '100%'
          }}>
            {
              videoTrack.current ?
                <CallVideo
                  version={streamVersion}
                  flip={true}
                  track={videoTrack.current}
                  hasVideo={true}
                  style={{ width: props.size, height: props.size }}
                />
                :
                <Column style={{ margin: 'auto', alignItems: 'center'}} spacing={12}>
                  <Spinner />
                  <Print color='white'>Waiting for camera...</Print>
                </Column>
            }
          </Panel>
      }
      <div
        style={{
          content: '',
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "50%",
          width: props.size, height: props.size,
          boxShadow: "0px 0px 0px 2000px rgba(255,255,255,0.3)"
        }}
      />
      {
        countdown != undefined ?
          <Panel style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {
              capturing ?
                <Spinner size={80} />
                :
                countdown > 0 ?
                  <H1 style={{ color: "rgba(255,255,255,0.9)", fontSize: 80, fontWeight: 'bold' }}>{countdown}</H1>
                  :
                  null
            }
          </Panel>
          :
          null
      }


      <Row style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 12,
        justifyContent: 'center'
      }}>
        {
          image ?
            <Row>
              <DSAccept onClick={stop} />
              <HSpacer size={12} />
              <DSReject onClick={clearPhoto} />
            </Row>
            :
            capturing || countdown ?
              <Spinner />
              : videoTrack.current ?
                <DSSelfieButton
                  onClick={startTimer}
                  style={{ backgroundColor: DSTheme.EmphasisColor }}
                />
                : null
        }
      </Row>
    </Panel>
  )

}
