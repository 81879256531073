
import React, { InputHTMLAttributes, useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { DSTheme } from "./DSTheme";


export interface IDSInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (newValue: string) => string,
  resetOnBlur?: boolean
}

export const DSInput =  React.forwardRef<HTMLInputElement, IDSInputProps>((props, ref) => {
  const { style,  onValueChange, onChange, value: _value, resetOnBlur=false, ...otherProps } = props
  const [value, setValue] = React.useState(_value as string)

  useEffect(() => setValue(_value as string), [_value])

  return (
    <input
      ref={ref}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          if (onValueChange) {
            const newValue = onValueChange(value)
            newValue !== undefined && setValue(newValue)
          }
        }
      }}
      onChange={(ev) => {
        setValue(ev.target.value)
        onChange && onChange(ev)
      }}
      onBlur={()=> {setValue(_value as string)}}
      value={value}
      {...otherProps}
      style={{
        flex: 1,
        // fontFamily: 'Inter, sans-serif',
        fontSize: 14,
        outline: 'none',
        padding: "8 5",
        borderRadius: DSTheme.BaseBorderRadius,
        border: 'none',
        backgroundColor: DSTheme.InputColor,
        color: DSTheme.InputTextColor,
        ...style
      }}
    />

  )
})

interface PropsTextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
}

export const DSTextArea =  React.forwardRef<HTMLTextAreaElement, PropsTextArea>((props, ref) => {
  const { style, ...otherProps } = props
  return (
    <textarea
    ref={ref}
        {...otherProps}
        style={{
          flex: 1,
          // fontFamily: 'Inter, sans-serif',
          fontSize: 14,
          outline: 'none',
          padding: "8 5",
          borderRadius: 8,
          border: 'none',
          backgroundColor: DSTheme.InputColor,
          color: DSTheme.InputTextColor,
          ...style
      }} />

  )
})
interface EditableProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (newValue: string) => void
  onEdit?: (s: boolean) => void
  align?: "left" | "right"
  showIcon?: boolean
  fontSize?: number
}

export const DSEditable = React.forwardRef<HTMLInputElement, EditableProps>((props, ref) => {
  const { style, onValueChange, onEdit, onChange, align, value: _value, showIcon, fontSize=14, ...otherProps } = props
  const [editing, setEditing] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const [value, setValue] = React.useState(_value as string)

  useEffect(() => {
    onEdit && onEdit(editing)
  }, [editing, onEdit])

  return (
    <>
      {(showIcon ?? props.disabled === false) &&
        <FaPen fontSize={fontSize} style={{visibility: hovered || editing ? "visible" : "hidden", marginRight: 5}} />
      }
      <input
        ref={ref}
        onMouseEnter={(ev) => {setHovered(true)}}
        onMouseLeave={(ev) => {setHovered(false)}}
        onFocus={() => setEditing(true)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            setEditing(false)
            onValueChange && onValueChange(value)
          }
        }}
        onChange={(ev) => {
          setValue(ev.target.value)
          onChange && onChange(ev)
        }}
        onBlur={()=> {setValue(_value as string); setEditing(false)}}
        value={value}
        {...otherProps}
        style={{
          flex: 1,
          // fontFamily: 'Inter, sans-serif',
          fontSize: fontSize,
          outline: 'none',
          border: 'none',
          textAlign: editing ? "left" : align,
          cursor: editing ? "text" : "default",
          borderRadius: 6,
          padding: "8 5",
          backgroundColor: editing ? "rgba(0,0,0,0.3)" : "transparent",
          ...style
        }} />
    </>
  )
})
