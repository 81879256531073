import { FireDb } from "./fire/FireDb";
import { makeObservable, observable } from "mobx";
import { IUserTeamReq } from "@openteam/models";
import { OTGlobals } from "./OTGlobals";
import { OTUITree } from "./OTUITree";
import { Database } from "firebase/database";

export class TeamAccessReqManager {
  fbDb: Database;
  teamId: string;

  @observable accessReqs: Record<string, IUserTeamReq> = {};
  @observable showReqs: boolean = false;

  constructor(fbDb: Database, teamId: string) {
    makeObservable(this)

    this.fbDb = fbDb;
    this.teamId = teamId;

    OTUITree.registerTeamAccessReqManager(this);

    FireDb.watchTeamAccessRequests(this.fbDb, this.teamId, this.handleAccessRequests);
  }

  stop = () => {
    FireDb.unwatchTeamAccessRequests(this.fbDb, this.teamId);
  };

  handleAccessRequests = (doc: Record<string, IUserTeamReq>) => {
    this.accessReqs = doc;
    var teamData = OTGlobals.getTeamData(this.teamId);
    teamData.numAccessReqs = Object.keys(doc || {}).length;
  };

  toggleShowReqs = () => {
    this.showReqs = !this.showReqs;
  };

  acceptReq = (id: string, userDoc: IUserTeamReq) => {
    FireDb.approveTeamAccessReq(this.fbDb, this.teamId, id, userDoc);
  };
  rejectReq = (id: string) => {
    FireDb.rejectTeamAccessReq(this.fbDb, this.teamId, id);
  };
}
