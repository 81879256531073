import { UIDataState } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";
import { Column, H3, H4, HSpacer, Panel, Row } from "@openteam/design-system";
import { IUIWebcamStream } from "@openteam/models";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";
import EhloLogo from '../../assets/logoe.svg?component';
import { RoomCallChat } from "../../Components/Chat/RoomCallChat";
import { DSCircleIconButton } from "../../DesignSystem/DSButton";
import { DSTheme } from "../../DesignSystem/DSTheme";
import { timeSince } from "../../Util/DateFormat";
import { CallAudio } from "./CallAudio";
import { ChatButton } from "./CallButtons/ChatButton";
import { MicButton } from "./CallButtons/MicButton";
import { ScreenShareButton } from "./CallButtons/ScreenShareButton";
import { VideoButton } from "./CallButtons/VideoButton";
import CallRoomContent from "./CallRoomContent";


const logger = new Logger("MeetCall")


interface Props {
}

export const MeetCall = observer((props: Props) => {
  const call = UIDataState.activeCall
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false)

  if (!call) {
    return null
  }

  const endCall = () => {
    call.actions.leaveCall()
    var url = new URL(window.location.href);
    url.searchParams.set('mode', "finished");

    window.location.href = url.href
  }

  return (
    <Panel style={{ height: "100vh", width: "100vw", maxHeight: "100vh", maxWidth: "100vw", backgroundColor: DSTheme.BackgroundColor, overflow: 'hidden' }}>
      <Row style={{ paddingTop: 28, paddingBottom: 8, paddingLeft: 36, paddingRight: 36, alignItems: 'center', maxWidth: "100vw" }}>

        <DSCircleIconButton
          onClick={endCall}
          icon={
            <FaPhone
              size={20}
              style={{
                color: "white",
                transform: "rotate(225deg)"
              }}
            />

          }
          title="Leave"
          analyticsEvent="leaveCall"
          style={{ backgroundColor: DSTheme.CancelColor, }}
        />
        <Column style={{ marginLeft: 16, flex: 1, }}>

          <H3 style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
            {call.name}
          </H3>
          {call?.crDate && (
            <H4
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Started {timeSince(new Date(call.crDate), " ago")}
            </H4>
          )}
        </Column>

        <Column style={{ marginRight: 16, }}>
          <EhloLogo style={{ width: 95, height: 40 }} />

        </Column>

      </Row>

      <Row style={{
        flex: 1, height: "100%", width: "100%", overflow: 'hidden'
      }}>

        <CallRoomContent />

        {

          isChatOpen ?
            <Panel style={{
              maxWidth: 500, padding: 8,
            }}>
              <RoomCallChat
                spaceId={call.spaceId}
                callId={call.id}
                callMessageManager={call.callMessageManager}
              />
            </Panel>
            :
            undefined

        }

      </Row>

      <Row style={{ justifyContent: 'center', paddingBottom: 12, }}>
        <Row style={{ padding: 6, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 999, color: 'white' }}>
          <MicButton />
          <HSpacer size={8} />
          <VideoButton />
          <HSpacer size={8} />
          <ScreenShareButton />
          <HSpacer size={8} />
          <ChatButton chatOpen={isChatOpen} onChatOpen={() => setIsChatOpen(!isChatOpen)} />
        </Row>
      </Row>

      {call.participantList.map((userId) => {
        const callUser = call?.participants[userId];
        const webcamStream = callUser?.streams["camera"] as IUIWebcamStream;
        const muted =
          callUser !== undefined &&
          (callUser.isSoundDisabled || callUser.isSameRoom || webcamStream?.muted);

        return callUser && webcamStream && !call.isCallPaused ? (
          <CallAudio
            key={`${userId}-audio`}
            stream={webcamStream.stream}
            muted={muted}
            volume={callUser.volumeFactor}
          />
        ) : null;
      })}

    </Panel>
  );
})
