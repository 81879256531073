import { UIDataState } from '@openteam/app-core';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { FaExternalLinkAlt, FaPause } from 'react-icons/fa';
// import { focusScreenShareWindow } from '../../../Data/UIState';
import { DSCircleIconButton } from '../../../DesignSystem/DSButton';
import { DSTheme } from '../../../DesignSystem/DSTheme';
import CallTileStyles from './CallTileStyles';

type Props = {
  tileHovered: boolean;
  tileSize: number;
  userId: string
};

const PopoutScreenShareButton: React.FC<Props> = (
  { tileHovered, tileSize, userId }) => {
  const call = UIDataState.activeCall!;

  return (
    <div
      style={{
        ...CallTileStyles.absolute,
        zIndex: 10,
        opacity: tileHovered || call?.isCallPaused ? 1 : 0,
        transition: 'opacity-color .15s ease-in-out',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        pointerEvents: 'none'
      }}
    >
      <DSCircleIconButton
        icon={<FaExternalLinkAlt size={Math.max(tileSize / 6, 20)} />}
        // onClick={() => focusScreenShareWindow(userId)}
        stopPropagation={{ onClick: true }}
        style={{
          backgroundColor: tileHovered ? 'rgba(0,0,0,0.5)' : undefined,
          width: Math.max(tileSize / 3, 40),
          aspectRatio: '1 / 1'
        }}
      />
    </div>
  );
};

export default observer(PopoutScreenShareButton);
