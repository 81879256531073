import { getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import { getAuth } from "firebase/auth"
import { Timestamp } from "firebase/firestore"
import { Logger } from "@openteam/app-util";
import * as uuid from "uuid";


const logger = new Logger("FireUtil");

export class FireUtils {
  static uploadUserFile = async (
    teamId: string | null,
    userId: string,
    imageKind,
    file,
    callback?: (url) => void
  ) => {

    const blob = new Blob([file], { type: file.type });
    return await FireUtils.uploadUserBlob(teamId, userId, imageKind, file.name, blob, callback)
  };

  static uploadUserBlob = async (
    teamId: string | null,
    userId: string,
    imageKind: string,
    name: string | null,
    blob: Blob,
    callback?: (url: string) => void
  ) => {
    const fileName = name || uuid.v1()
    const path = teamId ?
      `/users/${userId}/team/${teamId}/${imageKind}/${fileName}` :
      `/users/${userId}/${imageKind}/${fileName}`;

    const storageRef = ref( getStorage(), path);

    const result = await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef)
    callback && callback(url);
    return url;
  }

  static getAuthToken = async () => getAuth().currentUser?.getIdToken(true);
}


export function firestoreTimestampToDate(val: any): Date | null {
  if (val && (val instanceof Timestamp)) {
    return val.toDate();
  } else if (val instanceof Date) {
    return val;
  }
  return null;
}
