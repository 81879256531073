import { Logger } from "@openteam/app-util";
import { IPluginResource } from "@openteam/models";
import { Database, onChildAdded, onChildChanged, onChildRemoved, push, ref, set } from "firebase/database";

const logger = new Logger("PluginDb");

export class PluginDb {
  static watchRoomPlugins = (
    fbDb: Database,
    teamId: string,
    roomId: string,
    callback: (snapshot: any, deleted: any) => void
  ) => {
    var pluginsRef = ref(fbDb, `/roomdetails/${teamId}/room/${roomId}/plugins/`)

    onChildAdded(pluginsRef, (doc) => callback(doc.val(), false));
    onChildChanged(pluginsRef, (doc) => callback(doc.val(), false));
    onChildRemoved(pluginsRef, (doc) => callback(doc.val(), true));
  };

  static setRoomPlugin = (
    fbDb: Database,
    teamId: string,
    roomId: string,
    pluginId: string,
    pluginData: IPluginResource
  ) => {
    set(ref(fbDb, `/roomdetails/${teamId}/room/${roomId}/plugins/${pluginId}`), pluginData);
  };

  static genPluginId(fbDb: Database, teamId: string, roomId: string) {
    return push(ref(fbDb, `/roomdetails/${teamId}/room/${roomId}/plugins/`)).key!;
  }
}
